export const discontinuedData = [
    {
        "Make": "ABARTH",
        "Model": "500\/595\/695",
        "Variant": "ABARTH 500 1.4 3DR",
        "MM_CODES": 815150,
        "GCode": 8,
        "MCode": 15,
        "Vcode": 150,
        "Discontinued_Date": "2015-07-01 00:00:00.000"
    },
    {
        "Make": "ABARTH",
        "Model": "500\/695",
        "Variant": "ABARTH 500 1.4 3DR",
        "MM_CODES": 815150,
        "GCode": 8,
        "MCode": 15,
        "Vcode": 150,
        "Discontinued_Date": "2015-07-01 00:00:00.000"
    },
    {
        "Make": "ABARTH",
        "Model": "500\/595\/695",
        "Variant": "ABARTH 500 1.4T (595)",
        "MM_CODES": 815151,
        "GCode": 8,
        "MCode": 15,
        "Vcode": 151,
        "Discontinued_Date": "2016-12-08 00:00:00.000"
    },
    {
        "Make": "ABARTH",
        "Model": "500\/695",
        "Variant": "ABARTH 500 1.4T (595)",
        "MM_CODES": 815151,
        "GCode": 8,
        "MCode": 15,
        "Vcode": 151,
        "Discontinued_Date": "2017-01-03 00:00:00.000"
    },
    {
        "Make": "ABARTH",
        "Model": "500\/695",
        "Variant": "ABARTH 595 1.4T COMPETZIONE",
        "MM_CODES": 815170,
        "GCode": 8,
        "MCode": 15,
        "Vcode": 170,
        "Discontinued_Date": "2023-08-04 00:00:00.000"
    },
    {
        "Make": "ABARTH",
        "Model": "500\/595\/695",
        "Variant": "ABARTH 500 1.4 CABRIOLET",
        "MM_CODES": 815180,
        "GCode": 8,
        "MCode": 15,
        "Vcode": 180,
        "Discontinued_Date": "2015-07-01 00:00:00.000"
    },
    {
        "Make": "ABARTH",
        "Model": "500\/695",
        "Variant": "ABARTH 500 1.4 CABRIOLET",
        "MM_CODES": 815180,
        "GCode": 8,
        "MCode": 15,
        "Vcode": 180,
        "Discontinued_Date": "2015-07-01 00:00:00.000"
    },
    {
        "Make": "ABARTH",
        "Model": "500\/595\/695",
        "Variant": "ABARTH 500 1.4T CABRIOLET (595)",
        "MM_CODES": 815181,
        "GCode": 8,
        "MCode": 15,
        "Vcode": 181,
        "Discontinued_Date": "2017-01-03 00:00:00.000"
    },
    {
        "Make": "ABARTH",
        "Model": "500\/695",
        "Variant": "ABARTH 500 1.4T CABRIOLET (595)",
        "MM_CODES": 815181,
        "GCode": 8,
        "MCode": 15,
        "Vcode": 181,
        "Discontinued_Date": "2017-01-03 00:00:00.000"
    },
    {
        "Make": "ABARTH",
        "Model": "500\/695",
        "Variant": "ABARTH 595 1.4T TURISMO CABRIOLET",
        "MM_CODES": 815190,
        "GCode": 8,
        "MCode": 15,
        "Vcode": 190,
        "Discontinued_Date": "2022-10-10 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "GIULIETTA",
        "Variant": "GIULIETTA 1.4T PROGRESSION 5DR",
        "MM_CODES": 2045100,
        "GCode": 20,
        "MCode": 45,
        "Vcode": 100,
        "Discontinued_Date": "2016-01-07 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "GIULIETTA",
        "Variant": "GIULIETTA 1.4T DISTINCTIVE 5DR",
        "MM_CODES": 2045110,
        "GCode": 20,
        "MCode": 45,
        "Vcode": 110,
        "Discontinued_Date": "2016-09-09 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "GIULIETTA",
        "Variant": "GIULIETTA 1.8T QUAD VERDE 5DR",
        "MM_CODES": 2045200,
        "GCode": 20,
        "MCode": 45,
        "Vcode": 200,
        "Discontinued_Date": "2015-01-13 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "GIULIETTA",
        "Variant": "GIULIETTA 1.8T QUAD VERDE TCT 5DR",
        "MM_CODES": 2045210,
        "GCode": 20,
        "MCode": 45,
        "Vcode": 210,
        "Discontinued_Date": "2016-09-09 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "GIULIETTA",
        "Variant": "GIULIETTA 1750T VELOCE TCT 5DR",
        "MM_CODES": 2045211,
        "GCode": 20,
        "MCode": 45,
        "Vcode": 211,
        "Discontinued_Date": "2020-09-14 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "MITO",
        "Variant": "MITO 1.4T MULTIAIR DISTINCTIVE TCT",
        "MM_CODES": 2079110,
        "GCode": 20,
        "MCode": 79,
        "Vcode": 110,
        "Discontinued_Date": "2017-04-13 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "MITO",
        "Variant": "MITO 1.4 QV",
        "MM_CODES": 2079120,
        "GCode": 20,
        "MCode": 79,
        "Vcode": 120,
        "Discontinued_Date": "2011-09-02 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "MITO",
        "Variant": "MITO 1.4T MULTIAIR QV",
        "MM_CODES": 2079121,
        "GCode": 20,
        "MCode": 79,
        "Vcode": 121,
        "Discontinued_Date": "2017-04-13 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "159",
        "Variant": "159 3.2 DISTINCTIVE",
        "MM_CODES": 2082410,
        "GCode": 20,
        "MCode": 82,
        "Vcode": 410,
        "Discontinued_Date": "2014-01-07 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "159",
        "Variant": "159 3.2 TI",
        "MM_CODES": 2082420,
        "GCode": 20,
        "MCode": 82,
        "Vcode": 420,
        "Discontinued_Date": "2014-01-07 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "159",
        "Variant": "159 3.2 Q4 DISTINCTIVE Q-TRONIC",
        "MM_CODES": 2082430,
        "GCode": 20,
        "MCode": 82,
        "Vcode": 430,
        "Discontinued_Date": "2014-01-07 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "GIULIA",
        "Variant": "GIULIA 2.9T V6 LAUNCH EDITION QV",
        "MM_CODES": 2084400,
        "GCode": 20,
        "MCode": 84,
        "Vcode": 400,
        "Discontinued_Date": "2020-07-27 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "BRERA\/4C",
        "Variant": "4C COUPE",
        "MM_CODES": 2089410,
        "GCode": 20,
        "MCode": 89,
        "Vcode": 410,
        "Discontinued_Date": "2020-07-27 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "BRERA\/4C",
        "Variant": "4C SPIDER",
        "MM_CODES": 2089420,
        "GCode": 20,
        "MCode": 89,
        "Vcode": 420,
        "Discontinued_Date": "2020-07-27 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "STELVIO",
        "Variant": "STELVIO 2.0T SUPER",
        "MM_CODES": 2091150,
        "GCode": 20,
        "MCode": 91,
        "Vcode": 150,
        "Discontinued_Date": "2022-08-03 00:00:00.000"
    },
    {
        "Make": "ALFA ROMEO",
        "Model": "STELVIO",
        "Variant": "STELVIO 2.0T B-TECH",
        "MM_CODES": 2091160,
        "GCode": 20,
        "MCode": 91,
        "Vcode": 160,
        "Discontinued_Date": "2021-03-24 00:00:00.000"
    },
    {
        "Make": "ASTON MARTIN",
        "Model": "VANTAGE",
        "Variant": "VANTAGE COUPE S\/SHIFT",
        "MM_CODES": 3515110,
        "GCode": 35,
        "MCode": 15,
        "Vcode": 110,
        "Discontinued_Date": "2018-09-06 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A1",
        "Variant": "A1 1.2T FSI ATTRACTION 3DR",
        "MM_CODES": 4025100,
        "GCode": 40,
        "MCode": 25,
        "Vcode": 100,
        "Discontinued_Date": "2015-04-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A1",
        "Variant": "A1 SPORTBACK 1.2T FSI ATTRACTION",
        "MM_CODES": 4025110,
        "GCode": 40,
        "MCode": 25,
        "Vcode": 110,
        "Discontinued_Date": "2015-04-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A1",
        "Variant": "A1 1.4T FSI  ATTRACTION 3DR",
        "MM_CODES": 4025200,
        "GCode": 40,
        "MCode": 25,
        "Vcode": 200,
        "Discontinued_Date": "2015-04-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A1",
        "Variant": "A1 SPORTBACK 1.4T FSI  ATTRACTION",
        "MM_CODES": 4025205,
        "GCode": 40,
        "MCode": 25,
        "Vcode": 205,
        "Discontinued_Date": "2015-04-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A1",
        "Variant": "A1 1.4T FSI  ATTRACTION S-TRON 3DR",
        "MM_CODES": 4025210,
        "GCode": 40,
        "MCode": 25,
        "Vcode": 210,
        "Discontinued_Date": "2015-04-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A1",
        "Variant": "A1 SPORTBACK 1.4T FSI  ATT S-TRON",
        "MM_CODES": 4025215,
        "GCode": 40,
        "MCode": 25,
        "Vcode": 215,
        "Discontinued_Date": "2015-04-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A1",
        "Variant": "A1 SPORTBACK 1.4T FSI  AMBITION",
        "MM_CODES": 4025235,
        "GCode": 40,
        "MCode": 25,
        "Vcode": 235,
        "Discontinued_Date": "2015-04-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A1",
        "Variant": "A1 SPORTBACK 1.4T FSI SE",
        "MM_CODES": 4025236,
        "GCode": 40,
        "MCode": 25,
        "Vcode": 236,
        "Discontinued_Date": "2018-02-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A1",
        "Variant": "A1 1.4T FSI AMBIT S-TRONIC 3DR",
        "MM_CODES": 4025240,
        "GCode": 40,
        "MCode": 25,
        "Vcode": 240,
        "Discontinued_Date": "2015-04-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A1",
        "Variant": "A1 SPORTBACK 1.4T FSI  AMB S-TRON",
        "MM_CODES": 4025245,
        "GCode": 40,
        "MCode": 25,
        "Vcode": 245,
        "Discontinued_Date": "2015-04-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A1",
        "Variant": "A1 1.8T FSI SPORT S-TRONIC",
        "MM_CODES": 4025360,
        "GCode": 40,
        "MCode": 25,
        "Vcode": 360,
        "Discontinued_Date": "2018-02-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.8 TFSI AMBITION",
        "MM_CODES": 4030100,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 100,
        "Discontinued_Date": "2012-10-19 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 SPORTBACK 1.8T FSI SE",
        "MM_CODES": 4030106,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 106,
        "Discontinued_Date": "2016-09-26 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "S3 SPORTBACK",
        "MM_CODES": 4030510,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 510,
        "Discontinued_Date": "2013-12-10 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "S3 SPORTBACK STRONIC (228KW)",
        "MM_CODES": 4030514,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 514,
        "Discontinued_Date": "2021-08-04 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "S3 SPORTBACK STRONIC",
        "MM_CODES": 4030515,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 515,
        "Discontinued_Date": "2013-12-10 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "S3 SPORTBACK STRONIC",
        "MM_CODES": 4030516,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 516,
        "Discontinued_Date": "2016-09-26 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "RS3 SPORTBACK STRONIC",
        "MM_CODES": 4030517,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 517,
        "Discontinued_Date": "2012-10-19 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "RS3 SPORTBACK STRONIC",
        "MM_CODES": 4030518,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 518,
        "Discontinued_Date": "2017-10-10 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "RS3 SPORTBACK STRONIC",
        "MM_CODES": 4030519,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 519,
        "Discontinued_Date": "2021-12-03 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.8T FSI CABRIOLET",
        "MM_CODES": 4030520,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 520,
        "Discontinued_Date": "2014-05-08 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.8T FSI CABRIOLET A\/T",
        "MM_CODES": 4030525,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 525,
        "Discontinued_Date": "2014-05-08 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 2.0T FSI CABRIOLET",
        "MM_CODES": 4030545,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 545,
        "Discontinued_Date": "2014-05-08 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 2.0T FSI CABRIOLET A\/T",
        "MM_CODES": 4030550,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 550,
        "Discontinued_Date": "2014-05-08 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.0T FSI STRONIC 3DR",
        "MM_CODES": 4030557,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 557,
        "Discontinued_Date": "2020-10-01 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.2T FSI S 3DR",
        "MM_CODES": 4030570,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 570,
        "Discontinued_Date": "2016-10-14 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.4T FSI S 3DR",
        "MM_CODES": 4030625,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 625,
        "Discontinued_Date": "2016-10-14 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.4T FSI S STRONIC 3DR",
        "MM_CODES": 4030630,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 630,
        "Discontinued_Date": "2023-07-10 07:37:17.687"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.4T FSI STRONIC 3DR",
        "MM_CODES": 4030631,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 631,
        "Discontinued_Date": "2020-10-01 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.6 TDI S STRONIC 3DR",
        "MM_CODES": 4030675,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 675,
        "Discontinued_Date": "2016-10-14 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.8T FSI SE 3DR",
        "MM_CODES": 4030735,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 735,
        "Discontinued_Date": "2016-10-14 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.8T FSI SE STRONIC 3DR",
        "MM_CODES": 4030740,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 740,
        "Discontinued_Date": "2016-10-14 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.8T FSI QUATTRO STRONIC 3DR",
        "MM_CODES": 4030775,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 775,
        "Discontinued_Date": "2016-10-14 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.0T FSI STRONIC",
        "MM_CODES": 4030785,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 785,
        "Discontinued_Date": "2021-08-04 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.4T FSI S",
        "MM_CODES": 4030795,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 795,
        "Discontinued_Date": "2016-10-14 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.4T FSI S CABRIOLET",
        "MM_CODES": 4030800,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 800,
        "Discontinued_Date": "2016-10-14 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "A3 1.4T FSI SE",
        "MM_CODES": 4030820,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 820,
        "Discontinued_Date": "2016-10-14 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "S3 CABRIOLET STRONIC",
        "MM_CODES": 4030993,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 993,
        "Discontinued_Date": "2016-10-14 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "S3 CABRIOLET STRONIC",
        "MM_CODES": 4030994,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 994,
        "Discontinued_Date": "2021-06-01 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A3 2004 - ON",
        "Variant": "RS3 2.5 STRONIC",
        "MM_CODES": 4030997,
        "GCode": 40,
        "MCode": 30,
        "Vcode": 997,
        "Discontinued_Date": "2021-12-21 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q2",
        "Variant": "Q2 1.0T FSI STRONIC",
        "MM_CODES": 4033130,
        "GCode": 40,
        "MCode": 33,
        "Vcode": 130,
        "Discontinued_Date": "2021-05-13 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q2",
        "Variant": "Q2 1.0T FSI SPORT STRONIC (30 TFSI)",
        "MM_CODES": 4033140,
        "GCode": 40,
        "MCode": 33,
        "Vcode": 140,
        "Discontinued_Date": "2021-05-13 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q2",
        "Variant": "Q2 1.4T FSI STRONIC (35 TFSI)",
        "MM_CODES": 4033255,
        "GCode": 40,
        "MCode": 33,
        "Vcode": 255,
        "Discontinued_Date": "2021-05-13 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q2",
        "Variant": "Q2 1.4T FSI SPORT STRONIC",
        "MM_CODES": 4033260,
        "GCode": 40,
        "MCode": 33,
        "Vcode": 260,
        "Discontinued_Date": "2021-05-13 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q2",
        "Variant": "Q2 2.0 TDI SPORT STRONIC",
        "MM_CODES": 4033350,
        "GCode": 40,
        "MCode": 33,
        "Vcode": 350,
        "Discontinued_Date": "2018-03-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q3",
        "Variant": "Q3 1.4T FSI (110KW)",
        "MM_CODES": 4035105,
        "GCode": 40,
        "MCode": 35,
        "Vcode": 105,
        "Discontinued_Date": "2018-02-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q3",
        "Variant": "Q3 1.4T FSI STRONIC (110KW)",
        "MM_CODES": 4035110,
        "GCode": 40,
        "MCode": 35,
        "Vcode": 110,
        "Discontinued_Date": "2019-07-15 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q3",
        "Variant": "Q3 2.0T FSI QUATT (125KW)",
        "MM_CODES": 4035200,
        "GCode": 40,
        "MCode": 35,
        "Vcode": 200,
        "Discontinued_Date": "2015-05-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q3",
        "Variant": "Q3 2.0T FSI QUATT STRONIC (125KW)",
        "MM_CODES": 4035220,
        "GCode": 40,
        "MCode": 35,
        "Vcode": 220,
        "Discontinued_Date": "2015-05-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q3",
        "Variant": "Q3 2.0T FSI QUATT STRONIC (132KW)",
        "MM_CODES": 4035221,
        "GCode": 40,
        "MCode": 35,
        "Vcode": 221,
        "Discontinued_Date": "2018-02-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q3",
        "Variant": "Q3 2.0T FSI QUATT STRONIC (155KW)",
        "MM_CODES": 4035250,
        "GCode": 40,
        "MCode": 35,
        "Vcode": 250,
        "Discontinued_Date": "2015-05-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q3",
        "Variant": "RS Q3 2.5 TFSI STRONIC",
        "MM_CODES": 4035500,
        "GCode": 40,
        "MCode": 35,
        "Vcode": 500,
        "Discontinued_Date": "2016-01-11 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q3",
        "Variant": "RS Q3 2.5 TFSI STRONIC",
        "MM_CODES": 4035501,
        "GCode": 40,
        "MCode": 35,
        "Vcode": 501,
        "Discontinued_Date": "2019-06-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 1.8T SE",
        "MM_CODES": 4042121,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 121,
        "Discontinued_Date": "2016-02-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 1.8T SE MULTITRONIC",
        "MM_CODES": 4042131,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 131,
        "Discontinued_Date": "2016-02-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 1.8T S AVANT MULTITRONIC",
        "MM_CODES": 4042151,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 151,
        "Discontinued_Date": "2016-02-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0T SE MULTITRONIC",
        "MM_CODES": 4042243,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 243,
        "Discontinued_Date": "2013-09-06 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0 TFSI SE MULTI (165KW)",
        "MM_CODES": 4042244,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 244,
        "Discontinued_Date": "2016-02-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 ALLROAD 2.0 TFSI QUATT S-TRONIC",
        "MM_CODES": 4042265,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 265,
        "Discontinued_Date": "2018-11-07 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0 TFSI QUATT STRONIC 155KW",
        "MM_CODES": 4042270,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 270,
        "Discontinued_Date": "2012-03-01 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0T QUATTRO STRONIC",
        "MM_CODES": 4042271,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 271,
        "Discontinued_Date": "2013-09-06 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0 TFSI SE QUATTRO STRON (165KW)",
        "MM_CODES": 4042272,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 272,
        "Discontinued_Date": "2016-02-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0 TDI AMBITION 100KW (B8)",
        "MM_CODES": 4042275,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 275,
        "Discontinued_Date": "2012-03-01 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0 TDI S 105KW",
        "MM_CODES": 4042281,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 281,
        "Discontinued_Date": "2016-02-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0 TDI S  MULTITRONIC",
        "MM_CODES": 4042355,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 355,
        "Discontinued_Date": "2016-02-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0 TDI S AVANT",
        "MM_CODES": 4042360,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 360,
        "Discontinued_Date": "2016-02-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0 TDI SE",
        "MM_CODES": 4042370,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 370,
        "Discontinued_Date": "2016-02-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0 TDI SE MULTITRONIC",
        "MM_CODES": 4042375,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 375,
        "Discontinued_Date": "2016-02-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 ALLROAD 2.0 TDI QUATT S-TRONIC",
        "MM_CODES": 4042380,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 380,
        "Discontinued_Date": "2018-10-11 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON",
        "Variant": "A4 3.0 TDI QUATT AMBIENT STRON (B8)",
        "MM_CODES": 4042393,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 393,
        "Discontinued_Date": "2018-01-31 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 3.0 TDI QUATT AMBIENT STRON (B8)",
        "MM_CODES": 4042393,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 393,
        "Discontinued_Date": "2012-03-01 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 3.0 TDI QUATTRO STRONIC",
        "MM_CODES": 4042394,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 394,
        "Discontinued_Date": "2016-02-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON",
        "Variant": "S4 3.0 TFSI QUATTRO (B8)",
        "MM_CODES": 4042450,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 450,
        "Discontinued_Date": "2021-10-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "S4 3.0 TFSI QUATTRO (B8)",
        "MM_CODES": 4042450,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 450,
        "Discontinued_Date": "2012-03-01 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "S4 3.0 TFSI QUATTRO STRONIC",
        "MM_CODES": 4042460,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 460,
        "Discontinued_Date": "2012-03-01 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "S4 3.0T AVANT QUATTRO STRONIC",
        "MM_CODES": 4042470,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 470,
        "Discontinued_Date": "2016-02-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 1.4T FSI (B9)",
        "MM_CODES": 4042495,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 495,
        "Discontinued_Date": "2018-02-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 1.4T FSI DESIGN STRONIC (B9)",
        "MM_CODES": 4042510,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 510,
        "Discontinued_Date": "2018-02-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 1.4T FSI SPORT STRONIC (B9)",
        "MM_CODES": 4042515,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 515,
        "Discontinued_Date": "2020-08-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0T FSI STRONIC (B9)",
        "MM_CODES": 4042665,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 665,
        "Discontinued_Date": "2020-09-18 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0T FSI DESIGN STRONIC (B9)",
        "MM_CODES": 4042670,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 670,
        "Discontinued_Date": "2018-02-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0T FSI SPORT STRONIC (B9)",
        "MM_CODES": 4042675,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 675,
        "Discontinued_Date": "2020-08-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0 TDI STRONIC (B9)",
        "MM_CODES": 4042710,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 710,
        "Discontinued_Date": "2020-08-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0 TDI DESIGN STRONIC (B9)",
        "MM_CODES": 4042715,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 715,
        "Discontinued_Date": "2018-02-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "A4 2.0 TDI SPORT STRONIC (B9) (40 TDI)",
        "MM_CODES": 4042720,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 720,
        "Discontinued_Date": "2020-08-20 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A4 (B8) 2008 - ON (B9)",
        "Variant": "S4 3.0 TFSI QUATTRO TIPTRONIC",
        "MM_CODES": 4042800,
        "GCode": 40,
        "MCode": 42,
        "Vcode": 800,
        "Discontinued_Date": "2020-12-17 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "Q5 2.0 T FSI QUATTRO (132KW)",
        "MM_CODES": 4054210,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 210,
        "Discontinued_Date": "2012-12-11 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "Q5 2.0T FSI S QUATTRO",
        "MM_CODES": 4054211,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 211,
        "Discontinued_Date": "2017-06-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "Q5 2.0 TFSI QUATTRO TIP (155KW)",
        "MM_CODES": 4054221,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 221,
        "Discontinued_Date": "2012-12-11 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "Q5 2.0 TFSI SE QUATTRO TIP",
        "MM_CODES": 4054222,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 222,
        "Discontinued_Date": "2017-06-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "2.0 TFSI QUATTRO STRONIC ",
        "MM_CODES": 4054223,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 223,
        "Discontinued_Date": "2021-08-13 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "Q5 2.0 TSFI QUATTRO STRONIC SPORT",
        "MM_CODES": 4054230,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 230,
        "Discontinued_Date": "2021-08-13 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "Q5 2.0 TDI S QUATTRO",
        "MM_CODES": 4054301,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 301,
        "Discontinued_Date": "2016-04-15 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "Q5 2.0 TDI QUATTRO S TRONIC",
        "MM_CODES": 4054310,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 310,
        "Discontinued_Date": "2012-12-11 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "Q5 2.0 TDI S QUATTRO S TRONIC",
        "MM_CODES": 4054311,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 311,
        "Discontinued_Date": "2017-06-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "Q5 2.0 TDI QUATTRO STRONIC",
        "MM_CODES": 4054312,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 312,
        "Discontinued_Date": "2021-08-13 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "Q5 2.0 TDI QUATTRO STRONIC SPORT",
        "MM_CODES": 4054320,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 320,
        "Discontinued_Date": "2021-08-13 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "Q5 3.0 TDI SE QUATTRO STRONIC",
        "MM_CODES": 4054401,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 401,
        "Discontinued_Date": "2017-06-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "Q5 3.0 TFSI SE QUATTRO TIP",
        "MM_CODES": 4054461,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 461,
        "Discontinued_Date": "2017-06-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "SQ5 3.0 TFSI QUATTRO TIPTRONIC",
        "MM_CODES": 4054470,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 470,
        "Discontinued_Date": "2021-08-13 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "SQ5 3.0TDI QUATTRO STRONIC",
        "MM_CODES": 4054520,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 520,
        "Discontinued_Date": "2016-02-09 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q5",
        "Variant": "SQ5 3.0TDI QUATTRO STRONIC",
        "MM_CODES": 4054521,
        "GCode": 40,
        "MCode": 54,
        "Vcode": 521,
        "Discontinued_Date": "2018-03-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 SPORTBACK 1.8TFSI",
        "MM_CODES": 4084100,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 100,
        "Discontinued_Date": "2017-04-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 2.0T FSI STRONIC SPORT QUATT (185KW) (45 TFSI)",
        "MM_CODES": 4084214,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 214,
        "Discontinued_Date": "2020-12-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 2.0 TDI MULTI",
        "MM_CODES": 4084215,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 215,
        "Discontinued_Date": "2017-03-06 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 2.0 TDI STRONIC SPORT (40 TDI)",
        "MM_CODES": 4084217,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 217,
        "Discontinued_Date": "2020-12-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 2.0 TDI STRONIC SPORT QUATTRO (40 TDI)",
        "MM_CODES": 4084219,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 219,
        "Discontinued_Date": "2020-12-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 SPORTBACK 2.0T FSI MULTI",
        "MM_CODES": 4084221,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 221,
        "Discontinued_Date": "2014-02-07 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 SPRTBACK 2.0 TFSI MULTI (165KW)",
        "MM_CODES": 4084222,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 222,
        "Discontinued_Date": "2017-04-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 SPORTBACK 2.0T FSI STRONIC SPORT (40 TFSI)",
        "MM_CODES": 4084227,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 227,
        "Discontinued_Date": "2020-12-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 SPRTBACK 2.0 TFSI QUATT STRON (165KW)",
        "MM_CODES": 4084232,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 232,
        "Discontinued_Date": "2017-04-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 SPORTBACK 2.0T FSI STRONIC SPORT QUAT(45TFSI)",
        "MM_CODES": 4084234,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 234,
        "Discontinued_Date": "2020-12-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 SPRTBACK 2.0 TDI MULTI",
        "MM_CODES": 4084235,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 235,
        "Discontinued_Date": "2017-04-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 SPORTBACK 2.0T FSI STRONIC SPORT",
        "MM_CODES": 4084237,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 237,
        "Discontinued_Date": "2020-12-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 SPORTBACK 2.0 TDI STRONIC SPORT QUATT (40 TDI)",
        "MM_CODES": 4084239,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 239,
        "Discontinued_Date": "2020-12-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 3.0 TDI QUATTRO STRONIC",
        "MM_CODES": 4084241,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 241,
        "Discontinued_Date": "2012-01-26 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 3.0 TDI QUATT STRONIC",
        "MM_CODES": 4084242,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 242,
        "Discontinued_Date": "2017-03-06 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 SPRTBACK 3.0TDI QUATT STRNIC",
        "MM_CODES": 4084250,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 250,
        "Discontinued_Date": "2012-01-26 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 SPRTBACK 3.0 TDI QUATT STRNIC",
        "MM_CODES": 4084251,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 251,
        "Discontinued_Date": "2017-04-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 3.0T FSI Q STRONIC",
        "MM_CODES": 4084261,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 261,
        "Discontinued_Date": "2019-05-07 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 CABRIOLET 1.8TFSI MULTI",
        "MM_CODES": 4084460,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 460,
        "Discontinued_Date": "2017-07-05 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 2.0 TFSI CABRIOLET",
        "MM_CODES": 4084500,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 500,
        "Discontinued_Date": "2012-01-26 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 2.0 TFSI CABRIOLET MTRONIC",
        "MM_CODES": 4084510,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 510,
        "Discontinued_Date": "2012-01-26 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 2.0 TFSI CAB MULTI",
        "MM_CODES": 4084511,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 511,
        "Discontinued_Date": "2013-09-06 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 2.0 TFSI CAB MULTI (165KW)",
        "MM_CODES": 4084512,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 512,
        "Discontinued_Date": "2017-07-05 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 2.0T FSI CABRIOLET STRONIC",
        "MM_CODES": 4084513,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 513,
        "Discontinued_Date": "2019-04-05 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 2.0T FSI CABRIOLET SPORT STRONIC (40 TFSI)",
        "MM_CODES": 4084516,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 516,
        "Discontinued_Date": "2020-12-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 2.0 TFSI QUATT CAB STRONIC",
        "MM_CODES": 4084520,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 520,
        "Discontinued_Date": "2012-01-26 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 2.0 TFSI QUATT CAB STRONIC",
        "MM_CODES": 4084521,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 521,
        "Discontinued_Date": "2013-09-06 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 2.0 TFSI QUATT CAB STRONIC (165KW)",
        "MM_CODES": 4084522,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 522,
        "Discontinued_Date": "2017-07-05 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 2.0 TDI CAB MULTI",
        "MM_CODES": 4084530,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 530,
        "Discontinued_Date": "2017-07-05 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 3.0 TDI QUATTRO CAB STRONIC",
        "MM_CODES": 4084550,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 550,
        "Discontinued_Date": "2012-01-26 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 3.0 TDI QUATTRO CAB STRONIC",
        "MM_CODES": 4084551,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 551,
        "Discontinued_Date": "2016-06-06 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 3.2 FSI QUATTRO CAB STRONIC",
        "MM_CODES": 4084590,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 590,
        "Discontinued_Date": "2012-01-26 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 3.0T FSI QUATTRO CAB STRONIC",
        "MM_CODES": 4084591,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 591,
        "Discontinued_Date": "2017-04-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "A5 S5 QUATTRO CAB STRONIC",
        "MM_CODES": 4084650,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 650,
        "Discontinued_Date": "2012-01-26 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "S5 CAB 3.0 TFSI QUATT STRNIC",
        "MM_CODES": 4084651,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 651,
        "Discontinued_Date": "2017-04-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "S5 CAB 3.0T FSI QUATT TIP",
        "MM_CODES": 4084652,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 652,
        "Discontinued_Date": "2020-12-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "RS5 COUPE QUATTRO STRONIC",
        "MM_CODES": 4084750,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 750,
        "Discontinued_Date": "2012-01-26 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "RS5 COUPE QUATTRO STRONIC",
        "MM_CODES": 4084751,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 751,
        "Discontinued_Date": "2017-04-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "RS5 COUPE QUATTRO TIP",
        "MM_CODES": 4084752,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 752,
        "Discontinued_Date": "2020-12-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A5",
        "Variant": "RS5  QUATTRO CAB STRONIC",
        "MM_CODES": 4084860,
        "GCode": 40,
        "MCode": 84,
        "Vcode": 860,
        "Discontinued_Date": "2017-04-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A6 2004 - ON",
        "Variant": "A6 4.2 FSI QUATTRO TIP",
        "MM_CODES": 4086351,
        "GCode": 40,
        "MCode": 86,
        "Vcode": 351,
        "Discontinued_Date": "2009-03-10 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A6 2004 - ON",
        "Variant": "A6 1.8T FSI STRONIC",
        "MM_CODES": 4086370,
        "GCode": 40,
        "MCode": 86,
        "Vcode": 370,
        "Discontinued_Date": "2020-01-17 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A6 2004 - ON",
        "Variant": "A6 2.0 TFSI MULTITRONIC",
        "MM_CODES": 4086380,
        "GCode": 40,
        "MCode": 86,
        "Vcode": 380,
        "Discontinued_Date": "2011-06-21 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A6 2004 - ON",
        "Variant": "A6 2.0 TFSI MULTITRONIC",
        "MM_CODES": 4086381,
        "GCode": 40,
        "MCode": 86,
        "Vcode": 381,
        "Discontinued_Date": "2015-03-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A6 2004 - ON",
        "Variant": "A6 2.0T FSI QUATTRO S TRONIC",
        "MM_CODES": 4086400,
        "GCode": 40,
        "MCode": 86,
        "Vcode": 400,
        "Discontinued_Date": "2018-02-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A6 2004 - ON",
        "Variant": "A6 2.0 TDI MULTITRONIC",
        "MM_CODES": 4086420,
        "GCode": 40,
        "MCode": 86,
        "Vcode": 420,
        "Discontinued_Date": "2015-03-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A6 2004 - ON",
        "Variant": "A6 2.0 TDI STRONIC",
        "MM_CODES": 4086421,
        "GCode": 40,
        "MCode": 86,
        "Vcode": 421,
        "Discontinued_Date": "2020-01-17 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A6 2004 - ON",
        "Variant": "A6 3.0T FSI QUAT S TRONIC (228KW)",
        "MM_CODES": 4086491,
        "GCode": 40,
        "MCode": 86,
        "Vcode": 491,
        "Discontinued_Date": "2015-03-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A6 2004 - ON",
        "Variant": "A6 3.0 TDI MULTITRONIC",
        "MM_CODES": 4086511,
        "GCode": 40,
        "MCode": 86,
        "Vcode": 511,
        "Discontinued_Date": "2015-03-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A6 2004 - ON",
        "Variant": "A6 3.0 TDI  S TRONIC",
        "MM_CODES": 4086512,
        "GCode": 40,
        "MCode": 86,
        "Vcode": 512,
        "Discontinued_Date": "2018-02-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A6 2004 - ON",
        "Variant": "A6 3.0 TDI  QUAT S TRONIC (180KW)",
        "MM_CODES": 4086520,
        "GCode": 40,
        "MCode": 86,
        "Vcode": 520,
        "Discontinued_Date": "2015-03-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A6 2004 - ON",
        "Variant": "S6 QUATTRO 4.0T FSI STRONIC",
        "MM_CODES": 4086671,
        "GCode": 40,
        "MCode": 86,
        "Vcode": 671,
        "Discontinued_Date": "2019-06-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A7",
        "Variant": "A7 SPRTBACK 3.0T FSI QUAT STRON",
        "MM_CODES": 4088320,
        "GCode": 40,
        "MCode": 88,
        "Vcode": 320,
        "Discontinued_Date": "2015-03-26 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A7",
        "Variant": "A7 SPRTBACK 3.0BITDI  (230KW)",
        "MM_CODES": 4088390,
        "GCode": 40,
        "MCode": 88,
        "Vcode": 390,
        "Discontinued_Date": "2015-03-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A7",
        "Variant": "A7 SPORTBACK 3.0TDI  QUAT STRONIC",
        "MM_CODES": 4088401,
        "GCode": 40,
        "MCode": 88,
        "Vcode": 401,
        "Discontinued_Date": "2019-10-09 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A7",
        "Variant": "S7 SPORTBACK 4.0T FSI",
        "MM_CODES": 4088550,
        "GCode": 40,
        "MCode": 88,
        "Vcode": 550,
        "Discontinued_Date": "2015-03-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A7",
        "Variant": "S7 SPORTBACK 4.0T FSI",
        "MM_CODES": 4088551,
        "GCode": 40,
        "MCode": 88,
        "Vcode": 551,
        "Discontinued_Date": "2019-06-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A7",
        "Variant": "RS 7 SPORTBACK 4.0T FSI",
        "MM_CODES": 4088560,
        "GCode": 40,
        "MCode": 88,
        "Vcode": 560,
        "Discontinued_Date": "2015-03-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A7",
        "Variant": "RS 7 SPORTBACK 4.0T FSI (412KW)",
        "MM_CODES": 4088561,
        "GCode": 40,
        "MCode": 88,
        "Vcode": 561,
        "Discontinued_Date": "2019-06-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT 1.8T FSI COUPE",
        "MM_CODES": 4091330,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 330,
        "Discontinued_Date": "2015-10-07 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT 1.8T FSI COUPE  STRONIC",
        "MM_CODES": 4091340,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 340,
        "Discontinued_Date": "2015-10-07 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT 1.8T FSI COUPE STRONIC",
        "MM_CODES": 4091341,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 341,
        "Discontinued_Date": "2020-02-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT 2.0T FSI COUPE",
        "MM_CODES": 4091401,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 401,
        "Discontinued_Date": "2015-01-19 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT 2.0T FSI QUATT COUPE STRONIC",
        "MM_CODES": 4091420,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 420,
        "Discontinued_Date": "2015-01-19 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT 2.0T FSI COUPE STRONIC",
        "MM_CODES": 4091441,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 441,
        "Discontinued_Date": "2015-01-19 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT 2.0 TFSI COUPE S TRONIC",
        "MM_CODES": 4091442,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 442,
        "Discontinued_Date": "2020-02-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT 2.0 TFSI COUPE  S TRONIC (45 TFSI)",
        "MM_CODES": 4091443,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 443,
        "Discontinued_Date": "2023-02-28 08:30:54.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT 2.0 TFSI QUATT COUPE S TRONIC",
        "MM_CODES": 4091455,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 455,
        "Discontinued_Date": "2018-02-28 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT 2.0T FSI ROADSTER",
        "MM_CODES": 4091461,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 461,
        "Discontinued_Date": "2015-10-07 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT 2.0T FSI ROADSTER STRONIC",
        "MM_CODES": 4091481,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 481,
        "Discontinued_Date": "2015-10-07 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT 2.0T FSI QUATT ROADSTER STRONIC",
        "MM_CODES": 4091500,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 500,
        "Discontinued_Date": "2015-10-07 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TTS QUATTRO COUPE S TRONIC (228KW)",
        "MM_CODES": 4091731,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 731,
        "Discontinued_Date": "2020-02-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT RS QUATTRO COUPE",
        "MM_CODES": 4091800,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 800,
        "Discontinued_Date": "2014-09-09 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT RS QUATTRO COUPE STRONIC",
        "MM_CODES": 4091810,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 810,
        "Discontinued_Date": "2020-02-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT RS QUATTRO ROADSTER",
        "MM_CODES": 4091820,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 820,
        "Discontinued_Date": "2014-02-07 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "TT",
        "Variant": "TT RS QUATTRO ROADSTER STRONIC",
        "MM_CODES": 4091830,
        "GCode": 40,
        "MCode": 91,
        "Vcode": 830,
        "Discontinued_Date": "2015-10-07 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A8 2004 - ON",
        "Variant": "A8 3.0 TDI QUATTRO",
        "MM_CODES": 4093102,
        "GCode": 40,
        "MCode": 93,
        "Vcode": 102,
        "Discontinued_Date": "2021-06-01 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A8 2004 - ON",
        "Variant": "A8 4.2 TDI QUATTRO",
        "MM_CODES": 4093180,
        "GCode": 40,
        "MCode": 93,
        "Vcode": 180,
        "Discontinued_Date": "2014-04-08 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A8 2004 - ON",
        "Variant": "A8 4.2 TDI QUATTRO",
        "MM_CODES": 4093181,
        "GCode": 40,
        "MCode": 93,
        "Vcode": 181,
        "Discontinued_Date": "2021-06-01 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A8 2004 - ON",
        "Variant": "A8 4.2 TDI  QUATTRO LWB",
        "MM_CODES": 4093190,
        "GCode": 40,
        "MCode": 93,
        "Vcode": 190,
        "Discontinued_Date": "2021-06-01 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A8 2004 - ON",
        "Variant": "S8 QUATTRO A\/T",
        "MM_CODES": 4093201,
        "GCode": 40,
        "MCode": 93,
        "Vcode": 201,
        "Discontinued_Date": "2014-04-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A8 2004 - ON",
        "Variant": "S8 QUATTRO TIP",
        "MM_CODES": 4093202,
        "GCode": 40,
        "MCode": 93,
        "Vcode": 202,
        "Discontinued_Date": "2021-03-18 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "A8 2004 - ON",
        "Variant": "A8 6.3 QUAT TIP (W12)",
        "MM_CODES": 4093300,
        "GCode": 40,
        "MCode": 93,
        "Vcode": 300,
        "Discontinued_Date": "2014-04-25 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q7",
        "Variant": "Q7 3.0 TDI V6 QUATTRO TIP",
        "MM_CODES": 4095101,
        "GCode": 40,
        "MCode": 95,
        "Vcode": 101,
        "Discontinued_Date": "2015-10-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q7",
        "Variant": "Q7 3.0 TDI V6 QUATTRO TIP",
        "MM_CODES": 4095102,
        "GCode": 40,
        "MCode": 95,
        "Vcode": 102,
        "Discontinued_Date": "2020-10-19 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q7",
        "Variant": "Q7 4.2 TDI V8 QUATTRO TIP",
        "MM_CODES": 4095221,
        "GCode": 40,
        "MCode": 95,
        "Vcode": 221,
        "Discontinued_Date": "2015-10-02 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q7",
        "Variant": "Q7 6.0 TDI V12 QUATTRO TIP",
        "MM_CODES": 4095250,
        "GCode": 40,
        "MCode": 95,
        "Vcode": 250,
        "Discontinued_Date": "2013-04-16 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "Q8",
        "Variant": "SQ8 TDI QUATTRO TIP",
        "MM_CODES": 4097400,
        "GCode": 40,
        "MCode": 97,
        "Vcode": 400,
        "Discontinued_Date": "2023-02-28 08:38:46.000"
    },
    {
        "Make": "AUDI",
        "Model": "R8",
        "Variant": "R8 4.2 FSI QUATTRO S TRONIC",
        "MM_CODES": 4098142,
        "GCode": 40,
        "MCode": 98,
        "Vcode": 142,
        "Discontinued_Date": "2016-08-05 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "R8",
        "Variant": "R8 4.2 FSI QUATTRO SPYDER S TRONIC",
        "MM_CODES": 4098170,
        "GCode": 40,
        "MCode": 98,
        "Vcode": 170,
        "Discontinued_Date": "2016-08-05 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "R8",
        "Variant": "R8 5.2 FSI QUATTRO A\/T",
        "MM_CODES": 4098230,
        "GCode": 40,
        "MCode": 98,
        "Vcode": 230,
        "Discontinued_Date": "2013-03-07 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "R8",
        "Variant": "R8 5.2 FSI QUATTRO S TRONIC (PLUS)",
        "MM_CODES": 4098250,
        "GCode": 40,
        "MCode": 98,
        "Vcode": 250,
        "Discontinued_Date": "2016-08-05 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "R8",
        "Variant": "R8 5.2 FSI QUATTRO SPYDER S TRONIC",
        "MM_CODES": 4098331,
        "GCode": 40,
        "MCode": 98,
        "Vcode": 331,
        "Discontinued_Date": "2016-08-05 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "R8",
        "Variant": "R8 5.2 FSI V10 QUATTRO SPYDER S TRONIC",
        "MM_CODES": 4098332,
        "GCode": 40,
        "MCode": 98,
        "Vcode": 332,
        "Discontinued_Date": "2020-12-04 00:00:00.000"
    },
    {
        "Make": "AUDI",
        "Model": "R8",
        "Variant": "R8 5.2 FSI GT QUATTRO RTRONIC",
        "MM_CODES": 4098400,
        "GCode": 40,
        "MCode": 98,
        "Vcode": 400,
        "Discontinued_Date": "2013-04-16 00:00:00.000"
    },
    {
        "Make": "B.A.W",
        "Model": "SASUKA\/TOURLUX",
        "Variant": "SASUKA 2.7I (16 SEAT)",
        "MM_CODES": 4614100,
        "GCode": 46,
        "MCode": 14,
        "Vcode": 100,
        "Discontinued_Date": "2019-04-11 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "116I 5DR (F20)",
        "MM_CODES": 5011106,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 106,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "116I 5DR (F20)",
        "MM_CODES": 5011106,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 106,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "116I 5DR (F20)",
        "MM_CODES": 5011106,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 106,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "116I URBAN LINE 5DR (F20)",
        "MM_CODES": 5011145,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 145,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "116I URBAN LINE 5DR (F20)",
        "MM_CODES": 5011145,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 145,
        "Discontinued_Date": "2021-09-29 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "116I SPORT LINE 5DR (F20)",
        "MM_CODES": 5011166,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 166,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "116I SPORT LINE 5DR (F20)",
        "MM_CODES": 5011166,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 166,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "116I SPORT LINE 5DR (F20)",
        "MM_CODES": 5011166,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 166,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "116I URBAN LINE 5DR A\/T (F20)",
        "MM_CODES": 5011185,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 185,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "116I URBAN LINE 5DR A\/T (F20)",
        "MM_CODES": 5011185,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 185,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "116I URBAN LINE 5DR A\/T (F20)",
        "MM_CODES": 5011185,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 185,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "116I SPORT LINE 5DR A\/T (F20)",
        "MM_CODES": 5011191,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 191,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "116I SPORT LINE 5DR A\/T (F20)",
        "MM_CODES": 5011191,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 191,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "116I SPORT LINE 5DR A\/T (F20)",
        "MM_CODES": 5011191,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 191,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "116I M SPORT 5DR A\/T (F20)",
        "MM_CODES": 5011193,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 193,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "116I M SPORT 5DR A\/T (F20)",
        "MM_CODES": 5011193,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 193,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "118I 3DR (E81)",
        "MM_CODES": 5011200,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 200,
        "Discontinued_Date": "2011-09-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I 3DR (E81)",
        "MM_CODES": 5011200,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 200,
        "Discontinued_Date": "2011-09-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "118I 3DR (E81)",
        "MM_CODES": 5011200,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 200,
        "Discontinued_Date": "2011-09-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "118I 3DR (F21)",
        "MM_CODES": 5011201,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 201,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I 3DR (F21)",
        "MM_CODES": 5011201,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 201,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "118I 3DR (F21)",
        "MM_CODES": 5011201,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 201,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I SPORT LINE 3DR (F21)",
        "MM_CODES": 5011221,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 221,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I M SPORT 3DR (F21)",
        "MM_CODES": 5011224,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 224,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "118I M SPORT 3DR (F21)",
        "MM_CODES": 5011224,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 224,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I 3DR A\/T (F21)",
        "MM_CODES": 5011231,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 231,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "118I 3DR A\/T (F21)",
        "MM_CODES": 5011231,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 231,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "118I URBAN LINE 3DR A\/T (F21)",
        "MM_CODES": 5011245,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 245,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I SPORT LINE 3DR A\/T (F21)",
        "MM_CODES": 5011251,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 251,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I M SPORT 3DR A\/T (F21)",
        "MM_CODES": 5011254,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 254,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "118I M SPORT 3DR A\/T (F21)",
        "MM_CODES": 5011254,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 254,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "118I 5DR (F20)",
        "MM_CODES": 5011307,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 307,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I 5DR (F20)",
        "MM_CODES": 5011307,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 307,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "118I 5DR (F20)",
        "MM_CODES": 5011307,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 307,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "118I 5DR (F20)",
        "MM_CODES": 5011308,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 308,
        "Discontinued_Date": "2019-04-11 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I 5DR (F20)",
        "MM_CODES": 5011308,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 308,
        "Discontinued_Date": "2019-04-11 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "118I 5DR (F20)",
        "MM_CODES": 5011308,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 308,
        "Discontinued_Date": "2019-04-11 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I 5DR A\/T (F20)",
        "MM_CODES": 5011319,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 319,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I URBAN LINE 5DR (F20)",
        "MM_CODES": 5011341,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 341,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "118I URBAN LINE 5DR (F20)",
        "MM_CODES": 5011341,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 341,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "118I URBAN LINE 5DR A\/T (F20)",
        "MM_CODES": 5011355,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 355,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I URBAN LINE 5DR A\/T (F20)",
        "MM_CODES": 5011356,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 356,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "118I SPORT LINE 5DR (F20)",
        "MM_CODES": 5011367,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 367,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I SPORT LINE 5DR (F20)",
        "MM_CODES": 5011367,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 367,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "118I SPORT LINE 5DR (F20)",
        "MM_CODES": 5011367,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 367,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I M SPORT 5DR (F20)",
        "MM_CODES": 5011369,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 369,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "118I M SPORT 5DR (F20)",
        "MM_CODES": 5011369,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 369,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I M SPORT 5DR (F20)",
        "MM_CODES": 5011370,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 370,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "118I SPORT LINE 5DR A\/T (F20) ",
        "MM_CODES": 5011378,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 378,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I SPORT LINE 5DR A\/T (F20) ",
        "MM_CODES": 5011378,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 378,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "118I SPORT LINE 5DR A\/T (F20)",
        "MM_CODES": 5011378,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 378,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "118I M SPORT LINE 5DR A\/T (F20)",
        "MM_CODES": 5011379,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 379,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "118I M SPORT LINE 5DR A\/T (F20)",
        "MM_CODES": 5011379,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 379,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "118I M SPORT LINE 5DR A\/T (F20)",
        "MM_CODES": 5011379,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 379,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120I 3DR (E81)",
        "MM_CODES": 5011400,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 400,
        "Discontinued_Date": "2011-09-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "120I 3DR (E81)",
        "MM_CODES": 5011400,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 400,
        "Discontinued_Date": "2011-09-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120I M SPORT 3DR A\/T (F21)",
        "MM_CODES": 5011456,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 456,
        "Discontinued_Date": "2016-11-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120I (E87)",
        "MM_CODES": 5011505,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 505,
        "Discontinued_Date": "2007-03-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "120I (E87)",
        "MM_CODES": 5011505,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 505,
        "Discontinued_Date": "2007-03-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120I A\/T (E87)",
        "MM_CODES": 5011516,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 516,
        "Discontinued_Date": "2007-03-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "120I A\/T (E87)",
        "MM_CODES": 5011516,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 516,
        "Discontinued_Date": "2007-03-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120I 5DR A\/T (F20)",
        "MM_CODES": 5011518,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 518,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120I URBAN LINE 5DR A\/T (F20)",
        "MM_CODES": 5011548,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 548,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120I M SPORT 5DR (F20)",
        "MM_CODES": 5011571,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 571,
        "Discontinued_Date": "2018-04-06 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "120I M SPORT 5DR (F20)",
        "MM_CODES": 5011571,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 571,
        "Discontinued_Date": "2018-04-06 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120I SPORT A\/T (E87)",
        "MM_CODES": 5011577,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 577,
        "Discontinued_Date": "2011-09-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120I M SPORT 5DR A\/T (F20)",
        "MM_CODES": 5011582,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 582,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120D 5DR (F20)",
        "MM_CODES": 5011608,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 608,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "120D 5DR A\/T (F20)",
        "MM_CODES": 5011618,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 618,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120D 5DR A\/T (F20)",
        "MM_CODES": 5011618,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 618,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "120D 5DR A\/T (F20)",
        "MM_CODES": 5011618,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 618,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120D 5DR A\/T (F20)",
        "MM_CODES": 5011619,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 619,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120D M SPORT 5DR (F20)",
        "MM_CODES": 5011669,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 669,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "120D M SPORT 5DR (F20)",
        "MM_CODES": 5011669,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 669,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120D SPORT LINE 5DR A\/T (F20)",
        "MM_CODES": 5011670,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 670,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120D SPORT LINE 5DR A\/T (F20)",
        "MM_CODES": 5011678,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 678,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "120D SPORT LINE 5DR A\/T (F20)",
        "MM_CODES": 5011678,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 678,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120D M SPORT 5DR A\/T (F20)",
        "MM_CODES": 5011679,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 679,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "120D M SPORT 5DR A\/T (F20)",
        "MM_CODES": 5011679,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 679,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "125I 3DR (F21)",
        "MM_CODES": 5011680,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 680,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "125I 3DR (F21)",
        "MM_CODES": 5011680,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 680,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "125I 5DR (F20)",
        "MM_CODES": 5011681,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 681,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "125I 5DR (F20)",
        "MM_CODES": 5011681,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 681,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "125I 5DR (F20)",
        "MM_CODES": 5011681,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 681,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "125I SPORT LINE 3DR (F21)",
        "MM_CODES": 5011684,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 684,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "125I SPORT LINE 5DR (F20)",
        "MM_CODES": 5011685,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 685,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "125I SPORT LINE 5DR (F20)",
        "MM_CODES": 5011685,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 685,
        "Discontinued_Date": "2019-12-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "125I M SPORT 5DR (F20)",
        "MM_CODES": 5011687,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 687,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "125I M SPORT 5DR (F20)",
        "MM_CODES": 5011687,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 687,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "125I M SPORT 5DR (F20)",
        "MM_CODES": 5011687,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 687,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "125I  A\/T 3DR (F21)",
        "MM_CODES": 5011688,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 688,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "125I  A\/T 3DR (F21)",
        "MM_CODES": 5011688,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 688,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "125I A\/T 5DR (F20)",
        "MM_CODES": 5011689,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 689,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "125I A\/T 5DR (F20)",
        "MM_CODES": 5011689,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 689,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "125I A\/T 5DR (F20)",
        "MM_CODES": 5011689,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 689,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "125I SPORT LINE 5DR A\/T (F20)",
        "MM_CODES": 5011693,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 693,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "125I M SPORT 5DR A\/T (F20)",
        "MM_CODES": 5011695,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 695,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "125I M SPORT 5DR A\/T (F20)",
        "MM_CODES": 5011695,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 695,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "125I M SPORT 5DR A\/T (F20)",
        "MM_CODES": 5011699,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 699,
        "Discontinued_Date": "2019-04-11 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "125I M SPORT 5DR A\/T (F20)",
        "MM_CODES": 5011699,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 699,
        "Discontinued_Date": "2019-04-11 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "120D COUPE A\/T",
        "MM_CODES": 5011845,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 845,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120D COUPE A\/T",
        "MM_CODES": 5011845,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 845,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "120D COUPE A\/T",
        "MM_CODES": 5011845,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 845,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "120D COUPE SPORT A\/T",
        "MM_CODES": 5011851,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 851,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120D COUPE SPORT A\/T",
        "MM_CODES": 5011851,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 851,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "120D COUPE SPORT A\/T",
        "MM_CODES": 5011851,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 851,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "125I COUPE SPORT",
        "MM_CODES": 5011860,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 860,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "125I COUPE SPORT",
        "MM_CODES": 5011860,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 860,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "125I COUPE A\/T",
        "MM_CODES": 5011863,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 863,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "125I COUPE A\/T",
        "MM_CODES": 5011863,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 863,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "125I COUPE A\/T",
        "MM_CODES": 5011863,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 863,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "125I COUPE SPORT A\/T",
        "MM_CODES": 5011869,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 869,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "125I COUPE SPORT A\/T",
        "MM_CODES": 5011869,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 869,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "135I COUPE",
        "MM_CODES": 5011872,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 872,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "135I COUPE",
        "MM_CODES": 5011872,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 872,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "135I COUPE A\/T",
        "MM_CODES": 5011881,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 881,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "135I COUPE A\/T",
        "MM_CODES": 5011881,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 881,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "135I COUPE A\/T",
        "MM_CODES": 5011881,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 881,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "120I CONVERTIBLE",
        "MM_CODES": 5011890,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 890,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120I CONVERTIBLE",
        "MM_CODES": 5011890,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 890,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "120I CONVERTIBLE",
        "MM_CODES": 5011890,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 890,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "120I CONVERTIBLE A\/T",
        "MM_CODES": 5011899,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 899,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "120I CONVERTIBLE A\/T",
        "MM_CODES": 5011899,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 899,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "120I CONVERTIBLE A\/T",
        "MM_CODES": 5011899,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 899,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "125I CONVERTIBLE A\/T",
        "MM_CODES": 5011935,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 935,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "125I CONVERTIBLE A\/T",
        "MM_CODES": 5011935,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 935,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "125I CONVERTIBLE A\/T",
        "MM_CODES": 5011935,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 935,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "135I CONVERTIBLE",
        "MM_CODES": 5011944,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 944,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "135I CONVERTIBLE A\/T",
        "MM_CODES": 5011953,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 953,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "135I CONVERTIBLE A\/T",
        "MM_CODES": 5011953,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 953,
        "Discontinued_Date": "2014-03-25 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "M135I 3DR (F21)",
        "MM_CODES": 5011965,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 965,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "M135I 3DR (F21)",
        "MM_CODES": 5011965,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 965,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)",
        "Variant": "M135I 3DR A\/T (F21)",
        "MM_CODES": 5011968,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 968,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "M135I 3DR A\/T (F21)",
        "MM_CODES": 5011968,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 968,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "M135I 3DR A\/T (F21)",
        "MM_CODES": 5011968,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 968,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "M135I 5DR (F20)",
        "MM_CODES": 5011971,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 971,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "M135I 5DR (F20)",
        "MM_CODES": 5011971,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 971,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "M135I 5DR A\/T(F20)",
        "MM_CODES": 5011973,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 973,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES E81 \/ 87\/ F20\/F21",
        "Variant": "M135I 5DR A\/T(F20)",
        "MM_CODES": 5011973,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 973,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "1 SERIES (E81\/ 87)\/(F20\/F21)(F40)",
        "Variant": "1 M COUPE",
        "MM_CODES": 5011975,
        "GCode": 50,
        "MCode": 11,
        "Vcode": 975,
        "Discontinued_Date": "2012-12-11 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES ACTIVE TOURER (F45)",
        "Variant": "218I SPORT LINE ACTIVE TOURER A\/T",
        "MM_CODES": 5013100,
        "GCode": 50,
        "MCode": 13,
        "Vcode": 100,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES ACTIVE TOURER (F45)",
        "Variant": "220I ACTIVE TOURER",
        "MM_CODES": 5013110,
        "GCode": 50,
        "MCode": 13,
        "Vcode": 110,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES ACTIVE TOURER (F45)",
        "Variant": "220I ACTIVE TOURER A\/T",
        "MM_CODES": 5013130,
        "GCode": 50,
        "MCode": 13,
        "Vcode": 130,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES ACTIVE TOURER (F45)",
        "Variant": "220D ACTIVE TOURER",
        "MM_CODES": 5013150,
        "GCode": 50,
        "MCode": 13,
        "Vcode": 150,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES ACTIVE TOURER (F45)",
        "Variant": "220D LUXURY LINE ACTIVE TOURER",
        "MM_CODES": 5013155,
        "GCode": 50,
        "MCode": 13,
        "Vcode": 155,
        "Discontinued_Date": "2020-10-13 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES ACTIVE TOURER (F45)",
        "Variant": "220D SPORT LINE ACTIVE TOURER",
        "MM_CODES": 5013160,
        "GCode": 50,
        "MCode": 13,
        "Vcode": 160,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES ACTIVE TOURER (F45)",
        "Variant": "220D ACTIVE TOURER A\/T",
        "MM_CODES": 5013170,
        "GCode": 50,
        "MCode": 13,
        "Vcode": 170,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES ACTIVE TOURER (F45)",
        "Variant": "220D LUXURY LINE ACTIVE TOURER A\/T",
        "MM_CODES": 5013175,
        "GCode": 50,
        "MCode": 13,
        "Vcode": 175,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES ACTIVE TOURER (F45)",
        "Variant": "220D SPORT LINE ACTIVE TOURER A\/T",
        "MM_CODES": 5013180,
        "GCode": 50,
        "MCode": 13,
        "Vcode": 180,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES ACTIVE TOURER (F45)",
        "Variant": "225I LUXURY LINE ACTIVE TOURER A\/T",
        "MM_CODES": 5013215,
        "GCode": 50,
        "MCode": 13,
        "Vcode": 215,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES",
        "Variant": "220I MODERN LINE(F22)",
        "MM_CODES": 5015215,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 215,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "220I MODERN LINE(F22)",
        "MM_CODES": 5015215,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 215,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES",
        "Variant": "220I LUXURY LINE (F22)",
        "MM_CODES": 5015216,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 216,
        "Discontinued_Date": "2018-04-06 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "220I LUXURY LINE (F22)",
        "MM_CODES": 5015216,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 216,
        "Discontinued_Date": "2018-04-06 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "220I SPORT LINE (F22)",
        "MM_CODES": 5015220,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 220,
        "Discontinued_Date": "2018-04-06 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "220I SPORT LINE A\/T(F22)",
        "MM_CODES": 5015240,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 240,
        "Discontinued_Date": "2022-05-23 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "220I M SPORT A\/T(F22)",
        "MM_CODES": 5015245,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 245,
        "Discontinued_Date": "2022-05-23 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES",
        "Variant": "220D MODERN LINE(F22)",
        "MM_CODES": 5015295,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 295,
        "Discontinued_Date": "2021-03-31 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "220D MODERN LINE(F22)",
        "MM_CODES": 5015295,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 295,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "220D LUXURY LINE (F22)",
        "MM_CODES": 5015296,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 296,
        "Discontinued_Date": "2019-01-18 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "220D M SPORT(F22)",
        "MM_CODES": 5015305,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 305,
        "Discontinued_Date": "2019-04-11 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES",
        "Variant": "220D MODERN LINE A\/T(F22)",
        "MM_CODES": 5015315,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 315,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "220D MODERN LINE A\/T(F22)",
        "MM_CODES": 5015315,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 315,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES",
        "Variant": "220D LUXURY LINE A\/T (F22)",
        "MM_CODES": 5015316,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 316,
        "Discontinued_Date": "2019-02-21 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "220D LUXURY LINE A\/T (F22)",
        "MM_CODES": 5015316,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 316,
        "Discontinued_Date": "2019-01-18 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "220D SPORT LINE A\/T(F22)",
        "MM_CODES": 5015320,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 320,
        "Discontinued_Date": "2022-05-23 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "220D M SPORT A\/T(F22)",
        "MM_CODES": 5015325,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 325,
        "Discontinued_Date": "2022-05-23 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES",
        "Variant": "228I MODERN LINE (F22)",
        "MM_CODES": 5015455,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 455,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "228I MODERN LINE (F22)",
        "MM_CODES": 5015455,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 455,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES",
        "Variant": "228I SPORT LINE A\/T (F22)",
        "MM_CODES": 5015480,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 480,
        "Discontinued_Date": "2017-11-29 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "228I SPORT LINE A\/T (F22)",
        "MM_CODES": 5015480,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 480,
        "Discontinued_Date": "2016-06-28 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "230I SPORT LINE A\/T (F22)",
        "MM_CODES": 5015481,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 481,
        "Discontinued_Date": "2019-01-18 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "M240I (F22)",
        "MM_CODES": 5015531,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 531,
        "Discontinued_Date": "2018-04-06 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES",
        "Variant": "M235I A\/T(F22)",
        "MM_CODES": 5015535,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 535,
        "Discontinued_Date": "2016-06-28 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "M235I A\/T(F22)",
        "MM_CODES": 5015535,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 535,
        "Discontinued_Date": "2016-06-28 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "M2  COUPE COMPETITION (F87)",
        "MM_CODES": 5015548,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 548,
        "Discontinued_Date": "2023-07-17 13:40:40.233"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES",
        "Variant": "220I CONVERT LUXURY LINE A\/T (F23)",
        "MM_CODES": 5015595,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 595,
        "Discontinued_Date": "2019-01-18 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "220I CONVERT LUXURY LINE A\/T (F23)",
        "MM_CODES": 5015595,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 595,
        "Discontinued_Date": "2019-01-18 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES",
        "Variant": "220I CONVERT SPORT LINE A\/T (F23)",
        "MM_CODES": 5015600,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 600,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "220I CONVERT SPORT LINE A\/T (F23)",
        "MM_CODES": 5015600,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 600,
        "Discontinued_Date": "2022-12-08 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "228I CONVERT LUXURY LINE (F23)",
        "MM_CODES": 5015670,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 670,
        "Discontinued_Date": "2016-06-28 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES",
        "Variant": "230I CONVERT SPORT LINE A\/T (F23)",
        "MM_CODES": 5015691,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 691,
        "Discontinued_Date": "2019-01-18 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "230I CONVERT SPORT LINE A\/T (F23)",
        "MM_CODES": 5015691,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 691,
        "Discontinued_Date": "2019-01-18 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES",
        "Variant": "M235 CONVERT A\/T (F23)",
        "MM_CODES": 5015735,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 735,
        "Discontinued_Date": "2016-06-28 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "M235 CONVERT A\/T (F23)",
        "MM_CODES": 5015735,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 735,
        "Discontinued_Date": "2016-06-28 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "2 SERIES (F22)",
        "Variant": "M240 CONVERT A\/T (F23)",
        "MM_CODES": 5015736,
        "GCode": 50,
        "MCode": 15,
        "Vcode": 736,
        "Discontinued_Date": "2022-12-08 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "I3\/I8",
        "Variant": "I3 (94AH)",
        "MM_CODES": 5018201,
        "GCode": 50,
        "MCode": 18,
        "Vcode": 201,
        "Discontinued_Date": "2020-01-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "I3\/I8\/IX",
        "Variant": "I3 (94AH)",
        "MM_CODES": 5018201,
        "GCode": 50,
        "MCode": 18,
        "Vcode": 201,
        "Discontinued_Date": "2020-01-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "I3\/I8",
        "Variant": "I3 REX",
        "MM_CODES": 5018210,
        "GCode": 50,
        "MCode": 18,
        "Vcode": 210,
        "Discontinued_Date": "2020-01-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "I3\/I8\/IX",
        "Variant": "I3 REX",
        "MM_CODES": 5018210,
        "GCode": 50,
        "MCode": 18,
        "Vcode": 210,
        "Discontinued_Date": "2020-01-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "I3\/I8\/IX",
        "Variant": "I3 REX (120AH)",
        "MM_CODES": 5018212,
        "GCode": 50,
        "MCode": 18,
        "Vcode": 212,
        "Discontinued_Date": "2023-07-17 14:32:35.800"
    },
    {
        "Make": "BMW",
        "Model": "I3\/I8",
        "Variant": "I8",
        "MM_CODES": 5018500,
        "GCode": 50,
        "MCode": 18,
        "Vcode": 500,
        "Discontinued_Date": "2021-07-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "I3\/I8\/IX",
        "Variant": "I8",
        "MM_CODES": 5018500,
        "GCode": 50,
        "MCode": 18,
        "Vcode": 500,
        "Discontinued_Date": "2021-07-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "I3\/I8",
        "Variant": "I8 ROADSTER",
        "MM_CODES": 5018520,
        "GCode": 50,
        "MCode": 18,
        "Vcode": 520,
        "Discontinued_Date": "2021-07-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X1",
        "Variant": "X1 SDRIVE20D XLINE A\/T",
        "MM_CODES": 5020261,
        "GCode": 50,
        "MCode": 20,
        "Vcode": 261,
        "Discontinued_Date": "2015-09-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X1 (F48)",
        "Variant": "X1 SDRIVE20D XLINE A\/T",
        "MM_CODES": 5020261,
        "GCode": 50,
        "MCode": 20,
        "Vcode": 261,
        "Discontinued_Date": "2015-09-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X1 (F48)",
        "Variant": "X1 SDRIVE20D XLINE A\/T (F48)",
        "MM_CODES": 5020262,
        "GCode": 50,
        "MCode": 20,
        "Vcode": 262,
        "Discontinued_Date": "2019-09-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X1",
        "Variant": "X1 SDRIVE20D SPORT LINE A\/T",
        "MM_CODES": 5020273,
        "GCode": 50,
        "MCode": 20,
        "Vcode": 273,
        "Discontinued_Date": "2015-09-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X1 (F48)",
        "Variant": "X1 SDRIVE20D SPORT LINE A\/T",
        "MM_CODES": 5020273,
        "GCode": 50,
        "MCode": 20,
        "Vcode": 273,
        "Discontinued_Date": "2015-09-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X1",
        "Variant": "X1 XDRIVE20D A\/T",
        "MM_CODES": 5020371,
        "GCode": 50,
        "MCode": 20,
        "Vcode": 371,
        "Discontinued_Date": "2015-09-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X1 (F48)",
        "Variant": "X1 XDRIVE20D A\/T",
        "MM_CODES": 5020371,
        "GCode": 50,
        "MCode": 20,
        "Vcode": 371,
        "Discontinued_Date": "2015-09-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X1 (F48)",
        "Variant": "X1 XDRIVE20D A\/T (F48)",
        "MM_CODES": 5020372,
        "GCode": 50,
        "MCode": 20,
        "Vcode": 372,
        "Discontinued_Date": "2019-09-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X1 (F48)",
        "Variant": "X1 XDRIVE20D XLINE A\/T",
        "MM_CODES": 5020391,
        "GCode": 50,
        "MCode": 20,
        "Vcode": 391,
        "Discontinued_Date": "2015-09-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X1 (F48)",
        "Variant": "X1 XDRIVE20D SPORT LINE A\/T",
        "MM_CODES": 5020403,
        "GCode": 50,
        "MCode": 20,
        "Vcode": 403,
        "Discontinued_Date": "2015-09-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X1 (F48)",
        "Variant": "X1 XDRIVE25I A\/T (F48)",
        "MM_CODES": 5020430,
        "GCode": 50,
        "MCode": 20,
        "Vcode": 430,
        "Discontinued_Date": "2019-09-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X1 (F48)",
        "Variant": "X1 XDRIVE25D XLINE A\/T",
        "MM_CODES": 5020475,
        "GCode": 50,
        "MCode": 20,
        "Vcode": 475,
        "Discontinued_Date": "2015-09-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X2 (F39)",
        "Variant": "X2 SDRIVE20D M SPORT A\/T (F39)",
        "MM_CODES": 5022170,
        "GCode": 50,
        "MCode": 22,
        "Vcode": 170,
        "Discontinued_Date": "2022-12-14 22:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X2 (F39)",
        "Variant": "X2 XDRIVE20D M SPORT A\/T (F39)",
        "MM_CODES": 5022190,
        "GCode": 50,
        "MCode": 22,
        "Vcode": 190,
        "Discontinued_Date": "2022-12-14 22:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES E90 \/ 91 \/ 92 \/ 93",
        "Variant": "325I COUPE (E92)",
        "MM_CODES": 5036511,
        "GCode": 50,
        "MCode": 36,
        "Vcode": 511,
        "Discontinued_Date": "2013-11-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES E90 \/ 91 \/ 92 \/ 93",
        "Variant": "325I COUPE A\/T (E92)",
        "MM_CODES": 5036526,
        "GCode": 50,
        "MCode": 36,
        "Vcode": 526,
        "Discontinued_Date": "2013-11-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES E90 \/ 91 \/ 92 \/ 93",
        "Variant": "330I A\/T (E90)",
        "MM_CODES": 5036642,
        "GCode": 50,
        "MCode": 36,
        "Vcode": 642,
        "Discontinued_Date": "2012-07-03 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES E90 \/ 91 \/ 92 \/ 93",
        "Variant": "335I COUPE (E92)",
        "MM_CODES": 5036781,
        "GCode": 50,
        "MCode": 36,
        "Vcode": 781,
        "Discontinued_Date": "2013-11-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES E90 \/ 91 \/ 92 \/ 93",
        "Variant": "335I COUPE A\/T (E92)",
        "MM_CODES": 5036801,
        "GCode": 50,
        "MCode": 36,
        "Vcode": 801,
        "Discontinued_Date": "2013-11-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES E90 \/ 91 \/ 92 \/ 93",
        "Variant": "330I CONVERT A\/T (E93)",
        "MM_CODES": 5036866,
        "GCode": 50,
        "MCode": 36,
        "Vcode": 866,
        "Discontinued_Date": "2014-08-04 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES E90 \/ 91 \/ 92 \/ 93",
        "Variant": "335I CONVERT (E93)",
        "MM_CODES": 5036881,
        "GCode": 50,
        "MCode": 36,
        "Vcode": 881,
        "Discontinued_Date": "2014-07-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES E90 \/ 91 \/ 92 \/ 93",
        "Variant": "M3 COUPE",
        "MM_CODES": 5036951,
        "GCode": 50,
        "MCode": 36,
        "Vcode": 951,
        "Discontinued_Date": "2014-08-04 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES E90 \/ 91 \/ 92 \/ 93",
        "Variant": "M3 COUPE M-DCT",
        "MM_CODES": 5036956,
        "GCode": 50,
        "MCode": 36,
        "Vcode": 956,
        "Discontinued_Date": "2014-08-04 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES E90 \/ 91 \/ 92 \/ 93",
        "Variant": "M3 CONVERTIBLE M-DCT",
        "MM_CODES": 5036970,
        "GCode": 50,
        "MCode": 36,
        "Vcode": 970,
        "Discontinued_Date": "2014-08-04 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "320D (F30)",
        "MM_CODES": 5037149,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 149,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "320D (F30)",
        "MM_CODES": 5037150,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 150,
        "Discontinued_Date": "2019-03-13 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "320D MODERN LINE (F30)",
        "MM_CODES": 5037152,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 152,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "320D LUXURY LINE (F30)",
        "MM_CODES": 5037155,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 155,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "320D SPORT LINE (F30)",
        "MM_CODES": 5037158,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 158,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "320D A\/T (F30)",
        "MM_CODES": 5037167,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 167,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "320D A\/T (F30)",
        "MM_CODES": 5037168,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 168,
        "Discontinued_Date": "2019-03-13 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "320D SPORT LINE A\/T (F30)",
        "MM_CODES": 5037177,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 177,
        "Discontinued_Date": "2019-03-13 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "328I SPORT LINE (F30)",
        "MM_CODES": 5037338,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 338,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "328I A\/T (F30)",
        "MM_CODES": 5037347,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 347,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "328I SPORT LINE A\/T (F30)",
        "MM_CODES": 5037356,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 356,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "330D A\/T (F30)",
        "MM_CODES": 5037563,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 563,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "330D A\/T(F30)",
        "MM_CODES": 5037564,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 564,
        "Discontinued_Date": "2019-03-13 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "330D MODERN LINE A\/T (F30)",
        "MM_CODES": 5037566,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 566,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "330D LUXURY LINE A\/T (F30)",
        "MM_CODES": 5037569,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 569,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "330D SPORT LINE A\/T (F30)",
        "MM_CODES": 5037573,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 573,
        "Discontinued_Date": "2019-03-13 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "335I MODERN LINE A\/T (F30)",
        "MM_CODES": 5037674,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 674,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "335I LUXURY LINE A\/T (F30)",
        "MM_CODES": 5037677,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 677,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "335I SPORT LINE A\/T (F30)",
        "MM_CODES": 5037680,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 680,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "335I ACTIVEHYBRID3 A\/T (F30)",
        "MM_CODES": 5037689,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 689,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "335I MODERN LINE  ACTIVEHYBRID3 A\/T (F30)",
        "MM_CODES": 5037692,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 692,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "335I LUXURY LINE ACTIVEHYBRID3 A\/T (F30)",
        "MM_CODES": 5037695,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 695,
        "Discontinued_Date": "2015-08-19 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "340I A\/T (F30)",
        "MM_CODES": 5037715,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 715,
        "Discontinued_Date": "2019-03-13 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "M3 (F80)",
        "MM_CODES": 5037800,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 800,
        "Discontinued_Date": "2017-04-05 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (F30)",
        "Variant": "M3 M-DCT (F80)",
        "MM_CODES": 5037810,
        "GCode": 50,
        "MCode": 37,
        "Vcode": 810,
        "Discontinued_Date": "2019-03-13 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (G20)",
        "Variant": "320D M SPORT A\/T (G20)",
        "MM_CODES": 5038105,
        "GCode": 50,
        "MCode": 38,
        "Vcode": 105,
        "Discontinued_Date": "2022-09-12 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (G20)",
        "Variant": "330I SPORT LINE LAUNCH EDITION A\/T (G20)",
        "MM_CODES": 5038140,
        "GCode": 50,
        "MCode": 38,
        "Vcode": 140,
        "Discontinued_Date": "2020-01-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (G20\/G80)",
        "Variant": "330I SPORT LINE LAUNCH EDITION A\/T (G20)",
        "MM_CODES": 5038140,
        "GCode": 50,
        "MCode": 38,
        "Vcode": 140,
        "Discontinued_Date": "2020-01-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (G20)",
        "Variant": "330I M SPORT LAUNCH EDITION A\/T (G20)",
        "MM_CODES": 5038150,
        "GCode": 50,
        "MCode": 38,
        "Vcode": 150,
        "Discontinued_Date": "2020-01-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (G20\/G80)",
        "Variant": "330I M SPORT LAUNCH EDITION A\/T (G20)",
        "MM_CODES": 5038150,
        "GCode": 50,
        "MCode": 38,
        "Vcode": 150,
        "Discontinued_Date": "2020-01-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (G20)",
        "Variant": "330I M SPORT A\/T (G20)",
        "MM_CODES": 5038155,
        "GCode": 50,
        "MCode": 38,
        "Vcode": 155,
        "Discontinued_Date": "2022-09-12 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES (G20)",
        "Variant": "M3 COMPETITION (G80)",
        "MM_CODES": 5038270,
        "GCode": 50,
        "MCode": 38,
        "Vcode": 270,
        "Discontinued_Date": "2023-01-02 10:01:53.687"
    },
    {
        "Make": "BMW",
        "Model": "M COUPE \/ Z3 (E36\/7) \/ Z4 (E85) (G29)",
        "Variant": "Z4 SDRIVE20I ",
        "MM_CODES": 5040525,
        "GCode": 50,
        "MCode": 40,
        "Vcode": 525,
        "Discontinued_Date": "2016-11-16 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "M COUPE \/ Z3 \/ Z4",
        "Variant": "Z4 SDRIVE20I",
        "MM_CODES": 5040525,
        "GCode": 50,
        "MCode": 40,
        "Vcode": 525,
        "Discontinued_Date": "2016-11-16 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "M COUPE \/ Z3 (E36\/7) \/ Z4 (E85) (G29)",
        "Variant": "Z4 SDRIVE20I A\/T",
        "MM_CODES": 5040527,
        "GCode": 50,
        "MCode": 40,
        "Vcode": 527,
        "Discontinued_Date": "2016-11-16 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "M COUPE \/ Z3 \/ Z4",
        "Variant": "Z4 SDRIVE20I A\/T",
        "MM_CODES": 5040527,
        "GCode": 50,
        "MCode": 40,
        "Vcode": 527,
        "Discontinued_Date": "2016-11-16 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "M COUPE \/ Z3 (E36\/7) \/ Z4 (E85) (G29)",
        "Variant": "Z4 SDRIVE28I",
        "MM_CODES": 5040620,
        "GCode": 50,
        "MCode": 40,
        "Vcode": 620,
        "Discontinued_Date": "2016-11-16 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "M COUPE \/ Z3 (E36\/7) \/ Z4 (E85) (G29)",
        "Variant": "Z4 SDRIVE28I A\/T",
        "MM_CODES": 5040623,
        "GCode": 50,
        "MCode": 40,
        "Vcode": 623,
        "Discontinued_Date": "2016-11-16 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "M COUPE \/ Z3 \/ Z4",
        "Variant": "Z4 SDRIVE28I A\/T",
        "MM_CODES": 5040623,
        "GCode": 50,
        "MCode": 40,
        "Vcode": 623,
        "Discontinued_Date": "2016-11-16 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "M COUPE \/ Z3 (E36\/7) \/ Z4 (E85) (G29)",
        "Variant": "Z4 SDRIVE28I M SPORT A\/T",
        "MM_CODES": 5040632,
        "GCode": 50,
        "MCode": 40,
        "Vcode": 632,
        "Discontinued_Date": "2016-11-16 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320I GT MODERN LINE (F34)",
        "MM_CODES": 5044110,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 110,
        "Discontinued_Date": "2015-06-29 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320I GT LUXURY LINE (F34)",
        "MM_CODES": 5044115,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 115,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320I GT SPORT LINE (F34)",
        "MM_CODES": 5044120,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 120,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320I GT M SPORT (F34)",
        "MM_CODES": 5044125,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 125,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320I GT A\/T (F34)",
        "MM_CODES": 5044130,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 130,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320I GT LUXURY LINE A\/T (F34)",
        "MM_CODES": 5044140,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 140,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320I GT SPORT LINE A\/T (F34)",
        "MM_CODES": 5044145,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 145,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320D GT (F34)",
        "MM_CODES": 5044155,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 155,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320D GT MODERN LINE (F34)",
        "MM_CODES": 5044160,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 160,
        "Discontinued_Date": "2015-06-29 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320D GT LUXURY LINE (F34)",
        "MM_CODES": 5044165,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 165,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320D GT SPORT LINE (F34)",
        "MM_CODES": 5044170,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 170,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320D GT A\/T (F34)",
        "MM_CODES": 5044180,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 180,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320D GT MODERN LINE A\/T (F34)",
        "MM_CODES": 5044185,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 185,
        "Discontinued_Date": "2015-06-29 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320D GT LUXURY LINE A\/T (F34)",
        "MM_CODES": 5044190,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 190,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "320D GT SPORT LINE A\/T (F34)",
        "MM_CODES": 5044195,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 195,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "328I GT (F34)",
        "MM_CODES": 5044310,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 310,
        "Discontinued_Date": "2017-04-05 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "328I GT LUXURY LINE (F34)",
        "MM_CODES": 5044320,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 320,
        "Discontinued_Date": "2017-04-05 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "328I GT SPORT LINE (F34)",
        "MM_CODES": 5044325,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 325,
        "Discontinued_Date": "2017-04-05 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "328I GT LUXURY LINE A\/T(F34)",
        "MM_CODES": 5044345,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 345,
        "Discontinued_Date": "2017-04-05 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "335I GT LUXURY LINE A\/T (F34)",
        "MM_CODES": 5044495,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 495,
        "Discontinued_Date": "2017-04-05 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "3 SERIES GT (F34)",
        "Variant": "335I GT SPORT LINE A\/T (F34)",
        "MM_CODES": 5044500,
        "GCode": 50,
        "MCode": 44,
        "Vcode": 500,
        "Discontinued_Date": "2017-04-05 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X3 (G01)",
        "Variant": "X3 SDRIVE 20I (G01)",
        "MM_CODES": 5046110,
        "GCode": 50,
        "MCode": 46,
        "Vcode": 110,
        "Discontinued_Date": "2021-08-17 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X3 (G01)",
        "Variant": "X3 XDRIVE 30I (G01)",
        "MM_CODES": 5046300,
        "GCode": 50,
        "MCode": 46,
        "Vcode": 300,
        "Discontinued_Date": "2020-12-21 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X3 (G01)",
        "Variant": "X3 XDRIVE M40I (G01)",
        "MM_CODES": 5046450,
        "GCode": 50,
        "MCode": 46,
        "Vcode": 450,
        "Discontinued_Date": "2021-08-17 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X3",
        "Variant": "X3 XDRIVE28I A\/T (F25)",
        "MM_CODES": 5047601,
        "GCode": 50,
        "MCode": 47,
        "Vcode": 601,
        "Discontinued_Date": "2017-09-08 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X3 (F25)",
        "Variant": "X3 XDRIVE28I A\/T (F25)",
        "MM_CODES": 5047601,
        "GCode": 50,
        "MCode": 47,
        "Vcode": 601,
        "Discontinued_Date": "2017-09-08 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X3",
        "Variant": "X3 XDRIVE 30D A\/T",
        "MM_CODES": 5047711,
        "GCode": 50,
        "MCode": 47,
        "Vcode": 711,
        "Discontinued_Date": "2014-06-18 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X3 (F25)",
        "Variant": "X3 XDRIVE 30D A\/T",
        "MM_CODES": 5047711,
        "GCode": 50,
        "MCode": 47,
        "Vcode": 711,
        "Discontinued_Date": "2014-06-18 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X3",
        "Variant": "X3 XDRIVE35I  M-SPORT A\/T",
        "MM_CODES": 5047820,
        "GCode": 50,
        "MCode": 47,
        "Vcode": 820,
        "Discontinued_Date": "2014-06-18 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X3 (F25)",
        "Variant": "X3 XDRIVE35I  M-SPORT A\/T",
        "MM_CODES": 5047820,
        "GCode": 50,
        "MCode": 47,
        "Vcode": 820,
        "Discontinued_Date": "2014-06-18 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE",
        "Variant": "420D GRAN COUPE A\/T (F36)",
        "MM_CODES": 5048115,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 115,
        "Discontinued_Date": "1990-04-01 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE (F36)",
        "Variant": "420D GRAN COUPE A\/T",
        "MM_CODES": 5048115,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 115,
        "Discontinued_Date": "2021-10-01 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE",
        "Variant": "420D GRAN COUPE MODERN LINE A\/T (F36)",
        "MM_CODES": 5048120,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 120,
        "Discontinued_Date": "2021-05-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE (F36)",
        "Variant": "420D GRAN COUPE LUXURY LINE A\/T (F36)",
        "MM_CODES": 5048125,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 125,
        "Discontinued_Date": "2020-01-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE",
        "Variant": "420D GRAN COUPE SPORT LINE A\/T (F36)",
        "MM_CODES": 5048130,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 130,
        "Discontinued_Date": "2019-09-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE (F36)",
        "Variant": "420D GRAN COUPE SPORT LINE A\/T (F36)",
        "MM_CODES": 5048130,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 130,
        "Discontinued_Date": "2020-01-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE (F36)",
        "Variant": "420D GRAN COUPE M SPORT A\/T (F36)",
        "MM_CODES": 5048135,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 135,
        "Discontinued_Date": "2021-10-01 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE",
        "Variant": "428I GRAN COUPE (F36)",
        "MM_CODES": 5048240,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 240,
        "Discontinued_Date": "2016-03-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE (F36)",
        "Variant": "428I GRAN COUPE (F36)",
        "MM_CODES": 5048240,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 240,
        "Discontinued_Date": "2016-03-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE",
        "Variant": "428I GRAN COUPE MODERN LINE (F36)",
        "MM_CODES": 5048245,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 245,
        "Discontinued_Date": "2015-03-31 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE",
        "Variant": "428I GRAN COUPE LUXURY LINE (F36)",
        "MM_CODES": 5048250,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 250,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE",
        "Variant": "428I GRAN COUPE SPORT LINE A\/T (F36)",
        "MM_CODES": 5048280,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 280,
        "Discontinued_Date": "2019-12-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE",
        "Variant": "435I GRAN COUPE A\/T (F36)",
        "MM_CODES": 5048415,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 415,
        "Discontinued_Date": "2022-12-14 22:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE",
        "Variant": "435I GRAN COUPE MODERN LINE A\/T (F36)",
        "MM_CODES": 5048420,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 420,
        "Discontinued_Date": "2019-06-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE",
        "Variant": "435I GRAN COUPE SPORT LINE A\/T (F36)",
        "MM_CODES": 5048430,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 430,
        "Discontinued_Date": "2021-05-05 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES GRAN COUPE (F36)",
        "Variant": "440I GRAN COUPE M SPORT A\/T (F36)",
        "MM_CODES": 5048436,
        "GCode": 50,
        "MCode": 48,
        "Vcode": 436,
        "Discontinued_Date": "2021-10-01 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "420I CONVERT MODERN LINE A\/T (F33)",
        "MM_CODES": 5049100,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 100,
        "Discontinued_Date": "2015-06-29 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "420I CONVERT LUXURY LINE A\/T (F33)",
        "MM_CODES": 5049103,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 103,
        "Discontinued_Date": "2019-04-11 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "420I CONVERT SPORT LINE A\/T (F33)",
        "MM_CODES": 5049107,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 107,
        "Discontinued_Date": "2019-04-11 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "420D COUPE (F32)",
        "MM_CODES": 5049115,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 115,
        "Discontinued_Date": "2021-07-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "420D COUPE (F32)",
        "MM_CODES": 5049115,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 115,
        "Discontinued_Date": "2019-04-11 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "420D COUPE LUXURY LINE (F32)",
        "MM_CODES": 5049122,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 122,
        "Discontinued_Date": "2012-12-01 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "420D COUPE SPORT LINE (F32)",
        "MM_CODES": 5049126,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 126,
        "Discontinued_Date": "2020-07-27 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "420D COUPE A\/T (F32)",
        "MM_CODES": 5049133,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 133,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "420D COUPE A\/T (F32)",
        "MM_CODES": 5049133,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 133,
        "Discontinued_Date": "2020-08-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "420D COUPE LUXURY LINE A\/T (F32)",
        "MM_CODES": 5049141,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 141,
        "Discontinued_Date": "2019-04-11 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "420D COUPE SPORT LINE A\/T (F32)",
        "MM_CODES": 5049144,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 144,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "428I COUPE (F32)",
        "MM_CODES": 5049337,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 337,
        "Discontinued_Date": "2020-05-14 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "428I COUPE (F32) ",
        "MM_CODES": 5049337,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 337,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "428I COUPE A\/T (F32)",
        "MM_CODES": 5049356,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 356,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "428I COUPE A\/T (F32)",
        "MM_CODES": 5049356,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 356,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "428I COUPE LUXURY LINE A\/T (F32)",
        "MM_CODES": 5049363,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 363,
        "Discontinued_Date": "2009-09-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "428I COUPE SPORT LINE A\/T (F32)",
        "MM_CODES": 5049367,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 367,
        "Discontinued_Date": "2015-04-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "428I COUPE SPORT LINE A\/T (F32)",
        "MM_CODES": 5049367,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 367,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "428I CONVERT(F33)",
        "MM_CODES": 5049375,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 375,
        "Discontinued_Date": "2006-10-04 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "428I CONVERT MODERN LINE(F33)",
        "MM_CODES": 5049378,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 378,
        "Discontinued_Date": "2015-06-29 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "428I CONVERT LUXURY LINE(F33)",
        "MM_CODES": 5049382,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 382,
        "Discontinued_Date": "2015-09-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "428I CONVERT LUXURY LINE(F33)",
        "MM_CODES": 5049382,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 382,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "428I CONVERT SPORT LINE(F33)",
        "MM_CODES": 5049386,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 386,
        "Discontinued_Date": "2016-06-28 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "428I CONVERT SPORT LINE(F33)",
        "MM_CODES": 5049386,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 386,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "428I CONVERT A\/T(F33)",
        "MM_CODES": 5049393,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 393,
        "Discontinued_Date": "2014-01-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "428I CONVERT A\/T(F33)",
        "MM_CODES": 5049393,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 393,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "428I CONVERT MODERN LINE A\/T(F33)",
        "MM_CODES": 5049397,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 397,
        "Discontinued_Date": "2018-08-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "428I CONVERT LUXURY LINE A\/T(F33)",
        "MM_CODES": 5049401,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 401,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "428I CONVERT LUXURY LINE A\/T(F33)",
        "MM_CODES": 5049401,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 401,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "428I CONVERT SPORT LINE A\/T(F33)",
        "MM_CODES": 5049404,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 404,
        "Discontinued_Date": "2019-04-12 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "428I CONVERT SPORT LINE A\/T(F33)",
        "MM_CODES": 5049404,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 404,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "435I COUPE A\/T (F32)",
        "MM_CODES": 5049583,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 583,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "435I COUPE A\/T (F32) ",
        "MM_CODES": 5049583,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 583,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "435I COUPE MODERN LINE  A\/T (F32)",
        "MM_CODES": 5049586,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 586,
        "Discontinued_Date": "2019-12-20 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "435I COUPE LUXURY LINE  A\/T (F32)",
        "MM_CODES": 5049590,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 590,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "435I COUPE LUXURY LINE  A\/T (F32)  ",
        "MM_CODES": 5049590,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 590,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "435I COUPE SPORT LINE  A\/T (F32)",
        "MM_CODES": 5049594,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 594,
        "Discontinued_Date": "2016-07-04 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "435I COUPE SPORT LINE  A\/T (F32)  ",
        "MM_CODES": 5049594,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 594,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "435I COUPE M SPORT A\/T (F32)",
        "MM_CODES": 5049597,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 597,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "435I COUPE M SPORT A\/T (F32) ",
        "MM_CODES": 5049597,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 597,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "435I CONVERT LUXURY LINE A\/T (F33)",
        "MM_CODES": 5049646,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 646,
        "Discontinued_Date": "2008-12-01 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "435I CONVERT LUXURY LINE A\/T (F33)",
        "MM_CODES": 5049646,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 646,
        "Discontinued_Date": "2016-03-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "440I CONVERT M SPORT A\/T(F33)",
        "MM_CODES": 5049654,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 654,
        "Discontinued_Date": "2021-02-16 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "M4 COUPE M-DCT COMPETITION ",
        "MM_CODES": 5049705,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 705,
        "Discontinued_Date": "2018-04-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "M4 COUPE M-DCT COMPETITION",
        "MM_CODES": 5049705,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 705,
        "Discontinued_Date": "2021-06-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES",
        "Variant": "M4 CONVERTIBLE (F83)",
        "MM_CODES": 5049710,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 710,
        "Discontinued_Date": "2018-04-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "M4 CONVERTIBLE (F83)",
        "MM_CODES": 5049710,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 710,
        "Discontinued_Date": "2018-04-09 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "M4 CONVERTIBLE M-DCT (F83)",
        "MM_CODES": 5049720,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 720,
        "Discontinued_Date": "2021-09-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "4 SERIES (F32)",
        "Variant": "M4 CONVERTIBLE M-DCT COMPETITION (F83)",
        "MM_CODES": 5049725,
        "GCode": 50,
        "MCode": 49,
        "Vcode": 725,
        "Discontinued_Date": "2021-09-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "5 SERIES E60 \/ GT",
        "Variant": "GRAN TURISMO 535I LUXURY LINE",
        "MM_CODES": 5051621,
        "GCode": 50,
        "MCode": 51,
        "Vcode": 621,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "5 SERIES E60 \/ GT",
        "Variant": "GRAN TURISMO 550I",
        "MM_CODES": 5051650,
        "GCode": 50,
        "MCode": 51,
        "Vcode": 650,
        "Discontinued_Date": "2013-08-15 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "5 SERIES E60 \/ GT",
        "Variant": "GRAN TURISMO 550I MODERN LINE",
        "MM_CODES": 5051653,
        "GCode": 50,
        "MCode": 51,
        "Vcode": 653,
        "Discontinued_Date": "2014-06-11 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "5 SERIES E60 \/ GT",
        "Variant": "GRAN TURISMO 550I LUXURY LINE",
        "MM_CODES": 5051660,
        "GCode": 50,
        "MCode": 51,
        "Vcode": 660,
        "Discontinued_Date": "2001-12-01 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "5 SERIES (F10)",
        "Variant": "528I A\/T (F10)",
        "MM_CODES": 5052205,
        "GCode": 50,
        "MCode": 52,
        "Vcode": 205,
        "Discontinued_Date": "2012-02-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "5 SERIES (F10) \/ (F90)",
        "Variant": "528I A\/T (F10)",
        "MM_CODES": 5052205,
        "GCode": 50,
        "MCode": 52,
        "Vcode": 205,
        "Discontinued_Date": "2012-02-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "5 SERIES F10",
        "Variant": "528I A\/T (F10)",
        "MM_CODES": 5052205,
        "GCode": 50,
        "MCode": 52,
        "Vcode": 205,
        "Discontinued_Date": "1993-12-01 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "5 SERIES (F10)",
        "Variant": "528I A\/T (F10)",
        "MM_CODES": 5052207,
        "GCode": 50,
        "MCode": 52,
        "Vcode": 207,
        "Discontinued_Date": "2017-02-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "5 SERIES (F10) \/ (F90)",
        "Variant": "528I A\/T (F10)",
        "MM_CODES": 5052207,
        "GCode": 50,
        "MCode": 52,
        "Vcode": 207,
        "Discontinued_Date": "2017-02-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "5 SERIES F10",
        "Variant": "528I A\/T (F10)",
        "MM_CODES": 5052207,
        "GCode": 50,
        "MCode": 52,
        "Vcode": 207,
        "Discontinued_Date": "2017-02-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "5 SERIES F10",
        "Variant": "528I LUXURY LINE A\/T (F10)",
        "MM_CODES": 5052227,
        "GCode": 50,
        "MCode": 52,
        "Vcode": 227,
        "Discontinued_Date": "2004-06-01 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "5 SERIES F10",
        "Variant": "535I A\/T (F10)",
        "MM_CODES": 5052356,
        "GCode": 50,
        "MCode": 52,
        "Vcode": 356,
        "Discontinued_Date": "2017-02-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "5 SERIES F10",
        "Variant": "535I LUXURY LINE A\/T (F10)",
        "MM_CODES": 5052376,
        "GCode": 50,
        "MCode": 52,
        "Vcode": 376,
        "Discontinued_Date": "2021-09-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4",
        "Variant": "X4 XDRIVE20I XLINE",
        "MM_CODES": 5056140,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 140,
        "Discontinued_Date": "2019-02-26 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4 (G02)",
        "Variant": "X4 XDRIVE20I XLINE",
        "MM_CODES": 5056140,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 140,
        "Discontinued_Date": "2018-08-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4 (G02)",
        "Variant": "X4 XDRIVE20I M SPORT (G02)",
        "MM_CODES": 5056151,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 151,
        "Discontinued_Date": "2021-08-17 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4",
        "Variant": "X4 XDRIVE20D",
        "MM_CODES": 5056190,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 190,
        "Discontinued_Date": "2018-08-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4 (G02)",
        "Variant": "X4 XDRIVE20D ",
        "MM_CODES": 5056190,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 190,
        "Discontinued_Date": "2018-08-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4 (G02)",
        "Variant": "X4 XDRIVE20D (G02)",
        "MM_CODES": 5056191,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 191,
        "Discontinued_Date": "2021-08-17 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4 (G02)",
        "Variant": "X4 XDRIVE20D M SPORT (G02)",
        "MM_CODES": 5056211,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 211,
        "Discontinued_Date": "2021-08-17 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4 (G02)",
        "Variant": "X4 XDRIVE28I ",
        "MM_CODES": 5056220,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 220,
        "Discontinued_Date": "2018-08-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4 (G02)",
        "Variant": "X4 XDRIVE28I XLINE",
        "MM_CODES": 5056230,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 230,
        "Discontinued_Date": "2018-08-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4",
        "Variant": "X4 XDRIVE30D",
        "MM_CODES": 5056250,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 250,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4 (G02)",
        "Variant": "X4 XDRIVE30D",
        "MM_CODES": 5056250,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 250,
        "Discontinued_Date": "2018-08-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4",
        "Variant": "X4 XDRIVE30D XLINE",
        "MM_CODES": 5056260,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 260,
        "Discontinued_Date": "2007-07-05 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4",
        "Variant": "X4 XDRIVE35I XLINE",
        "MM_CODES": 5056290,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 290,
        "Discontinued_Date": "2018-08-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4 (G02)",
        "Variant": "X4 XDRIVE35I XLINE",
        "MM_CODES": 5056290,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 290,
        "Discontinued_Date": "2018-08-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4 (G02)",
        "Variant": "X4 M40I (G02)",
        "MM_CODES": 5056320,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 320,
        "Discontinued_Date": "2021-08-17 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4 (G02)",
        "Variant": "X4 M40D (G02)",
        "MM_CODES": 5056340,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 340,
        "Discontinued_Date": "2022-02-10 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X4 (G02)",
        "Variant": "X4 M COMPETITION (F98)",
        "MM_CODES": 5056350,
        "GCode": 50,
        "MCode": 56,
        "Vcode": 350,
        "Discontinued_Date": "2021-09-30 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X5 E53 \/ E70",
        "Variant": "X5 M",
        "MM_CODES": 5060950,
        "GCode": 50,
        "MCode": 60,
        "Vcode": 950,
        "Discontinued_Date": "2014-03-12 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X5 E70 2010 - ON (F15) (G05)",
        "Variant": "X5 XDRIVE35I  A\/T (F15)",
        "MM_CODES": 5061101,
        "GCode": 50,
        "MCode": 61,
        "Vcode": 101,
        "Discontinued_Date": "2016-06-28 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X5 E70 2010 - ON",
        "Variant": "X5 XDRIVE30D  A\/T (F15)",
        "MM_CODES": 5061140,
        "GCode": 50,
        "MCode": 61,
        "Vcode": 140,
        "Discontinued_Date": "2018-09-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X5 E70 2010 - ON (F15) (G05)",
        "Variant": "X5 XDRIVE30D  A\/T (F15)",
        "MM_CODES": 5061140,
        "GCode": 50,
        "MCode": 61,
        "Vcode": 140,
        "Discontinued_Date": "2018-09-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X5 E70 2010 - ON (F15) (G05)",
        "Variant": "X5 XDRIVE30D XLINE A\/T (G05)",
        "MM_CODES": 5061143,
        "GCode": 50,
        "MCode": 61,
        "Vcode": 143,
        "Discontinued_Date": "2023-06-27 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X5 E70 2010 - ON (F15) (G05)",
        "Variant": "X5 XDRIVE30D M SPORT (G05)",
        "MM_CODES": 5061163,
        "GCode": 50,
        "MCode": 61,
        "Vcode": 163,
        "Discontinued_Date": "2023-06-27 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X5 E70 2010 - ON (F15) (G05)",
        "Variant": "X5 XDRIVE40E A\/T (F15)",
        "MM_CODES": 5061200,
        "GCode": 50,
        "MCode": 61,
        "Vcode": 200,
        "Discontinued_Date": "2018-09-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X5 E70 2010 - ON",
        "Variant": "X5 XDRIVE40D A\/T (F15)",
        "MM_CODES": 5061221,
        "GCode": 50,
        "MCode": 61,
        "Vcode": 221,
        "Discontinued_Date": "2018-09-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X5 E70 2010 - ON (F15) (G05)",
        "Variant": "X5 XDRIVE40D A\/T (F15)",
        "MM_CODES": 5061221,
        "GCode": 50,
        "MCode": 61,
        "Vcode": 221,
        "Discontinued_Date": "2018-09-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X5 E70 2010 - ON",
        "Variant": "X5 M50D (F15)",
        "MM_CODES": 5061451,
        "GCode": 50,
        "MCode": 61,
        "Vcode": 451,
        "Discontinued_Date": "2018-09-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X5 E70 2010 - ON (F15) (G05)",
        "Variant": "X5 M50D (F15)",
        "MM_CODES": 5061451,
        "GCode": 50,
        "MCode": 61,
        "Vcode": 451,
        "Discontinued_Date": "2018-09-07 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X6 (F16) (G06)",
        "Variant": "X6 M (F96)",
        "MM_CODES": 5065503,
        "GCode": 50,
        "MCode": 65,
        "Vcode": 503,
        "Discontinued_Date": "2023-06-27 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "7 SERIES E65 \/ F01",
        "Variant": "760LI (F02)",
        "MM_CODES": 5068626,
        "GCode": 50,
        "MCode": 68,
        "Vcode": 626,
        "Discontinued_Date": "2016-01-06 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "7 SERIES (G11) (G12)",
        "Variant": "750LI (G12)                          ",
        "MM_CODES": 5069680,
        "GCode": 50,
        "MCode": 69,
        "Vcode": 680,
        "Discontinued_Date": "2019-03-14 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "7 SERIES (G11) (G12) (G70)",
        "Variant": "750LI (G12)                          ",
        "MM_CODES": 5069680,
        "GCode": 50,
        "MCode": 69,
        "Vcode": 680,
        "Discontinued_Date": "2019-03-14 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "6 SERIES E24 \/ E63 \/ E64\/ F12\/ F13",
        "Variant": "640D GRAN COUPE",
        "MM_CODES": 5083650,
        "GCode": 50,
        "MCode": 83,
        "Vcode": 650,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "6 SERIES E24 \/ E63 \/ E64\/ F12\/ F13",
        "Variant": "650I GRAN COUPE",
        "MM_CODES": 5083700,
        "GCode": 50,
        "MCode": 83,
        "Vcode": 700,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "6 SERIES E24 \/ E63 \/ E64\/ F12\/ F13",
        "Variant": "M6 GRAN COUPE M-DCT",
        "MM_CODES": 5083745,
        "GCode": 50,
        "MCode": 83,
        "Vcode": 745,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "6 SERIES E24 \/ E63 \/ E64\/ F12\/ F13",
        "Variant": "M6 COUPE (F12)",
        "MM_CODES": 5083801,
        "GCode": 50,
        "MCode": 83,
        "Vcode": 801,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "6 SERIES E24 \/ E63 \/ E64\/ F12\/ F13",
        "Variant": "M6 CONVERTIBLE (F12)",
        "MM_CODES": 5083851,
        "GCode": 50,
        "MCode": 83,
        "Vcode": 851,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "6 SERIES E24 \/ E63 \/ E64\/ F12\/ F13",
        "Variant": "630D GRAN TURISMO (G32)",
        "MM_CODES": 5083900,
        "GCode": 50,
        "MCode": 83,
        "Vcode": 900,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "6 SERIES E24 \/ E63 \/ E64\/ F12\/ F13",
        "Variant": "640I XDRIVE GRAN TURISMO (G32)",
        "MM_CODES": 5083930,
        "GCode": 50,
        "MCode": 83,
        "Vcode": 930,
        "Discontinued_Date": "2020-04-02 00:00:00.000"
    },
    {
        "Make": "BMW",
        "Model": "X7",
        "Variant": "X7 XDRIVE30D M SPORT (G07)",
        "MM_CODES": 5088230,
        "GCode": 50,
        "MCode": 88,
        "Vcode": 230,
        "Discontinued_Date": "2023-07-17 14:37:20.493"
    },
    {
        "Make": "BMW",
        "Model": "X7",
        "Variant": "X7 XDRIVE 40D M SPORT PRO (G07)",
        "MM_CODES": 5088290,
        "GCode": 50,
        "MCode": 88,
        "Vcode": 290,
        "Discontinued_Date": "2023-07-17 14:36:33.893"
    },
    {
        "Make": "BMW",
        "Model": "X7",
        "Variant": "X7 M50D (G07)",
        "MM_CODES": 5088410,
        "GCode": 50,
        "MCode": 88,
        "Vcode": 410,
        "Discontinued_Date": "2023-07-17 14:37:27.543"
    },
    {
        "Make": "BMW",
        "Model": "X7",
        "Variant": "X7 M50I (G07)",
        "MM_CODES": 5088430,
        "GCode": 50,
        "MCode": 88,
        "Vcode": 430,
        "Discontinued_Date": "2023-07-17 14:37:28.673"
    },
    {
        "Make": "CHEVROLET",
        "Model": "SPARK",
        "Variant": "SPARK LS 5DR",
        "MM_CODES": 10010160,
        "GCode": 100,
        "MCode": 10,
        "Vcode": 160,
        "Discontinued_Date": "2010-07-06 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "SPARK",
        "Variant": "SPARK 1.2 LS 5DR",
        "MM_CODES": 10010161,
        "GCode": 100,
        "MCode": 10,
        "Vcode": 161,
        "Discontinued_Date": "2017-12-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "SPARK",
        "Variant": "SPARK LT 5DR",
        "MM_CODES": 10010170,
        "GCode": 100,
        "MCode": 10,
        "Vcode": 170,
        "Discontinued_Date": "2010-07-06 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "SPARK",
        "Variant": "SPARK 1.2 LT 5DR",
        "MM_CODES": 10010171,
        "GCode": 100,
        "MCode": 10,
        "Vcode": 171,
        "Discontinued_Date": "2017-12-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "SPARK",
        "Variant": "SPARK PRONTO 1.2 F\/C P\/V",
        "MM_CODES": 10010500,
        "GCode": 100,
        "MCode": 10,
        "Vcode": 500,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "CORSA UTILITY 1.4 P\/U S\/C",
        "MM_CODES": 10019100,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 100,
        "Discontinued_Date": "2011-12-01 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "UTILITY 1.4 S\/C P\/U",
        "MM_CODES": 10019101,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 101,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "CORSA UTILITY 1.4 A\/C P\/U S\/C",
        "MM_CODES": 10019115,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 115,
        "Discontinued_Date": "2011-12-02 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "UTILITY 1.4 A\/C P\/U S\/C",
        "MM_CODES": 10019116,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 116,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "CORSA UTILITY 1.4 CLUB P\/U S\/C",
        "MM_CODES": 10019130,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 130,
        "Discontinued_Date": "2011-12-02 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "UTILITY 1.4 CLUB P\/U S\/C",
        "MM_CODES": 10019131,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 131,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "CORSA UTILITY 1.4 SPORT P\/U S\/C",
        "MM_CODES": 10019145,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 145,
        "Discontinued_Date": "2011-12-02 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "UTILITY 1.4 SPORT P\/U S\/C",
        "MM_CODES": 10019146,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 146,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "CORSA UTILITY 1.7 DTI P\/U S\/C",
        "MM_CODES": 10019220,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 220,
        "Discontinued_Date": "2011-12-02 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "CORSA UTILITY 1.7 DTI  CLUB P\/U S\/C",
        "MM_CODES": 10019235,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 235,
        "Discontinued_Date": "2011-12-02 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "CORSA UTILITY 1.7 DTI SPORT P\/U S\/C",
        "MM_CODES": 10019250,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 250,
        "Discontinued_Date": "2011-12-02 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "CORSA UTILITY 1.8 P\/U S\/C",
        "MM_CODES": 10019320,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 320,
        "Discontinued_Date": "2011-12-02 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "UTILITY 1.8 A\/C P\/U S\/C",
        "MM_CODES": 10019325,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 325,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "CORSA UTILITY 1.8 CLUB P\/U S\/C",
        "MM_CODES": 10019335,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 335,
        "Discontinued_Date": "2011-12-01 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "UTILITY 1.8 CLUB P\/U S\/C",
        "MM_CODES": 10019336,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 336,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CORSA\/UTILITY",
        "Variant": "CORSA UTILITY 1.8 SPORT P\/U S\/C",
        "MM_CODES": 10019350,
        "GCode": 100,
        "MCode": 19,
        "Vcode": 350,
        "Discontinued_Date": "2011-12-01 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "AVEO",
        "Variant": "AVEO 1.6 L 5DR",
        "MM_CODES": 10030201,
        "GCode": 100,
        "MCode": 30,
        "Vcode": 201,
        "Discontinued_Date": "2016-09-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "AVEO",
        "Variant": "AVEO 1.6 LS 5DR",
        "MM_CODES": 10030282,
        "GCode": 100,
        "MCode": 30,
        "Vcode": 282,
        "Discontinued_Date": "2012-10-10 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "AVEO",
        "Variant": "AVEO 1.6 LS 5DR A\/T",
        "MM_CODES": 10030290,
        "GCode": 100,
        "MCode": 30,
        "Vcode": 290,
        "Discontinued_Date": "2012-08-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "AVEO",
        "Variant": "AVEO 1.6 LT 5DR",
        "MM_CODES": 10030341,
        "GCode": 100,
        "MCode": 30,
        "Vcode": 341,
        "Discontinued_Date": "2010-10-01 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "AVEO",
        "Variant": "AVEO 1.6 L",
        "MM_CODES": 10030390,
        "GCode": 100,
        "MCode": 30,
        "Vcode": 390,
        "Discontinued_Date": "2016-09-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "AVEO",
        "Variant": "AVEO 1.6 LS",
        "MM_CODES": 10030402,
        "GCode": 100,
        "MCode": 30,
        "Vcode": 402,
        "Discontinued_Date": "2016-09-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "AVEO",
        "Variant": "AVEO 1.6 LS A\/T",
        "MM_CODES": 10030410,
        "GCode": 100,
        "MCode": 30,
        "Vcode": 410,
        "Discontinued_Date": "2016-09-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "AVEO",
        "Variant": "AVEO 1.6 LT",
        "MM_CODES": 10030441,
        "GCode": 100,
        "MCode": 30,
        "Vcode": 441,
        "Discontinued_Date": "2010-10-01 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "SONIC",
        "Variant": "SONIC 1.3D LS 5DR",
        "MM_CODES": 10031150,
        "GCode": 100,
        "MCode": 31,
        "Vcode": 150,
        "Discontinued_Date": "2015-08-26 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "SONIC",
        "Variant": "SONIC 1.4 LS 5DR",
        "MM_CODES": 10031250,
        "GCode": 100,
        "MCode": 31,
        "Vcode": 250,
        "Discontinued_Date": "2016-04-01 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "SONIC",
        "Variant": "SONIC 1.4T RS 5DR",
        "MM_CODES": 10031255,
        "GCode": 100,
        "MCode": 31,
        "Vcode": 255,
        "Discontinued_Date": "2016-09-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "SONIC",
        "Variant": "SONIC 1.4 LS",
        "MM_CODES": 10031260,
        "GCode": 100,
        "MCode": 31,
        "Vcode": 260,
        "Discontinued_Date": "2016-04-01 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "SONIC",
        "Variant": "SONIC 1.6 LS 5DR",
        "MM_CODES": 10031350,
        "GCode": 100,
        "MCode": 31,
        "Vcode": 350,
        "Discontinued_Date": "2016-09-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "SONIC",
        "Variant": "SONIC 1.6 LS",
        "MM_CODES": 10031360,
        "GCode": 100,
        "MCode": 31,
        "Vcode": 360,
        "Discontinued_Date": "2016-09-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "SONIC",
        "Variant": "SONIC 1.6 LS A\/T",
        "MM_CODES": 10031370,
        "GCode": 100,
        "MCode": 31,
        "Vcode": 370,
        "Discontinued_Date": "2016-09-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "OPTRA",
        "Variant": "OPTRA 1.6 L",
        "MM_CODES": 10032100,
        "GCode": 100,
        "MCode": 32,
        "Vcode": 100,
        "Discontinued_Date": "2013-06-26 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "OPTRA",
        "Variant": "OPTRA 1.6 LS",
        "MM_CODES": 10032120,
        "GCode": 100,
        "MCode": 32,
        "Vcode": 120,
        "Discontinued_Date": "2013-06-26 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CRUZE",
        "Variant": "CRUZE 2.0D LS",
        "MM_CODES": 10067170,
        "GCode": 100,
        "MCode": 67,
        "Vcode": 170,
        "Discontinued_Date": "2015-04-14 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CRUZE",
        "Variant": "CRUZE 2.0D LT",
        "MM_CODES": 10067180,
        "GCode": 100,
        "MCode": 67,
        "Vcode": 180,
        "Discontinued_Date": "2012-09-25 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CAPTIVA",
        "Variant": "CAPTIVA 2.0D LTZ 4X4",
        "MM_CODES": 10070120,
        "GCode": 100,
        "MCode": 70,
        "Vcode": 120,
        "Discontinued_Date": "2011-05-19 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CAPTIVA",
        "Variant": "CAPTIVA 2.2D LT A\/T",
        "MM_CODES": 10070126,
        "GCode": 100,
        "MCode": 70,
        "Vcode": 126,
        "Discontinued_Date": "2017-12-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CAPTIVA",
        "Variant": "CAPTIVA 2.2D LTZ 4X4 A\/T",
        "MM_CODES": 10070141,
        "GCode": 100,
        "MCode": 70,
        "Vcode": 141,
        "Discontinued_Date": "2014-03-17 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CAPTIVA",
        "Variant": "CAPTIVA 2.4 LT A\/T",
        "MM_CODES": 10070211,
        "GCode": 100,
        "MCode": 70,
        "Vcode": 211,
        "Discontinued_Date": "2017-12-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CAPTIVA",
        "Variant": "CAPTIVA 2.4 LT 4X4",
        "MM_CODES": 10070220,
        "GCode": 100,
        "MCode": 70,
        "Vcode": 220,
        "Discontinued_Date": "2011-05-19 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CAPTIVA",
        "Variant": "CAPTIVA 3.0 LTZ 4X4 A\/T",
        "MM_CODES": 10070250,
        "GCode": 100,
        "MCode": 70,
        "Vcode": 250,
        "Discontinued_Date": "2013-06-19 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "CAPTIVA",
        "Variant": "CAPTIVA 3.2 LTZ 4X4 A\/T",
        "MM_CODES": 10070300,
        "GCode": 100,
        "MCode": 70,
        "Vcode": 300,
        "Discontinued_Date": "2011-05-19 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "ORLANDO",
        "Variant": "ORLANDO 1.8LS",
        "MM_CODES": 10074150,
        "GCode": 100,
        "MCode": 74,
        "Vcode": 150,
        "Discontinued_Date": "2017-12-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "TRAILBLAZER",
        "Variant": "TRAILBLAZER 2.5 LT A\/T",
        "MM_CODES": 10077220,
        "GCode": 100,
        "MCode": 77,
        "Vcode": 220,
        "Discontinued_Date": "2017-12-21 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "TRAILBLAZER",
        "Variant": "TRAILBLAZER 2.8 LTZ A\/T",
        "MM_CODES": 10077320,
        "GCode": 100,
        "MCode": 77,
        "Vcode": 320,
        "Discontinued_Date": "2017-03-07 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "TRAILBLAZER",
        "Variant": "TRAILBLAZER 2.8 LTZ A\/T",
        "MM_CODES": 10077321,
        "GCode": 100,
        "MCode": 77,
        "Vcode": 321,
        "Discontinued_Date": "2018-11-13 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "TRAILBLAZER",
        "Variant": "TRAILBLAZER 2.8 LTZ 4X4 A\/T",
        "MM_CODES": 10077350,
        "GCode": 100,
        "MCode": 77,
        "Vcode": 350,
        "Discontinued_Date": "2017-03-07 00:00:00.000"
    },
    {
        "Make": "CHEVROLET",
        "Model": "TRAILBLAZER",
        "Variant": "TRAILBLAZER 2.8 LTZ 4X4 A\/T Z71",
        "MM_CODES": 10077360,
        "GCode": 100,
        "MCode": 77,
        "Vcode": 360,
        "Discontinued_Date": "2018-11-13 00:00:00.000"
    },
    {
        "Make": "CHERY",
        "Model": "J1",
        "Variant": "CHERY J1 1.3 TX",
        "MM_CODES": 10310110,
        "GCode": 103,
        "MCode": 10,
        "Vcode": 110,
        "Discontinued_Date": "2014-03-03 00:00:00.000"
    },
    {
        "Make": "CHERY",
        "Model": "J2",
        "Variant": "J2 1.5 TX (5DR)",
        "MM_CODES": 10311200,
        "GCode": 103,
        "MCode": 11,
        "Vcode": 200,
        "Discontinued_Date": "2018-03-22 00:00:00.000"
    },
    {
        "Make": "CHERY",
        "Model": "J2",
        "Variant": "J2 1.5 SWAG (5DR)",
        "MM_CODES": 10311210,
        "GCode": 103,
        "MCode": 11,
        "Vcode": 210,
        "Discontinued_Date": "2018-03-22 00:00:00.000"
    },
    {
        "Make": "CHERY",
        "Model": "J3",
        "Variant": "CHERY J3 1.6 TXE",
        "MM_CODES": 10312200,
        "GCode": 103,
        "MCode": 12,
        "Vcode": 200,
        "Discontinued_Date": "2018-03-22 00:00:00.000"
    },
    {
        "Make": "CHERY",
        "Model": "J3",
        "Variant": "J3 1.6 VVT",
        "MM_CODES": 10312201,
        "GCode": 103,
        "MCode": 12,
        "Vcode": 201,
        "Discontinued_Date": "2018-03-22 00:00:00.000"
    },
    {
        "Make": "CHERY",
        "Model": "TIGGO",
        "Variant": "CHERY TIGGO 1.6 VVT",
        "MM_CODES": 10320101,
        "GCode": 103,
        "MCode": 20,
        "Vcode": 101,
        "Discontinued_Date": "2018-03-22 00:00:00.000"
    },
    {
        "Make": "CHERY",
        "Model": "TIGGO 4",
        "Variant": "TIGGO 4 PRO 1.5T ELITE CVT ",
        "MM_CODES": 10323300,
        "GCode": 103,
        "MCode": 23,
        "Vcode": 300,
        "Discontinued_Date": "1899-12-30 00:00:00.000"
    },
    {
        "Make": "CHERY",
        "Model": "TIGGO 8 PRO",
        "Variant": "TIGGO 8 PRO 1.6 TDGI DISTINCTION DCT",
        "MM_CODES": 10328200,
        "GCode": 103,
        "MCode": 28,
        "Vcode": 200,
        "Discontinued_Date": "1899-12-30 00:00:00.000"
    },
    {
        "Make": "CHERY",
        "Model": "P-10",
        "Variant": "P-10 2.0TCI 15S",
        "MM_CODES": 10350150,
        "GCode": 103,
        "MCode": 50,
        "Vcode": 150,
        "Discontinued_Date": "2018-03-22 00:00:00.000"
    },
    {
        "Make": "CHRYSLER",
        "Model": "300C",
        "Variant": "300C 3.5 V6 A\/T",
        "MM_CODES": 11070100,
        "GCode": 110,
        "MCode": 70,
        "Vcode": 100,
        "Discontinued_Date": "2012-06-27 00:00:00.000"
    },
    {
        "Make": "CHRYSLER",
        "Model": "300C",
        "Variant": "300C 3.6L LUX A\/T",
        "MM_CODES": 11070110,
        "GCode": 110,
        "MCode": 70,
        "Vcode": 110,
        "Discontinued_Date": "2017-10-13 00:00:00.000"
    },
    {
        "Make": "CHRYSLER",
        "Model": "300C",
        "Variant": "300C 5.7 HEMI V8 A\/T",
        "MM_CODES": 11070200,
        "GCode": 110,
        "MCode": 70,
        "Vcode": 200,
        "Discontinued_Date": "2012-06-27 00:00:00.000"
    },
    {
        "Make": "CHRYSLER",
        "Model": "300C",
        "Variant": "300C SRT8",
        "MM_CODES": 11070250,
        "GCode": 110,
        "MCode": 70,
        "Vcode": 250,
        "Discontinued_Date": "2012-06-27 00:00:00.000"
    },
    {
        "Make": "CHRYSLER",
        "Model": "300C",
        "Variant": "300C SRT8",
        "MM_CODES": 11070251,
        "GCode": 110,
        "MCode": 70,
        "Vcode": 251,
        "Discontinued_Date": "2017-10-13 00:00:00.000"
    },
    {
        "Make": "CHRYSLER",
        "Model": "VOYAGER 2001 - ON",
        "Variant": "GRAND VOYAGER 2.8 LX A\/T",
        "MM_CODES": 11086331,
        "GCode": 110,
        "MCode": 86,
        "Vcode": 331,
        "Discontinued_Date": "2017-10-13 00:00:00.000"
    },
    {
        "Make": "CHRYSLER",
        "Model": "VOYAGER 2001 - ON",
        "Variant": "GRAND VOYAGER 2.8 LIMITED A\/T",
        "MM_CODES": 11086340,
        "GCode": 110,
        "MCode": 86,
        "Vcode": 340,
        "Discontinued_Date": "2017-10-13 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "BERLINGO I \/ II",
        "Variant": "BERLINGO 1.6I F\/C P\/V",
        "MM_CODES": 12010160,
        "GCode": 120,
        "MCode": 10,
        "Vcode": 160,
        "Discontinued_Date": "2015-11-02 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "BERLINGO I \/ II",
        "Variant": "BERLINGO 1.6 HDI F\/C P\/V",
        "MM_CODES": 12010180,
        "GCode": 120,
        "MCode": 10,
        "Vcode": 180,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "C3 1.2 VTI 82 SEDUCTION",
        "MM_CODES": 12011385,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 385,
        "Discontinued_Date": "2015-11-03 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "C3 1.2 PURETECH FEEL (66KW)",
        "MM_CODES": 12011390,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 390,
        "Discontinued_Date": "2023-05-22 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "C3 1.4 ATTRACTION",
        "MM_CODES": 12011400,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 400,
        "Discontinued_Date": "2013-06-13 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "C3 1.4 VTI SEDUCTION",
        "MM_CODES": 12011420,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 420,
        "Discontinued_Date": "2012-09-14 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "C3 1.4 HDI",
        "MM_CODES": 12011440,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 440,
        "Discontinued_Date": "2012-09-14 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "C3 1.6 VTI 120 SEDUCTION",
        "MM_CODES": 12011460,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 460,
        "Discontinued_Date": "2015-11-03 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "C3 1.6 VTI 120 EXCLUSIVE",
        "MM_CODES": 12011491,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 491,
        "Discontinued_Date": "2015-11-03 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "C3 1.6 VTI EXCLUSIVE A\/T",
        "MM_CODES": 12011495,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 495,
        "Discontinued_Date": "2013-06-13 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "C3 1.6 HDI SEDUCTION",
        "MM_CODES": 12011550,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 550,
        "Discontinued_Date": "2012-09-14 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "C3 1.6 E- HDI 90 SEDUCTION",
        "MM_CODES": 12011551,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 551,
        "Discontinued_Date": "2013-06-13 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "DS3 1.2 VTI DESIGN 3DR",
        "MM_CODES": 12011600,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 600,
        "Discontinued_Date": "2015-05-19 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "DS3 1.2 PURETECH DESIGN 3DR (60KW)",
        "MM_CODES": 12011601,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 601,
        "Discontinued_Date": "2017-11-29 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "DS3 1.2 PURETECH STYLE 3DR (81KW)",
        "MM_CODES": 12011603,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 603,
        "Discontinued_Date": "2017-11-29 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "DS3 CABRIO 1.2 VTI DESIGN",
        "MM_CODES": 12011610,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 610,
        "Discontinued_Date": "2015-05-19 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "DS3 CABRIO 1.2 PURETCH STYLE (81KW)",
        "MM_CODES": 12011615,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 615,
        "Discontinued_Date": "2017-11-29 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "DS3 1.6 VTI STYLE",
        "MM_CODES": 12011650,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 650,
        "Discontinued_Date": "2015-09-14 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "DS3 1.6 STYLE A\/T 3DR",
        "MM_CODES": 12011653,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 653,
        "Discontinued_Date": "2017-11-29 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "DS3 CABRIO 1.6 VTI STYLE",
        "MM_CODES": 12011655,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 655,
        "Discontinued_Date": "2015-05-19 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "DS3 1.6 VTI DESIGN",
        "MM_CODES": 12011660,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 660,
        "Discontinued_Date": "2015-05-19 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "DS3 1.6 VTI TECHNO",
        "MM_CODES": 12011670,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 670,
        "Discontinued_Date": "2015-05-19 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "DS3 1.6 VTI TECHNO A\/T",
        "MM_CODES": 12011675,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 675,
        "Discontinued_Date": "2015-05-19 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "DS3 1.6 THP SPORT 3DR",
        "MM_CODES": 12011680,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 680,
        "Discontinued_Date": "2015-05-19 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "DS3 1.6 E-THP SPORT 3DR (120KW)",
        "MM_CODES": 12011681,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 681,
        "Discontinued_Date": "2017-11-29 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C3",
        "Variant": "DS3 CABRIO 1.6 THP SPORT",
        "MM_CODES": 12011683,
        "GCode": 120,
        "MCode": 11,
        "Vcode": 683,
        "Discontinued_Date": "2015-05-19 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "C4 1.6 VTI SEDUCTION A\/T",
        "MM_CODES": 12021385,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 385,
        "Discontinued_Date": "2016-06-07 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "C4 1.6 VTI EXCLUSIVE",
        "MM_CODES": 12021390,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 390,
        "Discontinued_Date": "2017-11-29 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "C4 1.6 THP EXCLUSIVE",
        "MM_CODES": 12021400,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 400,
        "Discontinued_Date": "2016-06-07 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "C4 1.6 THP EXCLUSIVE A\/T",
        "MM_CODES": 12021410,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 410,
        "Discontinued_Date": "2013-10-01 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "DS4 1.6 VTI 120 DESIGN 5DR",
        "MM_CODES": 12021440,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 440,
        "Discontinued_Date": "2017-06-02 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "DS4 1.6 VTI 120 STYLE 5DR",
        "MM_CODES": 12021460,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 460,
        "Discontinued_Date": "2014-08-06 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "DS4 1.6 VTI 120 STYLE S\/ PACK 5DR",
        "MM_CODES": 12021470,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 470,
        "Discontinued_Date": "2014-08-06 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "DS4 1.6 THP STYLE 5DR A\/T",
        "MM_CODES": 12021472,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 472,
        "Discontinued_Date": "2017-06-02 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "DS4 1.6 THP 200 SPORT 5DR",
        "MM_CODES": 12021480,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 480,
        "Discontinued_Date": "2016-06-07 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "DS4 2.0 HDI 160 SPORT 5DR",
        "MM_CODES": 12021550,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 550,
        "Discontinued_Date": "2017-06-02 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "C4 AIRCROSS 1.6 HDI SEDUCTION",
        "MM_CODES": 12021575,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 575,
        "Discontinued_Date": "2017-11-29 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "C4 AIRCROSS 2.0 SEDUCTION",
        "MM_CODES": 12021620,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 620,
        "Discontinued_Date": "2017-11-29 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "C4 AIRCROSS 2.0 EXCLUSIVE AWD A\/T",
        "MM_CODES": 12021650,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 650,
        "Discontinued_Date": "2017-11-29 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "C4 CACTUS 1.2T PURETECH FEEL (81KW)",
        "MM_CODES": 12021720,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 720,
        "Discontinued_Date": "2017-11-29 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C4",
        "Variant": "C4 CACTUS 1.2T PURETECH SHINE (81KW)",
        "MM_CODES": 12021740,
        "GCode": 120,
        "MCode": 21,
        "Vcode": 740,
        "Discontinued_Date": "2017-11-29 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "DISPATCH",
        "Variant": "DISPATCH MULTISPACE 2.0 HDI A\/T 8 SEAT",
        "MM_CODES": 12030250,
        "GCode": 120,
        "MCode": 30,
        "Vcode": 250,
        "Discontinued_Date": "2016-12-08 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "DISPATCH",
        "Variant": "DISPATCH MULTISPACE 2.0 HDI A\/T 9 SEAT",
        "MM_CODES": 12030260,
        "GCode": 120,
        "MCode": 30,
        "Vcode": 260,
        "Discontinued_Date": "2016-12-08 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C5",
        "Variant": "DS5  1.6 THP STYLE (147KW)",
        "MM_CODES": 12060520,
        "GCode": 120,
        "MCode": 60,
        "Vcode": 520,
        "Discontinued_Date": "2014-05-13 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C5",
        "Variant": "DS5  1.6 THP SPORT (147KW)",
        "MM_CODES": 12060540,
        "GCode": 120,
        "MCode": 60,
        "Vcode": 540,
        "Discontinued_Date": "2017-11-29 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C5",
        "Variant": "DS5 2.0 HDI SPORT A\/T",
        "MM_CODES": 12060600,
        "GCode": 120,
        "MCode": 60,
        "Vcode": 600,
        "Discontinued_Date": "2017-11-29 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C5",
        "Variant": "C5 AIRCROSS 1.6 THP FEEL (121KW)",
        "MM_CODES": 12060650,
        "GCode": 120,
        "MCode": 60,
        "Vcode": 650,
        "Discontinued_Date": "2022-11-09 00:00:00.000"
    },
    {
        "Make": "CITROEN",
        "Model": "C5",
        "Variant": "C5 AIRCROSS 1.6 THP SHINE (121KW)",
        "MM_CODES": 12060670,
        "GCode": 120,
        "MCode": 60,
        "Vcode": 670,
        "Discontinued_Date": "2022-11-09 00:00:00.000"
    },
    {
        "Make": "DAIHATSU",
        "Model": "GRAN MAX",
        "Variant": "GRAN MAX 1.5 HIGH P\/U D\/S",
        "MM_CODES": 13030260,
        "GCode": 130,
        "MCode": 30,
        "Vcode": 260,
        "Discontinued_Date": "2015-12-17 00:00:00.000"
    },
    {
        "Make": "DAIHATSU",
        "Model": "2.0 \/ 3.5 \/ 4.0 TON",
        "Variant": "3.5 TON F\/C C\/C",
        "MM_CODES": 13045200,
        "GCode": 130,
        "MCode": 45,
        "Vcode": 200,
        "Discontinued_Date": "2015-03-25 00:00:00.000"
    },
    {
        "Make": "DAIHATSU",
        "Model": "2.0 \/ 3.5 \/ 4.0 TON",
        "Variant": "4.0 TON F\/C C\/C",
        "MM_CODES": 13045400,
        "GCode": 130,
        "MCode": 45,
        "Vcode": 400,
        "Discontinued_Date": "2015-03-25 00:00:00.000"
    },
    {
        "Make": "DAIHATSU",
        "Model": "MATERIA",
        "Variant": "MATERIA 1.5",
        "MM_CODES": 13065200,
        "GCode": 130,
        "MCode": 65,
        "Vcode": 200,
        "Discontinued_Date": "2012-11-02 00:00:00.000"
    },
    {
        "Make": "DAIHATSU",
        "Model": "TERIOS \/ ROCKY \/ FEROZA",
        "Variant": "TERIOS",
        "MM_CODES": 13080220,
        "GCode": 130,
        "MCode": 80,
        "Vcode": 220,
        "Discontinued_Date": "2015-11-03 00:00:00.000"
    },
    {
        "Make": "DAIHATSU",
        "Model": "TERIOS \/ ROCKY \/ FEROZA",
        "Variant": "TERIOS 7 SEAT A\/T",
        "MM_CODES": 13080230,
        "GCode": 130,
        "MCode": 80,
        "Vcode": 230,
        "Discontinued_Date": "2013-11-08 00:00:00.000"
    },
    {
        "Make": "DAIHATSU",
        "Model": "TERIOS \/ ROCKY \/ FEROZA",
        "Variant": "TERIOS 4X4 UPGRADE",
        "MM_CODES": 13080254,
        "GCode": 130,
        "MCode": 80,
        "Vcode": 254,
        "Discontinued_Date": "2015-11-03 00:00:00.000"
    },
    {
        "Make": "DODGE",
        "Model": "CALIBER",
        "Variant": "CALIBER 2.0 SXT",
        "MM_CODES": 16020290,
        "GCode": 160,
        "MCode": 20,
        "Vcode": 290,
        "Discontinued_Date": "2013-06-04 00:00:00.000"
    },
    {
        "Make": "DODGE",
        "Model": "CALIBER",
        "Variant": "CALIBER 2.0 CVT SXT",
        "MM_CODES": 16020301,
        "GCode": 160,
        "MCode": 20,
        "Vcode": 301,
        "Discontinued_Date": "2013-06-04 00:00:00.000"
    },
    {
        "Make": "DODGE",
        "Model": "CALIBER",
        "Variant": "CALIBER 2.4 R\/T",
        "MM_CODES": 16020400,
        "GCode": 160,
        "MCode": 20,
        "Vcode": 400,
        "Discontinued_Date": "2007-12-01 00:00:00.000"
    },
    {
        "Make": "DODGE",
        "Model": "CALIBER",
        "Variant": "CALIBER 2.4 SXT",
        "MM_CODES": 16020410,
        "GCode": 160,
        "MCode": 20,
        "Vcode": 410,
        "Discontinued_Date": "2010-05-24 00:00:00.000"
    },
    {
        "Make": "DODGE",
        "Model": "JOURNEY",
        "Variant": "JOURNEY 2.0 CRD RT A\/T",
        "MM_CODES": 16040120,
        "GCode": 160,
        "MCode": 40,
        "Vcode": 120,
        "Discontinued_Date": "2011-07-20 00:00:00.000"
    },
    {
        "Make": "DODGE",
        "Model": "JOURNEY",
        "Variant": "JOURNEY 3.6 V6 SXT A\/T",
        "MM_CODES": 16040300,
        "GCode": 160,
        "MCode": 40,
        "Vcode": 300,
        "Discontinued_Date": "2015-10-26 00:00:00.000"
    },
    {
        "Make": "DODGE",
        "Model": "JOURNEY",
        "Variant": "JOURNEY 3.6 V6 R\/T A\/T",
        "MM_CODES": 16040310,
        "GCode": 160,
        "MCode": 40,
        "Vcode": 310,
        "Discontinued_Date": "2017-10-13 00:00:00.000"
    },
    {
        "Make": "DODGE",
        "Model": "JOURNEY",
        "Variant": "JOURNEY 3.6 V6 CROSSROAD",
        "MM_CODES": 16040320,
        "GCode": 160,
        "MCode": 40,
        "Vcode": 320,
        "Discontinued_Date": "2017-10-13 00:00:00.000"
    },
    {
        "Make": "FAW",
        "Model": "SIRIUS",
        "Variant": "SIRIUS S80 1.5 7 SEAT",
        "MM_CODES": 18610150,
        "GCode": 186,
        "MCode": 10,
        "Vcode": 150,
        "Discontinued_Date": "2018-03-22 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "PANDA",
        "Variant": "PANDA 1.4 HP100",
        "MM_CODES": 20011151,
        "GCode": 200,
        "MCode": 11,
        "Vcode": 151,
        "Discontinued_Date": "2011-11-22 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "PANDA",
        "Variant": "PANDA 1.2 LOUNGE",
        "MM_CODES": 20011170,
        "GCode": 200,
        "MCode": 11,
        "Vcode": 170,
        "Discontinued_Date": "2017-05-22 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "PANDA",
        "Variant": "PANDA 900T EASY",
        "MM_CODES": 20011200,
        "GCode": 200,
        "MCode": 11,
        "Vcode": 200,
        "Discontinued_Date": "2021-03-24 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "PANDA",
        "Variant": "PANDA 900T 4X4",
        "MM_CODES": 20011250,
        "GCode": 200,
        "MCode": 11,
        "Vcode": 250,
        "Discontinued_Date": "2021-03-24 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "PANDA",
        "Variant": "PANDA 900T CROSS 4X4",
        "MM_CODES": 20011270,
        "GCode": 200,
        "MCode": 11,
        "Vcode": 270,
        "Discontinued_Date": "2021-03-24 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "PUNTO",
        "Variant": "PUNTO 1.4 ESSENCE 5 DR",
        "MM_CODES": 20015190,
        "GCode": 200,
        "MCode": 15,
        "Vcode": 190,
        "Discontinued_Date": "2012-02-07 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "PUNTO",
        "Variant": "PUNTO 1.4 EMOTION 5DR",
        "MM_CODES": 20015200,
        "GCode": 200,
        "MCode": 15,
        "Vcode": 200,
        "Discontinued_Date": "2011-11-22 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "PUNTO",
        "Variant": "PUNTO 1.4 POP 5DR",
        "MM_CODES": 20015300,
        "GCode": 200,
        "MCode": 15,
        "Vcode": 300,
        "Discontinued_Date": "2013-05-20 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "PUNTO",
        "Variant": "PUNTO 1.4 EASY 5DR",
        "MM_CODES": 20015310,
        "GCode": 200,
        "MCode": 15,
        "Vcode": 310,
        "Discontinued_Date": "2018-12-12 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "PUNTO",
        "Variant": "PUNTO 1.4T LOUNGE MULTI AIR",
        "MM_CODES": 20015330,
        "GCode": 200,
        "MCode": 15,
        "Vcode": 330,
        "Discontinued_Date": "2013-07-16 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "STRADA \/ FULLBACK",
        "Variant": "STRADA 1.4 LIFE P\/U S\/C",
        "MM_CODES": 20019155,
        "GCode": 200,
        "MCode": 19,
        "Vcode": 155,
        "Discontinued_Date": "2012-12-10 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "STRADA \/ FULLBACK",
        "Variant": "FULLBACK 2.4 DI-D 4X4 A\/T P\/U D\/C",
        "MM_CODES": 20019590,
        "GCode": 200,
        "MCode": 19,
        "Vcode": 590,
        "Discontinued_Date": "2021-09-14 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "STRADA \/ FULLBACK",
        "Variant": "FULLBACK 2.5 DI-D P\/U S\/C",
        "MM_CODES": 20019600,
        "GCode": 200,
        "MCode": 19,
        "Vcode": 600,
        "Discontinued_Date": "2021-09-14 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "STRADA \/ FULLBACK",
        "Variant": "FULLBACK 2.5 DI-D P\/U D\/C",
        "MM_CODES": 20019650,
        "GCode": 200,
        "MCode": 19,
        "Vcode": 650,
        "Discontinued_Date": "2021-03-24 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "STRADA \/ FULLBACK",
        "Variant": "FULLBACK 2.5 DI-D 4X4 131KW P\/U D\/C",
        "MM_CODES": 20019700,
        "GCode": 200,
        "MCode": 19,
        "Vcode": 700,
        "Discontinued_Date": "2021-03-24 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500",
        "Variant": "500 1.2 LOUNGE",
        "MM_CODES": 20025103,
        "GCode": 200,
        "MCode": 25,
        "Vcode": 103,
        "Discontinued_Date": "2018-11-12 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500",
        "Variant": "500 1.2 CABRIOLET",
        "MM_CODES": 20025105,
        "GCode": 200,
        "MCode": 25,
        "Vcode": 105,
        "Discontinued_Date": "2018-11-13 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500",
        "Variant": "500 1.4 POP",
        "MM_CODES": 20025110,
        "GCode": 200,
        "MCode": 25,
        "Vcode": 110,
        "Discontinued_Date": "2011-11-22 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500",
        "Variant": "500 L 1.4 EASY 5DR",
        "MM_CODES": 20025115,
        "GCode": 200,
        "MCode": 25,
        "Vcode": 115,
        "Discontinued_Date": "2018-11-13 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500",
        "Variant": "500 1.4 SPORT",
        "MM_CODES": 20025122,
        "GCode": 200,
        "MCode": 25,
        "Vcode": 122,
        "Discontinued_Date": "2018-11-20 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500",
        "Variant": "500 1.4 SPORT 150 ANNIVESARY ED",
        "MM_CODES": 20025123,
        "GCode": 200,
        "MCode": 25,
        "Vcode": 123,
        "Discontinued_Date": "2012-10-23 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500",
        "Variant": "500 1.4 SPORT M\/BLACK",
        "MM_CODES": 20025124,
        "GCode": 200,
        "MCode": 25,
        "Vcode": 124,
        "Discontinued_Date": "2013-01-15 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500",
        "Variant": "500 1.4 LOUNGE",
        "MM_CODES": 20025126,
        "GCode": 200,
        "MCode": 25,
        "Vcode": 126,
        "Discontinued_Date": "2018-11-20 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500",
        "Variant": "500 L 1.4 LOUNGE 5DR",
        "MM_CODES": 20025127,
        "GCode": 200,
        "MCode": 25,
        "Vcode": 127,
        "Discontinued_Date": "2018-11-20 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500",
        "Variant": "500 1.4 BY DIESEL",
        "MM_CODES": 20025130,
        "GCode": 200,
        "MCode": 25,
        "Vcode": 130,
        "Discontinued_Date": "2012-10-23 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500",
        "Variant": "500 1.4 CABRIOLET",
        "MM_CODES": 20025150,
        "GCode": 200,
        "MCode": 25,
        "Vcode": 150,
        "Discontinued_Date": "2018-11-29 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500",
        "Variant": "500 1.4 BY DIESEL CABRIOLET",
        "MM_CODES": 20025170,
        "GCode": 200,
        "MCode": 25,
        "Vcode": 170,
        "Discontinued_Date": "2012-10-23 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500",
        "Variant": "500 L 1.4 TJET TREKKING 5DR",
        "MM_CODES": 20025190,
        "GCode": 200,
        "MCode": 25,
        "Vcode": 190,
        "Discontinued_Date": "2018-11-29 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500",
        "Variant": "500 L 1.6 MJET LOUNGE 5DR",
        "MM_CODES": 20025220,
        "GCode": 200,
        "MCode": 25,
        "Vcode": 220,
        "Discontinued_Date": "2018-11-29 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500X",
        "Variant": "500X 1.4T POP STAR",
        "MM_CODES": 20028150,
        "GCode": 200,
        "MCode": 28,
        "Vcode": 150,
        "Discontinued_Date": "2021-03-23 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500X",
        "Variant": "500X 1.4T POP STAR DDCT",
        "MM_CODES": 20028160,
        "GCode": 200,
        "MCode": 28,
        "Vcode": 160,
        "Discontinued_Date": "2020-07-27 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500X",
        "Variant": "500X 1.4T CROSS",
        "MM_CODES": 20028200,
        "GCode": 200,
        "MCode": 28,
        "Vcode": 200,
        "Discontinued_Date": "2021-05-24 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "500X",
        "Variant": "500X 1.4T CROSS DDCT",
        "MM_CODES": 20028210,
        "GCode": 200,
        "MCode": 28,
        "Vcode": 210,
        "Discontinued_Date": "2020-07-27 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "TIPO",
        "Variant": "TIPO 1.6 EASY A\/T",
        "MM_CODES": 20039350,
        "GCode": 200,
        "MCode": 39,
        "Vcode": 350,
        "Discontinued_Date": "2021-06-29 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "DOBLO\/FIORINO",
        "Variant": "DOBLO CARGO 1.3 MJT F\/C P\/V",
        "MM_CODES": 20051101,
        "GCode": 200,
        "MCode": 51,
        "Vcode": 101,
        "Discontinued_Date": "2016-07-18 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "DOBLO\/FIORINO",
        "Variant": "DOBLO CARGO 1.4 F\/C P\/V",
        "MM_CODES": 20051122,
        "GCode": 200,
        "MCode": 51,
        "Vcode": 122,
        "Discontinued_Date": "2022-04-19 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "DOBLO\/FIORINO",
        "Variant": "FIORINO 1.3 MJT F\/C P\/V",
        "MM_CODES": 20051500,
        "GCode": 200,
        "MCode": 51,
        "Vcode": 500,
        "Discontinued_Date": "2016-09-14 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "DOBLO\/FIORINO",
        "Variant": "FIORINO 1.4 F\/C P\/V",
        "MM_CODES": 20051550,
        "GCode": 200,
        "MCode": 51,
        "Vcode": 550,
        "Discontinued_Date": "2015-09-07 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "DOBLO\/FIORINO",
        "Variant": "FIORINO 1.4 F\/C P\/V",
        "MM_CODES": 20051551,
        "GCode": 200,
        "MCode": 51,
        "Vcode": 551,
        "Discontinued_Date": "2016-09-14 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "BRAVO",
        "Variant": "BRAVO 1.4 T-JET SPORT 5DR",
        "MM_CODES": 20065180,
        "GCode": 200,
        "MCode": 65,
        "Vcode": 180,
        "Discontinued_Date": "2010-07-29 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "DUCATO",
        "Variant": "DUCATO 2.3 JTD LWB HR F\/C P\/V",
        "MM_CODES": 20085240,
        "GCode": 200,
        "MCode": 85,
        "Vcode": 240,
        "Discontinued_Date": "2014-01-07 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "DUCATO",
        "Variant": "DUCATO MH2 C8 F\/C P\/V",
        "MM_CODES": 20085520,
        "GCode": 200,
        "MCode": 85,
        "Vcode": 520,
        "Discontinued_Date": "2019-07-01 00:00:00.000"
    },
    {
        "Make": "FIAT",
        "Model": "MULTIPLA\/QUBO",
        "Variant": "QUBO 1.3D MULTI JET",
        "MM_CODES": 20088250,
        "GCode": 200,
        "MCode": 88,
        "Vcode": 250,
        "Discontinued_Date": "2019-07-09 00:00:00.000"
    },
    {
        "Make": "FOTON",
        "Model": "INKUNZI \/ VIEW",
        "Variant": "INKUNZI 2.2 TAXI",
        "MM_CODES": 21518200,
        "GCode": 215,
        "MCode": 18,
        "Vcode": 200,
        "Discontinued_Date": "2019-11-22 00:00:00.000"
    },
    {
        "Make": "FOTON",
        "Model": "THUNDA",
        "Variant": "THUNDA 2.2I V S\/C P\/U",
        "MM_CODES": 21520110,
        "GCode": 215,
        "MCode": 20,
        "Vcode": 110,
        "Discontinued_Date": "2018-06-08 00:00:00.000"
    },
    {
        "Make": "FOTON",
        "Model": "THUNDA",
        "Variant": "THUNDA 2.2I CV S\/C P\/U",
        "MM_CODES": 21520120,
        "GCode": 215,
        "MCode": 20,
        "Vcode": 120,
        "Discontinued_Date": "2018-06-08 00:00:00.000"
    },
    {
        "Make": "FOTON",
        "Model": "THUNDA",
        "Variant": "THUNDA 2.8TD XV D\/C P\/U",
        "MM_CODES": 21520350,
        "GCode": 215,
        "MCode": 20,
        "Vcode": 350,
        "Discontinued_Date": "2018-06-08 00:00:00.000"
    },
    {
        "Make": "FOTON",
        "Model": "TUNLAND",
        "Variant": "TUNLAND 2.8 ISF ON ROAD P\/U S\/C",
        "MM_CODES": 21522195,
        "GCode": 215,
        "MCode": 22,
        "Vcode": 195,
        "Discontinued_Date": "2018-06-08 00:00:00.000"
    },
    {
        "Make": "FOTON",
        "Model": "TUNLAND",
        "Variant": "TUNLAND 2.8 ISF LUXURY P\/U D\/C",
        "MM_CODES": 21522340,
        "GCode": 215,
        "MCode": 22,
        "Vcode": 340,
        "Discontinued_Date": "2018-06-08 00:00:00.000"
    },
    {
        "Make": "FOTON",
        "Model": "TUNLAND",
        "Variant": "TUNLAND 2.8 ISF LUXURY 4X4 P\/U D\/C",
        "MM_CODES": 21522350,
        "GCode": 215,
        "MCode": 22,
        "Vcode": 350,
        "Discontinued_Date": "2018-06-08 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "BANTAM 2002 - ON",
        "Variant": "BANTAM 1.4 TDCI P\/U S\/C",
        "MM_CODES": 22014300,
        "GCode": 220,
        "MCode": 14,
        "Vcode": 300,
        "Discontinued_Date": "2012-06-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "BANTAM 2002 - 2012",
        "Variant": "BANTAM 1.4 TDCI  A\/C P\/U S\/C",
        "MM_CODES": 22014320,
        "GCode": 220,
        "MCode": 14,
        "Vcode": 320,
        "Discontinued_Date": "2012-06-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "BANTAM 2002 - ON",
        "Variant": "BANTAM 1.4 TDCI  A\/C P\/U S\/C",
        "MM_CODES": 22014320,
        "GCode": 220,
        "MCode": 14,
        "Vcode": 320,
        "Discontinued_Date": "2012-06-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "BANTAM 2002 - ON",
        "Variant": "BANTAM 1.4 TDCI  XLT P\/U S\/C",
        "MM_CODES": 22014370,
        "GCode": 220,
        "MCode": 14,
        "Vcode": 370,
        "Discontinued_Date": "2012-06-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "BANTAM 2002 - 2012",
        "Variant": "BANTAM 1.6I P\/U S\/C",
        "MM_CODES": 22014402,
        "GCode": 220,
        "MCode": 14,
        "Vcode": 402,
        "Discontinued_Date": "2012-06-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "BANTAM 2002 - ON",
        "Variant": "BANTAM 1.6I P\/U S\/C",
        "MM_CODES": 22014402,
        "GCode": 220,
        "MCode": 14,
        "Vcode": 402,
        "Discontinued_Date": "2012-06-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "BANTAM 2002 - 2012",
        "Variant": "BANTAM 1.6I XL P\/U S\/C",
        "MM_CODES": 22014421,
        "GCode": 220,
        "MCode": 14,
        "Vcode": 421,
        "Discontinued_Date": "2012-06-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "BANTAM 2002 - ON",
        "Variant": "BANTAM 1.6I XL P\/U S\/C",
        "MM_CODES": 22014421,
        "GCode": 220,
        "MCode": 14,
        "Vcode": 421,
        "Discontinued_Date": "2012-06-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "BANTAM 2002 - 2012",
        "Variant": "BANTAM 1.6I XLT P\/U S\/C",
        "MM_CODES": 22014436,
        "GCode": 220,
        "MCode": 14,
        "Vcode": 436,
        "Discontinued_Date": "2012-06-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "BANTAM 2002 - ON",
        "Variant": "BANTAM 1.6I XLT P\/U S\/C",
        "MM_CODES": 22014436,
        "GCode": 220,
        "MCode": 14,
        "Vcode": 436,
        "Discontinued_Date": "2012-06-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "BANTAM 2002 - 2012",
        "Variant": "BANTAM 1.6I XLE P\/U S\/C",
        "MM_CODES": 22014456,
        "GCode": 220,
        "MCode": 14,
        "Vcode": 456,
        "Discontinued_Date": "2012-06-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "BANTAM 2002 - ON",
        "Variant": "BANTAM 1.6I XLE P\/U S\/C",
        "MM_CODES": 22014456,
        "GCode": 220,
        "MCode": 14,
        "Vcode": 456,
        "Discontinued_Date": "2012-06-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.4 AMBIENTE",
        "MM_CODES": 22020100,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 100,
        "Discontinued_Date": "2018-11-29 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.4 TDCI AMBIENTE",
        "MM_CODES": 22020150,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 150,
        "Discontinued_Date": "2018-11-29 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.5 AMBIENTE (5DR)",
        "MM_CODES": 22020250,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 250,
        "Discontinued_Date": "2018-06-18 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.5TI VCT AMBIENTE (5DR)",
        "MM_CODES": 22020251,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 251,
        "Discontinued_Date": "2022-04-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.5 TREND (5DR)",
        "MM_CODES": 22020260,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 260,
        "Discontinued_Date": "2018-06-18 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.5TI VCT TREND (5DR)",
        "MM_CODES": 22020261,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 261,
        "Discontinued_Date": "2022-04-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO FREESTYLE 1.5TI VCT TREND (5DR)",
        "MM_CODES": 22020263,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 263,
        "Discontinued_Date": "2022-04-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.5TI VCT TREND A\/T (5DR)",
        "MM_CODES": 22020266,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 266,
        "Discontinued_Date": "2022-04-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.5 TITANIUM (5DR)",
        "MM_CODES": 22020270,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 270,
        "Discontinued_Date": "2018-06-18 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.5TI VCT TITAMIUM (5DR)",
        "MM_CODES": 22020271,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 271,
        "Discontinued_Date": "2022-04-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.5 TITANIUM POWERSHIFT (5DR)",
        "MM_CODES": 22020280,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 280,
        "Discontinued_Date": "2018-06-18 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.5 TDCI  TREND (5DR)",
        "MM_CODES": 22020300,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 300,
        "Discontinued_Date": "2020-09-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.5  AMBIENTE",
        "MM_CODES": 22020310,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 310,
        "Discontinued_Date": "2018-06-18 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.5TI VCT AMBIENTE ",
        "MM_CODES": 22020311,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 311,
        "Discontinued_Date": "2022-04-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.5 TREND",
        "MM_CODES": 22020320,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 320,
        "Discontinued_Date": "2018-06-18 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.5TI VCT TREND",
        "MM_CODES": 22020321,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 321,
        "Discontinued_Date": "2022-04-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIGO",
        "Variant": "FIGO 1.5 TITANIUM POWERSHIFT",
        "MM_CODES": 22020330,
        "GCode": 220,
        "MCode": 20,
        "Vcode": 330,
        "Discontinued_Date": "2018-06-18 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI P\/U S\/C",
        "MM_CODES": 22032123,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 123,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI P\/U S\/C",
        "MM_CODES": 22032123,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 123,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XL L\/R P\/U S\/C",
        "MM_CODES": 22032125,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 125,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI XL L\/R P\/U S\/C",
        "MM_CODES": 22032125,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 125,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XL P\/U S\/C",
        "MM_CODES": 22032127,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 127,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI XL P\/U S\/C",
        "MM_CODES": 22032127,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 127,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI XL PLUS 4X4 P\/U S\/C",
        "MM_CODES": 22032130,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 130,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XLS P\/U S\/C",
        "MM_CODES": 22032131,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 131,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI XLS P\/U S\/C",
        "MM_CODES": 22032131,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 131,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XLS 4X4 P\/U S\/C",
        "MM_CODES": 22032133,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 133,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI XLS 4X4 P\/U S\/C",
        "MM_CODES": 22032133,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 133,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XL P\/U SUP\/CAB",
        "MM_CODES": 22032135,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 135,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI XL P\/U SUP\/CAB",
        "MM_CODES": 22032135,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 135,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XLS P\/U D\/C",
        "MM_CODES": 22032139,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 139,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI XLS PU D\/C",
        "MM_CODES": 22032139,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 139,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XLS 4X4 P\/U D\/C",
        "MM_CODES": 22032141,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 141,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI XLS 4X4 PU\/D\/C",
        "MM_CODES": 22032141,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 141,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.5I P\/U C\/C",
        "MM_CODES": 22032142,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 142,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.5I XL HI-TRAIL P\/U S\/C",
        "MM_CODES": 22032147,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 147,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.5I XL P\/U SUP\/CAB",
        "MM_CODES": 22032149,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 149,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.5I XL P\/U SUP\/CAB",
        "MM_CODES": 22032149,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 149,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.5I XL P\/U D\/C",
        "MM_CODES": 22032151,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 151,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.5I XL P\/U D\/C",
        "MM_CODES": 22032151,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 151,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.5 TD 4X4 P\/U S\/C",
        "MM_CODES": 22032201,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 201,
        "Discontinued_Date": "2012-06-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.5 TD 4X4 P\/U S\/C",
        "MM_CODES": 22032201,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 201,
        "Discontinued_Date": "2012-06-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.0TDCI XLT HI -TRAIL P\/U SUP\/CAB",
        "MM_CODES": 22032331,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 331,
        "Discontinued_Date": "2012-06-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 3.0TDCI XLT HI -TRAIL P\/U SUP\/CAB",
        "MM_CODES": 22032331,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 331,
        "Discontinued_Date": "2012-06-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 3.0TDCI HI -TRAIL XLE A\/T P\/U D\/C",
        "MM_CODES": 22032381,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 381,
        "Discontinued_Date": "2012-06-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.0TDCI WILDTRAK 4X4 P\/U D\/C",
        "MM_CODES": 22032392,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 392,
        "Discontinued_Date": "2012-06-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 3.0TDCI WILDTRAK 4X4 P\/U D\/C",
        "MM_CODES": 22032392,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 392,
        "Discontinued_Date": "2012-06-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.2TDCI XLT 4X4 P\/U D\/C",
        "MM_CODES": 22032415,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 415,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 3.2TDCI XLT 4X4 P\/U D\/C",
        "MM_CODES": 22032415,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 415,
        "Discontinued_Date": "2015-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.2TDCI XLT 4X4 A\/T P\/U D\/C",
        "MM_CODES": 22032417,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 417,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 3.2TDCI XLT 4X4 A\/T P\/U D\/C",
        "MM_CODES": 22032417,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 417,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.2TDCI WILDTRAK 4X4 A\/T P\/U D\/C",
        "MM_CODES": 22032555,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 555,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 3.2TDCI WILDTRAK 4X4 A\/T P\/U D\/C",
        "MM_CODES": 22032555,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 555,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.0D XLT A\/T P\/U D\/C",
        "MM_CODES": 22032568,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 568,
        "Discontinued_Date": "2023-03-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.0D XLT 4X4 A\/T P\/U D\/C",
        "MM_CODES": 22032578,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 578,
        "Discontinued_Date": "2023-03-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.0D BI-TURBO THUNDER A\/T P\/U D\/C",
        "MM_CODES": 22032589,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 589,
        "Discontinued_Date": "2023-03-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.0D BI-TURBO STORMTRAK 4X4 A\/T P\/U D\/C",
        "MM_CODES": 22032594,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 594,
        "Discontinued_Date": "2023-03-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.0D BI-TURBO WILDTRAK 4X4 A\/T P\/U D\/C",
        "MM_CODES": 22032598,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 598,
        "Discontinued_Date": "2023-03-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.0D BI-TURBO THUNDER 4X4 A\/T P\/U D\/C",
        "MM_CODES": 22032599,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 599,
        "Discontinued_Date": "2023-03-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI L\/R P\/U C\/C",
        "MM_CODES": 22032600,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 600,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI L\/R P\/U C\/C",
        "MM_CODES": 22032600,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 600,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI L\/R P\/U S\/C",
        "MM_CODES": 22032606,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 606,
        "Discontinued_Date": "2023-03-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XL P\/U S\/C",
        "MM_CODES": 22032613,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 613,
        "Discontinued_Date": "2023-03-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XL 4X4 P\/U S\/C",
        "MM_CODES": 22032618,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 618,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI XL 4X4 P\/U S\/C",
        "MM_CODES": 22032618,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 618,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XL 4X4 P\/U S\/C",
        "MM_CODES": 22032619,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 619,
        "Discontinued_Date": "2023-03-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XL PLUS 4X4 P\/U C\/C",
        "MM_CODES": 22032624,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 624,
        "Discontinued_Date": "2017-01-24 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI XL PLUS 4X4 P\/U C\/C",
        "MM_CODES": 22032624,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 624,
        "Discontinued_Date": "2017-01-24 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XLS 4X4 A\/T P\/U S\/C",
        "MM_CODES": 22032650,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 650,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI XLS 4X4 A\/T P\/U S\/C",
        "MM_CODES": 22032650,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 650,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI P\/U SUP\/CAB",
        "MM_CODES": 22032660,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 660,
        "Discontinued_Date": "2023-03-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XL P\/U SUP\/CAB",
        "MM_CODES": 22032666,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 666,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI XL P\/U SUP\/CAB",
        "MM_CODES": 22032666,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 666,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XL P\/U SUP\/CAB",
        "MM_CODES": 22032667,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 667,
        "Discontinued_Date": "2023-03-29 07:36:31.830"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XL A\/T P\/U SUP\/CAB",
        "MM_CODES": 22032669,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 669,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI XL A\/T P\/U SUP\/CAB",
        "MM_CODES": 22032669,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 669,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI P\/U D\/C",
        "MM_CODES": 22032680,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 680,
        "Discontinued_Date": "2023-03-29 07:36:35.797"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XL 4X4 P\/U D\/C",
        "MM_CODES": 22032693,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 693,
        "Discontinued_Date": "2023-03-29 07:36:37.667"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 2.2TDCI XL PLUS 4X4 P\/U D\/C C\/C",
        "MM_CODES": 22032698,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 698,
        "Discontinued_Date": "2017-01-24 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 2.2TDCI XL PLUS 4X4 P\/U D\/C C\/C",
        "MM_CODES": 22032698,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 698,
        "Discontinued_Date": "2017-01-24 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.2TDCI XLT 4X4 A\/T P\/U SUP\/CAB",
        "MM_CODES": 22032912,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 912,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 3.2TDCI XLT 4X4 A\/T P\/U SUP\/CAB",
        "MM_CODES": 22032912,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 912,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.2TDCI XLT 4X4 A\/T P\/U SUP\/CAB",
        "MM_CODES": 22032913,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 913,
        "Discontinued_Date": "2023-03-29 07:36:41.253"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.2TDCI XLT P\/U D\/C",
        "MM_CODES": 22032920,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 920,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 3.2TDCI XLT P\/U D\/C",
        "MM_CODES": 22032920,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 920,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.2TDCI WILDTRAK P\/U D\/C",
        "MM_CODES": 22032926,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 926,
        "Discontinued_Date": "2019-07-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 3.2TDCI WILDTRAK P\/U D\/C",
        "MM_CODES": 22032926,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 926,
        "Discontinued_Date": "2019-07-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.2TDCI XLT A\/T P\/U D\/C",
        "MM_CODES": 22032932,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 932,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 3.2TDCI XLT A\/T P\/U D\/C",
        "MM_CODES": 22032932,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 932,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.2TDCI XLT A\/T P\/U D\/C",
        "MM_CODES": 22032933,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 933,
        "Discontinued_Date": "2023-03-29 07:36:41.747"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.2TDCI XLT 4X4 P\/U D\/C",
        "MM_CODES": 22032944,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 944,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 3.2TDCI XLT 4X4 P\/U D\/C",
        "MM_CODES": 22032944,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 944,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.2TDCI XLT 4X4 P\/U D\/C",
        "MM_CODES": 22032945,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 945,
        "Discontinued_Date": "2021-08-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.2TDCI XLT 4X4 A\/T P\/U D\/C",
        "MM_CODES": 22032956,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 956,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - ON",
        "Variant": "RANGER 3.2TDCI XLT 4X4 A\/T P\/U D\/C",
        "MM_CODES": 22032956,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 956,
        "Discontinued_Date": "2019-10-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER 3.2TDCI XLT 4X4 A\/T P\/U D\/C",
        "MM_CODES": 22032957,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 957,
        "Discontinued_Date": "2023-03-29 07:36:43.650"
    },
    {
        "Make": "FORD",
        "Model": "RANGER 2007 - 2022",
        "Variant": "RANGER RAPTOR 2.0D BI-TURBO 4X4 A\/T P\/U D\/C",
        "MM_CODES": 22032975,
        "GCode": 220,
        "MCode": 32,
        "Vcode": 975,
        "Discontinued_Date": "2023-03-29 07:36:44.173"
    },
    {
        "Make": "FORD",
        "Model": "TRANSIT \/ SPECTRON",
        "Variant": "TRANSIT CUSTOM 2.2TDCI SPORT 114KW F\/C P\/V",
        "MM_CODES": 22042120,
        "GCode": 220,
        "MCode": 42,
        "Vcode": 120,
        "Discontinued_Date": "2018-11-16 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TRANSIT \/ SPECTRON",
        "Variant": "TRANSIT CUSTOM KOMBI 2.2TDCI AMB LWB F\/C P\/V",
        "MM_CODES": 22042405,
        "GCode": 220,
        "MCode": 42,
        "Vcode": 405,
        "Discontinued_Date": "2018-11-16 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TRANSIT \/ SPECTRON",
        "Variant": "TRANSIT CUSTOM KOMBI 2.2TDCI TREND SWB F\/C P\/V",
        "MM_CODES": 22042420,
        "GCode": 220,
        "MCode": 42,
        "Vcode": 420,
        "Discontinued_Date": "2018-11-16 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.0 ECOBOOST AMBIENTE 5DR",
        "MM_CODES": 22045355,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 355,
        "Discontinued_Date": "2018-05-11 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.0 ECOBOOST TREND 5DR",
        "MM_CODES": 22045360,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 360,
        "Discontinued_Date": "2018-05-11 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.0 ECOBOOST TREND 5DR",
        "MM_CODES": 22045361,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 361,
        "Discontinued_Date": "2022-04-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.0 ECOBOOST AMBIENTE POWERSHIFT 5DR",
        "MM_CODES": 22045366,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 366,
        "Discontinued_Date": "2018-05-11 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.0 ECOBOOST TITANIUM 5DR",
        "MM_CODES": 22045380,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 380,
        "Discontinued_Date": "2018-05-11 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.0 ECOBOOST TITANIUM 5DR",
        "MM_CODES": 22045381,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 381,
        "Discontinued_Date": "2022-04-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.0 ECOBOOST TITANIUM POWERSHIFT 5DR",
        "MM_CODES": 22045390,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 390,
        "Discontinued_Date": "2018-05-11 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.0 ECOBOOST TITANIUM A\/T 5DR",
        "MM_CODES": 22045391,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 391,
        "Discontinued_Date": "2021-09-13 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.4I TREND 5DR",
        "MM_CODES": 22045427,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 427,
        "Discontinued_Date": "2013-01-25 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.4 TREND 5DR",
        "MM_CODES": 22045428,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 428,
        "Discontinued_Date": "2019-11-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.4I AMBIENTE 5DR",
        "MM_CODES": 22045435,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 435,
        "Discontinued_Date": "2013-01-25 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.4 AMBIENTE 5 DR",
        "MM_CODES": 22045436,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 436,
        "Discontinued_Date": "2018-05-11 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6I TREND 5DR",
        "MM_CODES": 22045465,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 465,
        "Discontinued_Date": "2013-01-25 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6I AMBIENTE 5DR",
        "MM_CODES": 22045478,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 478,
        "Discontinued_Date": "2013-01-25 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6 AMBIENTE POWERSHIFT 5DR",
        "MM_CODES": 22045486,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 486,
        "Discontinued_Date": "2013-01-25 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6I TITANIUM 3DR",
        "MM_CODES": 22045505,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 505,
        "Discontinued_Date": "2013-02-14 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6I MAGNET 3DR",
        "MM_CODES": 22045508,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 508,
        "Discontinued_Date": "2013-11-12 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6I TITANIUM 5DR",
        "MM_CODES": 22045515,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 515,
        "Discontinued_Date": "2013-01-25 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6 SPORT 5DR",
        "MM_CODES": 22045520,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 520,
        "Discontinued_Date": "2012-07-17 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.5 TDCI AMBIENTE",
        "MM_CODES": 22045533,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 533,
        "Discontinued_Date": "2018-10-05 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.5 TDCI TREND 5DR",
        "MM_CODES": 22045536,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 536,
        "Discontinued_Date": "2018-05-11 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.5 TDCI TREND 5DR",
        "MM_CODES": 22045537,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 537,
        "Discontinued_Date": "2021-11-23 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6 TDCI TREND 5DR",
        "MM_CODES": 22045541,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 541,
        "Discontinued_Date": "2018-05-11 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6 TDCI AMBIENTE 5DR",
        "MM_CODES": 22045550,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 550,
        "Discontinued_Date": "2008-10-22 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6 TDCI AMBIENTE 5DR",
        "MM_CODES": 22045551,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 551,
        "Discontinued_Date": "2013-01-25 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6 TDCI AMBIENTE 5DR",
        "MM_CODES": 22045552,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 552,
        "Discontinued_Date": "2018-10-05 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA ST 1.6 ECOBOOST GDTI",
        "MM_CODES": 22045590,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 590,
        "Discontinued_Date": "2019-10-03 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "ST 200 1.6 ECOBOOST 3DR",
        "MM_CODES": 22045591,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 591,
        "Discontinued_Date": "2018-03-19 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.4 AMBIENTE",
        "MM_CODES": 22045740,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 740,
        "Discontinued_Date": "2013-07-09 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6 TREND",
        "MM_CODES": 22045760,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 760,
        "Discontinued_Date": "2013-07-09 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6 TREND POWERSHIFT",
        "MM_CODES": 22045770,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 770,
        "Discontinued_Date": "2013-07-09 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6 AMBIENTE",
        "MM_CODES": 22045800,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 800,
        "Discontinued_Date": "2013-07-09 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FIESTA 2000 - ON",
        "Variant": "FIESTA 1.6 TDCI AMBIENTE",
        "MM_CODES": 22045840,
        "GCode": 220,
        "MCode": 45,
        "Vcode": 840,
        "Discontinued_Date": "2013-07-09 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "IKON",
        "Variant": "IKON 1.6 AMBIENTE",
        "MM_CODES": 22046810,
        "GCode": 220,
        "MCode": 46,
        "Vcode": 810,
        "Discontinued_Date": "2017-10-10 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "ECOSPORT",
        "Variant": "ECOSPORT 1.0 ECOBOOST TREND",
        "MM_CODES": 22048120,
        "GCode": 220,
        "MCode": 48,
        "Vcode": 120,
        "Discontinued_Date": "2018-06-25 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "ECOSPORT",
        "Variant": "ECOSPORT 1.0 ECOBOOST TREND",
        "MM_CODES": 22048121,
        "GCode": 220,
        "MCode": 48,
        "Vcode": 121,
        "Discontinued_Date": "2023-10-02 06:29:30.113"
    },
    {
        "Make": "FORD",
        "Model": "ECOSPORT",
        "Variant": "ECOSPORT 1.0 ECOBOOST TITANIUM ",
        "MM_CODES": 22048141,
        "GCode": 220,
        "MCode": 48,
        "Vcode": 141,
        "Discontinued_Date": "2023-10-02 06:29:34.320"
    },
    {
        "Make": "FORD",
        "Model": "ECOSPORT",
        "Variant": "ECOSPORT 1.0 ECOBOOST TITANIUM A\/T",
        "MM_CODES": 22048150,
        "GCode": 220,
        "MCode": 48,
        "Vcode": 150,
        "Discontinued_Date": "2023-10-02 06:29:36.097"
    },
    {
        "Make": "FORD",
        "Model": "ECOSPORT",
        "Variant": "ECOSPORT 1.5TIVCT AMBIENTE",
        "MM_CODES": 22048300,
        "GCode": 220,
        "MCode": 48,
        "Vcode": 300,
        "Discontinued_Date": "2018-06-25 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "ECOSPORT",
        "Variant": "ECOSPORT 1.5TIVCT AMBIENTE",
        "MM_CODES": 22048301,
        "GCode": 220,
        "MCode": 48,
        "Vcode": 301,
        "Discontinued_Date": "2023-10-02 06:29:37.160"
    },
    {
        "Make": "FORD",
        "Model": "ECOSPORT",
        "Variant": "ECOSPORT 1.5TIVCT TITANIUM P\/SHIFT",
        "MM_CODES": 22048320,
        "GCode": 220,
        "MCode": 48,
        "Vcode": 320,
        "Discontinued_Date": "2018-06-25 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "B-MAX",
        "Variant": "B-MAX 1.0 ECOBOOST AMBIENTE",
        "MM_CODES": 22049120,
        "GCode": 220,
        "MCode": 49,
        "Vcode": 120,
        "Discontinued_Date": "2017-12-05 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "B-MAX",
        "Variant": "B-MAX 1.0 ECOBOOST TREND",
        "MM_CODES": 22049130,
        "GCode": 220,
        "MCode": 49,
        "Vcode": 130,
        "Discontinued_Date": "2017-12-05 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "B-MAX",
        "Variant": "B-MAX 1.0 ECOBOOST TITANIUM",
        "MM_CODES": 22049140,
        "GCode": 220,
        "MCode": 49,
        "Vcode": 140,
        "Discontinued_Date": "2017-12-05 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 1.6 TI VCT AMBIENTE 5DR",
        "MM_CODES": 22071151,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 151,
        "Discontinued_Date": "2015-10-28 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 1.6 TI VCT TREND 5DR",
        "MM_CODES": 22071170,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 170,
        "Discontinued_Date": "2015-10-28 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 1.6 TI VCT AMBIENTE",
        "MM_CODES": 22071201,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 201,
        "Discontinued_Date": "2015-10-28 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 1.6 TI VCT AMBIENTE POWERSHIFT",
        "MM_CODES": 22071210,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 210,
        "Discontinued_Date": "2015-10-28 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 1.6 TI VCT TREND",
        "MM_CODES": 22071220,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 220,
        "Discontinued_Date": "2015-10-28 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 1.5 TDCI AMBIENTE",
        "MM_CODES": 22071240,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 240,
        "Discontinued_Date": "2017-04-06 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 1.6 TDCI TREND",
        "MM_CODES": 22071260,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 260,
        "Discontinued_Date": "2017-04-06 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 1.5 TDCI TREND",
        "MM_CODES": 22071261,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 261,
        "Discontinued_Date": "2018-12-28 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 1.8 AMBIENTE",
        "MM_CODES": 22071700,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 700,
        "Discontinued_Date": "2010-09-08 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 1.8 AMBIENTE",
        "MM_CODES": 22071701,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 701,
        "Discontinued_Date": "2011-07-18 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 1.8 AMBIENTE 5DR",
        "MM_CODES": 22071710,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 710,
        "Discontinued_Date": "2010-09-08 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 1.8 AMBIENTE 5DR",
        "MM_CODES": 22071711,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 711,
        "Discontinued_Date": "2011-07-18 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 1.8 SI 5DR",
        "MM_CODES": 22071720,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 720,
        "Discontinued_Date": "2010-09-08 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 TREND",
        "MM_CODES": 22071741,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 741,
        "Discontinued_Date": "2011-07-18 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 SI",
        "MM_CODES": 22071771,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 771,
        "Discontinued_Date": "2010-09-08 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 SI A\/T",
        "MM_CODES": 22071781,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 781,
        "Discontinued_Date": "2010-09-08 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 GDI TREND",
        "MM_CODES": 22071785,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 785,
        "Discontinued_Date": "2015-10-28 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 GDI TREND POWERSHIFT",
        "MM_CODES": 22071790,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 790,
        "Discontinued_Date": "2015-10-28 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 SI 5DR",
        "MM_CODES": 22071801,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 801,
        "Discontinued_Date": "2010-09-08 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 GDI TREND 5DR",
        "MM_CODES": 22071805,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 805,
        "Discontinued_Date": "2015-10-28 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 GDI SPORT 5DR",
        "MM_CODES": 22071810,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 810,
        "Discontinued_Date": "2015-10-28 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 TDCI SI POWERSHIFT",
        "MM_CODES": 22071831,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 831,
        "Discontinued_Date": "2010-09-08 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 TDCI SI POWERSHIFT",
        "MM_CODES": 22071832,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 832,
        "Discontinued_Date": "2011-07-18 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 TDCI TREND POWERSHIFT",
        "MM_CODES": 22071870,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 870,
        "Discontinued_Date": "2015-10-28 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 TDCI SI 5DR",
        "MM_CODES": 22071901,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 901,
        "Discontinued_Date": "2010-09-08 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 TDCI SI POWERSHIFT 5DR",
        "MM_CODES": 22071910,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 910,
        "Discontinued_Date": "2010-09-08 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 TDCI SI POWERSHIFT 5DR",
        "MM_CODES": 22071911,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 911,
        "Discontinued_Date": "2011-07-18 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 TDCI TREND POWERSHIFT 5DR",
        "MM_CODES": 22071912,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 912,
        "Discontinued_Date": "2016-06-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 GTDI ST1 (5DR)",
        "MM_CODES": 22071962,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 962,
        "Discontinued_Date": "2015-07-04 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 ECOBOOST ST1",
        "MM_CODES": 22071963,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 963,
        "Discontinued_Date": "2019-07-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 GTDI ST3 (5DR)",
        "MM_CODES": 22071965,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 965,
        "Discontinued_Date": "2015-07-04 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.0 ECOBOOST ST3",
        "MM_CODES": 22071966,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 966,
        "Discontinued_Date": "2020-07-02 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "FOCUS 2000 - ON",
        "Variant": "FOCUS 2.5 RS 3DR",
        "MM_CODES": 22071970,
        "GCode": 220,
        "MCode": 71,
        "Vcode": 970,
        "Discontinued_Date": "2011-07-07 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "MUSTANG",
        "Variant": "MUSTANG 2.3 ECOBOOST A\/T",
        "MM_CODES": 22078260,
        "GCode": 220,
        "MCode": 78,
        "Vcode": 260,
        "Discontinued_Date": "2019-06-14 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "MUSTANG",
        "Variant": "MUSTANG 2.3 A\/T",
        "MM_CODES": 22078261,
        "GCode": 220,
        "MCode": 78,
        "Vcode": 261,
        "Discontinued_Date": "2021-04-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "MUSTANG",
        "Variant": "MUSTANG 2.3 ECOBOOST CONVERT A\/T",
        "MM_CODES": 22078280,
        "GCode": 220,
        "MCode": 78,
        "Vcode": 280,
        "Discontinued_Date": "2019-06-14 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "MUSTANG",
        "Variant": "MUSTANG 2.3 CONVERT A\/T",
        "MM_CODES": 22078281,
        "GCode": 220,
        "MCode": 78,
        "Vcode": 281,
        "Discontinued_Date": "2021-04-01 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "MUSTANG",
        "Variant": "MUSTANG 5.0 GT",
        "MM_CODES": 22078400,
        "GCode": 220,
        "MCode": 78,
        "Vcode": 400,
        "Discontinued_Date": "2019-06-14 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "MUSTANG",
        "Variant": "MUSTANG 5.0 GT A\/T",
        "MM_CODES": 22078410,
        "GCode": 220,
        "MCode": 78,
        "Vcode": 410,
        "Discontinued_Date": "2019-06-14 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "KUGA",
        "Variant": "KUGA 1.5 ECOBOOST AMBIENTE",
        "MM_CODES": 22084102,
        "GCode": 220,
        "MCode": 84,
        "Vcode": 102,
        "Discontinued_Date": "2021-05-27 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "KUGA",
        "Variant": "KUGA 1.5 ECOBOOST TREND",
        "MM_CODES": 22084111,
        "GCode": 220,
        "MCode": 84,
        "Vcode": 111,
        "Discontinued_Date": "2017-07-10 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "KUGA",
        "Variant": "KUGA 1.5 TDCI AMBIENTE",
        "MM_CODES": 22084160,
        "GCode": 220,
        "MCode": 84,
        "Vcode": 160,
        "Discontinued_Date": "2021-05-28 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "KUGA",
        "Variant": "KUGA 2.0 ECOBOOST TITANIUM AWD A\/T",
        "MM_CODES": 22084230,
        "GCode": 220,
        "MCode": 84,
        "Vcode": 230,
        "Discontinued_Date": "2017-07-10 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "KUGA",
        "Variant": "KUGA 2.0 ECOBOOST TITANIUM AWD A\/T",
        "MM_CODES": 22084231,
        "GCode": 220,
        "MCode": 84,
        "Vcode": 231,
        "Discontinued_Date": "2018-12-11 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "KUGA",
        "Variant": "KUGA 2.0 ECOBOOST ST AWD A\/T",
        "MM_CODES": 22084237,
        "GCode": 220,
        "MCode": 84,
        "Vcode": 237,
        "Discontinued_Date": "2021-05-28 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "KUGA",
        "Variant": "KUGA 2.0 TDCI TREND POWERSHIFT",
        "MM_CODES": 22084240,
        "GCode": 220,
        "MCode": 84,
        "Vcode": 240,
        "Discontinued_Date": "2017-07-11 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "KUGA",
        "Variant": "KUGA 2.0 TDCI TREND AWD POWERSHIFT",
        "MM_CODES": 22084250,
        "GCode": 220,
        "MCode": 84,
        "Vcode": 250,
        "Discontinued_Date": "2015-01-05 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "KUGA",
        "Variant": "KUGA 2.0 TDCI TREND AWD POWERSHIFT",
        "MM_CODES": 22084251,
        "GCode": 220,
        "MCode": 84,
        "Vcode": 251,
        "Discontinued_Date": "2018-12-11 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "KUGA",
        "Variant": "KUGA 2.0 TDCI TITANIUM AWD POWERSHIFT",
        "MM_CODES": 22084270,
        "GCode": 220,
        "MCode": 84,
        "Vcode": 270,
        "Discontinued_Date": "2015-01-05 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TERRITORY \/ EVEREST",
        "Variant": "EVEREST 2.0D BI-TURBO 4X4 A\/T",
        "MM_CODES": 22089330,
        "GCode": 220,
        "MCode": 89,
        "Vcode": 330,
        "Discontinued_Date": "2022-09-27 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TERRITORY \/ EVEREST",
        "Variant": "EVEREST 2.0D BI-TURBO LTD 4X4 A\/T",
        "MM_CODES": 22089340,
        "GCode": 220,
        "MCode": 89,
        "Vcode": 340,
        "Discontinued_Date": "2022-09-27 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TERRITORY \/ EVEREST",
        "Variant": "EVEREST 2.2 TDCI  XLS",
        "MM_CODES": 22089500,
        "GCode": 220,
        "MCode": 89,
        "Vcode": 500,
        "Discontinued_Date": "2019-04-17 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TERRITORY \/ EVEREST",
        "Variant": "EVEREST 2.2 TDCI XLS A\/T",
        "MM_CODES": 22089511,
        "GCode": 220,
        "MCode": 89,
        "Vcode": 511,
        "Discontinued_Date": "2022-09-27 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TERRITORY \/ EVEREST",
        "Variant": "EVEREST 2.2 TDCI  XLS 4X4",
        "MM_CODES": 22089520,
        "GCode": 220,
        "MCode": 89,
        "Vcode": 520,
        "Discontinued_Date": "2019-04-17 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TERRITORY \/ EVEREST",
        "Variant": "EVEREST 2.2 TDCI XLT A\/T",
        "MM_CODES": 22089550,
        "GCode": 220,
        "MCode": 89,
        "Vcode": 550,
        "Discontinued_Date": "2019-04-17 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TERRITORY \/ EVEREST",
        "Variant": "EVEREST 3.0 TDCI XLT",
        "MM_CODES": 22089600,
        "GCode": 220,
        "MCode": 89,
        "Vcode": 600,
        "Discontinued_Date": "2015-11-13 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TERRITORY \/ EVEREST",
        "Variant": "EVEREST 3.0 TDCI XLT 4X4",
        "MM_CODES": 22089620,
        "GCode": 220,
        "MCode": 89,
        "Vcode": 620,
        "Discontinued_Date": "2015-11-13 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TERRITORY \/ EVEREST",
        "Variant": "EVEREST 3.0 TDCI LTD 4X4 A\/T",
        "MM_CODES": 22089640,
        "GCode": 220,
        "MCode": 89,
        "Vcode": 640,
        "Discontinued_Date": "2015-11-13 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TERRITORY \/ EVEREST",
        "Variant": "EVEREST 3.2 TDCI XLT A\/T",
        "MM_CODES": 22089670,
        "GCode": 220,
        "MCode": 89,
        "Vcode": 670,
        "Discontinued_Date": "2019-04-17 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TERRITORY \/ EVEREST",
        "Variant": "EVEREST 3.2 TDCI XLT 4X4 A\/T",
        "MM_CODES": 22089681,
        "GCode": 220,
        "MCode": 89,
        "Vcode": 681,
        "Discontinued_Date": "2022-09-27 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TERRITORY \/ EVEREST",
        "Variant": "EVEREST 3.2 LTD 4X4 A\/T",
        "MM_CODES": 22089690,
        "GCode": 220,
        "MCode": 89,
        "Vcode": 690,
        "Discontinued_Date": "2019-04-17 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TOURNEO",
        "Variant": "TOURNEO CUSTOM 2.2TDCI  AMBIENTE SWB",
        "MM_CODES": 22093100,
        "GCode": 220,
        "MCode": 93,
        "Vcode": 100,
        "Discontinued_Date": "2018-11-16 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TOURNEO",
        "Variant": "TOURNEO CUSTOM 2.2TDCI  AMBIENTE LWB",
        "MM_CODES": 22093110,
        "GCode": 220,
        "MCode": 93,
        "Vcode": 110,
        "Discontinued_Date": "2018-11-16 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TOURNEO",
        "Variant": "TOURNEO CUSTOM 2.2TDCI  TREND SWB (92KW)",
        "MM_CODES": 22093120,
        "GCode": 220,
        "MCode": 93,
        "Vcode": 120,
        "Discontinued_Date": "2018-11-16 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TOURNEO",
        "Variant": "TOURNEO CUSTOM 2.2TDCI TREND LWB (92KW)",
        "MM_CODES": 22093130,
        "GCode": 220,
        "MCode": 93,
        "Vcode": 130,
        "Discontinued_Date": "2018-11-16 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TOURNEO",
        "Variant": "TOURNEO 2.2 TDCI MWB (100KW)",
        "MM_CODES": 22093160,
        "GCode": 220,
        "MCode": 93,
        "Vcode": 160,
        "Discontinued_Date": "2018-11-16 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TOURNEO",
        "Variant": "TOURNEO CUSTOM LTD 2.2TDCI SWB (114KW)",
        "MM_CODES": 22093180,
        "GCode": 220,
        "MCode": 93,
        "Vcode": 180,
        "Discontinued_Date": "2018-11-16 00:00:00.000"
    },
    {
        "Make": "FORD",
        "Model": "TOURNEO",
        "Variant": "TOURNEO 2.2 TDCI ELWB (100KW)",
        "MM_CODES": 22093500,
        "GCode": 220,
        "MCode": 93,
        "Vcode": 500,
        "Discontinued_Date": "2018-12-12 00:00:00.000"
    },
    {
        "Make": "GEELY",
        "Model": "MK",
        "Variant": "MK 1.5 GT",
        "MM_CODES": 23411103,
        "GCode": 234,
        "MCode": 11,
        "Vcode": 103,
        "Discontinued_Date": "2016-12-13 00:00:00.000"
    },
    {
        "Make": "GEELY",
        "Model": "MK",
        "Variant": "MK 1.5 GT 5DR",
        "MM_CODES": 23411105,
        "GCode": 234,
        "MCode": 11,
        "Vcode": 105,
        "Discontinued_Date": "2016-12-13 00:00:00.000"
    },
    {
        "Make": "GEELY",
        "Model": "MK",
        "Variant": "MK 1.6 GL",
        "MM_CODES": 23411107,
        "GCode": 234,
        "MCode": 11,
        "Vcode": 107,
        "Discontinued_Date": "2016-12-13 00:00:00.000"
    },
    {
        "Make": "GEELY",
        "Model": "MK",
        "Variant": "MK 1.6 GT",
        "MM_CODES": 23411110,
        "GCode": 234,
        "MCode": 11,
        "Vcode": 110,
        "Discontinued_Date": "2016-12-20 00:00:00.000"
    },
    {
        "Make": "GEELY",
        "Model": "EMGRAND",
        "Variant": "EMGRAND EC 7 1.8 LUX",
        "MM_CODES": 23420300,
        "GCode": 234,
        "MCode": 20,
        "Vcode": 300,
        "Discontinued_Date": "2016-12-13 00:00:00.000"
    },
    {
        "Make": "GEELY",
        "Model": "EMGRAND",
        "Variant": "EMGRAND EC 7 1.8 EXEC",
        "MM_CODES": 23420310,
        "GCode": 234,
        "MCode": 20,
        "Vcode": 310,
        "Discontinued_Date": "2016-12-13 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "FLORID \/ CB",
        "Variant": "FLORID 1.5 SUPER LUX",
        "MM_CODES": 23510150,
        "GCode": 235,
        "MCode": 10,
        "Vcode": 150,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "FLORID \/ CB",
        "Variant": "FLORID 1.5 CROSS",
        "MM_CODES": 23510160,
        "GCode": 235,
        "MCode": 10,
        "Vcode": 160,
        "Discontinued_Date": "2015-10-01 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "FLORID \/ CB",
        "Variant": "C10 1.5",
        "MM_CODES": 23510180,
        "GCode": 235,
        "MCode": 10,
        "Vcode": 180,
        "Discontinued_Date": "2015-10-01 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "FLORID \/ CB",
        "Variant": "C20R 1.5",
        "MM_CODES": 23510190,
        "GCode": 235,
        "MCode": 10,
        "Vcode": 190,
        "Discontinued_Date": "2015-10-10 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "FLORID \/ CB",
        "Variant": "CB 150 ELITE",
        "MM_CODES": 23510400,
        "GCode": 235,
        "MCode": 10,
        "Vcode": 400,
        "Discontinued_Date": "2013-07-11 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "M4",
        "Variant": "M4 1.5 CROSSOVER",
        "MM_CODES": 23513175,
        "GCode": 235,
        "MCode": 13,
        "Vcode": 175,
        "Discontinued_Date": "2020-06-23 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "2.2 P\/U S\/C",
        "MM_CODES": 23515100,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 100,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "2.2 P\/U S\/C",
        "MM_CODES": 23515100,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 100,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 3 2.2 LUX P\/U S\/C",
        "MM_CODES": 23515125,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 125,
        "Discontinued_Date": "2012-10-01 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 3 2.2 LUX P\/U S\/C",
        "MM_CODES": 23515125,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 125,
        "Discontinued_Date": "2012-10-01 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "2.2 4X4 P\/U S\/C",
        "MM_CODES": 23515140,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 140,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "2.2 4X4 P\/U S\/C",
        "MM_CODES": 23515140,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 140,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 5 2.2 MPI BASE P\/U D\/C",
        "MM_CODES": 23515180,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 180,
        "Discontinued_Date": "2018-03-01 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 5 2.2 MPI BASE P\/U D\/C",
        "MM_CODES": 23515180,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 180,
        "Discontinued_Date": "2018-03-01 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 5 2.2 MPI BASE P\/U D\/C",
        "MM_CODES": 23515181,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 181,
        "Discontinued_Date": "2018-03-01 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 5 2.2 MPI BASE P\/U D\/C",
        "MM_CODES": 23515181,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 181,
        "Discontinued_Date": "2024-02-01 10:00:52.653"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 5 2.2 MPI  P\/U D\/C",
        "MM_CODES": 23515200,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 200,
        "Discontinued_Date": "2016-10-07 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 5 2.2 MPI  P\/U D\/C",
        "MM_CODES": 23515200,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 200,
        "Discontinued_Date": "2016-10-07 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 5 2.2 MPI SV P\/U D\/C",
        "MM_CODES": 23515205,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 205,
        "Discontinued_Date": "2018-05-03 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 5 2.2 MPI SV P\/U D\/C",
        "MM_CODES": 23515205,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 205,
        "Discontinued_Date": "2018-05-03 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 5 2.2 MPI SX P\/U D\/C",
        "MM_CODES": 23515210,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 210,
        "Discontinued_Date": "2019-11-10 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 5 2.2 MPI SX P\/U D\/C",
        "MM_CODES": 23515210,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 210,
        "Discontinued_Date": "2019-11-10 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 2.2 LUX P\/U D\/C",
        "MM_CODES": 23515250,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 250,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "2.2 LUX P\/U D\/C",
        "MM_CODES": 23515250,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 250,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 3 2.2 LUX P\/U D\/C",
        "MM_CODES": 23515254,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 254,
        "Discontinued_Date": "2013-07-11 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 3 2.2 LUX P\/U D\/C",
        "MM_CODES": 23515254,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 254,
        "Discontinued_Date": "2013-07-11 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 2.4 P\/U S\/C",
        "MM_CODES": 23515260,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 260,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 2.4 P\/U S\/C",
        "MM_CODES": 23515260,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 260,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 2.4 4X4 P\/U S\/C",
        "MM_CODES": 23515270,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 270,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 2.4 4X4 P\/U S\/C",
        "MM_CODES": 23515270,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 270,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 2.4 P\/U D\/C",
        "MM_CODES": 23515280,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 280,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 2.4 P\/U D\/C",
        "MM_CODES": 23515280,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 280,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 5 2.4 MPI P\/U D\/C",
        "MM_CODES": 23515283,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 283,
        "Discontinued_Date": "2016-10-07 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 2.4  4X4 P\/U D\/C",
        "MM_CODES": 23515290,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 290,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 2.4  4X4 P\/U D\/C",
        "MM_CODES": 23515290,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 290,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 5 2.4 MPI 4X4 P\/U D\/C",
        "MM_CODES": 23515293,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 293,
        "Discontinued_Date": "2016-10-07 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 5 2.4 MPI 4X4 P\/U D\/C",
        "MM_CODES": 23515293,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 293,
        "Discontinued_Date": "2016-10-07 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "2.8 TDI P\/U S\/C",
        "MM_CODES": 23515300,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 300,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 2.8 TCI LUX P\/U D\/C",
        "MM_CODES": 23515321,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 321,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 2.8 TCI LUX P\/U D\/C",
        "MM_CODES": 23515321,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 321,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 2.8 TCI LUX 4X4 P\/U D\/C",
        "MM_CODES": 23515326,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 326,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 2.8 TCI P\/U S\/C",
        "MM_CODES": 23515330,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 330,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 2.8 TCI 4X4 P\/U S\/C",
        "MM_CODES": 23515335,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 335,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 5 2.4 P\/U S\/C",
        "MM_CODES": 23515490,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 490,
        "Discontinued_Date": "2016-10-07 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 5 2.5 TCI P\/U S\/C",
        "MM_CODES": 23515550,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 550,
        "Discontinued_Date": "2014-09-30 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 5 2.5 TCI 4X4 P\/U\/S\/C",
        "MM_CODES": 23515575,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 575,
        "Discontinued_Date": "2014-09-30 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 5 2.5 TCI 4X4 P\/U S\/C",
        "MM_CODES": 23515575,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 575,
        "Discontinued_Date": "2014-09-30 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 5 2.5 TCI P\/U D\/C",
        "MM_CODES": 23515600,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 600,
        "Discontinued_Date": "2014-09-30 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 5 2.5 TCI P\/U D\/C",
        "MM_CODES": 23515600,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 600,
        "Discontinued_Date": "2014-09-30 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED",
        "Variant": "STEED 5 2.5 TCI 4X4 P\/U D\/C",
        "MM_CODES": 23515610,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 610,
        "Discontinued_Date": "2014-09-30 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "LDV \/ STEED 3 \/ 5",
        "Variant": "STEED 5 2.5 TCI 4X4 P\/U D\/C",
        "MM_CODES": 23515610,
        "GCode": 235,
        "MCode": 15,
        "Vcode": 610,
        "Discontinued_Date": "2014-09-30 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "STEED 5E \/ 6",
        "Variant": "STEED 5E 2.0 VGT SX P\/U D\/C",
        "MM_CODES": 23516250,
        "GCode": 235,
        "MCode": 16,
        "Vcode": 250,
        "Discontinued_Date": "2024-02-01 10:01:07.990"
    },
    {
        "Make": "GWM",
        "Model": "STEED 5E \/ 6",
        "Variant": "STEED 6 2.0 VGT SX P\/U D\/C",
        "MM_CODES": 23516265,
        "GCode": 235,
        "MCode": 16,
        "Vcode": 265,
        "Discontinued_Date": "2021-04-19 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "STEED 5E \/ 6",
        "Variant": "STEED 5E 2.0 VGT XSCAPE P\/U D\/C",
        "MM_CODES": 23516280,
        "GCode": 235,
        "MCode": 16,
        "Vcode": 280,
        "Discontinued_Date": "2021-10-04 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "STEED 5E \/ 6",
        "Variant": "STEED 6 2.0 VGT XSCAPE P\/U D\/C",
        "MM_CODES": 23516345,
        "GCode": 235,
        "MCode": 16,
        "Vcode": 345,
        "Discontinued_Date": "2021-04-19 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "STEED 5E \/ 6",
        "Variant": "STEED 5E 2.4 XSCAPE P\/U D\/C",
        "MM_CODES": 23516615,
        "GCode": 235,
        "MCode": 16,
        "Vcode": 615,
        "Discontinued_Date": "2024-02-01 10:01:12.460"
    },
    {
        "Make": "GWM",
        "Model": "C30",
        "Variant": "C30 1.5",
        "MM_CODES": 23517150,
        "GCode": 235,
        "MCode": 17,
        "Vcode": 150,
        "Discontinued_Date": "2016-10-07 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "C50",
        "Variant": "C50 1.5 LUX",
        "MM_CODES": 23520150,
        "GCode": 235,
        "MCode": 20,
        "Vcode": 150,
        "Discontinued_Date": "2016-10-07 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "C50",
        "Variant": "C50 1.5 ELITE",
        "MM_CODES": 23520160,
        "GCode": 235,
        "MCode": 20,
        "Vcode": 160,
        "Discontinued_Date": "2016-10-07 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "HOVER \/ H5\/ H6",
        "Variant": "H6 1.5T",
        "MM_CODES": 23532115,
        "GCode": 235,
        "MCode": 32,
        "Vcode": 115,
        "Discontinued_Date": "2016-01-18 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "HOVER \/ H5\/ H6",
        "Variant": "H6 2.0 TCI",
        "MM_CODES": 23532130,
        "GCode": 235,
        "MCode": 32,
        "Vcode": 130,
        "Discontinued_Date": "2017-07-11 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "HOVER \/ H5\/ H6",
        "Variant": "2.4 HOVER",
        "MM_CODES": 23532201,
        "GCode": 235,
        "MCode": 32,
        "Vcode": 201,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "HOVER \/ H5\/ H6",
        "Variant": "2.4 HOVER 4X4",
        "MM_CODES": 23532221,
        "GCode": 235,
        "MCode": 32,
        "Vcode": 221,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "HOVER \/ H5\/ H6",
        "Variant": "2.5 TCI HOVER",
        "MM_CODES": 23532250,
        "GCode": 235,
        "MCode": 32,
        "Vcode": 250,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "HOVER \/ H5\/ H6",
        "Variant": "2.5 TCI HOVER 4X4",
        "MM_CODES": 23532260,
        "GCode": 235,
        "MCode": 32,
        "Vcode": 260,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "HOVER \/ H5\/ H6",
        "Variant": "H5 2.4 CITY",
        "MM_CODES": 23532290,
        "GCode": 235,
        "MCode": 32,
        "Vcode": 290,
        "Discontinued_Date": "2016-06-23 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "HOVER \/ H5\/ H6",
        "Variant": "H5 2.4 CITY",
        "MM_CODES": 23532291,
        "GCode": 235,
        "MCode": 32,
        "Vcode": 291,
        "Discontinued_Date": "2020-06-23 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "HOVER \/ H5\/ H6",
        "Variant": "H5 2.4",
        "MM_CODES": 23532300,
        "GCode": 235,
        "MCode": 32,
        "Vcode": 300,
        "Discontinued_Date": "2016-06-23 00:00:00.000"
    },
    {
        "Make": "GWM",
        "Model": "HOVER \/ H5\/ H6",
        "Variant": "H5 2.4 4X4",
        "MM_CODES": 23532310,
        "GCode": 235,
        "MCode": 32,
        "Vcode": 310,
        "Discontinued_Date": "2016-06-23 00:00:00.000"
    },
    {
        "Make": "HINO",
        "Model": "300 SERIES",
        "Variant": "HINO 300 614 SWB (AL3) F\/C C\/C",
        "MM_CODES": 24010140,
        "GCode": 240,
        "MCode": 10,
        "Vcode": 140,
        "Discontinued_Date": "2021-10-13 00:00:00.000"
    },
    {
        "Make": "HINO",
        "Model": "300 SERIES",
        "Variant": "HINO 300 714 SWB (AX3) F\/C C\/C",
        "MM_CODES": 24010241,
        "GCode": 240,
        "MCode": 10,
        "Vcode": 241,
        "Discontinued_Date": "2021-10-13 00:00:00.000"
    },
    {
        "Make": "HINO",
        "Model": "300 SERIES",
        "Variant": "HINO 300 714 A\/T SWB F\/C C\/C (AJ3)",
        "MM_CODES": 24010245,
        "GCode": 240,
        "MCode": 10,
        "Vcode": 245,
        "Discontinued_Date": "2021-10-13 00:00:00.000"
    },
    {
        "Make": "HINO",
        "Model": "300 SERIES",
        "Variant": "HINO 300 714 LWB (AY3) F\/C C\/C",
        "MM_CODES": 24010261,
        "GCode": 240,
        "MCode": 10,
        "Vcode": 261,
        "Discontinued_Date": "2021-10-13 00:00:00.000"
    },
    {
        "Make": "HINO",
        "Model": "300 SERIES",
        "Variant": "HINO 300 714 LWB CREW CAB  F\/C C\/C (AM3)",
        "MM_CODES": 24010270,
        "GCode": 240,
        "MCode": 10,
        "Vcode": 270,
        "Discontinued_Date": "2021-10-13 00:00:00.000"
    },
    {
        "Make": "HINO",
        "Model": "300 SERIES",
        "Variant": "HINO 300 814 LWB (AZ3) F\/C C\/C",
        "MM_CODES": 24010341,
        "GCode": 240,
        "MCode": 10,
        "Vcode": 341,
        "Discontinued_Date": "2021-10-13 00:00:00.000"
    },
    {
        "Make": "HINO",
        "Model": "300 SERIES",
        "Variant": "HINO 300 815 A\/T SWB F\/C C\/C (AK3)",
        "MM_CODES": 24010355,
        "GCode": 240,
        "MCode": 10,
        "Vcode": 355,
        "Discontinued_Date": "2021-10-13 00:00:00.000"
    },
    {
        "Make": "HINO",
        "Model": "300 SERIES",
        "Variant": "HINO 300 815 LWB (BB3) A\/T F\/C C\/C",
        "MM_CODES": 24010365,
        "GCode": 240,
        "MCode": 10,
        "Vcode": 365,
        "Discontinued_Date": "2017-05-02 00:00:00.000"
    },
    {
        "Make": "HINO",
        "Model": "300 SERIES",
        "Variant": "HINO 300 815 LWB (BC3) CREW CAB A\/T F\/C C\/C",
        "MM_CODES": 24010380,
        "GCode": 240,
        "MCode": 10,
        "Vcode": 380,
        "Discontinued_Date": "2021-10-13 00:00:00.000"
    },
    {
        "Make": "HINO",
        "Model": "300 SERIES",
        "Variant": "HINO 300 915 LWB (BA3) F\/C C\/C",
        "MM_CODES": 24010422,
        "GCode": 240,
        "MCode": 10,
        "Vcode": 422,
        "Discontinued_Date": "2021-10-13 00:00:00.000"
    },
    {
        "Make": "HINO",
        "Model": "300 SERIES",
        "Variant": "HINO 300 915 LWB CREW CAB  F\/C C\/C (AN3)",
        "MM_CODES": 24010430,
        "GCode": 240,
        "MCode": 10,
        "Vcode": 430,
        "Discontinued_Date": "2021-10-13 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "BRIO\/ AMAZE",
        "Variant": "BRIO 1.2 COMFORT 5DR",
        "MM_CODES": 25010100,
        "GCode": 250,
        "MCode": 10,
        "Vcode": 100,
        "Discontinued_Date": "2019-11-08 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "BRIO",
        "Variant": "BRIO 1.2 TREND",
        "MM_CODES": 25010130,
        "GCode": 250,
        "MCode": 10,
        "Vcode": 130,
        "Discontinued_Date": "2018-09-05 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "BRIO\/ AMAZE",
        "Variant": "BRIO 1.2 TREND",
        "MM_CODES": 25010130,
        "GCode": 250,
        "MCode": 10,
        "Vcode": 130,
        "Discontinued_Date": "2018-09-05 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "BRIO",
        "Variant": "BRIO 1.2 COMFORT A\/T",
        "MM_CODES": 25010150,
        "GCode": 250,
        "MCode": 10,
        "Vcode": 150,
        "Discontinued_Date": "2018-09-05 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "BRIO\/ AMAZE",
        "Variant": "BRIO 1.2 COMFORT A\/T",
        "MM_CODES": 25010150,
        "GCode": 250,
        "MCode": 10,
        "Vcode": 150,
        "Discontinued_Date": "2018-09-05 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "FIT",
        "Variant": "1.5 COMFORT CVT ",
        "MM_CODES": 25012260,
        "GCode": 250,
        "MCode": 12,
        "Vcode": 260,
        "Discontinued_Date": "2024-01-22 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "FIT",
        "Variant": "FIT 1.5 ELEGANCE CVT",
        "MM_CODES": 25012280,
        "GCode": 250,
        "MCode": 12,
        "Vcode": 280,
        "Discontinued_Date": "2024-01-16 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "JAZZ",
        "Variant": "JAZZ 1.3 TREND",
        "MM_CODES": 25013100,
        "GCode": 250,
        "MCode": 13,
        "Vcode": 100,
        "Discontinued_Date": "2015-01-06 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "JAZZ",
        "Variant": "JAZZ 1.3 COMFORT",
        "MM_CODES": 25013110,
        "GCode": 250,
        "MCode": 13,
        "Vcode": 110,
        "Discontinued_Date": "2015-01-06 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "JAZZ",
        "Variant": "JAZZ 1.3 COMFORT CVT",
        "MM_CODES": 25013120,
        "GCode": 250,
        "MCode": 13,
        "Vcode": 120,
        "Discontinued_Date": "2015-01-06 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "JAZZ",
        "Variant": "JAZZ 1.5 ELEGANCE",
        "MM_CODES": 25013305,
        "GCode": 250,
        "MCode": 13,
        "Vcode": 305,
        "Discontinued_Date": "2015-01-06 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "JAZZ",
        "Variant": "JAZZ 1.5 ELEGANCE",
        "MM_CODES": 25013306,
        "GCode": 250,
        "MCode": 13,
        "Vcode": 306,
        "Discontinued_Date": "2021-12-15 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "JAZZ",
        "Variant": "JAZZ 1.5 DYNAMIC",
        "MM_CODES": 25013310,
        "GCode": 250,
        "MCode": 13,
        "Vcode": 310,
        "Discontinued_Date": "2021-12-15 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "JAZZ",
        "Variant": "JAZZ 1.5 ELEGANCE A\/T",
        "MM_CODES": 25013325,
        "GCode": 250,
        "MCode": 13,
        "Vcode": 325,
        "Discontinued_Date": "2015-01-06 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "JAZZ",
        "Variant": "JAZZ 1.5 ELEGANCE CVT",
        "MM_CODES": 25013326,
        "GCode": 250,
        "MCode": 13,
        "Vcode": 326,
        "Discontinued_Date": "2021-12-15 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "JAZZ",
        "Variant": "JAZZ 1.5 DYNAMIC CVT",
        "MM_CODES": 25013330,
        "GCode": 250,
        "MCode": 13,
        "Vcode": 330,
        "Discontinued_Date": "2018-01-18 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "JAZZ",
        "Variant": "JAZZ 1.5 SPORT CVT",
        "MM_CODES": 25013335,
        "GCode": 250,
        "MCode": 13,
        "Vcode": 335,
        "Discontinued_Date": "2021-12-15 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "JAZZ",
        "Variant": "JAZZ 1.5 EXECUTIVE",
        "MM_CODES": 25013345,
        "GCode": 250,
        "MCode": 13,
        "Vcode": 345,
        "Discontinued_Date": "2015-01-06 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "JAZZ",
        "Variant": "JAZZ 1.5 EXECUTIVE  A\/T",
        "MM_CODES": 25013355,
        "GCode": 250,
        "MCode": 13,
        "Vcode": 355,
        "Discontinued_Date": "2015-01-06 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CIVIC 5DR 2006 - ON",
        "Variant": "CIVIC 1.6I - DTEC EXECUTIVE 5DR",
        "MM_CODES": 25020200,
        "GCode": 250,
        "MCode": 20,
        "Vcode": 200,
        "Discontinued_Date": "2015-11-27 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "BALLADE 2011 - ON",
        "Variant": "BALLADE 1.5 ELEGANCE CVT",
        "MM_CODES": 25053131,
        "GCode": 250,
        "MCode": 53,
        "Vcode": 131,
        "Discontinued_Date": "2020-11-02 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "BALLADE 2011 - ON",
        "Variant": "BALLADE 1.5 ELEGANCE CVT",
        "MM_CODES": 25053132,
        "GCode": 250,
        "MCode": 53,
        "Vcode": 132,
        "Discontinued_Date": "2024-01-22 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "BALLADE 2011 - ON",
        "Variant": "BALLADE 1.5 EXECUTIVE",
        "MM_CODES": 25053140,
        "GCode": 250,
        "MCode": 53,
        "Vcode": 140,
        "Discontinued_Date": "2017-02-23 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "BALLADE 2011 - ON",
        "Variant": "BALLADE 1.5 EXECUTIVE CVT",
        "MM_CODES": 25053150,
        "GCode": 250,
        "MCode": 53,
        "Vcode": 150,
        "Discontinued_Date": "2020-11-02 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "INSIGHT",
        "Variant": "INSIGHT 1.3 HYBRID A\/T",
        "MM_CODES": 25054100,
        "GCode": 250,
        "MCode": 54,
        "Vcode": 100,
        "Discontinued_Date": "2015-11-12 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CRX \/ CR-Z",
        "Variant": "CR-Z 1.5",
        "MM_CODES": 25056550,
        "GCode": 250,
        "MCode": 56,
        "Vcode": 550,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CRX \/ CR-Z",
        "Variant": "CR-Z 1.5",
        "MM_CODES": 25056551,
        "GCode": 250,
        "MCode": 56,
        "Vcode": 551,
        "Discontinued_Date": "2018-12-12 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CRX \/ CR-Z",
        "Variant": "CR-Z 1.5 CVT",
        "MM_CODES": 25056560,
        "GCode": 250,
        "MCode": 56,
        "Vcode": 560,
        "Discontinued_Date": "2018-12-12 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CIVIC 2006 - ON",
        "Variant": "CIVIC 1.5T SPORT CVT",
        "MM_CODES": 25061180,
        "GCode": 250,
        "MCode": 61,
        "Vcode": 180,
        "Discontinued_Date": "2022-07-26 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CIVIC 2006 - ON",
        "Variant": "CIVIC 1.8 COMFORT CVT",
        "MM_CODES": 25061412,
        "GCode": 250,
        "MCode": 61,
        "Vcode": 412,
        "Discontinued_Date": "2023-07-10 07:37:36.923"
    },
    {
        "Make": "HONDA",
        "Model": "CIVIC 2006 - ON",
        "Variant": "CIVIC 1.8 ELEGANCE",
        "MM_CODES": 25061421,
        "GCode": 250,
        "MCode": 61,
        "Vcode": 421,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "ACCORD",
        "Variant": "ACCORD 2.2 ID-TEC EXECUTIVE A\/T",
        "MM_CODES": 25064150,
        "GCode": 250,
        "MCode": 64,
        "Vcode": 150,
        "Discontinued_Date": "2014-06-23 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "ACCORD",
        "Variant": "ACCORD 2.2 ID-TEC EXEC TOUR A\/T",
        "MM_CODES": 25064170,
        "GCode": 250,
        "MCode": 64,
        "Vcode": 170,
        "Discontinued_Date": "2013-05-17 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "ACCORD",
        "Variant": "ACCORD 2.4 EXECUTIVE",
        "MM_CODES": 25064281,
        "GCode": 250,
        "MCode": 64,
        "Vcode": 281,
        "Discontinued_Date": "2014-06-23 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "ACCORD",
        "Variant": "ACCORD 2.4 EXCLUSIVE",
        "MM_CODES": 25064285,
        "GCode": 250,
        "MCode": 64,
        "Vcode": 285,
        "Discontinued_Date": "2014-06-23 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "ACCORD",
        "Variant": "ACCORD 2.4 EXEC TOURER",
        "MM_CODES": 25064290,
        "GCode": 250,
        "MCode": 64,
        "Vcode": 290,
        "Discontinued_Date": "2014-01-06 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "ACCORD",
        "Variant": "ACCORD 2.4 EXECUTIVE A\/T",
        "MM_CODES": 25064302,
        "GCode": 250,
        "MCode": 64,
        "Vcode": 302,
        "Discontinued_Date": "2014-06-23 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "ACCORD",
        "Variant": "ACCORD 2.4 EXCLUSIVE A\/T",
        "MM_CODES": 25064305,
        "GCode": 250,
        "MCode": 64,
        "Vcode": 305,
        "Discontinued_Date": "2014-06-23 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "ACCORD",
        "Variant": "ACCORD 2.4 EXEC TOURER A\/T",
        "MM_CODES": 25064310,
        "GCode": 250,
        "MCode": 64,
        "Vcode": 310,
        "Discontinued_Date": "2013-11-19 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "ACCORD",
        "Variant": "ACCORD 2.4 EXCLUSIVE TOURER",
        "MM_CODES": 25064340,
        "GCode": 250,
        "MCode": 64,
        "Vcode": 340,
        "Discontinued_Date": "2013-11-19 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "ACCORD",
        "Variant": "ACCORD 2.4 EXCLUSIVE TOURER A\/T",
        "MM_CODES": 25064350,
        "GCode": 250,
        "MCode": 64,
        "Vcode": 350,
        "Discontinued_Date": "2013-11-19 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "ACCORD",
        "Variant": "ACCORD 3.5 V6 EXCLUSIVE A\/T",
        "MM_CODES": 25064400,
        "GCode": 250,
        "MCode": 64,
        "Vcode": 400,
        "Discontinued_Date": "2017-02-17 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "HR-V",
        "Variant": "HR-V 1.5 COMFORT CVT",
        "MM_CODES": 25072150,
        "GCode": 250,
        "MCode": 72,
        "Vcode": 150,
        "Discontinued_Date": "2022-05-31 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "HR-V",
        "Variant": "HR-V 1.8 ELEGANCE CVT",
        "MM_CODES": 25072250,
        "GCode": 250,
        "MCode": 72,
        "Vcode": 250,
        "Discontinued_Date": "2022-05-31 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "MOBILIO\/ BR-V",
        "Variant": "MOBILIO 1.5 TREND",
        "MM_CODES": 25073100,
        "GCode": 250,
        "MCode": 73,
        "Vcode": 100,
        "Discontinued_Date": "2017-07-05 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "MOBILIO\/ BR-V",
        "Variant": "MOBILIO 1.5 COMFORT",
        "MM_CODES": 25073110,
        "GCode": 250,
        "MCode": 73,
        "Vcode": 110,
        "Discontinued_Date": "2017-07-05 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "MOBILIO\/ BR-V",
        "Variant": "MOBILIO 1.5 COMFORT CVT",
        "MM_CODES": 25073120,
        "GCode": 250,
        "MCode": 73,
        "Vcode": 120,
        "Discontinued_Date": "2017-07-05 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "MOBILIO\/ BR-V",
        "Variant": "BR-V 1.5 TREND",
        "MM_CODES": 25073300,
        "GCode": 250,
        "MCode": 73,
        "Vcode": 300,
        "Discontinued_Date": "2022-09-06 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "MOBILIO\/ BR-V",
        "Variant": "BR-V 1.5 COMFORT",
        "MM_CODES": 25073310,
        "GCode": 250,
        "MCode": 73,
        "Vcode": 310,
        "Discontinued_Date": "2022-09-06 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "MOBILIO\/ BR-V",
        "Variant": "BR-V 1.5 ELEGANCE",
        "MM_CODES": 25073320,
        "GCode": 250,
        "MCode": 73,
        "Vcode": 320,
        "Discontinued_Date": "2023-10-02 06:58:47.587"
    },
    {
        "Make": "HONDA",
        "Model": "MOBILIO\/ BR-V",
        "Variant": "BR-V 1.5 COMFORT CVT",
        "MM_CODES": 25073330,
        "GCode": 250,
        "MCode": 73,
        "Vcode": 330,
        "Discontinued_Date": "2022-09-06 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "MOBILIO\/ BR-V",
        "Variant": "BR-V 1.5 ELEGANCE CVT",
        "MM_CODES": 25073340,
        "GCode": 250,
        "MCode": 73,
        "Vcode": 340,
        "Discontinued_Date": "2022-09-06 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CRV 2015 ON",
        "Variant": "CR-V 1.5T EXCLUSIVE AWD CVT",
        "MM_CODES": 25078110,
        "GCode": 250,
        "MCode": 78,
        "Vcode": 110,
        "Discontinued_Date": "2024-01-25 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CRV 2015 ON",
        "Variant": "CRV 2.0 COMFORT",
        "MM_CODES": 25078200,
        "GCode": 250,
        "MCode": 78,
        "Vcode": 200,
        "Discontinued_Date": "2017-07-18 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CRV 2015 ON",
        "Variant": "CRV 2.0 COMFORT A\/T",
        "MM_CODES": 25078210,
        "GCode": 250,
        "MCode": 78,
        "Vcode": 210,
        "Discontinued_Date": "2017-07-18 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CRV 2015 ON",
        "Variant": "CR-V 2.0 COMFORT CVT",
        "MM_CODES": 25078211,
        "GCode": 250,
        "MCode": 78,
        "Vcode": 211,
        "Discontinued_Date": "2024-01-25 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CRV 2015 ON",
        "Variant": "CRV 2.0 ELEGANCE",
        "MM_CODES": 25078220,
        "GCode": 250,
        "MCode": 78,
        "Vcode": 220,
        "Discontinued_Date": "2017-07-18 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CRV 2015 ON",
        "Variant": "CRV 2.0 ELEGANCE A\/T",
        "MM_CODES": 25078230,
        "GCode": 250,
        "MCode": 78,
        "Vcode": 230,
        "Discontinued_Date": "2017-07-18 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CRV 2015 ON",
        "Variant": "CR-V 2.0 ELEGANCE CVT",
        "MM_CODES": 25078231,
        "GCode": 250,
        "MCode": 78,
        "Vcode": 231,
        "Discontinued_Date": "2024-01-25 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CRV 2015 ON",
        "Variant": "CRV 2.4 EXECUTIVE A\/T",
        "MM_CODES": 25078400,
        "GCode": 250,
        "MCode": 78,
        "Vcode": 400,
        "Discontinued_Date": "2017-07-18 00:00:00.000"
    },
    {
        "Make": "HONDA",
        "Model": "CRV 2015 ON",
        "Variant": "CRV 2.4 EXCLUSIVE A\/T",
        "MM_CODES": 25078420,
        "GCode": 250,
        "MCode": 78,
        "Vcode": 420,
        "Discontinued_Date": "2017-07-18 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ATOZ",
        "Variant": "ATOS 1.1 MOTION",
        "MM_CODES": 26515450,
        "GCode": 265,
        "MCode": 15,
        "Vcode": 450,
        "Discontinued_Date": "2023-03-29 06:25:11.697"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ATOZ",
        "Variant": "ATOS 1.1 MOTION AMT",
        "MM_CODES": 26515460,
        "GCode": 265,
        "MCode": 15,
        "Vcode": 460,
        "Discontinued_Date": "2023-03-29 06:25:02.390"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "I10 1.1 GLS\/MOTION",
        "MM_CODES": 26516101,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 101,
        "Discontinued_Date": "2018-10-10 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "I10 1.1 MOTION A\/T",
        "MM_CODES": 26516131,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 131,
        "Discontinued_Date": "2018-10-10 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "I10 1.25 GLS\/FLUID",
        "MM_CODES": 26516201,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 201,
        "Discontinued_Date": "2015-08-07 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "I10 1.25 GLS\/FLUID A\/T",
        "MM_CODES": 26516211,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 211,
        "Discontinued_Date": "2015-08-07 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "I10 1.25 GLIDE",
        "MM_CODES": 26516231,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 231,
        "Discontinued_Date": "2018-10-10 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "GRAND I10 1.0 MOTION",
        "MM_CODES": 26516245,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 245,
        "Discontinued_Date": "2020-10-06 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "GRAND I10 1.0 MOTION",
        "MM_CODES": 26516246,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 246,
        "Discontinued_Date": "2023-06-27 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "GRAND I10 1.0 MOTION A\/T",
        "MM_CODES": 26516250,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 250,
        "Discontinued_Date": "2020-10-06 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "GRAND I10 1.0 MOTION A\/T",
        "MM_CODES": 26516251,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 251,
        "Discontinued_Date": "2023-06-27 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "GRAND I10 1.25 MOTION",
        "MM_CODES": 26516285,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 285,
        "Discontinued_Date": "2018-01-23 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "GRAND I10 1.25 MOTION A\/T",
        "MM_CODES": 26516290,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 290,
        "Discontinued_Date": "2018-01-23 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "GRAND I10 1.0 FLUID",
        "MM_CODES": 26516295,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 295,
        "Discontinued_Date": "2020-10-06 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "GRAND I10 1.0 FLUID",
        "MM_CODES": 26516296,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 296,
        "Discontinued_Date": "2023-06-27 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "GRAND I10 1.25 FLUID ",
        "MM_CODES": 26516306,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 306,
        "Discontinued_Date": "2020-10-06 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "GRAND I10 1.2 FLUID ",
        "MM_CODES": 26516307,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 307,
        "Discontinued_Date": "2023-06-27 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "GRAND I10 1.25 FLUID A\/T",
        "MM_CODES": 26516316,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 316,
        "Discontinued_Date": "2020-10-06 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "GRAND I10 1.2 FLUID A\/T",
        "MM_CODES": 26516317,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 317,
        "Discontinued_Date": "2023-06-27 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "GRAND I10 1.25 GLIDE",
        "MM_CODES": 26516325,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 325,
        "Discontinued_Date": "2020-10-06 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10",
        "Variant": "GRAND I10 1.0 MOTION CARGO F\/C P\/V",
        "MM_CODES": 26516400,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 400,
        "Discontinued_Date": "2021-06-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "I20 1.2 MOTION",
        "MM_CODES": 26516462,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 462,
        "Discontinued_Date": "2021-05-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "I20 1.4 MOTION A\/T",
        "MM_CODES": 26516495,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 495,
        "Discontinued_Date": "2021-05-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "I20 1.4",
        "MM_CODES": 26516500,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 500,
        "Discontinued_Date": "2012-08-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "I20 1.4 A\/T",
        "MM_CODES": 26516520,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 520,
        "Discontinued_Date": "2012-08-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "I20 1.4 FLUID A\/T",
        "MM_CODES": 26516522,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 522,
        "Discontinued_Date": "2021-05-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "I20 1.4 ACTIVE",
        "MM_CODES": 26516527,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 527,
        "Discontinued_Date": "2021-05-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "I20 1.6",
        "MM_CODES": 26516540,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 540,
        "Discontinued_Date": "2012-07-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "I20 1.4D GLIDE",
        "MM_CODES": 26516580,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 580,
        "Discontinued_Date": "2015-01-26 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "I10 \/  I20 \/ I30",
        "Variant": "I30 2.0",
        "MM_CODES": 26516650,
        "GCode": 265,
        "MCode": 16,
        "Vcode": 650,
        "Discontinued_Date": "2012-07-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ACCENT 2000 - ON",
        "Variant": "ACCENT 1.6 GLS\/FLUID",
        "MM_CODES": 26526501,
        "GCode": 265,
        "MCode": 26,
        "Vcode": 501,
        "Discontinued_Date": "2021-12-08 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ACCENT 2000 - ON",
        "Variant": "ACCENT 1.6 GLIDE",
        "MM_CODES": 26526521,
        "GCode": 265,
        "MCode": 26,
        "Vcode": 521,
        "Discontinued_Date": "2019-06-28 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ACCENT 2000 - ON",
        "Variant": "ACCENT 1.6 FLUID A\/T 5DR",
        "MM_CODES": 26526535,
        "GCode": 265,
        "MCode": 26,
        "Vcode": 535,
        "Discontinued_Date": "2018-12-11 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ACCENT 2000 - ON",
        "Variant": "ACCENT 1.6 GLIDE A\/T",
        "MM_CODES": 26526561,
        "GCode": 265,
        "MCode": 26,
        "Vcode": 561,
        "Discontinued_Date": "2019-06-28 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "VENUE",
        "Variant": "VENUE 1.0 TGDI MOTION",
        "MM_CODES": 26528150,
        "GCode": 265,
        "MCode": 28,
        "Vcode": 150,
        "Discontinued_Date": "2022-10-11 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "VENUE",
        "Variant": "VENUE 1.0 TGDI MOTION DCT",
        "MM_CODES": 26528160,
        "GCode": 265,
        "MCode": 28,
        "Vcode": 160,
        "Discontinued_Date": "2022-10-11 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "VENUE",
        "Variant": "VENUE 1.0 TGDI FLUID",
        "MM_CODES": 26528170,
        "GCode": 265,
        "MCode": 28,
        "Vcode": 170,
        "Discontinued_Date": "2022-10-11 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "VENUE",
        "Variant": "1.0 TDGI FLUID DCT",
        "MM_CODES": 26528180,
        "GCode": 265,
        "MCode": 28,
        "Vcode": 180,
        "Discontinued_Date": "2022-10-11 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "VENUE",
        "Variant": "VENUE 1.0 TGDI GLIDE DCT",
        "MM_CODES": 26528200,
        "GCode": 265,
        "MCode": 28,
        "Vcode": 200,
        "Discontinued_Date": "2022-10-11 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H100 \/ BAKKIE",
        "Variant": "H100 2.5 TCI F\/C D\/S",
        "MM_CODES": 26530201,
        "GCode": 265,
        "MCode": 30,
        "Vcode": 201,
        "Discontinued_Date": "2013-10-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H100 \/ BAKKIE",
        "Variant": "H100 2.5 TCI  A\/C F\/C D\/S",
        "MM_CODES": 26530210,
        "GCode": 265,
        "MCode": 30,
        "Vcode": 210,
        "Discontinued_Date": "2013-10-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H100 \/ BAKKIE",
        "Variant": "H100 2.5 TCI TIP C\/C",
        "MM_CODES": 26530250,
        "GCode": 265,
        "MCode": 30,
        "Vcode": 250,
        "Discontinued_Date": "2012-05-02 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "KONA",
        "Variant": "KONA 1.0TGDI EXECUTIVE",
        "MM_CODES": 26532150,
        "GCode": 265,
        "MCode": 32,
        "Vcode": 150,
        "Discontinued_Date": "2021-06-18 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "KONA",
        "Variant": "KONA 2.0 EXECUTIVE A\/T",
        "MM_CODES": 26532310,
        "GCode": 265,
        "MCode": 32,
        "Vcode": 310,
        "Discontinued_Date": "2021-06-18 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "KONA",
        "Variant": "KONA 2.0 EXECUTIVE IVT",
        "MM_CODES": 26532311,
        "GCode": 265,
        "MCode": 32,
        "Vcode": 311,
        "Discontinued_Date": "1899-12-30 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "HD65\/72 - EX-8",
        "Variant": "HD 72 F\/C C\/C",
        "MM_CODES": 26540100,
        "GCode": 265,
        "MCode": 40,
        "Vcode": 100,
        "Discontinued_Date": "2011-01-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "MIGHTY HD65\/72 - EX-8",
        "Variant": "HD 72 F\/C C\/C",
        "MM_CODES": 26540100,
        "GCode": 265,
        "MCode": 40,
        "Vcode": 100,
        "Discontinued_Date": "2011-01-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "MIGHTY HD65\/72\/EX-6\/EX-8",
        "Variant": "HD 72 F\/C C\/C",
        "MM_CODES": 26540100,
        "GCode": 265,
        "MCode": 40,
        "Vcode": 100,
        "Discontinued_Date": "2011-01-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "HD65\/72 - EX-8",
        "Variant": "MIGHTY HD72 F\/C C\/C",
        "MM_CODES": 26540102,
        "GCode": 265,
        "MCode": 40,
        "Vcode": 102,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "MIGHTY HD65\/72 - EX-8",
        "Variant": "MIGHTY HD72 F\/C C\/C",
        "MM_CODES": 26540102,
        "GCode": 265,
        "MCode": 40,
        "Vcode": 102,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "MIGHTY HD65\/72\/EX-6\/EX-8",
        "Variant": "MIGHTY HD72 F\/C C\/C",
        "MM_CODES": 26540102,
        "GCode": 265,
        "MCode": 40,
        "Vcode": 102,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "MIGHTY HD65\/72\/EX-6\/EX-8",
        "Variant": "MIGHTY HD72 A\/C F\/C D\/S",
        "MM_CODES": 26540115,
        "GCode": 265,
        "MCode": 40,
        "Vcode": 115,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1",
        "Variant": "H-1 GL 2.4 CVVT A\/C  F\/C P\/V",
        "MM_CODES": 26545210,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 210,
        "Discontinued_Date": "2015-07-14 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1\/STARIA",
        "Variant": "H-1 GL 2.4 CVVT A\/C  F\/C P\/V",
        "MM_CODES": 26545210,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 210,
        "Discontinued_Date": "2015-07-14 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1",
        "Variant": "H-1 2.5 CRDI F\/C P\/V",
        "MM_CODES": 26545220,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 220,
        "Discontinued_Date": "2013-07-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1\/STARIA",
        "Variant": "H-1 2.5 CRDI F\/C P\/V",
        "MM_CODES": 26545220,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 220,
        "Discontinued_Date": "2013-07-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1",
        "Variant": "H-1 2.5 CRDI  A\/C F\/C P\/V",
        "MM_CODES": 26545230,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 230,
        "Discontinued_Date": "2013-07-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1\/STARIA",
        "Variant": "H-1 2.5 CRDI  A\/C F\/C P\/V",
        "MM_CODES": 26545230,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 230,
        "Discontinued_Date": "2013-07-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1",
        "Variant": "H-1 2.5 CRDI A\/C F\/C P\/V A\/T",
        "MM_CODES": 26545240,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 240,
        "Discontinued_Date": "2021-12-05 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1\/STARIA",
        "Variant": "H-1 2.5 CRDI A\/C F\/C P\/V A\/T",
        "MM_CODES": 26545240,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 240,
        "Discontinued_Date": "2021-12-05 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1\/STARIA",
        "Variant": "H-1 GLS 2.4 CVVT\/ 2.4 EXECUTIVE",
        "MM_CODES": 26545400,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 400,
        "Discontinued_Date": "2022-01-05 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1",
        "Variant": "H-1 2.5 CRDI (VGT) WAGON A\/T",
        "MM_CODES": 26545410,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 410,
        "Discontinued_Date": "2015-12-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1\/STARIA",
        "Variant": "H-1 2.5 CRDI (VGT) WAGON A\/T",
        "MM_CODES": 26545410,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 410,
        "Discontinued_Date": "2015-12-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1\/STARIA",
        "Variant": "H-1 2.5 CRDI A\/T\/ 2.5 ELITE A\/T",
        "MM_CODES": 26545411,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 411,
        "Discontinued_Date": "2022-01-05 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1",
        "Variant": "H-1 2.4 GL MULTICAB 6 SEAT",
        "MM_CODES": 26545500,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 500,
        "Discontinued_Date": "2015-10-06 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1\/STARIA",
        "Variant": "H-1 2.4 GL MULTICAB 6 SEAT",
        "MM_CODES": 26545500,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 500,
        "Discontinued_Date": "2015-10-06 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1",
        "Variant": "H-1 2.5 CRDI MULTICAB A\/T 6 SEAT",
        "MM_CODES": 26545530,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 530,
        "Discontinued_Date": "2021-09-30 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1\/STARIA",
        "Variant": "H-1 2.5 CRDI MULTICAB A\/T 6 SEAT",
        "MM_CODES": 26545530,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 530,
        "Discontinued_Date": "2021-09-30 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "H-1\/STARIA",
        "Variant": "H1 2.5 CRDI ELITE A\/T (12 SEAT)",
        "MM_CODES": 26545610,
        "GCode": 265,
        "MCode": 45,
        "Vcode": 610,
        "Discontinued_Date": "2022-01-05 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ELANTRA 2001 - ON",
        "Variant": "ELANTRA 1.6",
        "MM_CODES": 26551400,
        "GCode": 265,
        "MCode": 51,
        "Vcode": 400,
        "Discontinued_Date": "2011-07-22 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ELANTRA 2001 - ON",
        "Variant": "ELANTRA 1.6 SR",
        "MM_CODES": 26551410,
        "GCode": 265,
        "MCode": 51,
        "Vcode": 410,
        "Discontinued_Date": "2010-02-24 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ELANTRA 2001 - ON",
        "Variant": "ELANTRA 1.6 A\/T",
        "MM_CODES": 26551420,
        "GCode": 265,
        "MCode": 51,
        "Vcode": 420,
        "Discontinued_Date": "2010-09-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ELANTRA 2001 - ON",
        "Variant": "ELANTRA 2.0",
        "MM_CODES": 26551500,
        "GCode": 265,
        "MCode": 51,
        "Vcode": 500,
        "Discontinued_Date": "2010-09-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ELANTRA 2001 - ON",
        "Variant": "ELANTRA 1.6 GLS\/PREMIUM",
        "MM_CODES": 26551550,
        "GCode": 265,
        "MCode": 51,
        "Vcode": 550,
        "Discontinued_Date": "2014-07-08 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ELANTRA 2001 - ON",
        "Variant": "ELANTRA 1.6 PREMIUM",
        "MM_CODES": 26551551,
        "GCode": 265,
        "MCode": 51,
        "Vcode": 551,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ELANTRA 2001 - ON",
        "Variant": "ELANTRA 1.6 PREMIUM A\/T",
        "MM_CODES": 26551560,
        "GCode": 265,
        "MCode": 51,
        "Vcode": 560,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ELANTRA 2001 - ON",
        "Variant": "ELANTRA 1.6 EXECUTIVE",
        "MM_CODES": 26551570,
        "GCode": 265,
        "MCode": 51,
        "Vcode": 570,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ELANTRA 2001 - ON",
        "Variant": "ELANTRA 1.6 EXECUTIVE",
        "MM_CODES": 26551571,
        "GCode": 265,
        "MCode": 51,
        "Vcode": 571,
        "Discontinued_Date": "2021-12-06 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ELANTRA 2001 - ON",
        "Variant": "ELANTRA 1.6 EXECUTIVE A\/T",
        "MM_CODES": 26551580,
        "GCode": 265,
        "MCode": 51,
        "Vcode": 580,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ELANTRA 2001 - ON",
        "Variant": "ELANTRA 1.6 TGDI ELITE DCT",
        "MM_CODES": 26551595,
        "GCode": 265,
        "MCode": 51,
        "Vcode": 595,
        "Discontinued_Date": "2021-12-06 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ELANTRA 2001 - ON",
        "Variant": "ELANTRA 1.8 GLS\/EXECUTIVE",
        "MM_CODES": 26551600,
        "GCode": 265,
        "MCode": 51,
        "Vcode": 600,
        "Discontinued_Date": "2014-07-08 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ELANTRA 2001 - ON",
        "Variant": "ELANTRA 1.8 GLS\/EXECUTIVE A\/T",
        "MM_CODES": 26551610,
        "GCode": 265,
        "MCode": 51,
        "Vcode": 610,
        "Discontinued_Date": "2014-07-08 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "ELANTRA 2001 - ON",
        "Variant": "ELANTRA 2.0 ELITE A\/T",
        "MM_CODES": 26551680,
        "GCode": 265,
        "MCode": 51,
        "Vcode": 680,
        "Discontinued_Date": "2021-12-06 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SONATA",
        "Variant": "SONATA 2.4 GLS\/PREMIUM A\/T",
        "MM_CODES": 26555871,
        "GCode": 265,
        "MCode": 55,
        "Vcode": 871,
        "Discontinued_Date": "2013-10-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SONATA",
        "Variant": "SONATA 2.4 GLS EXECUTIVE A\/T",
        "MM_CODES": 26555880,
        "GCode": 265,
        "MCode": 55,
        "Vcode": 880,
        "Discontinued_Date": "2013-10-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SONATA",
        "Variant": "SONATA 2.4 GDI ELITE A\/T",
        "MM_CODES": 26555890,
        "GCode": 265,
        "MCode": 55,
        "Vcode": 890,
        "Discontinued_Date": "2015-10-08 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "CRETA",
        "Variant": "CRETA 1.4 TGDI EXECUTIVE DCT",
        "MM_CODES": 26557160,
        "GCode": 265,
        "MCode": 57,
        "Vcode": 160,
        "Discontinued_Date": "2022-07-13 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "CRETA",
        "Variant": "CRETA 1.6 LIMITED ED",
        "MM_CODES": 26557205,
        "GCode": 265,
        "MCode": 57,
        "Vcode": 205,
        "Discontinued_Date": "2020-03-27 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "CRETA",
        "Variant": "CRETA 1.6 EXECUTIVE A\/T",
        "MM_CODES": 26557210,
        "GCode": 265,
        "MCode": 57,
        "Vcode": 210,
        "Discontinued_Date": "2020-11-04 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "CRETA",
        "Variant": "CRETA 1.5 EXECUTIVE IVT",
        "MM_CODES": 26557211,
        "GCode": 265,
        "MCode": 57,
        "Vcode": 211,
        "Discontinued_Date": "2022-07-13 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "CRETA",
        "Variant": "CRETA 1.5 EXECUTIVE IVT",
        "MM_CODES": 26557212,
        "GCode": 265,
        "MCode": 57,
        "Vcode": 212,
        "Discontinued_Date": "1899-12-30 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "CRETA",
        "Variant": "CRETA 1.6 LIMITED ED A\/T",
        "MM_CODES": 26557215,
        "GCode": 265,
        "MCode": 57,
        "Vcode": 215,
        "Discontinued_Date": "2020-11-04 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "CRETA",
        "Variant": "CRETA 1.6D EXECUTIVE A\/T",
        "MM_CODES": 26557280,
        "GCode": 265,
        "MCode": 57,
        "Vcode": 280,
        "Discontinued_Date": "2020-11-04 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "CRETA",
        "Variant": "CRETA 1.5D EXECUTIVE A\/T",
        "MM_CODES": 26557281,
        "GCode": 265,
        "MCode": 57,
        "Vcode": 281,
        "Discontinued_Date": "2022-07-13 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "CRETA",
        "Variant": "CRETA 1.6D LIMITED ED A\/T",
        "MM_CODES": 26557285,
        "GCode": 265,
        "MCode": 57,
        "Vcode": 285,
        "Discontinued_Date": "2020-03-27 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 2.0 GLS",
        "MM_CODES": 26568200,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 200,
        "Discontinued_Date": "2010-05-05 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 2.0 CRDI A\/T",
        "MM_CODES": 26568250,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 250,
        "Discontinued_Date": "2010-05-05 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 2.7 V6 GLS A\/T",
        "MM_CODES": 26568320,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 320,
        "Discontinued_Date": "2010-05-05 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 1.6 TGDI EXECUTIVE",
        "MM_CODES": 26568410,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 410,
        "Discontinued_Date": "2018-11-20 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 1.6 TGDI SPORT (150KW)",
        "MM_CODES": 26568430,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 430,
        "Discontinued_Date": "2019-05-07 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 1.6 TGDI SPORT DCT (150KW)",
        "MM_CODES": 26568435,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 435,
        "Discontinued_Date": "2022-02-23 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 1.6TGDI ELITE DCT",
        "MM_CODES": 26568445,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 445,
        "Discontinued_Date": "2022-02-23 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 1.6 TGDI ELITE DCT",
        "MM_CODES": 26568450,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 450,
        "Discontinued_Date": "2019-05-07 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 1.7 CRDI EXECUTIVE",
        "MM_CODES": 26568480,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 480,
        "Discontinued_Date": "2018-11-13 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 2.0 PREMIUM",
        "MM_CODES": 26568520,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 520,
        "Discontinued_Date": "2022-02-23 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 2.0 PREMIUM A\/T",
        "MM_CODES": 26568550,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 550,
        "Discontinued_Date": "2022-02-23 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 2.0 EXECUTIVE A\/T",
        "MM_CODES": 26568555,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 555,
        "Discontinued_Date": "2022-02-23 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 2.0 EXECUTIVE A\/T",
        "MM_CODES": 26568556,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 556,
        "Discontinued_Date": "1899-12-30 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 2.0 ELITE A\/T",
        "MM_CODES": 26568570,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 570,
        "Discontinued_Date": "2022-02-23 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 2.0 CRDI ELITE A\/T",
        "MM_CODES": 26568610,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 610,
        "Discontinued_Date": "2022-02-23 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TUCSON",
        "Variant": "TUCSON 2.0 CRDI SPORT A\/T",
        "MM_CODES": 26568620,
        "GCode": 265,
        "MCode": 68,
        "Vcode": 620,
        "Discontinued_Date": "2022-02-23 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "IX35",
        "Variant": "IX35 2.0 GL\/PREMIUM",
        "MM_CODES": 26569100,
        "GCode": 265,
        "MCode": 69,
        "Vcode": 100,
        "Discontinued_Date": "2013-12-10 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "IX35",
        "Variant": "IX35 2.0 PREMIUM",
        "MM_CODES": 26569101,
        "GCode": 265,
        "MCode": 69,
        "Vcode": 101,
        "Discontinued_Date": "2017-09-04 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "IX35",
        "Variant": "IX35 2.0 PREMIUM A\/T",
        "MM_CODES": 26569106,
        "GCode": 265,
        "MCode": 69,
        "Vcode": 106,
        "Discontinued_Date": "2017-09-04 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "IX35",
        "Variant": "IX35 2.0 GLS\/EXECUTIVE",
        "MM_CODES": 26569110,
        "GCode": 265,
        "MCode": 69,
        "Vcode": 110,
        "Discontinued_Date": "2014-01-28 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "IX35",
        "Variant": "IX35 2.0 EXECUTIVE",
        "MM_CODES": 26569111,
        "GCode": 265,
        "MCode": 69,
        "Vcode": 111,
        "Discontinued_Date": "2017-09-04 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "IX35",
        "Variant": "IX35 2.0 GLS\/EXECUTIVE A\/T",
        "MM_CODES": 26569120,
        "GCode": 265,
        "MCode": 69,
        "Vcode": 120,
        "Discontinued_Date": "2014-01-28 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "IX35",
        "Variant": "IX35 2.0 ELITE A\/T",
        "MM_CODES": 26569135,
        "GCode": 265,
        "MCode": 69,
        "Vcode": 135,
        "Discontinued_Date": "2017-09-04 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "IX35",
        "Variant": "IX35 R2.0 CRDI GLS\/EXECUTIVE",
        "MM_CODES": 26569150,
        "GCode": 265,
        "MCode": 69,
        "Vcode": 150,
        "Discontinued_Date": "2014-01-28 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "IX35",
        "Variant": "IX35 2.0 CRDI ELITE",
        "MM_CODES": 26569156,
        "GCode": 265,
        "MCode": 69,
        "Vcode": 156,
        "Discontinued_Date": "2017-09-04 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "IX35",
        "Variant": "IX35 R2.0 CRDI GLS\/ELITE AWD A\/T",
        "MM_CODES": 26569160,
        "GCode": 265,
        "MCode": 69,
        "Vcode": 160,
        "Discontinued_Date": "2014-01-28 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "IX35",
        "Variant": "IX35 2.0 CRDI ELITE AWD A\/T",
        "MM_CODES": 26569161,
        "GCode": 265,
        "MCode": 69,
        "Vcode": 161,
        "Discontinued_Date": "2017-09-04 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "IX35",
        "Variant": "IX35 2.4 GLS\/ELITE AWD A\/T",
        "MM_CODES": 26569220,
        "GCode": 265,
        "MCode": 69,
        "Vcode": 220,
        "Discontinued_Date": "2013-07-02 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TIBURON\/VELOSTER",
        "Variant": "VELOSTER 1.6 GDI EXECUTIVE",
        "MM_CODES": 26570250,
        "GCode": 265,
        "MCode": 70,
        "Vcode": 250,
        "Discontinued_Date": "2017-07-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TIBURON\/VELOSTER",
        "Variant": "VELOSTER 1.6 GDI EXECUTIVE DCT",
        "MM_CODES": 26570260,
        "GCode": 265,
        "MCode": 70,
        "Vcode": 260,
        "Discontinued_Date": "2017-07-03 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TIBURON\/VELOSTER",
        "Variant": "VELOSTER 1.6 GDI T",
        "MM_CODES": 26570270,
        "GCode": 265,
        "MCode": 70,
        "Vcode": 270,
        "Discontinued_Date": "2018-12-05 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "TIBURON\/VELOSTER",
        "Variant": "VELOSTER 1.6 GDI T DCT",
        "MM_CODES": 26570280,
        "GCode": 265,
        "MCode": 70,
        "Vcode": 280,
        "Discontinued_Date": "2018-12-05 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTA-FE",
        "Variant": "SANTA- FE R2.2D PREMIUM A\/T",
        "MM_CODES": 26572260,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 260,
        "Discontinued_Date": "2018-09-26 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTE-FE",
        "Variant": "SANTE- FE R2.2D PREMIUM A\/T",
        "MM_CODES": 26572260,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 260,
        "Discontinued_Date": "2018-09-26 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTA-FE",
        "Variant": "SANTA-FE R2.2 PREMIUM A\/T (7 SEAT)",
        "MM_CODES": 26572261,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 261,
        "Discontinued_Date": "2021-03-04 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTE-FE",
        "Variant": "SANTE-FE R2.2 PREMIUM A\/T (7 SEAT)",
        "MM_CODES": 26572261,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 261,
        "Discontinued_Date": "2021-03-04 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTA-FE",
        "Variant": "SANTA FE R2.2 CRDI GLS A\/T 4X4",
        "MM_CODES": 26572301,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 301,
        "Discontinued_Date": "2013-01-17 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTE-FE",
        "Variant": "SANTE FE R2.2 CRDI GLS A\/T 4X4",
        "MM_CODES": 26572301,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 301,
        "Discontinued_Date": "2013-01-17 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTA-FE",
        "Variant": "SANTA FE R2.2 ELITE A\/T",
        "MM_CODES": 26572320,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 320,
        "Discontinued_Date": "2018-11-02 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTE-FE",
        "Variant": "SANTE FE R2.2 ELITE A\/T",
        "MM_CODES": 26572320,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 320,
        "Discontinued_Date": "2018-11-02 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTA-FE",
        "Variant": "SANTA FE R2.2 CRDI GLS A\/T 7SEAT",
        "MM_CODES": 26572331,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 331,
        "Discontinued_Date": "2013-01-17 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTE-FE",
        "Variant": "SANTE FE R2.2 CRDI GLS A\/T 7SEAT",
        "MM_CODES": 26572331,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 331,
        "Discontinued_Date": "2013-01-17 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTA-FE",
        "Variant": "SANTA-FE R2.2 AWD EXEC 7S A\/T",
        "MM_CODES": 26572332,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 332,
        "Discontinued_Date": "2016-11-30 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTE-FE",
        "Variant": "SANTE-FE R2.2 AWD EXEC 7S A\/T",
        "MM_CODES": 26572332,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 332,
        "Discontinued_Date": "2016-11-30 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTA-FE",
        "Variant": "SANTA FE R2.2 AWD ELITE 7S A\/T",
        "MM_CODES": 26572340,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 340,
        "Discontinued_Date": "2015-09-29 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTE-FE",
        "Variant": "SANTA FE R2.2 AWD ELITE 7S A\/T",
        "MM_CODES": 26572340,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 340,
        "Discontinued_Date": "2015-09-29 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTA-FE",
        "Variant": "SANTA-FE R2.2 AWD ELITE A\/T (7 SEAT)",
        "MM_CODES": 26572342,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 342,
        "Discontinued_Date": "2021-03-04 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTE-FE",
        "Variant": "SANTE-FE R2.2 AWD ELITE A\/T (7 SEAT)",
        "MM_CODES": 26572342,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 342,
        "Discontinued_Date": "2021-03-04 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "SANTA-FE",
        "Variant": "SANTA-FE R2.2 AWD ELITE DCT (7 SEAT)",
        "MM_CODES": 26572343,
        "GCode": 265,
        "MCode": 72,
        "Vcode": 343,
        "Discontinued_Date": "2023-08-01 00:00:00.000"
    },
    {
        "Make": "HYUNDAI",
        "Model": "PALISADE",
        "Variant": "PALISADE 2.2D ELITE AWD A\/T (7 SEAT)",
        "MM_CODES": 26575100,
        "GCode": 265,
        "MCode": 75,
        "Vcode": 100,
        "Discontinued_Date": "2023-02-27 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "Q50",
        "Variant": "Q50 2.0 PREMIUM A\/T",
        "MM_CODES": 27510200,
        "GCode": 275,
        "MCode": 10,
        "Vcode": 200,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "Q50",
        "Variant": "Q50 2.0 SPORT A\/T",
        "MM_CODES": 27510210,
        "GCode": 275,
        "MCode": 10,
        "Vcode": 210,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "Q50",
        "Variant": "Q50 2.2D",
        "MM_CODES": 27510240,
        "GCode": 275,
        "MCode": 10,
        "Vcode": 240,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "Q50",
        "Variant": "Q50 2.2D PREMIUM A\/T",
        "MM_CODES": 27510260,
        "GCode": 275,
        "MCode": 10,
        "Vcode": 260,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "Q50",
        "Variant": "Q50 2.2D SPORT A\/T",
        "MM_CODES": 27510270,
        "GCode": 275,
        "MCode": 10,
        "Vcode": 270,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "Q50",
        "Variant": "Q50 3.5P HYBRID",
        "MM_CODES": 27510350,
        "GCode": 275,
        "MCode": 10,
        "Vcode": 350,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "Q50",
        "Variant": "Q50 3.5P HYBRID AWD",
        "MM_CODES": 27510360,
        "GCode": 275,
        "MCode": 10,
        "Vcode": 360,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "G\/Q60",
        "Variant": "G37\/Q60 GT COUPE",
        "MM_CODES": 27520250,
        "GCode": 275,
        "MCode": 20,
        "Vcode": 250,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "G\/Q60",
        "Variant": "G37\/Q60 GT COUPE S PREMIUM",
        "MM_CODES": 27520280,
        "GCode": 275,
        "MCode": 20,
        "Vcode": 280,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "G\/Q60",
        "Variant": "G37\/Q60 GT CABRIO PREMIUM",
        "MM_CODES": 27520350,
        "GCode": 275,
        "MCode": 20,
        "Vcode": 350,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "M\/Q70",
        "Variant": "M30D\/Q70 3.0D GT",
        "MM_CODES": 27540280,
        "GCode": 275,
        "MCode": 40,
        "Vcode": 280,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "M\/Q70",
        "Variant": "M30D\/Q70 3.0D GT PREMIUM",
        "MM_CODES": 27540290,
        "GCode": 275,
        "MCode": 40,
        "Vcode": 290,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "M\/Q70",
        "Variant": "M30D.\/Q70 3.0D S",
        "MM_CODES": 27540310,
        "GCode": 275,
        "MCode": 40,
        "Vcode": 310,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "M\/Q70",
        "Variant": "M30D\/Q70 3.0D S PREMIUM",
        "MM_CODES": 27540320,
        "GCode": 275,
        "MCode": 40,
        "Vcode": 320,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "M\/Q70",
        "Variant": "Q70 3.7 GT PREMIUM",
        "MM_CODES": 27540360,
        "GCode": 275,
        "MCode": 40,
        "Vcode": 360,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "M\/Q70",
        "Variant": "Q70 3.7 S PREMIUM",
        "MM_CODES": 27540380,
        "GCode": 275,
        "MCode": 40,
        "Vcode": 380,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "EX\/QX50",
        "Variant": "EX30D\/QX50 3.0D",
        "MM_CODES": 27550250,
        "GCode": 275,
        "MCode": 50,
        "Vcode": 250,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "EX\/QX50",
        "Variant": "EX30D\/QX50 3.0D GT",
        "MM_CODES": 27550270,
        "GCode": 275,
        "MCode": 50,
        "Vcode": 270,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "EX\/QX50",
        "Variant": "EX30D\/QX50 3.0D GT PREMIUM",
        "MM_CODES": 27550280,
        "GCode": 275,
        "MCode": 50,
        "Vcode": 280,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "EX\/QX50",
        "Variant": "EX37\/QX50 3.7 GT",
        "MM_CODES": 27550400,
        "GCode": 275,
        "MCode": 50,
        "Vcode": 400,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "EX\/QX50",
        "Variant": "EX37\/QX50 3.7  GT PREMIUM",
        "MM_CODES": 27550410,
        "GCode": 275,
        "MCode": 50,
        "Vcode": 410,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "FX\/QX70",
        "Variant": "FX\/QX70 30D GT",
        "MM_CODES": 27560240,
        "GCode": 275,
        "MCode": 60,
        "Vcode": 240,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "FX\/QX70",
        "Variant": "FX\/QX70 30D GT PREMIUM",
        "MM_CODES": 27560250,
        "GCode": 275,
        "MCode": 60,
        "Vcode": 250,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "FX\/QX70",
        "Variant": "FX\/QX70 30D S",
        "MM_CODES": 27560260,
        "GCode": 275,
        "MCode": 60,
        "Vcode": 260,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "FX\/QX70",
        "Variant": "FX\/QX70 30D S PREMIUM",
        "MM_CODES": 27560270,
        "GCode": 275,
        "MCode": 60,
        "Vcode": 270,
        "Discontinued_Date": "2018-12-11 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "FX\/QX70",
        "Variant": "FX\/QX70 3.7 GT",
        "MM_CODES": 27560320,
        "GCode": 275,
        "MCode": 60,
        "Vcode": 320,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "FX\/QX70",
        "Variant": "FX\/QX70 3.7 GT PREMIUM",
        "MM_CODES": 27560330,
        "GCode": 275,
        "MCode": 60,
        "Vcode": 330,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "FX\/QX70",
        "Variant": "FX\/QX70 3.7 S",
        "MM_CODES": 27560340,
        "GCode": 275,
        "MCode": 60,
        "Vcode": 340,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "FX\/QX70",
        "Variant": "FX\/QX70 3.7 S PREMIUM",
        "MM_CODES": 27560350,
        "GCode": 275,
        "MCode": 60,
        "Vcode": 350,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "FX\/QX70",
        "Variant": "FX\/QX70 5.0 S PREMIUM",
        "MM_CODES": 27560460,
        "GCode": 275,
        "MCode": 60,
        "Vcode": 460,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "FX\/QX70",
        "Variant": "FX50 SV VETTEL EDITION",
        "MM_CODES": 27560470,
        "GCode": 275,
        "MCode": 60,
        "Vcode": 470,
        "Discontinued_Date": "2016-06-10 00:00:00.000"
    },
    {
        "Make": "INFINITI",
        "Model": "QX80",
        "Variant": "QX80 5.5 V8",
        "MM_CODES": 27580270,
        "GCode": 275,
        "MCode": 80,
        "Vcode": 270,
        "Discontinued_Date": "2018-12-13 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB200I FLEETSIDE P\/U S\/C",
        "MM_CODES": 28015101,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 101,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB200I FLEETSIDE P\/U S\/C",
        "MM_CODES": 28015101,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 101,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 240I P\/U S\/C",
        "MM_CODES": 28015140,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 140,
        "Discontinued_Date": "2015-09-17 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 240I P\/U S\/C",
        "MM_CODES": 28015140,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 140,
        "Discontinued_Date": "2015-09-17 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 240I FLEETSIDE P\/U S\/C",
        "MM_CODES": 28015151,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 151,
        "Discontinued_Date": "2015-09-17 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 240I FLEETSIDE P\/U S\/C",
        "MM_CODES": 28015151,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 151,
        "Discontinued_Date": "2015-09-17 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB240I FLEETSIDE 4X4 P\/U S\/C",
        "MM_CODES": 28015155,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 155,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 240I FLEETSIDE 4X4 P\/U S\/C",
        "MM_CODES": 28015156,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 156,
        "Discontinued_Date": "2015-09-17 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 240I FLEETSIDE 4X4 P\/U S\/C",
        "MM_CODES": 28015156,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 156,
        "Discontinued_Date": "2015-09-17 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB240I LE A\/C P\/U S\/C",
        "MM_CODES": 28015161,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 161,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB240I LE A\/C P\/U S\/C",
        "MM_CODES": 28015161,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 161,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 240I LE P\/U S\/C",
        "MM_CODES": 28015171,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 171,
        "Discontinued_Date": "2016-01-11 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 240I LE P\/U S\/C",
        "MM_CODES": 28015171,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 171,
        "Discontinued_Date": "2016-01-11 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB240 LE P\/U D\/C",
        "MM_CODES": 28015211,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 211,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB240 LE P\/U D\/C",
        "MM_CODES": 28015211,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 211,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 240I LE P\/U D\/C",
        "MM_CODES": 28015212,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 212,
        "Discontinued_Date": "2016-01-11 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 240I LE P\/U D\/C",
        "MM_CODES": 28015212,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 212,
        "Discontinued_Date": "2016-01-11 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB240 LE P\/U D\/C (KB72)",
        "MM_CODES": 28015214,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 214,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB240 LE P\/U D\/C (KB72)",
        "MM_CODES": 28015214,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 214,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB240 LE 4X4 P\/U D\/C",
        "MM_CODES": 28015231,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 231,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB240 LE 4X4 P\/U D\/C",
        "MM_CODES": 28015231,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 231,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 240I LE 4X4 P\/U D\/C",
        "MM_CODES": 28015232,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 232,
        "Discontinued_Date": "2015-09-17 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 240I LE 4X4 P\/U D\/C",
        "MM_CODES": 28015232,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 232,
        "Discontinued_Date": "2015-09-17 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250D LEED S\/C C\/C",
        "MM_CODES": 28015238,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 238,
        "Discontinued_Date": "2019-07-19 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250D LEED FLEETSIDE S\/C C\/C",
        "MM_CODES": 28015242,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 242,
        "Discontinued_Date": "2019-07-19 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250D LEED P\/U S\/C",
        "MM_CODES": 28015245,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 245,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250D LEED P\/U S\/C",
        "MM_CODES": 28015245,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 245,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250D LEED P\/U S\/C",
        "MM_CODES": 28015246,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 246,
        "Discontinued_Date": "2019-07-19 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250D LEED P\/U S\/C",
        "MM_CODES": 28015246,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 246,
        "Discontinued_Date": "2019-07-19 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB250D LWB P\/U S\/C",
        "MM_CODES": 28015251,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 251,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB250D LWB P\/U S\/C",
        "MM_CODES": 28015251,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 251,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250 D-TEQ P\/U S\/C",
        "MM_CODES": 28015252,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 252,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250 D-TEQ P\/U S\/C",
        "MM_CODES": 28015252,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 252,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB250D FLEETSIDE P\/U S\/C",
        "MM_CODES": 28015256,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 256,
        "Discontinued_Date": "2010-09-07 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB250D FLEETSIDE P\/U S\/C",
        "MM_CODES": 28015256,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 256,
        "Discontinued_Date": "2010-09-07 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB250D FLEETSIDE A\/C P\/U S\/C",
        "MM_CODES": 28015258,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 258,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB250D FLEETSIDE A\/C P\/U S\/C",
        "MM_CODES": 28015258,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 258,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250 D-TEQ FLEETSIDE P\/U S\/C",
        "MM_CODES": 28015259,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 259,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250 D-TEQ FLEETSIDE P\/U S\/C",
        "MM_CODES": 28015259,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 259,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250D LEED FLEETSIDE P\/U S\/C",
        "MM_CODES": 28015261,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 261,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250D LEED FLEETSIDE P\/U S\/C",
        "MM_CODES": 28015261,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 261,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250D LEED FLEETSIDE P\/U S\/C",
        "MM_CODES": 28015262,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 262,
        "Discontinued_Date": "2019-07-19 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250D LEED FLEETSIDE P\/U S\/C",
        "MM_CODES": 28015262,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 262,
        "Discontinued_Date": "2019-07-19 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250 D-TEQ FLEETSIDE P\/U S\/C",
        "MM_CODES": 28015263,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 263,
        "Discontinued_Date": "2015-09-18 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250 D-TEQ FLEETSIDE P\/U S\/C",
        "MM_CODES": 28015263,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 263,
        "Discontinued_Date": "2015-09-18 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250 D-TEQ FLEETSIDE SAFETY P\/U S\/C",
        "MM_CODES": 28015265,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 265,
        "Discontinued_Date": "2016-01-11 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250 D-TEQ FLEETSIDE SAFETY P\/U S\/C",
        "MM_CODES": 28015265,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 265,
        "Discontinued_Date": "2016-01-11 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250 D-TEQ HO FLEETSIDE SAFETY P\/U S\/C",
        "MM_CODES": 28015266,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 266,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250 D-TEQ HO FLEETSIDE SAFETY P\/U S\/C",
        "MM_CODES": 28015266,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 266,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250 D-TEQ HO FLEETSIDE SAFETY P\/U S\/C",
        "MM_CODES": 28015267,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 267,
        "Discontinued_Date": "2019-07-19 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250 D-TEQ HO FLEETSIDE SAFETY",
        "MM_CODES": 28015267,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 267,
        "Discontinued_Date": "2019-07-19 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250 D-TEQ LE P\/U S\/C",
        "MM_CODES": 28015271,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 271,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250 D-TEQ LE P\/U S\/C",
        "MM_CODES": 28015271,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 271,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250 D-TEQ LE P\/U S\/C",
        "MM_CODES": 28015272,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 272,
        "Discontinued_Date": "2016-01-11 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250 D-TEQ LE P\/U S\/C",
        "MM_CODES": 28015272,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 272,
        "Discontinued_Date": "2016-01-11 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250 D-TEQ HO LE P\/U S\/C",
        "MM_CODES": 28015273,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 273,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250 D-TEQ HO LE P\/U S\/C",
        "MM_CODES": 28015273,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 273,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250 D-TEQ HO LE P\/U S\/C",
        "MM_CODES": 28015274,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 274,
        "Discontinued_Date": "2018-10-22 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250 D-TEQ HO HI RIDER 4X4 S\/C P\/U",
        "MM_CODES": 28015280,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 280,
        "Discontinued_Date": "2018-10-22 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB250D-TEQ FLEETSIDE 4X4 P\/U S\/C",
        "MM_CODES": 28015300,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 300,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB250D-TEQ FLEETSIDE 4X4 P\/U S\/C",
        "MM_CODES": 28015300,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 300,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250 D-TEQ LE 4X4 P\/U S\/C",
        "MM_CODES": 28015305,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 305,
        "Discontinued_Date": "2016-01-11 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250 D-TEQ LE 4X4 P\/U S\/C",
        "MM_CODES": 28015305,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 305,
        "Discontinued_Date": "2016-01-11 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250 D-TEQ HO LE 4X4 PU\/S\/C",
        "MM_CODES": 28015306,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 306,
        "Discontinued_Date": "2017-01-06 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 250 D-TEQ HO LE 4X4 PU\/S\/C",
        "MM_CODES": 28015306,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 306,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 250 D-TEQ HO LE 4X4 PU\/S\/C",
        "MM_CODES": 28015307,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 307,
        "Discontinued_Date": "2018-03-28 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ LX 4X4 P\/U S\/C",
        "MM_CODES": 28015370,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 370,
        "Discontinued_Date": "2018-10-22 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB300D-TEQ LE P\/U S\/C",
        "MM_CODES": 28015422,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 422,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB300D-TEQ LE P\/U S\/C",
        "MM_CODES": 28015422,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 422,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ LX 4X4 P\/U S\/C",
        "MM_CODES": 28015441,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 441,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 300 D-TEQ LX 4X4 P\/U S\/C",
        "MM_CODES": 28015441,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 441,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB300D-TEQ LX E\/CAB P\/U S\/C",
        "MM_CODES": 28015445,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 445,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB300D-TEQ LX E\/CAB P\/U S\/C",
        "MM_CODES": 28015445,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 445,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ LX P\/U E\/CAB",
        "MM_CODES": 28015446,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 446,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 300 D-TEQ LX P\/U E\/CAB",
        "MM_CODES": 28015446,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 446,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ LX A\/T P\/U E\/CAB",
        "MM_CODES": 28015448,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 448,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 300 D-TEQ LX A\/T P\/U E\/CAB",
        "MM_CODES": 28015448,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 448,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB300 D-TEQ LX 4X4 P\/U E\/CAB",
        "MM_CODES": 28015451,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 451,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB300 D-TEQ LX 4X4 P\/U E\/CAB",
        "MM_CODES": 28015451,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 451,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ LX STANDARD P\/U D\/C",
        "MM_CODES": 28015457,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 457,
        "Discontinued_Date": "2015-12-01 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 300 D-TEQ LX STANDARD P\/U D\/C",
        "MM_CODES": 28015457,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 457,
        "Discontinued_Date": "2015-12-01 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ LX STANDARD A\/T P\/U D\/C",
        "MM_CODES": 28015467,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 467,
        "Discontinued_Date": "2015-12-01 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 300 D-TEQ LX STANDARD A\/T P\/U D\/C",
        "MM_CODES": 28015467,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 467,
        "Discontinued_Date": "2015-12-01 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB300D-TEQ A\/T LX P\/U D\/C",
        "MM_CODES": 28015472,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 472,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB300D-TEQ A\/T LX P\/U D\/C",
        "MM_CODES": 28015472,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 472,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ LX A\/T P\/U D\/C",
        "MM_CODES": 28015473,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 473,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 300 D-TEQ LX A\/T P\/U D\/C",
        "MM_CODES": 28015473,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 473,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ LX A\/T P\/U D\/C",
        "MM_CODES": 28015474,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 474,
        "Discontinued_Date": "2019-07-19 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 300 D-TEQ LX A\/T P\/U D\/C",
        "MM_CODES": 28015474,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 474,
        "Discontinued_Date": "2019-07-19 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ LX STANDARD 4X4 P\/U D\/C",
        "MM_CODES": 28015487,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 487,
        "Discontinued_Date": "2015-12-01 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 300 D-TEQ LX STANDARD 4X4 P\/U D\/C",
        "MM_CODES": 28015487,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 487,
        "Discontinued_Date": "2015-12-01 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB300D-TEQ LX 4X4 P\/U D\/C",
        "MM_CODES": 28015492,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 492,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB300D-TEQ LX 4X4 P\/U D\/C",
        "MM_CODES": 28015492,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 492,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ LX 4X4 P\/U D\/C",
        "MM_CODES": 28015493,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 493,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 300 D-TEQ LX 4X4 P\/U D\/C",
        "MM_CODES": 28015493,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 493,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ LX 4X4 P\/U D\/C",
        "MM_CODES": 28015494,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 494,
        "Discontinued_Date": "2019-07-19 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 300 D-TEQ LX 4X4 P\/U D\/C",
        "MM_CODES": 28015494,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 494,
        "Discontinued_Date": "2019-07-19 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB300D-TEQ LX A\/T 4X4 P\/U D\/C",
        "MM_CODES": 28015495,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 495,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB300D-TEQ LX A\/T 4X4 P\/U D\/C",
        "MM_CODES": 28015495,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 495,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ LX A\/T 4X4 P\/U D\/C",
        "MM_CODES": 28015496,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 496,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 300 D-TEQ LX A\/T 4X4 P\/U D\/C",
        "MM_CODES": 28015496,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 496,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ LX A\/T 4X4 P\/U D\/C",
        "MM_CODES": 28015497,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 497,
        "Discontinued_Date": "2019-07-19 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 300 D-TEQ LX A\/T 4X4 P\/U D\/C",
        "MM_CODES": 28015497,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 497,
        "Discontinued_Date": "2019-07-19 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB360 V6 LX P\/U D\/C",
        "MM_CODES": 28015565,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 565,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB360 V6 LX P\/U D\/C",
        "MM_CODES": 28015565,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 565,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB360 V6 LX 4X4 P\/U D\/C",
        "MM_CODES": 28015570,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 570,
        "Discontinued_Date": "2010-09-07 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB360 V6 LX 4X4 P\/U D\/C",
        "MM_CODES": 28015570,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 570,
        "Discontinued_Date": "2010-09-07 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB360 V6 LX 4X4 A\/T P\/U D\/C",
        "MM_CODES": 28015575,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 575,
        "Discontinued_Date": "2013-05-02 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ SERENGETI P\/U D\/C",
        "MM_CODES": 28015800,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 800,
        "Discontinued_Date": "2016-08-24 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ SERENGETI A\/T P\/U D\/C",
        "MM_CODES": 28015820,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 820,
        "Discontinued_Date": "2016-11-18 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - ON",
        "Variant": "KB 300 D-TEQ SERENGETI A\/T P\/U D\/C",
        "MM_CODES": 28015820,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 820,
        "Discontinued_Date": "2016-11-18 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "KB 2004 - 2019",
        "Variant": "KB 300 D-TEQ SERENGETI 4X4 P\/U D\/C",
        "MM_CODES": 28015840,
        "GCode": 280,
        "MCode": 15,
        "Vcode": 840,
        "Discontinued_Date": "2016-08-24 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250C S\/C C\/C",
        "MM_CODES": 28016150,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 150,
        "Discontinued_Date": "2021-12-05 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250C FLEETSIDE S\/C C\/C",
        "MM_CODES": 28016155,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 155,
        "Discontinued_Date": "2021-12-05 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250C FLEETSIDE S\/C D\/S",
        "MM_CODES": 28016165,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 165,
        "Discontinued_Date": "2019-11-29 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO FLEETSIDE SAFETY S\/C C\/C",
        "MM_CODES": 28016205,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 205,
        "Discontinued_Date": "2021-12-05 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO FLEETSIDE S\/C P\/U",
        "MM_CODES": 28016225,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 225,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO HI-RIDE 4X4 S\/C P\/U",
        "MM_CODES": 28016245,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 245,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO HI-RIDER E\/CAB P\/U",
        "MM_CODES": 28016260,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 260,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO HI-RIDE A\/T E\/CAB P\/U",
        "MM_CODES": 28016265,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 265,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO X-RIDER E-CAB",
        "MM_CODES": 28016270,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 270,
        "Discontinued_Date": "2020-02-10 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO X-RIDER A\/T E\/CAB P\/U",
        "MM_CODES": 28016275,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 275,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO D\/C P\/U",
        "MM_CODES": 28016280,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 280,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO HI-RIDE 4X4 D\/C P\/U",
        "MM_CODES": 28016295,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 295,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO X-RIDER D\/C P\/U",
        "MM_CODES": 28016305,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 305,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO X-RIDER BLACK D\/C P\/U",
        "MM_CODES": 28016306,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 306,
        "Discontinued_Date": "2020-02-10 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO X-RIDER LTD D\/C P\/U",
        "MM_CODES": 28016307,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 307,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO X-RIDER A\/T D\/C P\/U",
        "MM_CODES": 28016310,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 310,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO X-RIDER 4X4 D\/C P\/U",
        "MM_CODES": 28016315,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 315,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 250 HO LE A\/T D\/C P\/U",
        "MM_CODES": 28016330,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 330,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 300 LX E\/CAB\/P\/U",
        "MM_CODES": 28016530,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 530,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 300 LX A\/T E\/CAB P\/U",
        "MM_CODES": 28016540,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 540,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 300 LX 4X4 E\/CAB P\/U",
        "MM_CODES": 28016545,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 545,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 300 HI-RIDE A\/T D\/C P\/U",
        "MM_CODES": 28016555,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 555,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 300 X-RIDER A\/T D\/C P\/U",
        "MM_CODES": 28016566,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 566,
        "Discontinued_Date": "2022-05-03 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 300 LX 4X4 D\/C P\/U",
        "MM_CODES": 28016575,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 575,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX 300 LX 4X4 A\/T D\/C P\/U",
        "MM_CODES": 28016580,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 580,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "D-MAX",
        "Variant": "D-MAX ARCTIC 4X4 A\/T D\/C P\/U",
        "MM_CODES": 28016600,
        "GCode": 280,
        "MCode": 16,
        "Vcode": 600,
        "Discontinued_Date": "2022-04-08 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "N \/ NPR \/ NPS  \/ NKR \/ NQR",
        "Variant": "NPR 300 F\/C C\/C",
        "MM_CODES": 28025702,
        "GCode": 280,
        "MCode": 25,
        "Vcode": 702,
        "Discontinued_Date": "2022-06-03 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "N \/ NPR \/ NPS  \/ NKR \/ NQR",
        "Variant": "NPR 300 AMT F\/C C\/C",
        "MM_CODES": 28025710,
        "GCode": 280,
        "MCode": 25,
        "Vcode": 710,
        "Discontinued_Date": "2022-06-03 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "N \/ NPR \/ NPS  \/ NKR \/ NQR",
        "Variant": "NPS 300 4X4 F\/C C\/C",
        "MM_CODES": 28025720,
        "GCode": 280,
        "MCode": 25,
        "Vcode": 720,
        "Discontinued_Date": "2022-06-03 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "N \/ NPR \/ NPS  \/ NKR \/ NQR",
        "Variant": "NPS 300 4X4 SWA F\/C C\/C",
        "MM_CODES": 28025723,
        "GCode": 280,
        "MCode": 25,
        "Vcode": 723,
        "Discontinued_Date": "2022-06-03 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "N \/ NPR \/ NPS  \/ NKR \/ NQR",
        "Variant": "NPS 300 CREW 4X4 F\/C C\/C",
        "MM_CODES": 28025725,
        "GCode": 280,
        "MCode": 25,
        "Vcode": 725,
        "Discontinued_Date": "2022-06-03 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "N \/ NPR \/ NPS  \/ NKR \/ NQR",
        "Variant": "NPR 400 SWB F\/C C\/C",
        "MM_CODES": 28025810,
        "GCode": 280,
        "MCode": 25,
        "Vcode": 810,
        "Discontinued_Date": "2022-06-03 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "N \/ NPR \/ NPS  \/ NKR \/ NQR",
        "Variant": "NPR 400 F\/C C\/C",
        "MM_CODES": 28025822,
        "GCode": 280,
        "MCode": 25,
        "Vcode": 822,
        "Discontinued_Date": "2022-06-03 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "N \/ NPR \/ NPS  \/ NKR \/ NQR",
        "Variant": "NPR 400 AMT F\/C C\/C",
        "MM_CODES": 28025830,
        "GCode": 280,
        "MCode": 25,
        "Vcode": 830,
        "Discontinued_Date": "2022-06-03 00:00:00.000"
    },
    {
        "Make": "ISUZU",
        "Model": "N \/ NPR \/ NPS  \/ NKR \/ NQR",
        "Variant": "NPR 400 AMT CREW CAB F\/C C\/C",
        "MM_CODES": 28025835,
        "GCode": 280,
        "MCode": 25,
        "Vcode": 835,
        "Discontinued_Date": "2022-06-03 00:00:00.000"
    },
    {
        "Make": "IVECO",
        "Model": "DAILY 2007 - ON",
        "Variant": "50C15 F\/C C\/C",
        "MM_CODES": 28515340,
        "GCode": 285,
        "MCode": 15,
        "Vcode": 340,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "IVECO",
        "Model": "DAILY 2007 - ON",
        "Variant": "50C15V16 BASIC F\/C P\/V",
        "MM_CODES": 28515421,
        "GCode": 285,
        "MCode": 15,
        "Vcode": 421,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "IVECO",
        "Model": "DAILY 2007 - ON",
        "Variant": "50C15V15 MIDIBUS",
        "MM_CODES": 28515425,
        "GCode": 285,
        "MCode": 15,
        "Vcode": 425,
        "Discontinued_Date": "2018-02-09 00:00:00.000"
    },
    {
        "Make": "IVECO",
        "Model": "DAILY 2007 - ON",
        "Variant": "50C17LAV15 F\/C P\/V",
        "MM_CODES": 28515435,
        "GCode": 285,
        "MCode": 15,
        "Vcode": 435,
        "Discontinued_Date": "2016-04-11 00:00:00.000"
    },
    {
        "Make": "IVECO",
        "Model": "DAILY 2007 - ON",
        "Variant": "55S15WX A8 E3 4X4 A\/T C\/C",
        "MM_CODES": 28515448,
        "GCode": 285,
        "MCode": 15,
        "Vcode": 448,
        "Discontinued_Date": "2022-10-29 00:00:00.000"
    },
    {
        "Make": "IVECO",
        "Model": "DAILY 2007 - ON",
        "Variant": "70C15D CREW CAB F\/C C\/C",
        "MM_CODES": 28515561,
        "GCode": 285,
        "MCode": 15,
        "Vcode": 561,
        "Discontinued_Date": "2021-01-03 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XE",
        "Variant": "XE 2.0 PRESTIGE A\/T",
        "MM_CODES": 29026150,
        "GCode": 290,
        "MCode": 26,
        "Vcode": 150,
        "Discontinued_Date": "2017-05-11 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XE",
        "Variant": "XE 2.0 R-SPORT A\/T",
        "MM_CODES": 29026170,
        "GCode": 290,
        "MCode": 26,
        "Vcode": 170,
        "Discontinued_Date": "2017-05-11 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XE",
        "Variant": "XE 2.0 PORTFOLIO A\/T",
        "MM_CODES": 29026180,
        "GCode": 290,
        "MCode": 26,
        "Vcode": 180,
        "Discontinued_Date": "2017-05-11 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XE",
        "Variant": "XE 2.0D PURE",
        "MM_CODES": 29026220,
        "GCode": 290,
        "MCode": 26,
        "Vcode": 220,
        "Discontinued_Date": "2020-01-09 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XE",
        "Variant": "XE 2.0D PRESTIGE A\/T",
        "MM_CODES": 29026230,
        "GCode": 290,
        "MCode": 26,
        "Vcode": 230,
        "Discontinued_Date": "2020-01-09 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XE",
        "Variant": "XE 2.0D R-SPORT A\/T",
        "MM_CODES": 29026250,
        "GCode": 290,
        "MCode": 26,
        "Vcode": 250,
        "Discontinued_Date": "2020-01-09 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XE",
        "Variant": "XE 2.0D PORTFOLIO A\/T",
        "MM_CODES": 29026260,
        "GCode": 290,
        "MCode": 26,
        "Vcode": 260,
        "Discontinued_Date": "2020-01-09 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XE",
        "Variant": "XE 3.0 S\/C S A\/T",
        "MM_CODES": 29026300,
        "GCode": 290,
        "MCode": 26,
        "Vcode": 300,
        "Discontinued_Date": "2017-05-11 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XE",
        "Variant": "XE 3.0 S\/C S A\/T",
        "MM_CODES": 29026301,
        "GCode": 290,
        "MCode": 26,
        "Vcode": 301,
        "Discontinued_Date": "2020-01-09 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XF",
        "Variant": "XF 3.0 V6 LUXURY",
        "MM_CODES": 29030201,
        "GCode": 290,
        "MCode": 30,
        "Vcode": 201,
        "Discontinued_Date": "2016-01-27 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XF",
        "Variant": "XF 3.0 V6 PREMIUM LUXURY",
        "MM_CODES": 29030211,
        "GCode": 290,
        "MCode": 30,
        "Vcode": 211,
        "Discontinued_Date": "2014-01-02 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XF",
        "Variant": "XF 3.0 V6 S\/C R-SPORT",
        "MM_CODES": 29030215,
        "GCode": 290,
        "MCode": 30,
        "Vcode": 215,
        "Discontinued_Date": "2016-01-27 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XF",
        "Variant": "XF 3.0 R SPORT",
        "MM_CODES": 29030216,
        "GCode": 290,
        "MCode": 30,
        "Vcode": 216,
        "Discontinued_Date": "2021-03-19 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XF",
        "Variant": "XF 3.0 S\/C PREMIUM LUXURY",
        "MM_CODES": 29030220,
        "GCode": 290,
        "MCode": 30,
        "Vcode": 220,
        "Discontinued_Date": "2016-01-27 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XF",
        "Variant": "XF 3.0D LUXURY",
        "MM_CODES": 29030240,
        "GCode": 290,
        "MCode": 30,
        "Vcode": 240,
        "Discontinued_Date": "2013-04-03 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XF",
        "Variant": "XF 3.0D S PREMIUM LUXURY",
        "MM_CODES": 29030250,
        "GCode": 290,
        "MCode": 30,
        "Vcode": 250,
        "Discontinued_Date": "2016-01-27 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XF",
        "Variant": "XF 3.0D S PORTFOLIO",
        "MM_CODES": 29030260,
        "GCode": 290,
        "MCode": 30,
        "Vcode": 260,
        "Discontinued_Date": "2013-04-03 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XF",
        "Variant": "XF 5.0 V8",
        "MM_CODES": 29030330,
        "GCode": 290,
        "MCode": 30,
        "Vcode": 330,
        "Discontinued_Date": "2012-07-03 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XF",
        "Variant": "XF 5.0 V8 PORTFOLIO",
        "MM_CODES": 29030335,
        "GCode": 290,
        "MCode": 30,
        "Vcode": 335,
        "Discontinued_Date": "2013-04-03 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XF",
        "Variant": "XFR 5.0 V8 S\/C",
        "MM_CODES": 29030340,
        "GCode": 290,
        "MCode": 30,
        "Vcode": 340,
        "Discontinued_Date": "2016-12-15 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XF",
        "Variant": "XFR- S 5.0 V8 S\/C",
        "MM_CODES": 29030350,
        "GCode": 290,
        "MCode": 30,
        "Vcode": 350,
        "Discontinued_Date": "2016-12-15 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE P250 2.0 S (183KW)",
        "MM_CODES": 29035210,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 210,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE 2.0 SE (183KW)",
        "MM_CODES": 29035220,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 220,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE P250 2.0 HSE (183KW)",
        "MM_CODES": 29035230,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 230,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE P250 2.0 FIRST EDITION (183KW)",
        "MM_CODES": 29035240,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 240,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE 2.0 HSE (221KW)",
        "MM_CODES": 29035320,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 320,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "2.0D (110KW)",
        "MM_CODES": 29035350,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 350,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE D150 2.0D S (110KW)",
        "MM_CODES": 29035360,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 360,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE D150 2.0D SE (110KW)",
        "MM_CODES": 29035370,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 370,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE D150 2.0D HSE (110KW)",
        "MM_CODES": 29035380,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 380,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE D180 2.0D S (132KW)",
        "MM_CODES": 29035410,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 410,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE 2.0D SE (132KW)",
        "MM_CODES": 29035420,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 420,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE 2.0D HSE (132KW)",
        "MM_CODES": 29035430,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 430,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE D180 2.0D FIRST EDITION (132KW)",
        "MM_CODES": 29035440,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 440,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE 2.0D S (177KW)",
        "MM_CODES": 29035500,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 500,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE D240 2.0D SE (177KW)",
        "MM_CODES": 29035510,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 510,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "E-PACE",
        "Variant": "E-PACE D240 2.0D HSE (177KW)",
        "MM_CODES": 29035520,
        "GCode": 290,
        "MCode": 35,
        "Vcode": 520,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-PACE",
        "Variant": "F-PACE 2.0 PURE AWD (221KW)",
        "MM_CODES": 29045110,
        "GCode": 290,
        "MCode": 45,
        "Vcode": 110,
        "Discontinued_Date": "2021-02-25 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-PACE",
        "Variant": "F-PACE 2.0I4 AWD R-SPORT",
        "MM_CODES": 29045120,
        "GCode": 290,
        "MCode": 45,
        "Vcode": 120,
        "Discontinued_Date": "2021-02-25 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-PACE",
        "Variant": "F-PACE 2.0DI4 AWD PURE",
        "MM_CODES": 29045150,
        "GCode": 290,
        "MCode": 45,
        "Vcode": 150,
        "Discontinued_Date": "2021-02-25 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-PACE",
        "Variant": "F-PACE 2.0DI4 AWD PURE (177KW)",
        "MM_CODES": 29045156,
        "GCode": 290,
        "MCode": 45,
        "Vcode": 156,
        "Discontinued_Date": "2021-02-25 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-PACE",
        "Variant": "F-PACE 2.0DI4 AWD R-SPORT",
        "MM_CODES": 29045176,
        "GCode": 290,
        "MCode": 45,
        "Vcode": 176,
        "Discontinued_Date": "2021-02-25 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-PACE",
        "Variant": "F-PACE 3.0 V6 S\/C AWD R-SPORT (177KW)",
        "MM_CODES": 29045370,
        "GCode": 290,
        "MCode": 45,
        "Vcode": 370,
        "Discontinued_Date": "2021-02-25 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XJ \/ XJ8 \/ XJR",
        "Variant": "XJ 5.0 V8 PORTFOLIO",
        "MM_CODES": 29070705,
        "GCode": 290,
        "MCode": 70,
        "Vcode": 705,
        "Discontinued_Date": "2013-04-03 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XJ \/ XJ8 \/ XJR",
        "Variant": "XJ 5.0 V8 S\/C PORTFOLIO",
        "MM_CODES": 29070710,
        "GCode": 290,
        "MCode": 70,
        "Vcode": 710,
        "Discontinued_Date": "2013-04-03 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XJ \/ XJ8 \/ XJR",
        "Variant": "XJ 5.0 V8 S\/C SUPERSPORT",
        "MM_CODES": 29070740,
        "GCode": 290,
        "MCode": 70,
        "Vcode": 740,
        "Discontinued_Date": "2014-09-30 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XJ \/ XJ8 \/ XJR",
        "Variant": "XJ 5.0 V8 S\/C SUPERSPORT LWB",
        "MM_CODES": 29070750,
        "GCode": 290,
        "MCode": 70,
        "Vcode": 750,
        "Discontinued_Date": "2016-01-27 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XJ \/ XJ8 \/ XJR",
        "Variant": "XJ 5.0 S\/C ULTIMATE LWB",
        "MM_CODES": 29070760,
        "GCode": 290,
        "MCode": 70,
        "Vcode": 760,
        "Discontinued_Date": "2014-09-30 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XJ \/ XJ8 \/ XJR",
        "Variant": "XJR 5.0 V8 S\/C",
        "MM_CODES": 29070770,
        "GCode": 290,
        "MCode": 70,
        "Vcode": 770,
        "Discontinued_Date": "2016-01-27 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "I-PACE ",
        "Variant": "I-PACE S 90 KWH (294KW)",
        "MM_CODES": 29075250,
        "GCode": 290,
        "MCode": 75,
        "Vcode": 250,
        "Discontinued_Date": "2023-11-21 07:56:55.340"
    },
    {
        "Make": "JAGUAR",
        "Model": "I-PACE ",
        "Variant": "I-PACE HSE 90KWH (294KW)",
        "MM_CODES": 29075270,
        "GCode": 290,
        "MCode": 75,
        "Vcode": 270,
        "Discontinued_Date": "2021-10-19 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-TYPE",
        "Variant": "F-TYPE 3.0 V6 COUPE",
        "MM_CODES": 29080210,
        "GCode": 290,
        "MCode": 80,
        "Vcode": 210,
        "Discontinued_Date": "2017-04-05 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-TYPE",
        "Variant": "F-TYPE 3.0 V6 COUPE A\/T ",
        "MM_CODES": 29080211,
        "GCode": 290,
        "MCode": 80,
        "Vcode": 211,
        "Discontinued_Date": "2021-10-19 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-TYPE",
        "Variant": "F-TYPE 3.0 V6 CONVERT",
        "MM_CODES": 29080220,
        "GCode": 290,
        "MCode": 80,
        "Vcode": 220,
        "Discontinued_Date": "2017-04-05 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-TYPE",
        "Variant": "F-TYPE S 3.0 V6 COUPE",
        "MM_CODES": 29080230,
        "GCode": 290,
        "MCode": 80,
        "Vcode": 230,
        "Discontinued_Date": "2017-04-05 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-TYPE",
        "Variant": "F-TYPE S 3.0 V6 COUPE A\/T",
        "MM_CODES": 29080231,
        "GCode": 290,
        "MCode": 80,
        "Vcode": 231,
        "Discontinued_Date": "2021-10-19 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-TYPE",
        "Variant": "F-TYPE S 3.0 V6 CONVERT",
        "MM_CODES": 29080240,
        "GCode": 290,
        "MCode": 80,
        "Vcode": 240,
        "Discontinued_Date": "2017-04-05 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-TYPE",
        "Variant": "F-TYPE S 3.0 V6 CONVERT R-DYNAMIC",
        "MM_CODES": 29080295,
        "GCode": 290,
        "MCode": 80,
        "Vcode": 295,
        "Discontinued_Date": "2021-10-19 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-TYPE",
        "Variant": "F-TYPE S 5.0 V8 CONVERT",
        "MM_CODES": 29080440,
        "GCode": 290,
        "MCode": 80,
        "Vcode": 440,
        "Discontinued_Date": "2015-04-29 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-TYPE",
        "Variant": "F-TYPE R 5.0 V8 CONVERT",
        "MM_CODES": 29080445,
        "GCode": 290,
        "MCode": 80,
        "Vcode": 445,
        "Discontinued_Date": "2017-04-05 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-TYPE",
        "Variant": "F-TYPE R 5.0 V8 S\/C COUPE",
        "MM_CODES": 29080450,
        "GCode": 290,
        "MCode": 80,
        "Vcode": 450,
        "Discontinued_Date": "2017-04-05 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-TYPE",
        "Variant": "F-TYPE R 5.0 V8 COUPE AWD",
        "MM_CODES": 29080460,
        "GCode": 290,
        "MCode": 80,
        "Vcode": 460,
        "Discontinued_Date": "2017-04-05 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "F-TYPE",
        "Variant": "F-TYPE 5.0 V8 S\/C SVR COUPE AWD",
        "MM_CODES": 29080471,
        "GCode": 290,
        "MCode": 80,
        "Vcode": 471,
        "Discontinued_Date": "2023-03-03 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XK \/ XK8 \/ XKR",
        "Variant": "XK 5.0 CONVERTIBLE LUXURY",
        "MM_CODES": 29090531,
        "GCode": 290,
        "MCode": 90,
        "Vcode": 531,
        "Discontinued_Date": "2018-11-01 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XK \/ XK8 \/ XKR",
        "Variant": "XKR 5.0 COUPE",
        "MM_CODES": 29090601,
        "GCode": 290,
        "MCode": 90,
        "Vcode": 601,
        "Discontinued_Date": "2018-11-01 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XK \/ XK8 \/ XKR",
        "Variant": "XKR 5.0 COUPE SPECIAL ED",
        "MM_CODES": 29090620,
        "GCode": 290,
        "MCode": 90,
        "Vcode": 620,
        "Discontinued_Date": "2018-11-01 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XK \/ XK8 \/ XKR",
        "Variant": "XKR 5.0 CONVERTIBLE SPECIAL ED",
        "MM_CODES": 29090650,
        "GCode": 290,
        "MCode": 90,
        "Vcode": 650,
        "Discontinued_Date": "2014-01-02 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XK \/ XK8 \/ XKR",
        "Variant": "XKR-S 5.0 V8 S\/C COUPE",
        "MM_CODES": 29090670,
        "GCode": 290,
        "MCode": 90,
        "Vcode": 670,
        "Discontinued_Date": "2018-11-01 00:00:00.000"
    },
    {
        "Make": "JAGUAR",
        "Model": "XK \/ XK8 \/ XKR",
        "Variant": "XKR-S 5.0 V8 S\/C CONVERTIBLE",
        "MM_CODES": 29090680,
        "GCode": 290,
        "MCode": 90,
        "Vcode": 680,
        "Discontinued_Date": "2018-11-01 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "RENEGADE",
        "Variant": "RENEGADE 1.4 TJET LTD",
        "MM_CODES": 30010200,
        "GCode": 300,
        "MCode": 10,
        "Vcode": 200,
        "Discontinued_Date": "2019-07-23 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "RENEGADE",
        "Variant": "RENEGADE 1.4 TJET LTD DDCT",
        "MM_CODES": 30010205,
        "GCode": 300,
        "MCode": 10,
        "Vcode": 205,
        "Discontinued_Date": "2019-07-23 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "RENEGADE",
        "Variant": "RENEGADE 1.4 TJET LTD AWD A\/T",
        "MM_CODES": 30010210,
        "GCode": 300,
        "MCode": 10,
        "Vcode": 210,
        "Discontinued_Date": "2019-07-23 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "RENEGADE",
        "Variant": "RENEGADE 1.6 E-TORQ LONGITUDE",
        "MM_CODES": 30010250,
        "GCode": 300,
        "MCode": 10,
        "Vcode": 250,
        "Discontinued_Date": "2019-07-23 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "RENEGADE",
        "Variant": "RENEGADE 1.6 MJET LTD",
        "MM_CODES": 30010300,
        "GCode": 300,
        "MCode": 10,
        "Vcode": 300,
        "Discontinued_Date": "2019-07-23 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "RENEGADE",
        "Variant": "RENEGADE 2.4 TRAILHAWK A\/T",
        "MM_CODES": 30010400,
        "GCode": 300,
        "MCode": 10,
        "Vcode": 400,
        "Discontinued_Date": "2019-07-23 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "RENEGADE",
        "Variant": "RENEGADE 2.4 TRAILHAWK AWD A\/T",
        "MM_CODES": 30010401,
        "GCode": 300,
        "MCode": 10,
        "Vcode": 401,
        "Discontinued_Date": "2021-10-02 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "PATRIOT",
        "Variant": "PATRIOT 2.4 LIMITED",
        "MM_CODES": 30015220,
        "GCode": 300,
        "MCode": 15,
        "Vcode": 220,
        "Discontinued_Date": "2019-07-16 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "PATRIOT",
        "Variant": "PATRIOT 2.4 LIMITED  CVT A\/T",
        "MM_CODES": 30015240,
        "GCode": 300,
        "MCode": 15,
        "Vcode": 240,
        "Discontinued_Date": "2014-02-03 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "PATRIOT",
        "Variant": "PATRIOT 2.4 LIMITED A\/T",
        "MM_CODES": 30015250,
        "GCode": 300,
        "MCode": 15,
        "Vcode": 250,
        "Discontinued_Date": "2019-07-09 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "WRANGLER",
        "Variant": "WRANGLER 2.8 CRD UNLTD SAHARA A\/T",
        "MM_CODES": 30020470,
        "GCode": 300,
        "MCode": 20,
        "Vcode": 470,
        "Discontinued_Date": "2021-03-24 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "WRANGLER",
        "Variant": "WRANGLER 3.8 SPORT M6 2DR",
        "MM_CODES": 30020540,
        "GCode": 300,
        "MCode": 20,
        "Vcode": 540,
        "Discontinued_Date": "2012-01-04 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "WRANGLER",
        "Variant": "WRANGLER 3.8 UNLTD SAHARA A\/T",
        "MM_CODES": 30020600,
        "GCode": 300,
        "MCode": 20,
        "Vcode": 600,
        "Discontinued_Date": "2012-01-04 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "WRANGLER",
        "Variant": "WRANGLER 3.8 RUBICON 2DR",
        "MM_CODES": 30020640,
        "GCode": 300,
        "MCode": 20,
        "Vcode": 640,
        "Discontinued_Date": "2012-01-04 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "WRANGLER",
        "Variant": "WRANGLER RUBICON 3.6L V6 2DR",
        "MM_CODES": 30020641,
        "GCode": 300,
        "MCode": 20,
        "Vcode": 641,
        "Discontinued_Date": "2016-01-07 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "WRANGLER",
        "Variant": "WRANGLER RUBICON 3.6 V6 A\/T 2DR",
        "MM_CODES": 30020650,
        "GCode": 300,
        "MCode": 20,
        "Vcode": 650,
        "Discontinued_Date": "2021-03-23 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "WRANGLER",
        "Variant": "WRANGLER 3.8 UNLTD RUBICON A\/T",
        "MM_CODES": 30020660,
        "GCode": 300,
        "MCode": 20,
        "Vcode": 660,
        "Discontinued_Date": "2012-01-04 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "WRANGLER",
        "Variant": "WRANGLER UNLTD RUBICON 3.6L V6 A\/T",
        "MM_CODES": 30020661,
        "GCode": 300,
        "MCode": 20,
        "Vcode": 661,
        "Discontinued_Date": "2021-03-24 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "WRANGLER",
        "Variant": "WRANGLER UNLTD SAHARA 3.6L V6 A\/T",
        "MM_CODES": 30020720,
        "GCode": 300,
        "MCode": 20,
        "Vcode": 720,
        "Discontinued_Date": "2021-03-23 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "COMPASS",
        "Variant": "COMPASS 2.0 LTD A\/T",
        "MM_CODES": 30021100,
        "GCode": 300,
        "MCode": 21,
        "Vcode": 100,
        "Discontinued_Date": "2016-01-07 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "COMPASS",
        "Variant": "COMPASS 2.4 TRAILHAWK A\/T",
        "MM_CODES": 30021230,
        "GCode": 300,
        "MCode": 21,
        "Vcode": 230,
        "Discontinued_Date": "2021-03-24 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "CHEROKEE",
        "Variant": "CHEROKEE 2.4 LONGITUDE A\/T",
        "MM_CODES": 30022270,
        "GCode": 300,
        "MCode": 22,
        "Vcode": 270,
        "Discontinued_Date": "2021-12-14 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "CHEROKEE",
        "Variant": "CHEROKEE 2.8 CRD SPORT",
        "MM_CODES": 30022301,
        "GCode": 300,
        "MCode": 22,
        "Vcode": 301,
        "Discontinued_Date": "2012-11-01 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "CHEROKEE",
        "Variant": "CHEROKEE 2.8 CRD SPORT A\/T",
        "MM_CODES": 30022351,
        "GCode": 300,
        "MCode": 22,
        "Vcode": 351,
        "Discontinued_Date": "2012-11-01 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "CHEROKEE",
        "Variant": "CHEROKEE 2.8 CRD LIMITED",
        "MM_CODES": 30022371,
        "GCode": 300,
        "MCode": 22,
        "Vcode": 371,
        "Discontinued_Date": "2012-11-01 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "CHEROKEE",
        "Variant": "CHEROKEE 2.8 CRD LIMITED A\/T",
        "MM_CODES": 30022401,
        "GCode": 300,
        "MCode": 22,
        "Vcode": 401,
        "Discontinued_Date": "2014-01-06 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "CHEROKEE",
        "Variant": "CHEROKEE 3.7 SPORT A\/T",
        "MM_CODES": 30022441,
        "GCode": 300,
        "MCode": 22,
        "Vcode": 441,
        "Discontinued_Date": "2014-05-06 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "CHEROKEE",
        "Variant": "CHEROKEE 3.2 LIMITED A\/T",
        "MM_CODES": 30022482,
        "GCode": 300,
        "MCode": 22,
        "Vcode": 482,
        "Discontinued_Date": "2021-12-14 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "CHEROKEE",
        "Variant": "CHEROKEE 3.2 LIMITED AWD A\/T",
        "MM_CODES": 30022490,
        "GCode": 300,
        "MCode": 22,
        "Vcode": 490,
        "Discontinued_Date": "2021-12-14 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE 3.0 CRD LAREDO",
        "MM_CODES": 30031100,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 100,
        "Discontinued_Date": "2010-12-13 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE 3.0 CRD LAREDO",
        "MM_CODES": 30031101,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 101,
        "Discontinued_Date": "2019-07-01 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE 3.0 V6 LIMITED",
        "MM_CODES": 30031153,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 153,
        "Discontinued_Date": "2021-03-24 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE 3.6 OVERLAND",
        "MM_CODES": 30031186,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 186,
        "Discontinued_Date": "2018-10-04 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE 3.6L OVERLAND",
        "MM_CODES": 30031187,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 187,
        "Discontinued_Date": "2022-10-10 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE 3.6 SUMMIT",
        "MM_CODES": 30031188,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 188,
        "Discontinued_Date": "2018-10-04 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE 3.7 LAREDO",
        "MM_CODES": 30031190,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 190,
        "Discontinued_Date": "2010-12-13 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE 3.6L LAREDO",
        "MM_CODES": 30031191,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 191,
        "Discontinued_Date": "2018-10-04 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE 5.7 HEMIV8 O\/LAND",
        "MM_CODES": 30031321,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 321,
        "Discontinued_Date": "2011-10-17 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE 5.7 V8 O\/LAND",
        "MM_CODES": 30031322,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 322,
        "Discontinued_Date": "2013-08-02 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE 5.7 V8 O\/LAND",
        "MM_CODES": 30031323,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 323,
        "Discontinued_Date": "2021-03-24 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE SRT8",
        "MM_CODES": 30031350,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 350,
        "Discontinued_Date": "2010-12-13 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE 6.4 SRT",
        "MM_CODES": 30031351,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 351,
        "Discontinued_Date": "2013-08-02 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE 6.4 SRT",
        "MM_CODES": 30031352,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 352,
        "Discontinued_Date": "2018-10-04 00:00:00.000"
    },
    {
        "Make": "JEEP",
        "Model": "GRAND CHEROKEE 2005 - ON",
        "Variant": "GRAND CHEROKEE 6.4 SRT",
        "MM_CODES": 30031353,
        "GCode": 300,
        "MCode": 31,
        "Vcode": 353,
        "Discontinued_Date": "2021-12-20 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "RIO",
        "Variant": "RIO 1.4 TEC A\/T 5DR",
        "MM_CODES": 32121282,
        "GCode": 321,
        "MCode": 21,
        "Vcode": 282,
        "Discontinued_Date": "2023-11-02 09:21:29.513"
    },
    {
        "Make": "KIA",
        "Model": "RIO",
        "Variant": "RIO1.4 (4DR)",
        "MM_CODES": 32121300,
        "GCode": 321,
        "MCode": 21,
        "Vcode": 300,
        "Discontinued_Date": "2018-10-04 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "RIO",
        "Variant": "RIO1.4 (4DR) A\/T",
        "MM_CODES": 32121310,
        "GCode": 321,
        "MCode": 21,
        "Vcode": 310,
        "Discontinued_Date": "2018-10-04 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "RIO",
        "Variant": "RIO1.4 TEC (4DR)",
        "MM_CODES": 32121330,
        "GCode": 321,
        "MCode": 21,
        "Vcode": 330,
        "Discontinued_Date": "2018-10-04 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "RIO",
        "Variant": "RIO 1.4 TEC (4DR) A\/T",
        "MM_CODES": 32121340,
        "GCode": 321,
        "MCode": 21,
        "Vcode": 340,
        "Discontinued_Date": "2018-10-04 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "RIO",
        "Variant": "RIO 1.4 4DR",
        "MM_CODES": 32121520,
        "GCode": 321,
        "MCode": 21,
        "Vcode": 520,
        "Discontinued_Date": "2008-04-17 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SOUL",
        "Variant": "SOUL 1.6",
        "MM_CODES": 32122100,
        "GCode": 321,
        "MCode": 22,
        "Vcode": 100,
        "Discontinued_Date": "2014-11-24 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SOUL",
        "Variant": "SOUL 1.6 HIGH",
        "MM_CODES": 32122105,
        "GCode": 321,
        "MCode": 22,
        "Vcode": 105,
        "Discontinued_Date": "2012-02-10 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SOUL",
        "Variant": "SOUL 1.6 A\/T",
        "MM_CODES": 32122110,
        "GCode": 321,
        "MCode": 22,
        "Vcode": 110,
        "Discontinued_Date": "2014-11-24 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SOUL",
        "Variant": "SOUL 1.6 START A\/T",
        "MM_CODES": 32122111,
        "GCode": 321,
        "MCode": 22,
        "Vcode": 111,
        "Discontinued_Date": "2018-11-14 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SOUL",
        "Variant": "SOUL 1.6 HIGH A\/T",
        "MM_CODES": 32122120,
        "GCode": 321,
        "MCode": 22,
        "Vcode": 120,
        "Discontinued_Date": "2012-02-10 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SOUL",
        "Variant": "SOUL 1.6 CRDI",
        "MM_CODES": 32122130,
        "GCode": 321,
        "MCode": 22,
        "Vcode": 130,
        "Discontinued_Date": "2012-06-29 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SOUL",
        "Variant": "SOUL 1.6 CRDI STREET",
        "MM_CODES": 32122135,
        "GCode": 321,
        "MCode": 22,
        "Vcode": 135,
        "Discontinued_Date": "2018-11-13 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SOUL",
        "Variant": "SOUL 1.6 CRDI HIGH",
        "MM_CODES": 32122140,
        "GCode": 321,
        "MCode": 22,
        "Vcode": 140,
        "Discontinued_Date": "2012-06-29 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SOUL",
        "Variant": "SOUL 1.6 CRDI SMART A\/T",
        "MM_CODES": 32122150,
        "GCode": 321,
        "MCode": 22,
        "Vcode": 150,
        "Discontinued_Date": "2015-12-11 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SOUL",
        "Variant": "SOUL 1.6 CRDI SMART DCT",
        "MM_CODES": 32122151,
        "GCode": 321,
        "MCode": 22,
        "Vcode": 151,
        "Discontinued_Date": "2018-11-12 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SOUL",
        "Variant": "SOUL 2.0",
        "MM_CODES": 32122250,
        "GCode": 321,
        "MCode": 22,
        "Vcode": 250,
        "Discontinued_Date": "2014-11-24 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SOUL",
        "Variant": "SOUL 2.0 A\/T",
        "MM_CODES": 32122260,
        "GCode": 321,
        "MCode": 22,
        "Vcode": 260,
        "Discontinued_Date": "2015-08-06 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SOUL",
        "Variant": "SOUL 2.0 SMART A\/T",
        "MM_CODES": 32122270,
        "GCode": 321,
        "MCode": 22,
        "Vcode": 270,
        "Discontinued_Date": "2018-11-14 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 1.6 5DR",
        "MM_CODES": 32128501,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 501,
        "Discontinued_Date": "2013-09-13 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 1.6 EX 5DR",
        "MM_CODES": 32128502,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 502,
        "Discontinued_Date": "2016-09-27 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 1.6 EX 5DR",
        "MM_CODES": 32128503,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 503,
        "Discontinued_Date": "2018-10-12 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 1.6 EX A\/T 5DR",
        "MM_CODES": 32128512,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 512,
        "Discontinued_Date": "2016-09-27 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 1.6 EX A\/T 5DR",
        "MM_CODES": 32128513,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 513,
        "Discontinued_Date": "2018-10-12 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 1.6",
        "MM_CODES": 32128561,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 561,
        "Discontinued_Date": "2013-05-24 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 1.6 EX",
        "MM_CODES": 32128562,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 562,
        "Discontinued_Date": "2016-09-27 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 1.6 EX",
        "MM_CODES": 32128563,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 563,
        "Discontinued_Date": "2018-10-12 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 1.6 EX A\/T",
        "MM_CODES": 32128567,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 567,
        "Discontinued_Date": "2018-10-12 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 1.6 SX A\/T",
        "MM_CODES": 32128569,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 569,
        "Discontinued_Date": "2018-10-12 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 1.6 A\/T",
        "MM_CODES": 32128571,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 571,
        "Discontinued_Date": "2013-05-24 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 2.0 EX 5DR",
        "MM_CODES": 32128573,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 573,
        "Discontinued_Date": "2016-09-27 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 2.0 5DR",
        "MM_CODES": 32128575,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 575,
        "Discontinued_Date": "2013-09-13 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 2.0 SX 5DR",
        "MM_CODES": 32128576,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 576,
        "Discontinued_Date": "2016-09-27 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 2.0 5DR A\/T",
        "MM_CODES": 32128578,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 578,
        "Discontinued_Date": "2013-09-13 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 2.0 SX A\/T",
        "MM_CODES": 32128588,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 588,
        "Discontinued_Date": "2016-09-27 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 2.0 EX 5DR",
        "MM_CODES": 32128605,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 605,
        "Discontinued_Date": "2018-10-12 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 2.0 EX",
        "MM_CODES": 32128615,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 615,
        "Discontinued_Date": "2019-09-03 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 2.0 EX A\/T",
        "MM_CODES": 32128620,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 620,
        "Discontinued_Date": "2019-09-03 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO KOUP 1.6T GDI",
        "MM_CODES": 32128640,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 640,
        "Discontinued_Date": "2018-12-04 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO KOUP 1.6T GDI  A\/T",
        "MM_CODES": 32128645,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 645,
        "Discontinued_Date": "2018-12-10 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 2.0 KOUP",
        "MM_CODES": 32128650,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 650,
        "Discontinued_Date": "2013-05-24 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPECTRA \/ CERATO",
        "Variant": "CERATO 2.0 KOUP A\/T",
        "MM_CODES": 32128660,
        "GCode": 321,
        "MCode": 28,
        "Vcode": 660,
        "Discontinued_Date": "2013-05-24 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SONET",
        "Variant": "SONET 1.5 LX",
        "MM_CODES": 32140200,
        "GCode": 321,
        "MCode": 40,
        "Vcode": 200,
        "Discontinued_Date": "1899-12-30 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "OPTIMA",
        "Variant": "OPTIMA 2.4 A\/T",
        "MM_CODES": 32155250,
        "GCode": 321,
        "MCode": 55,
        "Vcode": 250,
        "Discontinued_Date": "2013-04-10 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "OPTIMA",
        "Variant": "OPTIMA 2.4 GDI A\/T",
        "MM_CODES": 32155260,
        "GCode": 321,
        "MCode": 55,
        "Vcode": 260,
        "Discontinued_Date": "2016-01-06 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0 EX A\/T",
        "MM_CODES": 32160295,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 295,
        "Discontinued_Date": "2018-11-05 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0 EX+  A\/T",
        "MM_CODES": 32160298,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 298,
        "Discontinued_Date": "2022-06-29 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0 AWD",
        "MM_CODES": 32160303,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 303,
        "Discontinued_Date": "2017-05-26 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0 A\/T 4X4",
        "MM_CODES": 32160321,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 321,
        "Discontinued_Date": "2010-08-30 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0 AWD A\/T",
        "MM_CODES": 32160322,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 322,
        "Discontinued_Date": "2014-02-12 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0 AWD A\/T",
        "MM_CODES": 32160323,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 323,
        "Discontinued_Date": "2017-05-26 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0 CRDI IGNITE + A\/T",
        "MM_CODES": 32160325,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 325,
        "Discontinued_Date": "2022-06-29 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0 CRDI",
        "MM_CODES": 32160330,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 330,
        "Discontinued_Date": "2014-02-12 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0 CRDI",
        "MM_CODES": 32160331,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 331,
        "Discontinued_Date": "2017-05-26 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0 CRDI A\/T",
        "MM_CODES": 32160335,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 335,
        "Discontinued_Date": "2014-02-12 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0 CRDI A\/T",
        "MM_CODES": 32160336,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 336,
        "Discontinued_Date": "2017-05-26 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0D EX A\/T",
        "MM_CODES": 32160337,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 337,
        "Discontinued_Date": "2018-11-05 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0 CRDI SR TEC A\/T",
        "MM_CODES": 32160338,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 338,
        "Discontinued_Date": "2017-05-26 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0 CRDI EX A\/T",
        "MM_CODES": 32160339,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 339,
        "Discontinued_Date": "2022-12-14 00:00:00.000"
    },
    {
        "Make": "KIA",
        "Model": "SPORTAGE",
        "Variant": "SPORTAGE 2.0 CRDI AWD",
        "MM_CODES": 32160342,
        "GCode": 321,
        "MCode": 60,
        "Vcode": 342,
        "Discontinued_Date": "2014-02-12 00:00:00.000"
    },
    {
        "Make": "LAMBORGHINI",
        "Model": "HURACAN",
        "Variant": "HURACAN LP610-4",
        "MM_CODES": 33332120,
        "GCode": 333,
        "MCode": 32,
        "Vcode": 120,
        "Discontinued_Date": "2020-11-05 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "FREELANDER 1 \/ 2",
        "Variant": "FREELANDER II 2.0 SI4 DYNAMIC A\/T",
        "MM_CODES": 35020540,
        "GCode": 350,
        "MCode": 20,
        "Vcode": 540,
        "Discontinued_Date": "2016-12-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "FREELANDER 1 \/ 2",
        "Variant": "FREELANDER II 2.0 SI4 HSE A\/T",
        "MM_CODES": 35020550,
        "GCode": 350,
        "MCode": 20,
        "Vcode": 550,
        "Discontinued_Date": "2016-12-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "FREELANDER 1 \/ 2",
        "Variant": "FREELANDER II 2.2 TD4 S",
        "MM_CODES": 35020602,
        "GCode": 350,
        "MCode": 20,
        "Vcode": 602,
        "Discontinued_Date": "2012-10-29 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "FREELANDER 1 \/ 2",
        "Variant": "FREELANDER II 2.2 TD4 S A\/T",
        "MM_CODES": 35020610,
        "GCode": 350,
        "MCode": 20,
        "Vcode": 610,
        "Discontinued_Date": "2010-11-15 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "FREELANDER 1 \/ 2",
        "Variant": "FREELANDER II 2.2 SD4 S A\/T",
        "MM_CODES": 35020612,
        "GCode": 350,
        "MCode": 20,
        "Vcode": 612,
        "Discontinued_Date": "2015-12-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "FREELANDER 1 \/ 2",
        "Variant": "FREELANDER II 2.2 TD4 SE A\/T",
        "MM_CODES": 35020630,
        "GCode": 350,
        "MCode": 20,
        "Vcode": 630,
        "Discontinued_Date": "2010-11-15 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "FREELANDER 1 \/ 2",
        "Variant": "FREELANDER II 2.2 SD4 SE A\/T",
        "MM_CODES": 35020631,
        "GCode": 350,
        "MCode": 20,
        "Vcode": 631,
        "Discontinued_Date": "2012-10-29 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "FREELANDER 1 \/ 2",
        "Variant": "FREELANDER II 2.2 SD4 HSE LTD A\/T",
        "MM_CODES": 35020660,
        "GCode": 350,
        "MCode": 20,
        "Vcode": 660,
        "Discontinued_Date": "2012-10-29 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "FREELANDER 1 \/ 2",
        "Variant": "FREELANDER II 3.2 I6 HSE A\/T",
        "MM_CODES": 35020780,
        "GCode": 350,
        "MCode": 20,
        "Vcode": 780,
        "Discontinued_Date": "2012-10-29 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA 90",
        "Variant": "DEFENDER 90  2.2D S\/W",
        "MM_CODES": 35030180,
        "GCode": 350,
        "MCode": 30,
        "Vcode": 180,
        "Discontinued_Date": "2018-11-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA 90",
        "Variant": "DEFENDER 90 2.2D S\/W LTD",
        "MM_CODES": 35030190,
        "GCode": 350,
        "MCode": 30,
        "Vcode": 190,
        "Discontinued_Date": "2013-04-03 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA 90",
        "Variant": "DEFENDER 90 2.2D S\/W AFRICA ED",
        "MM_CODES": 35030191,
        "GCode": 350,
        "MCode": 30,
        "Vcode": 191,
        "Discontinued_Date": "2018-11-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA 90",
        "Variant": "DEFENDER PUMA 90 SW",
        "MM_CODES": 35030200,
        "GCode": 350,
        "MCode": 30,
        "Vcode": 200,
        "Discontinued_Date": "2011-12-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA 90",
        "Variant": "DEFENDER 90 2.2D S\/W HERITAGE",
        "MM_CODES": 35030216,
        "GCode": 350,
        "MCode": 30,
        "Vcode": 216,
        "Discontinued_Date": "2018-11-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA 90",
        "Variant": "DEFENDER 90 2.2D S\/W ADVENTURE",
        "MM_CODES": 35030220,
        "GCode": 350,
        "MCode": 30,
        "Vcode": 220,
        "Discontinued_Date": "2018-11-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.0 SI4 S",
        "MM_CODES": 35035250,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 250,
        "Discontinued_Date": "2020-11-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.0 SI4 SE",
        "MM_CODES": 35035260,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 260,
        "Discontinued_Date": "2020-11-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.0 SI4 HSE",
        "MM_CODES": 35035270,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 270,
        "Discontinued_Date": "2020-11-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.0 SI4 HSE LUX",
        "MM_CODES": 35035280,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 280,
        "Discontinued_Date": "2020-11-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.2 TD4 S",
        "MM_CODES": 35035300,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 300,
        "Discontinued_Date": "2017-12-19 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.0I4 D PURE (110KW)",
        "MM_CODES": 35035301,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 301,
        "Discontinued_Date": "2019-08-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.2 SD4 S",
        "MM_CODES": 35035350,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 350,
        "Discontinued_Date": "2017-12-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.0I4 D PURE",
        "MM_CODES": 35035351,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 351,
        "Discontinued_Date": "2019-11-11 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.2 SD4 SE",
        "MM_CODES": 35035360,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 360,
        "Discontinued_Date": "2017-12-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.0I4 D SE",
        "MM_CODES": 35035361,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 361,
        "Discontinued_Date": "2019-11-11 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.0D SE (177KW)",
        "MM_CODES": 35035365,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 365,
        "Discontinued_Date": "2020-12-02 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.2 SD4 HSE",
        "MM_CODES": 35035370,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 370,
        "Discontinued_Date": "2017-12-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.0I4 D HSE",
        "MM_CODES": 35035371,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 371,
        "Discontinued_Date": "2019-11-11 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.0D HSE (177KW)",
        "MM_CODES": 35035375,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 375,
        "Discontinued_Date": "2020-12-02 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.2 SD4 HSE LUX",
        "MM_CODES": 35035380,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 380,
        "Discontinued_Date": "2017-12-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.0I4 D HSE LUX",
        "MM_CODES": 35035381,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 381,
        "Discontinued_Date": "2019-11-11 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY SPORT",
        "Variant": "DISCOVERY SPORT 2.0D HSE LUXURY (177KW)",
        "MM_CODES": 35035385,
        "GCode": 350,
        "MCode": 35,
        "Vcode": 385,
        "Discontinued_Date": "2020-12-02 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 4 3.0 TD V6  XS (155KW)",
        "MM_CODES": 35047380,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 380,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 4 3.0 TD\/SD V6 S",
        "MM_CODES": 35047400,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 400,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 4 3.0 TD\/SD V6 SE",
        "MM_CODES": 35047420,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 420,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 4 3.0 SD V6 GRAPHITE",
        "MM_CODES": 35047421,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 421,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 4  3.0 V6 S\/C SE",
        "MM_CODES": 35047450,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 450,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 4 3.0 SD V6 GRAPHITE",
        "MM_CODES": 35047451,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 451,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 4  3.0 V6 S\/C HSE",
        "MM_CODES": 35047455,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 455,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 4 5.0 V8 SE",
        "MM_CODES": 35047460,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 460,
        "Discontinued_Date": "2014-07-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 4 5.0 V8 HSE",
        "MM_CODES": 35047470,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 470,
        "Discontinued_Date": "2014-07-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 2.0 HSE",
        "MM_CODES": 35047483,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 483,
        "Discontinued_Date": "2021-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 3.0 TD6 SE",
        "MM_CODES": 35047510,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 510,
        "Discontinued_Date": "2021-02-22 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 3.0 TD6 HSE",
        "MM_CODES": 35047520,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 520,
        "Discontinued_Date": "2021-02-22 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 3.0 TD6 HSE LUXURY",
        "MM_CODES": 35047530,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 530,
        "Discontinued_Date": "2021-02-22 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 3.0 TD6 FIRST EDITION",
        "MM_CODES": 35047540,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 540,
        "Discontinued_Date": "2019-01-07 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DISCOVERY 3 \/ 4",
        "Variant": "DISCOVERY 3.0 SI6 HSE",
        "MM_CODES": 35047590,
        "GCode": 350,
        "MCode": 47,
        "Vcode": 590,
        "Discontinued_Date": "2021-02-22 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA \/ PUP",
        "Variant": "DEFENDER 110 2.2D H\/T P\/U S\/C",
        "MM_CODES": 35055106,
        "GCode": 350,
        "MCode": 55,
        "Vcode": 106,
        "Discontinued_Date": "2017-11-08 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA \/ PUP",
        "Variant": "DEFENDER 110 P\/U D\/C",
        "MM_CODES": 35055340,
        "GCode": 350,
        "MCode": 55,
        "Vcode": 340,
        "Discontinued_Date": "2011-12-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA \/ PUP",
        "Variant": "DEFENDER 110 2.2D P\/U D\/C",
        "MM_CODES": 35055341,
        "GCode": 350,
        "MCode": 55,
        "Vcode": 341,
        "Discontinued_Date": "2017-11-08 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA \/ PUP",
        "Variant": "DEFENDER PUMA 110 P\/U S\/C",
        "MM_CODES": 35055400,
        "GCode": 350,
        "MCode": 55,
        "Vcode": 400,
        "Discontinued_Date": "2012-02-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA \/ PUP",
        "Variant": "DEFENDER PUMA 110 H\/T P\/U S\/C",
        "MM_CODES": 35055410,
        "GCode": 350,
        "MCode": 55,
        "Vcode": 410,
        "Discontinued_Date": "2012-02-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA \/ PUP",
        "Variant": "DEFENDER PUMA 130 CRWCAB HCPU P\/U D\/C",
        "MM_CODES": 35055460,
        "GCode": 350,
        "MCode": 55,
        "Vcode": 460,
        "Discontinued_Date": "2011-12-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA \/ PUP",
        "Variant": "DEFENDER 130 2.2D CREWCAB HCPU P\/U D\/C",
        "MM_CODES": 35055461,
        "GCode": 350,
        "MCode": 55,
        "Vcode": 461,
        "Discontinued_Date": "2017-11-08 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA 110",
        "Variant": "DEFENDER PUMA 110 SW",
        "MM_CODES": 35065600,
        "GCode": 350,
        "MCode": 65,
        "Vcode": 600,
        "Discontinued_Date": "2011-12-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA 110",
        "Variant": "DEFENDER 110  2.2D S\/W",
        "MM_CODES": 35065601,
        "GCode": 350,
        "MCode": 65,
        "Vcode": 601,
        "Discontinued_Date": "2017-11-08 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA 110",
        "Variant": "DEFENDER 110 2.2D S\/W LTD",
        "MM_CODES": 35065605,
        "GCode": 350,
        "MCode": 65,
        "Vcode": 605,
        "Discontinued_Date": "2014-01-02 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA 110",
        "Variant": "DEFENDER 110 2.2D S\/W AFRICA ED",
        "MM_CODES": 35065606,
        "GCode": 350,
        "MCode": 65,
        "Vcode": 606,
        "Discontinued_Date": "2018-11-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA 110",
        "Variant": "DEFENDER 110 2.2D S\/W HERITAGE",
        "MM_CODES": 35065616,
        "GCode": 350,
        "MCode": 65,
        "Vcode": 616,
        "Discontinued_Date": "2018-11-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "DEFENDER \/ PUMA 110",
        "Variant": "DEFENDER 110 2.2D S\/W ADVENTURE",
        "MM_CODES": 35065620,
        "GCode": 350,
        "MCode": 65,
        "Vcode": 620,
        "Discontinued_Date": "2018-11-01 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.0 SI4 PRESTIGE COUPE",
        "MM_CODES": 35071100,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 100,
        "Discontinued_Date": "2015-08-18 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.0 SI4 DYNAMIC COUPE",
        "MM_CODES": 35071120,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 120,
        "Discontinued_Date": "2015-08-18 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.0 SI4 SE",
        "MM_CODES": 35071146,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 146,
        "Discontinued_Date": "2019-03-29 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.0 SI4 PRESTIGE",
        "MM_CODES": 35071160,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 160,
        "Discontinued_Date": "2015-08-18 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.0 SI4 DYNAMIC",
        "MM_CODES": 35071180,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 180,
        "Discontinued_Date": "2015-08-18 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.0 SI4 HSE DYNAMIC",
        "MM_CODES": 35071181,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 181,
        "Discontinued_Date": "2019-03-29 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.0 HSE DYNAMIC ",
        "MM_CODES": 35071182,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 182,
        "Discontinued_Date": "2019-03-29 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.0 SI4 AUTOBIOGRAPHY",
        "MM_CODES": 35071190,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 190,
        "Discontinued_Date": "2015-08-18 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.0  DYNAMIC AUTOBIOGRAPHY",
        "MM_CODES": 35071192,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 192,
        "Discontinued_Date": "2019-03-29 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.2 SD4 PRESTIGE COUPE",
        "MM_CODES": 35071220,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 220,
        "Discontinued_Date": "2015-08-18 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.2 SD4 DYNAMIC COUPE",
        "MM_CODES": 35071240,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 240,
        "Discontinued_Date": "2015-08-18 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.2 SD4 HSE DYNAMIC COUPE",
        "MM_CODES": 35071241,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 241,
        "Discontinued_Date": "2019-03-29 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.0 TD4 HSE DYNAMIC COUPE",
        "MM_CODES": 35071242,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 242,
        "Discontinued_Date": "2019-03-29 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.2 SD4 PURE",
        "MM_CODES": 35071265,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 265,
        "Discontinued_Date": "2015-08-18 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.2 SD4 SE",
        "MM_CODES": 35071266,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 266,
        "Discontinued_Date": "2019-03-29 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.2 SD4 DYNAMIC",
        "MM_CODES": 35071310,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 310,
        "Discontinued_Date": "2015-08-18 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.2 SD4 HSE DYNAMIC",
        "MM_CODES": 35071311,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 311,
        "Discontinued_Date": "2019-03-29 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "EVOQUE",
        "Variant": "EVOQUE 2.2 SD4 AUTOBIOGRAPHY",
        "MM_CODES": 35071321,
        "GCode": 350,
        "MCode": 71,
        "Vcode": 321,
        "Discontinued_Date": "2019-03-29 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "VELAR",
        "Variant": "RANGE ROVER VELAR 2.0D S",
        "MM_CODES": 35073360,
        "GCode": 350,
        "MCode": 73,
        "Vcode": 360,
        "Discontinued_Date": "2022-04-05 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "VELAR",
        "Variant": "RANGE ROVER VELAR 2.0D SE",
        "MM_CODES": 35073370,
        "GCode": 350,
        "MCode": 73,
        "Vcode": 370,
        "Discontinued_Date": "2022-04-05 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "VELAR",
        "Variant": "RANGE ROVER VELAR 2.0D HSE (D180)",
        "MM_CODES": 35073380,
        "GCode": 350,
        "MCode": 73,
        "Vcode": 380,
        "Discontinued_Date": "2022-04-05 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "VELAR",
        "Variant": "RANGE ROVER VELAR 2.0D S (D240)",
        "MM_CODES": 35073460,
        "GCode": 350,
        "MCode": 73,
        "Vcode": 460,
        "Discontinued_Date": "2021-10-15 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "VELAR",
        "Variant": "RANGE ROVER VELAR 2.0D SE (D240)",
        "MM_CODES": 35073470,
        "GCode": 350,
        "MCode": 73,
        "Vcode": 470,
        "Discontinued_Date": "2021-10-15 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "VELAR",
        "Variant": "RANGE ROVER VELAR 3.0D FIRST ED",
        "MM_CODES": 35073780,
        "GCode": 350,
        "MCode": 73,
        "Vcode": 780,
        "Discontinued_Date": "2019-01-08 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 3.0 TD V6 VOGUE",
        "MM_CODES": 35081103,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 103,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 3.0 TD V6 VOGUE",
        "MM_CODES": 35081103,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 103,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER TDV8 VOGUE SE",
        "MM_CODES": 35081121,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 121,
        "Discontinued_Date": "2010-11-15 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER TDV8 VOGUE SE",
        "MM_CODES": 35081121,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 121,
        "Discontinued_Date": "2010-11-15 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 4.4 SD V8 VOGUE SE",
        "MM_CODES": 35081123,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 123,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 4.4 SD V8 VOGUE SE",
        "MM_CODES": 35081123,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 123,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 4.4 TDV8 AUTOBIO",
        "MM_CODES": 35081128,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 128,
        "Discontinued_Date": "2013-01-21 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 4.4 TDV8 AUTOBIO",
        "MM_CODES": 35081128,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 128,
        "Discontinued_Date": "2013-01-21 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 4.4 SD V8 AUTOBIOGRAPHY",
        "MM_CODES": 35081129,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 129,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 4.4 SD V8 AUTOBIOGRAPHY",
        "MM_CODES": 35081129,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 129,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 4.4 SD V8  LWB AUTOBIOGRAHPY ",
        "MM_CODES": 35081133,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 133,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 4.4 SD V8  LWB AUTOBIOGRAHPY",
        "MM_CODES": 35081133,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 133,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 4.4 SD V8  LWB AUTOBIOGRAHPY BLACK",
        "MM_CODES": 35081135,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 135,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 4.4 SD V8  LWB AUTOBIOGRAHPY BLACK",
        "MM_CODES": 35081135,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 135,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 4.4 SDV8  LWB SV AUTOBIOGRAPHY",
        "MM_CODES": 35081140,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 140,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER SE 5.0 V8 S\/C",
        "MM_CODES": 35081205,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 205,
        "Discontinued_Date": "2013-01-21 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER SE 5.0 V8 S\/C",
        "MM_CODES": 35081205,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 205,
        "Discontinued_Date": "2013-01-21 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 5.0 V8 S\/C VOGUE SE",
        "MM_CODES": 35081206,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 206,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 5.0 V8 S\/C VOGUE SE",
        "MM_CODES": 35081206,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 206,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 5.0 V8 S\/C VOGUE SE LWB",
        "MM_CODES": 35081208,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 208,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 5.0 S\/C AUTOBIO",
        "MM_CODES": 35081210,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 210,
        "Discontinued_Date": "2013-01-21 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 5.0 S\/C AUTOBIO",
        "MM_CODES": 35081210,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 210,
        "Discontinued_Date": "2013-01-21 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 5.0 S\/C AUTOBIO BLK",
        "MM_CODES": 35081215,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 215,
        "Discontinued_Date": "2012-04-03 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 5.0 S\/C AUTOBIO BLK",
        "MM_CODES": 35081215,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 215,
        "Discontinued_Date": "2012-04-03 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 5.0 V8 S\/C AUTOBIOGRAPHY",
        "MM_CODES": 35081216,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 216,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 5.0 V8 S\/C AUTOBIOGRAPHY",
        "MM_CODES": 35081216,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 216,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 5.0 V8 S\/C LWB AUTOBIOGRAPHY",
        "MM_CODES": 35081218,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 218,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 5.0 V8 S\/C LWB AUTOBIOGRAPHY",
        "MM_CODES": 35081218,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 218,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 5.0 V8 S\/C LWB AUTOBIOGRAPHY BLACK",
        "MM_CODES": 35081220,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 220,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 5.0 V8 S\/C LWB AUTOBIOGRAPHY BLACK",
        "MM_CODES": 35081220,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 220,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 5.0 V8 S\/C LWB SV AUTOBIOGRAPHY",
        "MM_CODES": 35081230,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 230,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 5.0 V8 S\/C LWB SV AUTOBIOGRAPHY",
        "MM_CODES": 35081230,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 230,
        "Discontinued_Date": "2018-02-23 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER SPORT 2.0 PHEV HSE",
        "MM_CODES": 35081305,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 305,
        "Discontinued_Date": "2022-10-03 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER SPORT 2.0 PHEV HSE DYNAMIC",
        "MM_CODES": 35081310,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 310,
        "Discontinued_Date": "2022-10-03 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER SPORT 3.0D SE (190KW)",
        "MM_CODES": 35081355,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 355,
        "Discontinued_Date": "2022-10-03 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER SPORT 3.0D HSE (190KW)",
        "MM_CODES": 35081360,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 360,
        "Discontinued_Date": "2022-10-03 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER SPORT 3.0D HSE (225KW)",
        "MM_CODES": 35081380,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 380,
        "Discontinued_Date": "2021-10-15 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER SPORT 4.4D HSE DYNAMIC (250KW)",
        "MM_CODES": 35081450,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 450,
        "Discontinued_Date": "2022-10-03 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER SPORT 5.0 V8 HSE DYNAMIC",
        "MM_CODES": 35081480,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 480,
        "Discontinued_Date": "2022-10-03 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER SPORT 5.0 V8 AUTOBIO DYNAMIC",
        "MM_CODES": 35081485,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 485,
        "Discontinued_Date": "2022-10-03 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER SPORT 5.0 V8 SVR (423KW)",
        "MM_CODES": 35081500,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 500,
        "Discontinued_Date": "2022-10-03 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 2.0 PHEV VOGUE SE (297KW)",
        "MM_CODES": 35081655,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 655,
        "Discontinued_Date": "2022-04-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 2.0 PHEV AUTOBIO (297KW)",
        "MM_CODES": 35081665,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 665,
        "Discontinued_Date": "2022-04-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 3.0D VOGUE (190KW)",
        "MM_CODES": 35081745,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 745,
        "Discontinued_Date": "2022-04-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 3.0D VOGUE (190KW)",
        "MM_CODES": 35081745,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 745,
        "Discontinued_Date": "2022-04-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 4.4D VOGUE SE (250KW)",
        "MM_CODES": 35081785,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 785,
        "Discontinued_Date": "2022-04-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 4.4D ROGUE SE (KW)",
        "MM_CODES": 35081785,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 785,
        "Discontinued_Date": "2022-04-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 4.4D SV AUTOBIO LWB (250KW)",
        "MM_CODES": 35081815,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 815,
        "Discontinued_Date": "2022-04-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - ON",
        "Variant": "RANGE ROVER 4.4D SV AUTOBIO LWB (250KW)",
        "MM_CODES": 35081815,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 815,
        "Discontinued_Date": "2022-04-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 5.0 VOGUE SE (386KW)",
        "MM_CODES": 35081830,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 830,
        "Discontinued_Date": "2022-04-12 00:00:00.000"
    },
    {
        "Make": "LAND ROVER",
        "Model": "RANGE ROVER 2002 - 2022",
        "Variant": "RANGE ROVER 5.0 SV AUTOBIO DYNAMIC (416KW)",
        "MM_CODES": 35081840,
        "GCode": 350,
        "MCode": 81,
        "Vcode": 840,
        "Discontinued_Date": "2022-04-12 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "CT",
        "Variant": "CT 200H S 5DR",
        "MM_CODES": 38117200,
        "GCode": 381,
        "MCode": 17,
        "Vcode": 200,
        "Discontinued_Date": "2014-01-13 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "CT",
        "Variant": "CT200H S 5DR",
        "MM_CODES": 38117201,
        "GCode": 381,
        "MCode": 17,
        "Vcode": 201,
        "Discontinued_Date": "2017-09-08 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "CT",
        "Variant": "CT 200H EX 5DR",
        "MM_CODES": 38117205,
        "GCode": 381,
        "MCode": 17,
        "Vcode": 205,
        "Discontinued_Date": "2013-10-01 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "CT",
        "Variant": "CT 200H F-SPORT 5DR",
        "MM_CODES": 38117220,
        "GCode": 381,
        "MCode": 17,
        "Vcode": 220,
        "Discontinued_Date": "2013-10-01 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "IS\/RC-F 2006 - ON",
        "Variant": "IS 250 E A\/T",
        "MM_CODES": 38121220,
        "GCode": 381,
        "MCode": 21,
        "Vcode": 220,
        "Discontinued_Date": "2013-05-16 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "IS\/RC-F 2006 - ON",
        "Variant": "IS 250 A\/T",
        "MM_CODES": 38121250,
        "GCode": 381,
        "MCode": 21,
        "Vcode": 250,
        "Discontinued_Date": "2013-05-16 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "IS\/RC-F 2006 - ON",
        "Variant": "IS 250 EX A\/T",
        "MM_CODES": 38121260,
        "GCode": 381,
        "MCode": 21,
        "Vcode": 260,
        "Discontinued_Date": "2013-05-16 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "IS\/RC-F 2006 - ON",
        "Variant": "IS 200T E",
        "MM_CODES": 38121355,
        "GCode": 381,
        "MCode": 21,
        "Vcode": 355,
        "Discontinued_Date": "2019-08-01 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "IS\/RC-F 2006 - ON",
        "Variant": "IS 200T EX",
        "MM_CODES": 38121360,
        "GCode": 381,
        "MCode": 21,
        "Vcode": 360,
        "Discontinued_Date": "2021-09-07 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "IS\/RC-F 2006 - ON",
        "Variant": "IS 300 HYBRID",
        "MM_CODES": 38121370,
        "GCode": 381,
        "MCode": 21,
        "Vcode": 370,
        "Discontinued_Date": "2021-12-14 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "IS\/RC-F 2006 - ON",
        "Variant": "IS 350 E",
        "MM_CODES": 38121380,
        "GCode": 381,
        "MCode": 21,
        "Vcode": 380,
        "Discontinued_Date": "2016-01-07 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "IS\/RC-F 2006 - ON",
        "Variant": "IS 350 EX",
        "MM_CODES": 38121390,
        "GCode": 381,
        "MCode": 21,
        "Vcode": 390,
        "Discontinued_Date": "2013-05-16 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "IS\/RC-F 2006 - ON",
        "Variant": "IS 350 EX",
        "MM_CODES": 38121391,
        "GCode": 381,
        "MCode": 21,
        "Vcode": 391,
        "Discontinued_Date": "2016-01-07 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "IS\/RC-F 2006 - ON",
        "Variant": "IS 350 SE",
        "MM_CODES": 38121400,
        "GCode": 381,
        "MCode": 21,
        "Vcode": 400,
        "Discontinued_Date": "2013-05-16 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "IS\/RC-F 2006 - ON",
        "Variant": "IS 350 F SPORT",
        "MM_CODES": 38121420,
        "GCode": 381,
        "MCode": 21,
        "Vcode": 420,
        "Discontinued_Date": "2021-12-14 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "IS\/RC-F 2006 - ON",
        "Variant": "IS-F",
        "MM_CODES": 38121450,
        "GCode": 381,
        "MCode": 21,
        "Vcode": 450,
        "Discontinued_Date": "2013-05-16 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "IS\/RC-F 2006 - ON",
        "Variant": "IS 250 CONVERT",
        "MM_CODES": 38121500,
        "GCode": 381,
        "MCode": 21,
        "Vcode": 500,
        "Discontinued_Date": "2013-05-16 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "IS\/RC-F 2006 - ON",
        "Variant": "IS 250 CONVERT LTD",
        "MM_CODES": 38121510,
        "GCode": 381,
        "MCode": 21,
        "Vcode": 510,
        "Discontinued_Date": "2012-08-21 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "ES",
        "Variant": "ES 250",
        "MM_CODES": 38125201,
        "GCode": 381,
        "MCode": 25,
        "Vcode": 201,
        "Discontinued_Date": "2018-10-02 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "GS 300 2006 - ON",
        "Variant": "GS 300 A\/T",
        "MM_CODES": 38131201,
        "GCode": 381,
        "MCode": 31,
        "Vcode": 201,
        "Discontinued_Date": "2012-05-14 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "GS 300 2006 - ON",
        "Variant": "GS 300 EX A\/T",
        "MM_CODES": 38131210,
        "GCode": 381,
        "MCode": 31,
        "Vcode": 210,
        "Discontinued_Date": "2012-05-14 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "GS 300 2006 - ON",
        "Variant": "GS 350 EX A\/T",
        "MM_CODES": 38131212,
        "GCode": 381,
        "MCode": 31,
        "Vcode": 212,
        "Discontinued_Date": "2016-01-28 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "GS 300 2006 - ON",
        "Variant": "GS 350 F-SPORT",
        "MM_CODES": 38131260,
        "GCode": 381,
        "MCode": 31,
        "Vcode": 260,
        "Discontinued_Date": "2016-01-28 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "GS 300 2006 - ON",
        "Variant": "GS 450H F-SPORT A\/T",
        "MM_CODES": 38131305,
        "GCode": 381,
        "MCode": 31,
        "Vcode": 305,
        "Discontinued_Date": "2013-10-22 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "GS 300 2006 - ON",
        "Variant": "GS 450H SE A\/T",
        "MM_CODES": 38131310,
        "GCode": 381,
        "MCode": 31,
        "Vcode": 310,
        "Discontinued_Date": "2012-05-14 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "GS 300 2006 - ON",
        "Variant": "GS 450H SE A\/T",
        "MM_CODES": 38131311,
        "GCode": 381,
        "MCode": 31,
        "Vcode": 311,
        "Discontinued_Date": "2016-04-01 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "LS \/ SC \/ LX \/ LC",
        "Variant": "LS 600H L",
        "MM_CODES": 38140310,
        "GCode": 381,
        "MCode": 40,
        "Vcode": 310,
        "Discontinued_Date": "2013-02-01 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "LS \/ SC \/ LX",
        "Variant": "LX 570",
        "MM_CODES": 38140320,
        "GCode": 381,
        "MCode": 40,
        "Vcode": 320,
        "Discontinued_Date": "2012-05-10 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "LS \/ SC \/ LX \/ LC",
        "Variant": "LX 570",
        "MM_CODES": 38140320,
        "GCode": 381,
        "MCode": 40,
        "Vcode": 320,
        "Discontinued_Date": "2012-05-10 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "LS \/ SC \/ LX",
        "Variant": "LX 570",
        "MM_CODES": 38140321,
        "GCode": 381,
        "MCode": 40,
        "Vcode": 321,
        "Discontinued_Date": "2015-11-12 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "LS \/ SC \/ LX \/ LC",
        "Variant": "LX 570",
        "MM_CODES": 38140321,
        "GCode": 381,
        "MCode": 40,
        "Vcode": 321,
        "Discontinued_Date": "2015-11-12 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "LS \/ SC \/ LX \/ LC",
        "Variant": "LX 5.7 V8 ",
        "MM_CODES": 38140322,
        "GCode": 381,
        "MCode": 40,
        "Vcode": 322,
        "Discontinued_Date": "2022-03-22 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "LS \/ SC \/ LX \/ LC",
        "Variant": "LX 4.5TD V8",
        "MM_CODES": 38140400,
        "GCode": 381,
        "MCode": 40,
        "Vcode": 400,
        "Discontinued_Date": "2022-03-22 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "NX",
        "Variant": "NX 300 E",
        "MM_CODES": 38144140,
        "GCode": 381,
        "MCode": 44,
        "Vcode": 140,
        "Discontinued_Date": "2019-06-11 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "NX",
        "Variant": "NX 2.0T E",
        "MM_CODES": 38144150,
        "GCode": 381,
        "MCode": 44,
        "Vcode": 150,
        "Discontinued_Date": "2017-12-01 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "NX",
        "Variant": "NX 2.0T EX",
        "MM_CODES": 38144160,
        "GCode": 381,
        "MCode": 44,
        "Vcode": 160,
        "Discontinued_Date": "2019-06-11 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "NX",
        "Variant": "NX 300 EX",
        "MM_CODES": 38144161,
        "GCode": 381,
        "MCode": 44,
        "Vcode": 161,
        "Discontinued_Date": "2022-03-03 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "NX",
        "Variant": "NX 2.0T F-SPORT",
        "MM_CODES": 38144180,
        "GCode": 381,
        "MCode": 44,
        "Vcode": 180,
        "Discontinued_Date": "2019-06-11 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "NX",
        "Variant": "NX 300 F-SPORT",
        "MM_CODES": 38144181,
        "GCode": 381,
        "MCode": 44,
        "Vcode": 181,
        "Discontinued_Date": "2022-03-03 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "NX",
        "Variant": "NX 300H EX",
        "MM_CODES": 38144270,
        "GCode": 381,
        "MCode": 44,
        "Vcode": 270,
        "Discontinued_Date": "2019-06-11 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "NX",
        "Variant": "NX 300H SE",
        "MM_CODES": 38144275,
        "GCode": 381,
        "MCode": 44,
        "Vcode": 275,
        "Discontinued_Date": "2022-03-03 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "RX 300 \/ 350",
        "Variant": "RX 350 XE",
        "MM_CODES": 38150221,
        "GCode": 381,
        "MCode": 50,
        "Vcode": 221,
        "Discontinued_Date": "2010-10-15 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "RX 300 \/ 350",
        "Variant": "RX 350 XE",
        "MM_CODES": 38150222,
        "GCode": 381,
        "MCode": 50,
        "Vcode": 222,
        "Discontinued_Date": "2012-05-10 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "RX 300 \/ 350",
        "Variant": "RX 350 EX",
        "MM_CODES": 38150223,
        "GCode": 381,
        "MCode": 50,
        "Vcode": 223,
        "Discontinued_Date": "2016-01-21 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "RX 300 \/ 350",
        "Variant": "RX 350 EX",
        "MM_CODES": 38150224,
        "GCode": 381,
        "MCode": 50,
        "Vcode": 224,
        "Discontinued_Date": "2019-11-21 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "RX 300 \/ 350",
        "Variant": "RX 350 EX",
        "MM_CODES": 38150225,
        "GCode": 381,
        "MCode": 50,
        "Vcode": 225,
        "Discontinued_Date": "2023-02-15 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "RX 300 \/ 350",
        "Variant": "RX 350L EX (7 SEATER)",
        "MM_CODES": 38150236,
        "GCode": 381,
        "MCode": 50,
        "Vcode": 236,
        "Discontinued_Date": "2023-02-15 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "RX 300 \/ 350",
        "Variant": "RX 350 SE",
        "MM_CODES": 38150240,
        "GCode": 381,
        "MCode": 50,
        "Vcode": 240,
        "Discontinued_Date": "2016-01-07 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "RX 300 \/ 350",
        "Variant": "RX 350 F-SPORT",
        "MM_CODES": 38150250,
        "GCode": 381,
        "MCode": 50,
        "Vcode": 250,
        "Discontinued_Date": "2023-02-15 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "RX 300 \/ 350",
        "Variant": "RX 450H XE",
        "MM_CODES": 38150310,
        "GCode": 381,
        "MCode": 50,
        "Vcode": 310,
        "Discontinued_Date": "2013-10-01 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "RX 300 \/ 350",
        "Variant": "RX 450H F-SPORT",
        "MM_CODES": 38150315,
        "GCode": 381,
        "MCode": 50,
        "Vcode": 315,
        "Discontinued_Date": "2014-09-01 00:00:00.000"
    },
    {
        "Make": "LEXUS",
        "Model": "RX 300 \/ 350",
        "Variant": "RX 450H SE",
        "MM_CODES": 38150331,
        "GCode": 381,
        "MCode": 50,
        "Vcode": 331,
        "Discontinued_Date": "2016-01-21 00:00:00.000"
    },
    {
        "Make": "MASERATI",
        "Model": "GHIBLI",
        "Variant": "GHIBLI",
        "MM_CODES": 41055250,
        "GCode": 410,
        "MCode": 55,
        "Vcode": 250,
        "Discontinued_Date": "2019-04-01 00:00:00.000"
    },
    {
        "Make": "MASERATI",
        "Model": "GHIBLI",
        "Variant": "GHIBLI DIESEL",
        "MM_CODES": 41055300,
        "GCode": 410,
        "MCode": 55,
        "Vcode": 300,
        "Discontinued_Date": "2019-04-01 00:00:00.000"
    },
    {
        "Make": "MASERATI",
        "Model": "QUATTROPORTE",
        "Variant": "QUATTROPORTE DIESEL",
        "MM_CODES": 41065300,
        "GCode": 410,
        "MCode": 65,
        "Vcode": 300,
        "Discontinued_Date": "2019-03-28 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "SCORPIO \/ PIK UP",
        "Variant": "SCORPIO 2.2 CRDE MHAWK P\/U S\/C",
        "MM_CODES": 41514100,
        "GCode": 415,
        "MCode": 14,
        "Vcode": 100,
        "Discontinued_Date": "2017-10-11 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "SCORPIO \/ PIK UP",
        "Variant": "PIK UP 2.2 MHAWK S6 P\/U S\/C",
        "MM_CODES": 41514103,
        "GCode": 415,
        "MCode": 14,
        "Vcode": 103,
        "Discontinued_Date": "2021-12-06 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "SCORPIO \/ PIK UP",
        "Variant": "PIK UP 2.2 MHAWK S4 4X4 P\/U S\/C",
        "MM_CODES": 41514108,
        "GCode": 415,
        "MCode": 14,
        "Vcode": 108,
        "Discontinued_Date": "1899-12-30 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "SCORPIO \/ PIK UP",
        "Variant": "ADVENTURE SCORPIO 2.2 CRDE MHAWK 4X4 P\/U D\/C",
        "MM_CODES": 41514135,
        "GCode": 415,
        "MCode": 14,
        "Vcode": 135,
        "Discontinued_Date": "2017-10-11 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "SCORPIO \/ PIK UP",
        "Variant": "SCORPIO 2.5TCI P\/U D\/C",
        "MM_CODES": 41514301,
        "GCode": 415,
        "MCode": 14,
        "Vcode": 301,
        "Discontinued_Date": "2017-10-11 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "SCORPIO \/ PIK UP",
        "Variant": "SCORPIO 2.5 TCI 4X4 P\/U D\/C",
        "MM_CODES": 41514321,
        "GCode": 415,
        "MCode": 14,
        "Vcode": 321,
        "Discontinued_Date": "2017-10-11 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "KUV 100",
        "Variant": "KUV 100 1.2 K4+ NXT",
        "MM_CODES": 41519151,
        "GCode": 415,
        "MCode": 19,
        "Vcode": 151,
        "Discontinued_Date": "2020-04-13 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "KUV 100",
        "Variant": "KUV 100 1.2 K6+",
        "MM_CODES": 41519160,
        "GCode": 415,
        "MCode": 19,
        "Vcode": 160,
        "Discontinued_Date": "2018-10-02 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "KUV 100",
        "Variant": "KUV 100 1.2 K8",
        "MM_CODES": 41519170,
        "GCode": 415,
        "MCode": 19,
        "Vcode": 170,
        "Discontinued_Date": "2018-10-02 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "KUV 100",
        "Variant": "KUV 100 1.2 K8+",
        "MM_CODES": 41519180,
        "GCode": 415,
        "MCode": 19,
        "Vcode": 180,
        "Discontinued_Date": "2020-04-13 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "KUV 100",
        "Variant": "KUV 100 1.2TD K6+",
        "MM_CODES": 41519220,
        "GCode": 415,
        "MCode": 19,
        "Vcode": 220,
        "Discontinued_Date": "2018-10-02 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "KUV 100",
        "Variant": "KUV 100 1.2TD K8 ",
        "MM_CODES": 41519230,
        "GCode": 415,
        "MCode": 19,
        "Vcode": 230,
        "Discontinued_Date": "2018-10-02 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "GENIO",
        "Variant": "GENIO 2.2 CRDE LWB P\/U D\/S",
        "MM_CODES": 41525148,
        "GCode": 415,
        "MCode": 25,
        "Vcode": 148,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "GENIO",
        "Variant": "GENIO 2.2 CRDE P\/U D\/C",
        "MM_CODES": 41525170,
        "GCode": 415,
        "MCode": 25,
        "Vcode": 170,
        "Discontinued_Date": "2018-03-06 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "GENIO",
        "Variant": "GENIO 2.2 CRDE MHAWK F\/B C\/C",
        "MM_CODES": 41525180,
        "GCode": 415,
        "MCode": 25,
        "Vcode": 180,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "MAHINDRA",
        "Model": "GENIO",
        "Variant": "GENIO MAXI 2.2 CRDE MHAWK VAN BODY P\/U C\/C",
        "MM_CODES": 41525190,
        "GCode": 415,
        "MCode": 25,
        "Vcode": 190,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "2",
        "Variant": "MAZDA2 1.3 DYNAMIC 5DR",
        "MM_CODES": 43015181,
        "GCode": 430,
        "MCode": 15,
        "Vcode": 181,
        "Discontinued_Date": "2015-02-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "2",
        "Variant": "MAZDA2 1.5 ACTIVE 5DR",
        "MM_CODES": 43015240,
        "GCode": 430,
        "MCode": 15,
        "Vcode": 240,
        "Discontinued_Date": "2024-02-29 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "2",
        "Variant": "MAZDA2 1.5 DYNAMIC 5DR",
        "MM_CODES": 43015261,
        "GCode": 430,
        "MCode": 15,
        "Vcode": 261,
        "Discontinued_Date": "2015-02-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "2",
        "Variant": "MAZDA2 1.5 DYNAMIC 5DR",
        "MM_CODES": 43015262,
        "GCode": 430,
        "MCode": 15,
        "Vcode": 262,
        "Discontinued_Date": "2024-02-29 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "2",
        "Variant": "MAZDA2 1.5 DYNAMIC A\/T 5DR",
        "MM_CODES": 43015270,
        "GCode": 430,
        "MCode": 15,
        "Vcode": 270,
        "Discontinued_Date": "2024-02-29 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "2",
        "Variant": "MAZDA2 1.5 INDIVIDUAL 5DR",
        "MM_CODES": 43015281,
        "GCode": 430,
        "MCode": 15,
        "Vcode": 281,
        "Discontinued_Date": "2015-02-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "2",
        "Variant": "MAZDA2 1.5 INDIVIDUAL PLUS \/ HAZUMI A\/T 5DR",
        "MM_CODES": 43015310,
        "GCode": 430,
        "MCode": 15,
        "Vcode": 310,
        "Discontinued_Date": "2024-02-01 14:36:10.453"
    },
    {
        "Make": "MAZDA",
        "Model": "2",
        "Variant": "MAZDA2 1.3 ACTIVE",
        "MM_CODES": 43015350,
        "GCode": 430,
        "MCode": 15,
        "Vcode": 350,
        "Discontinued_Date": "2012-12-19 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "2",
        "Variant": "MAZDA2 1.5 ACTIVE",
        "MM_CODES": 43015370,
        "GCode": 430,
        "MCode": 15,
        "Vcode": 370,
        "Discontinued_Date": "2012-12-19 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "2",
        "Variant": "MAZDA2 1.5 DYNAMIC",
        "MM_CODES": 43015400,
        "GCode": 430,
        "MCode": 15,
        "Vcode": 400,
        "Discontinued_Date": "2012-12-19 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "2",
        "Variant": "MAZDA2 1.5 INDIVIDUAL",
        "MM_CODES": 43015420,
        "GCode": 430,
        "MCode": 15,
        "Vcode": 420,
        "Discontinued_Date": "2012-12-19 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.6 SPORT ORIGINAL",
        "MM_CODES": 43046102,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 102,
        "Discontinued_Date": "2014-09-16 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.6 ORIGINAL 5DR",
        "MM_CODES": 43046103,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 103,
        "Discontinued_Date": "2019-08-01 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.6 SPORT ACTIVE",
        "MM_CODES": 43046122,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 122,
        "Discontinued_Date": "2014-09-16 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.6 ACTIVE 5DR",
        "MM_CODES": 43046123,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 123,
        "Discontinued_Date": "2019-07-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.5 ACTIVE 5DR",
        "MM_CODES": 43046124,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 124,
        "Discontinued_Date": "2023-08-02 06:53:26.657"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.6 SPORT DYNAMIC",
        "MM_CODES": 43046142,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 142,
        "Discontinued_Date": "2014-09-16 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.6 DYNAMIC 5DR",
        "MM_CODES": 43046143,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 143,
        "Discontinued_Date": "2019-07-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.6 DYNAMIC 5DR A\/T",
        "MM_CODES": 43046150,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 150,
        "Discontinued_Date": "2019-07-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.5 INDIVIDUAL 5DR",
        "MM_CODES": 43046165,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 165,
        "Discontinued_Date": "2023-08-02 06:53:32.393"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.5 INDIVIDUAL A\/T 5DR",
        "MM_CODES": 43046175,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 175,
        "Discontinued_Date": "2023-08-02 06:53:39.903"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 2.0 INDIVIDUAL 5DR",
        "MM_CODES": 43046252,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 252,
        "Discontinued_Date": "2019-07-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 2.0 INDIVIDUAL 5DR A\/T",
        "MM_CODES": 43046257,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 257,
        "Discontinued_Date": "2019-07-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 2.3 SPORT INDIVIDUAL",
        "MM_CODES": 43046260,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 260,
        "Discontinued_Date": "2009-07-28 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 2.0 ASTINA A\/T 5DR",
        "MM_CODES": 43046265,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 265,
        "Discontinued_Date": "2019-07-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 2.0 ASTINA PLUS A\/T 5DR",
        "MM_CODES": 43046268,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 268,
        "Discontinued_Date": "2019-07-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 2.3 SPORT MPS",
        "MM_CODES": 43046271,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 271,
        "Discontinued_Date": "2009-11-04 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 2.3 SPORT MPS",
        "MM_CODES": 43046275,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 275,
        "Discontinued_Date": "2014-09-22 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.6 ORIGINAL",
        "MM_CODES": 43046292,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 292,
        "Discontinued_Date": "2014-09-16 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.6 ORIGINAL",
        "MM_CODES": 43046293,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 293,
        "Discontinued_Date": "2019-08-01 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.6 ACTIVE",
        "MM_CODES": 43046312,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 312,
        "Discontinued_Date": "2014-09-16 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.6 ACTIVE",
        "MM_CODES": 43046313,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 313,
        "Discontinued_Date": "2019-07-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.5 ACTIVE",
        "MM_CODES": 43046314,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 314,
        "Discontinued_Date": "2022-03-31 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.6 DYNAMIC",
        "MM_CODES": 43046332,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 332,
        "Discontinued_Date": "2014-09-16 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.6 DYNAMIC",
        "MM_CODES": 43046333,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 333,
        "Discontinued_Date": "2019-07-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 1.5 DYNAMIC",
        "MM_CODES": 43046334,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 334,
        "Discontinued_Date": "2022-03-31 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 2.0 INDIVIDUAL",
        "MM_CODES": 43046453,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 453,
        "Discontinued_Date": "2019-08-01 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 2.0 INDIVIDUAL A\/T",
        "MM_CODES": 43046460,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 460,
        "Discontinued_Date": "2019-08-01 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 2.0 ASTINA A\/T",
        "MM_CODES": 43046480,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 480,
        "Discontinued_Date": "2019-07-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 2.0 ASTINA A\/T (2020)",
        "MM_CODES": 43046481,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 481,
        "Discontinued_Date": "2022-03-31 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "3",
        "Variant": "MAZDA3 2.0 ASTINA PLUS A\/T",
        "MM_CODES": 43046485,
        "GCode": 430,
        "MCode": 46,
        "Vcode": 485,
        "Discontinued_Date": "2019-07-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-3",
        "Variant": "CX-3 2.0 INDIVIDUAL A\/T",
        "MM_CODES": 43048300,
        "GCode": 430,
        "MCode": 48,
        "Vcode": 300,
        "Discontinued_Date": "1899-12-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "5",
        "Variant": "MAZDA5 2.0L ORIGINAL 6SP",
        "MM_CODES": 43054180,
        "GCode": 430,
        "MCode": 54,
        "Vcode": 180,
        "Discontinued_Date": "2011-01-27 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "5",
        "Variant": "MAZDA5 2.0 ORIGINAL 6SP",
        "MM_CODES": 43054181,
        "GCode": 430,
        "MCode": 54,
        "Vcode": 181,
        "Discontinued_Date": "2016-12-02 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "5",
        "Variant": "MAZDA5 2.0L ACTIVE 6SP",
        "MM_CODES": 43054201,
        "GCode": 430,
        "MCode": 54,
        "Vcode": 201,
        "Discontinued_Date": "2012-01-18 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "5",
        "Variant": "MAZDA5 2.0 ACTIVE 6SP",
        "MM_CODES": 43054202,
        "GCode": 430,
        "MCode": 54,
        "Vcode": 202,
        "Discontinued_Date": "2015-07-01 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "5",
        "Variant": "MAZDA5 2.0 ACTIVE A\/T",
        "MM_CODES": 43054210,
        "GCode": 430,
        "MCode": 54,
        "Vcode": 210,
        "Discontinued_Date": "2016-12-02 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "5",
        "Variant": "MAZDA5 2.0 INDIVIDUAL 6SP",
        "MM_CODES": 43054222,
        "GCode": 430,
        "MCode": 54,
        "Vcode": 222,
        "Discontinued_Date": "2016-12-02 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "5",
        "Variant": "MAZDA5 2.0 INDIVIDUAL A\/T",
        "MM_CODES": 43054230,
        "GCode": 430,
        "MCode": 54,
        "Vcode": 230,
        "Discontinued_Date": "2016-12-02 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 DRIFTER 2.5TDI SLX P\/U S\/C",
        "MM_CODES": 43060222,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 222,
        "Discontinued_Date": "2012-08-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 DRIFTER 2.5TDI SLX 4X4 F\/CAB P\/U S\/C",
        "MM_CODES": 43060290,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 290,
        "Discontinued_Date": "2012-08-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 2.5I SL 4X4 P\/U S\/C",
        "MM_CODES": 43060320,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 320,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 2.5I SLX P\/U F\/CAB",
        "MM_CODES": 43060340,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 340,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 2.5I SLX P\/U D\/C",
        "MM_CODES": 43060350,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 350,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 DRIFTER 2.6I P\/U S\/C",
        "MM_CODES": 43060722,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 722,
        "Discontinued_Date": "2012-08-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 DRIFTER 2.6I 4X4 P\/U S\/C",
        "MM_CODES": 43060762,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 762,
        "Discontinued_Date": "2012-08-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 DRIFTER 2.6I 4X4 SAFETY P\/U S\/C",
        "MM_CODES": 43060765,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 765,
        "Discontinued_Date": "2012-08-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 DRIFTER 2.6I SLE P\/U D\/C",
        "MM_CODES": 43060775,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 775,
        "Discontinued_Date": "2012-08-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 DRIFTER 2.6I 4X4 SAFETY P\/U D\/C",
        "MM_CODES": 43060782,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 782,
        "Discontinued_Date": "2012-08-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 DRIFTER 3.0CRDI SLX P\/U S\/C",
        "MM_CODES": 43060800,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 800,
        "Discontinued_Date": "2012-08-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 DRIFTER 3.0CRDI SLX P\/U F\/CAB",
        "MM_CODES": 43060820,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 820,
        "Discontinued_Date": "2012-08-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 DRIFTER 3.0CRDI SLE P\/U D\/C",
        "MM_CODES": 43060840,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 840,
        "Discontinued_Date": "2012-08-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 DRIFTER 3.0CRDI SLE EDGE P\/U D\/C",
        "MM_CODES": 43060841,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 841,
        "Discontinued_Date": "2012-08-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 DRIFTER 3.0CRDI SLE A\/T P\/U D\/C",
        "MM_CODES": 43060850,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 850,
        "Discontinued_Date": "2012-08-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 DRIFTER 3.0CRDI SLE 4X4 P\/U D\/C",
        "MM_CODES": 43060870,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 870,
        "Discontinued_Date": "2012-08-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 3.2TDI SLX P\/U S\/C",
        "MM_CODES": 43060875,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 875,
        "Discontinued_Date": "2014-11-07 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 3.2TDI SLX 4X4 P\/U S\/C",
        "MM_CODES": 43060880,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 880,
        "Discontinued_Date": "2014-11-07 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 3.2TDI SLE P\/U F\/CAB",
        "MM_CODES": 43060885,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 885,
        "Discontinued_Date": "2017-11-01 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 3.2TDI SLE A\/T P\/U F\/CAB",
        "MM_CODES": 43060890,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 890,
        "Discontinued_Date": "2017-11-01 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 3.2TDI SLE 4X4 P\/U F\/CAB",
        "MM_CODES": 43060895,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 895,
        "Discontinued_Date": "2017-11-01 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 3.2TDI SLE P\/U D\/C",
        "MM_CODES": 43060900,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 900,
        "Discontinued_Date": "2017-11-01 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 3.2TDI SLE A\/T P\/U D\/C",
        "MM_CODES": 43060905,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 905,
        "Discontinued_Date": "2017-11-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 3.2TDI SLE 4X4 P\/U D\/C",
        "MM_CODES": 43060910,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 910,
        "Discontinued_Date": "2017-01-27 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "B 2500 \/ 2600 \/ BT-50",
        "Variant": "BT-50 3.2TDI SLE 4X4 P\/U D\/C",
        "MM_CODES": 43060911,
        "GCode": 430,
        "MCode": 60,
        "Vcode": 911,
        "Discontinued_Date": "2021-10-01 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "6",
        "Variant": "MAZDA6 2.0 ORIGINAL",
        "MM_CODES": 43076162,
        "GCode": 430,
        "MCode": 76,
        "Vcode": 162,
        "Discontinued_Date": "2014-09-17 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "6",
        "Variant": "MAZDA6 2.0 ACTIVE",
        "MM_CODES": 43076182,
        "GCode": 430,
        "MCode": 76,
        "Vcode": 182,
        "Discontinued_Date": "2014-09-17 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "6",
        "Variant": "MAZDA6 2.0 ACTIVE",
        "MM_CODES": 43076183,
        "GCode": 430,
        "MCode": 76,
        "Vcode": 183,
        "Discontinued_Date": "2016-12-02 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "6",
        "Variant": "MAZDA6 2.0 ACTIVE A\/T",
        "MM_CODES": 43076190,
        "GCode": 430,
        "MCode": 76,
        "Vcode": 190,
        "Discontinued_Date": "2016-12-02 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "6",
        "Variant": "MAZDA6 2.2DE ATENZA A\/T",
        "MM_CODES": 43076240,
        "GCode": 430,
        "MCode": 76,
        "Vcode": 240,
        "Discontinued_Date": "2016-12-02 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.0 INDIVIDUAL A\/T",
        "MM_CODES": 43085100,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 100,
        "Discontinued_Date": "2015-12-14 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.0 INDIVIDUAL A\/T",
        "MM_CODES": 43085101,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 101,
        "Discontinued_Date": "2021-10-01 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.0 DYNAMIC",
        "MM_CODES": 43085110,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 110,
        "Discontinued_Date": "2015-12-14 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.0 DYNAMIC",
        "MM_CODES": 43085111,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 111,
        "Discontinued_Date": "2017-05-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.0 DYNAMIC",
        "MM_CODES": 43085112,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 112,
        "Discontinued_Date": "2022-03-03 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.0 DYNAMIC A\/T",
        "MM_CODES": 43085115,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 115,
        "Discontinued_Date": "2015-12-14 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.0 DYNAMIC A\/T",
        "MM_CODES": 43085116,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 116,
        "Discontinued_Date": "1899-12-30 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.2DE ACTIVE",
        "MM_CODES": 43085120,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 120,
        "Discontinued_Date": "2021-09-22 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.2DE ACTIVE A\/T",
        "MM_CODES": 43085136,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 136,
        "Discontinued_Date": "2017-05-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.2DE ACTIVE A\/T",
        "MM_CODES": 43085137,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 137,
        "Discontinued_Date": "2021-09-21 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.2DE AKERA  A\/T AWD",
        "MM_CODES": 43085150,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 150,
        "Discontinued_Date": "2015-12-14 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.2DE AKERA A\/T AWD",
        "MM_CODES": 43085151,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 151,
        "Discontinued_Date": "2017-05-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.2DE AKERA A\/T AWD",
        "MM_CODES": 43085152,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 152,
        "Discontinued_Date": "2018-04-25 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.2DE AKERA A\/T AWD",
        "MM_CODES": 43085153,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 153,
        "Discontinued_Date": "2024-02-01 14:37:53.977"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.5 INDIVIDUAL A\/T",
        "MM_CODES": 43085185,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 185,
        "Discontinued_Date": "2015-12-14 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.5 INDIVIDUAL A\/T",
        "MM_CODES": 43085186,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 186,
        "Discontinued_Date": "2017-05-12 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-5 2.5 INDIVIDUAL A\/T",
        "MM_CODES": 43085187,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 187,
        "Discontinued_Date": "2019-02-04 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-7 2.3 DISI INDIVIDUAL A\/T",
        "MM_CODES": 43085201,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 201,
        "Discontinued_Date": "2015-07-01 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "CX-5\/CX-7",
        "Variant": "CX-7 2.5 DYNAMIC A\/T",
        "MM_CODES": 43085220,
        "GCode": 430,
        "MCode": 85,
        "Vcode": 220,
        "Discontinued_Date": "2015-07-01 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "MX-6 \/ MX5",
        "Variant": "MX-5 2.0 ROADSTER COUPE",
        "MM_CODES": 43090571,
        "GCode": 430,
        "MCode": 90,
        "Vcode": 571,
        "Discontinued_Date": "2016-02-08 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "MX-6 \/ MX5",
        "Variant": "MX-5 2.0 ROADSTER COUPE",
        "MM_CODES": 43090572,
        "GCode": 430,
        "MCode": 90,
        "Vcode": 572,
        "Discontinued_Date": "2017-05-08 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "MX-6 \/ MX5",
        "Variant": "MX-5 2.0 ROADSTER COUPE 25 ED",
        "MM_CODES": 43090580,
        "GCode": 430,
        "MCode": 90,
        "Vcode": 580,
        "Discontinued_Date": "2016-02-08 00:00:00.000"
    },
    {
        "Make": "MAZDA",
        "Model": "MX-6 \/ MX5",
        "Variant": "MX-5 RF 2.0 ROADSTER COUPE A\/T",
        "MM_CODES": 43090590,
        "GCode": 430,
        "MCode": 90,
        "Vcode": 590,
        "Discontinued_Date": "2020-12-15 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA200",
        "MM_CODES": 44013292,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 292,
        "Discontinued_Date": "2016-11-02 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA200",
        "MM_CODES": 44013293,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 293,
        "Discontinued_Date": "2019-05-27 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA200 A\/T",
        "MM_CODES": 44013317,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 317,
        "Discontinued_Date": "2019-05-27 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA200 A\/T",
        "MM_CODES": 44013318,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 318,
        "Discontinued_Date": "2021-06-11 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA200 URBAN A\/T",
        "MM_CODES": 44013325,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 325,
        "Discontinued_Date": "2019-05-27 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA200 AMG A\/T",
        "MM_CODES": 44013333,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 333,
        "Discontinued_Date": "2019-05-27 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA200D",
        "MM_CODES": 44013336,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 336,
        "Discontinued_Date": "2019-05-27 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA220 CDI A\/T",
        "MM_CODES": 44013348,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 348,
        "Discontinued_Date": "2016-06-24 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA220D A\/T",
        "MM_CODES": 44013349,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 349,
        "Discontinued_Date": "2019-05-27 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA220D A\/T",
        "MM_CODES": 44013350,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 350,
        "Discontinued_Date": "2021-06-11 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA220D URBAN A\/T",
        "MM_CODES": 44013357,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 357,
        "Discontinued_Date": "2019-05-27 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA220D AMG A\/T",
        "MM_CODES": 44013365,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 365,
        "Discontinued_Date": "2019-05-27 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA250 SPORT 4MATIC",
        "MM_CODES": 44013380,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 380,
        "Discontinued_Date": "2016-06-24 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA250 SPORT 4MATIC",
        "MM_CODES": 44013381,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 381,
        "Discontinued_Date": "2019-08-07 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA45 AMG",
        "MM_CODES": 44013490,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 490,
        "Discontinued_Date": "2016-06-24 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "CLA",
        "Variant": "CLA45 AMG",
        "MM_CODES": 44013491,
        "GCode": 440,
        "MCode": 13,
        "Vcode": 491,
        "Discontinued_Date": "2019-08-07 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS 2007 - 2014",
        "Variant": "C180 BE ESTATE CLASSIC",
        "MM_CODES": 44047157,
        "GCode": 440,
        "MCode": 47,
        "Vcode": 157,
        "Discontinued_Date": "2012-07-18 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS 2007 - 2014",
        "Variant": "C200 BE ESTATE CLASSIC",
        "MM_CODES": 44047276,
        "GCode": 440,
        "MCode": 47,
        "Vcode": 276,
        "Discontinued_Date": "2014-06-26 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS 2007 - 2014",
        "Variant": "C200 CDI ESTATE CLASSIC  A\/T",
        "MM_CODES": 44047357,
        "GCode": 440,
        "MCode": 47,
        "Vcode": 357,
        "Discontinued_Date": "2014-06-26 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS 2007 - 2014",
        "Variant": "C250 BE ESTATE ELEGANCE A\/T",
        "MM_CODES": 44047518,
        "GCode": 440,
        "MCode": 47,
        "Vcode": 518,
        "Discontinued_Date": "2014-06-26 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS 2007 - 2014",
        "Variant": "C250 CDI BE ESTATE ELEGANCE A\/T",
        "MM_CODES": 44047572,
        "GCode": 440,
        "MCode": 47,
        "Vcode": 572,
        "Discontinued_Date": "2014-06-26 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS 2007 - 2014",
        "Variant": "C350 AVANTGARDE A\/T",
        "MM_CODES": 44047908,
        "GCode": 440,
        "MCode": 47,
        "Vcode": 908,
        "Discontinued_Date": "2011-06-08 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS 2007 - 2014",
        "Variant": "C350 BE ESTATE ELEGANCE A\/T",
        "MM_CODES": 44047925,
        "GCode": 440,
        "MCode": 47,
        "Vcode": 925,
        "Discontinued_Date": "2014-06-26 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C180",
        "MM_CODES": 44048140,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 140,
        "Discontinued_Date": "2018-06-20 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C180 A\/T",
        "MM_CODES": 44048161,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 161,
        "Discontinued_Date": "2021-12-22 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C180 EDITION-C A\/T",
        "MM_CODES": 44048178,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 178,
        "Discontinued_Date": "2018-06-20 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C180 ESTATE",
        "MM_CODES": 44048180,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 180,
        "Discontinued_Date": "2018-12-18 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C200",
        "MM_CODES": 44048260,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 260,
        "Discontinued_Date": "2018-06-20 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C200 A\/T",
        "MM_CODES": 44048281,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 281,
        "Discontinued_Date": "2020-12-22 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C200 EDITION-C A\/T",
        "MM_CODES": 44048298,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 298,
        "Discontinued_Date": "2018-06-20 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C200 ESTATE",
        "MM_CODES": 44048300,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 300,
        "Discontinued_Date": "2018-12-12 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C220 BLUETEC",
        "MM_CODES": 44048380,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 380,
        "Discontinued_Date": "2018-06-20 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C220D A\/T",
        "MM_CODES": 44048401,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 401,
        "Discontinued_Date": "2020-12-22 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C220D EDITION-C A\/T",
        "MM_CODES": 44048418,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 418,
        "Discontinued_Date": "2018-06-20 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C250 AVANTGARDE A\/T",
        "MM_CODES": 44048470,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 470,
        "Discontinued_Date": "2018-06-20 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C250 EDITION-C A\/T",
        "MM_CODES": 44048483,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 483,
        "Discontinued_Date": "2018-06-20 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C250 BLUETEC AVANTGARDE A\/T",
        "MM_CODES": 44048500,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 500,
        "Discontinued_Date": "2018-06-20 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C250D EDITION C A\/T",
        "MM_CODES": 44048518,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 518,
        "Discontinued_Date": "2018-06-20 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C250 BLUETEC ESTATE AVANTGARDE A\/T",
        "MM_CODES": 44048525,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 525,
        "Discontinued_Date": "2018-12-12 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C300 A\/T",
        "MM_CODES": 44048550,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 550,
        "Discontinued_Date": "2020-12-22 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C300 AVANTGARDE A\/T",
        "MM_CODES": 44048555,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 555,
        "Discontinued_Date": "2018-06-20 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C300 EDITION-C A\/T",
        "MM_CODES": 44048568,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 568,
        "Discontinued_Date": "2018-06-20 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "AMG C43 4MATIC",
        "MM_CODES": 44048590,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 590,
        "Discontinued_Date": "2018-06-20 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "AMG C43 4MATIC",
        "MM_CODES": 44048591,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 591,
        "Discontinued_Date": "2020-12-22 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C63 AMG",
        "MM_CODES": 44048610,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 610,
        "Discontinued_Date": "2019-04-10 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C63 AMG S",
        "MM_CODES": 44048615,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 615,
        "Discontinued_Date": "2019-04-10 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "AMG C63 S",
        "MM_CODES": 44048616,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 616,
        "Discontinued_Date": "2020-12-22 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C200 COUPE",
        "MM_CODES": 44048655,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 655,
        "Discontinued_Date": "2018-08-17 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C220D COUPE ",
        "MM_CODES": 44048675,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 675,
        "Discontinued_Date": "2018-08-17 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C300 COUPE",
        "MM_CODES": 44048735,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 735,
        "Discontinued_Date": "2018-08-17 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "AMG C43 COUPE",
        "MM_CODES": 44048765,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 765,
        "Discontinued_Date": "2018-08-17 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "AMG COUPE C63",
        "MM_CODES": 44048780,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 780,
        "Discontinued_Date": "2019-05-24 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "AMG COUPE C63 S",
        "MM_CODES": 44048785,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 785,
        "Discontinued_Date": "2019-05-24 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C200 CABRIO",
        "MM_CODES": 44048800,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 800,
        "Discontinued_Date": "2018-08-17 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C220D CABRIO A\/T",
        "MM_CODES": 44048820,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 820,
        "Discontinued_Date": "2018-08-17 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C300 CABRIO A\/T",
        "MM_CODES": 44048830,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 830,
        "Discontinued_Date": "2018-08-17 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "C43 AMG CABRIO ",
        "MM_CODES": 44048870,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 870,
        "Discontinued_Date": "2018-08-17 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "C CLASS (2014)",
        "Variant": "AMG C63 S CABRIO",
        "MM_CODES": 44048895,
        "GCode": 440,
        "MCode": 48,
        "Vcode": 895,
        "Discontinued_Date": "2019-05-24 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLA",
        "Variant": "GLA 200",
        "MM_CODES": 44062250,
        "GCode": 440,
        "MCode": 62,
        "Vcode": 250,
        "Discontinued_Date": "2017-02-01 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLA",
        "Variant": "GLA 200",
        "MM_CODES": 44062251,
        "GCode": 440,
        "MCode": 62,
        "Vcode": 251,
        "Discontinued_Date": "2020-03-24 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLA",
        "Variant": "GLA 200 CDI",
        "MM_CODES": 44062290,
        "GCode": 440,
        "MCode": 62,
        "Vcode": 290,
        "Discontinued_Date": "2017-02-01 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLA",
        "Variant": "GLA 200D A\/T",
        "MM_CODES": 44062312,
        "GCode": 440,
        "MCode": 62,
        "Vcode": 312,
        "Discontinued_Date": "2021-06-11 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLA",
        "Variant": "GLA 220 CDI A\/T 4MATIC",
        "MM_CODES": 44062340,
        "GCode": 440,
        "MCode": 62,
        "Vcode": 340,
        "Discontinued_Date": "2017-02-01 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLA",
        "Variant": "GLA 220D  A\/T 4MATIC",
        "MM_CODES": 44062341,
        "GCode": 440,
        "MCode": 62,
        "Vcode": 341,
        "Discontinued_Date": "2020-03-13 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLA",
        "Variant": "GLA 250 4MATIC",
        "MM_CODES": 44062375,
        "GCode": 440,
        "MCode": 62,
        "Vcode": 375,
        "Discontinued_Date": "2017-02-01 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLA",
        "Variant": "GLA 250 4MATIC",
        "MM_CODES": 44062376,
        "GCode": 440,
        "MCode": 62,
        "Vcode": 376,
        "Discontinued_Date": "2020-03-13 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLA",
        "Variant": "GLA 45 AMG",
        "MM_CODES": 44062410,
        "GCode": 440,
        "MCode": 62,
        "Vcode": 410,
        "Discontinued_Date": "2017-02-01 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLA",
        "Variant": "AMG GLA 45 4MATIC",
        "MM_CODES": 44062411,
        "GCode": 440,
        "MCode": 62,
        "Vcode": 411,
        "Discontinued_Date": "2020-07-03 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLB",
        "Variant": "GLB 220D 4MATIC",
        "MM_CODES": 44064250,
        "GCode": 440,
        "MCode": 64,
        "Vcode": 250,
        "Discontinued_Date": "2022-06-10 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "SLK 2000 - ON",
        "Variant": "SLC 200 ",
        "MM_CODES": 44067214,
        "GCode": 440,
        "MCode": 67,
        "Vcode": 214,
        "Discontinued_Date": "2020-07-03 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "SLK 2000 - ON",
        "Variant": "SLK 200 KOMPRESSOR A\/T",
        "MM_CODES": 44067221,
        "GCode": 440,
        "MCode": 67,
        "Vcode": 221,
        "Discontinued_Date": "2011-08-16 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "SLK 2000 - ON",
        "Variant": "SLC 300 A\/T",
        "MM_CODES": 44067235,
        "GCode": 440,
        "MCode": 67,
        "Vcode": 235,
        "Discontinued_Date": "2020-07-03 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "SLK 2000 - ON",
        "Variant": "SLK 350 A\/T",
        "MM_CODES": 44067242,
        "GCode": 440,
        "MCode": 67,
        "Vcode": 242,
        "Discontinued_Date": "2015-05-12 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "SLK 2000 - ON",
        "Variant": "AMG SLC 43",
        "MM_CODES": 44067255,
        "GCode": 440,
        "MCode": 67,
        "Vcode": 255,
        "Discontinued_Date": "2020-07-03 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E 200",
        "MM_CODES": 44069103,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 103,
        "Discontinued_Date": "2016-10-05 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E 250 CDI COUPE",
        "MM_CODES": 44069146,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 146,
        "Discontinued_Date": "2017-07-04 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E 250 CGI COUPE",
        "MM_CODES": 44069201,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 201,
        "Discontinued_Date": "2017-07-04 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E 250 CGI CABRIOLET",
        "MM_CODES": 44069211,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 211,
        "Discontinued_Date": "2017-11-01 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E 250",
        "MM_CODES": 44069220,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 220,
        "Discontinued_Date": "2016-10-05 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E 400 COUPE",
        "MM_CODES": 44069391,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 391,
        "Discontinued_Date": "2017-07-04 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E 400 CABRIOLET",
        "MM_CODES": 44069401,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 401,
        "Discontinued_Date": "2017-10-24 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E 500 COUPE",
        "MM_CODES": 44069471,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 471,
        "Discontinued_Date": "2017-07-04 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E 500 CABRIOLET",
        "MM_CODES": 44069473,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 473,
        "Discontinued_Date": "2017-10-17 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "CLS 250 CDI",
        "MM_CODES": 44069488,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 488,
        "Discontinued_Date": "2019-03-01 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "CLS 350 BE",
        "MM_CODES": 44069492,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 492,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "CLS 350D",
        "MM_CODES": 44069495,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 495,
        "Discontinued_Date": "2019-03-01 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "CLS 400D 4MATIC",
        "MM_CODES": 44069497,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 497,
        "Discontinued_Date": "2022-06-10 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "CLS 400",
        "MM_CODES": 44069498,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 498,
        "Discontinued_Date": "2019-03-01 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "CLS 500 BE",
        "MM_CODES": 44069502,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 502,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "CLS 500",
        "MM_CODES": 44069503,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 503,
        "Discontinued_Date": "2019-03-01 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "AMG CLS 53 4MATIC",
        "MM_CODES": 44069508,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 508,
        "Discontinued_Date": "2022-06-10 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "CLS 63 AMG",
        "MM_CODES": 44069522,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 522,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "CLS 63 AMG S",
        "MM_CODES": 44069527,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 527,
        "Discontinued_Date": "2019-03-01 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E220D",
        "MM_CODES": 44069610,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 610,
        "Discontinued_Date": "2020-12-14 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E 250",
        "MM_CODES": 44069670,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 670,
        "Discontinued_Date": "2020-07-03 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E 350D EXCLUSIVE",
        "MM_CODES": 44069735,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 735,
        "Discontinued_Date": "2020-12-14 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E 400",
        "MM_CODES": 44069750,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 750,
        "Discontinued_Date": "2020-07-03 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "AMG E43 4MATIC",
        "MM_CODES": 44069790,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 790,
        "Discontinued_Date": "2020-07-03 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "AMG E53 4MATIC",
        "MM_CODES": 44069795,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 795,
        "Discontinued_Date": "2020-12-15 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "AMG E63 S 4MATIC",
        "MM_CODES": 44069820,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 820,
        "Discontinued_Date": "2020-12-15 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E 200 COUPE",
        "MM_CODES": 44069870,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 870,
        "Discontinued_Date": "2020-12-15 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "E CLASS 2002 - ON \/ CLS",
        "Variant": "E 200 COUPE",
        "MM_CODES": 44069871,
        "GCode": 440,
        "MCode": 69,
        "Vcode": 871,
        "Discontinued_Date": "2022-06-10 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLC",
        "Variant": "GLC COUPE 300",
        "MM_CODES": 44071575,
        "GCode": 440,
        "MCode": 71,
        "Vcode": 575,
        "Discontinued_Date": "2019-06-13 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLC",
        "Variant": "GLC COUPE 350D",
        "MM_CODES": 44071635,
        "GCode": 440,
        "MCode": 71,
        "Vcode": 635,
        "Discontinued_Date": "2019-06-13 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLC",
        "Variant": "AMG GLC 63S COUPE 4MATIC",
        "MM_CODES": 44071725,
        "GCode": 440,
        "MCode": 71,
        "Vcode": 725,
        "Discontinued_Date": "2019-08-12 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLE",
        "Variant": "GLE 400 4MATIC",
        "MM_CODES": 44074250,
        "GCode": 440,
        "MCode": 74,
        "Vcode": 250,
        "Discontinued_Date": "2019-03-01 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLE",
        "Variant": "GLE 400D 4MATIC",
        "MM_CODES": 44074260,
        "GCode": 440,
        "MCode": 74,
        "Vcode": 260,
        "Discontinued_Date": "2024-02-08 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLE",
        "Variant": "GLE COUPE 500 4MATIC",
        "MM_CODES": 44074290,
        "GCode": 440,
        "MCode": 74,
        "Vcode": 290,
        "Discontinued_Date": "2020-05-14 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLE",
        "Variant": "GLE 53 AMG COUPE 4MATIC",
        "MM_CODES": 44074310,
        "GCode": 440,
        "MCode": 74,
        "Vcode": 310,
        "Discontinued_Date": "2024-02-09 12:41:29.427"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLE",
        "Variant": "GLE COUPE 63 S AMG",
        "MM_CODES": 44074330,
        "GCode": 440,
        "MCode": 74,
        "Vcode": 330,
        "Discontinued_Date": "2020-07-03 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLE",
        "Variant": "GLE 63 AMG",
        "MM_CODES": 44074340,
        "GCode": 440,
        "MCode": 74,
        "Vcode": 340,
        "Discontinued_Date": "2019-12-10 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLE",
        "Variant": "GLE 63 S AMG",
        "MM_CODES": 44074350,
        "GCode": 440,
        "MCode": 74,
        "Vcode": 350,
        "Discontinued_Date": "2019-12-10 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "GLE",
        "Variant": "AMG GLE 63 S 4MATIC+",
        "MM_CODES": 44074351,
        "GCode": 440,
        "MCode": 74,
        "Vcode": 351,
        "Discontinued_Date": "2024-02-09 12:43:03.657"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "S CLASS 2006 - ON",
        "Variant": "S400D",
        "MM_CODES": 44079270,
        "GCode": 440,
        "MCode": 79,
        "Vcode": 270,
        "Discontinued_Date": "2021-02-24 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "S CLASS 2006 - ON",
        "Variant": "S450",
        "MM_CODES": 44079280,
        "GCode": 440,
        "MCode": 79,
        "Vcode": 280,
        "Discontinued_Date": "2022-05-19 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "S CLASS 2006 - ON",
        "Variant": "S 500 COUPE",
        "MM_CODES": 44079290,
        "GCode": 440,
        "MCode": 79,
        "Vcode": 290,
        "Discontinued_Date": "2018-07-31 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "S CLASS 2006 - ON",
        "Variant": "S500 CABRIO",
        "MM_CODES": 44079295,
        "GCode": 440,
        "MCode": 79,
        "Vcode": 295,
        "Discontinued_Date": "2018-07-31 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "S CLASS 2006 - ON",
        "Variant": "S500 BE",
        "MM_CODES": 44079303,
        "GCode": 440,
        "MCode": 79,
        "Vcode": 303,
        "Discontinued_Date": "2017-10-19 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "S CLASS 2006 - ON",
        "Variant": "S 500 L BE",
        "MM_CODES": 44079313,
        "GCode": 440,
        "MCode": 79,
        "Vcode": 313,
        "Discontinued_Date": "2017-10-19 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "S CLASS 2006 - ON",
        "Variant": "S 600 L",
        "MM_CODES": 44079402,
        "GCode": 440,
        "MCode": 79,
        "Vcode": 402,
        "Discontinued_Date": "2017-10-19 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "S CLASS 2006 - ON",
        "Variant": "AMG S63 CABRIO",
        "MM_CODES": 44079445,
        "GCode": 440,
        "MCode": 79,
        "Vcode": 445,
        "Discontinued_Date": "2018-07-31 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "S CLASS 2006 - ON",
        "Variant": "S 63 AMG L",
        "MM_CODES": 44079460,
        "GCode": 440,
        "MCode": 79,
        "Vcode": 460,
        "Discontinued_Date": "2017-10-19 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "S CLASS 2006 - ON",
        "Variant": "AMG S63",
        "MM_CODES": 44079461,
        "GCode": 440,
        "MCode": 79,
        "Vcode": 461,
        "Discontinued_Date": "2022-05-19 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "S CLASS 2006 - ON",
        "Variant": "S 65 AMG COUPE",
        "MM_CODES": 44079465,
        "GCode": 440,
        "MCode": 79,
        "Vcode": 465,
        "Discontinued_Date": "2018-07-31 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "S CLASS 2006 - ON",
        "Variant": "S 65 AMG L",
        "MM_CODES": 44079472,
        "GCode": 440,
        "MCode": 79,
        "Vcode": 472,
        "Discontinued_Date": "2017-10-19 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "S CLASS 2006 - ON",
        "Variant": "AMG S65",
        "MM_CODES": 44079473,
        "GCode": 440,
        "MCode": 79,
        "Vcode": 473,
        "Discontinued_Date": "2020-05-14 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "SL 500 \/ SLS \/ AMG GT",
        "Variant": "SL 500",
        "MM_CODES": 44084104,
        "GCode": 440,
        "MCode": 84,
        "Vcode": 104,
        "Discontinued_Date": "2020-05-14 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "SL 500 \/ SLS \/ AMG GT",
        "Variant": "AMG SL63",
        "MM_CODES": 44084132,
        "GCode": 440,
        "MCode": 84,
        "Vcode": 132,
        "Discontinued_Date": "2022-06-10 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "SL 500 \/ SLS \/ AMG GT",
        "Variant": "AMG SL65",
        "MM_CODES": 44084142,
        "GCode": 440,
        "MCode": 84,
        "Vcode": 142,
        "Discontinued_Date": "2020-05-14 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "SL 500 \/ SLS \/ AMG GT",
        "Variant": "AMG GT53",
        "MM_CODES": 44084200,
        "GCode": 440,
        "MCode": 84,
        "Vcode": 200,
        "Discontinued_Date": "2022-06-10 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "VITO \/ VIANO \/ ML \/ GL",
        "Variant": "ML 350 BE",
        "MM_CODES": 44095552,
        "GCode": 440,
        "MCode": 95,
        "Vcode": 552,
        "Discontinued_Date": "2016-10-05 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "VITO \/ VIANO \/ ML \/ GL",
        "Variant": "ML 63 AMG",
        "MM_CODES": 44095653,
        "GCode": 440,
        "MCode": 95,
        "Vcode": 653,
        "Discontinued_Date": "2016-10-05 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "VITO \/ VIANO \/ ML \/ GL",
        "Variant": "G63 AMG",
        "MM_CODES": 44095910,
        "GCode": 440,
        "MCode": 95,
        "Vcode": 910,
        "Discontinued_Date": "2018-09-27 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "VITO \/ VIANO \/ ML \/ GL",
        "Variant": "GLS 400D",
        "MM_CODES": 44095950,
        "GCode": 440,
        "MCode": 95,
        "Vcode": 950,
        "Discontinued_Date": "2024-02-02 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "VITO \/ VIANO \/ ML \/ GL",
        "Variant": "AMG GLS 63",
        "MM_CODES": 44095976,
        "GCode": 440,
        "MCode": 95,
        "Vcode": 976,
        "Discontinued_Date": "2020-07-03 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "X-CLASS",
        "Variant": "X220D PROGRESSIVE",
        "MM_CODES": 44098110,
        "GCode": 440,
        "MCode": 98,
        "Vcode": 110,
        "Discontinued_Date": "2020-10-04 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "X-CLASS",
        "Variant": "X250D 4X4 PROGRESSIVE A\/T",
        "MM_CODES": 44098180,
        "GCode": 440,
        "MCode": 98,
        "Vcode": 180,
        "Discontinued_Date": "2020-10-04 00:00:00.000"
    },
    {
        "Make": "MERCEDES-BENZ",
        "Model": "X-CLASS",
        "Variant": "X250D 4X4 POWER A\/T",
        "MM_CODES": 44098200,
        "GCode": 440,
        "MCode": 98,
        "Vcode": 200,
        "Discontinued_Date": "2020-10-04 00:00:00.000"
    },
    {
        "Make": "MG",
        "Model": "MG6",
        "Variant": "MG6 1.8T COMFORT 5DR",
        "MM_CODES": 44530200,
        "GCode": 445,
        "MCode": 30,
        "Vcode": 200,
        "Discontinued_Date": "2016-04-20 00:00:00.000"
    },
    {
        "Make": "MG",
        "Model": "MG6",
        "Variant": "MG6 1.8T COMFORT",
        "MM_CODES": 44530230,
        "GCode": 445,
        "MCode": 30,
        "Vcode": 230,
        "Discontinued_Date": "2016-04-20 00:00:00.000"
    },
    {
        "Make": "MG",
        "Model": "MG6",
        "Variant": "MG6 1.8T DELUXE",
        "MM_CODES": 44530250,
        "GCode": 445,
        "MCode": 30,
        "Vcode": 250,
        "Discontinued_Date": "2016-04-20 00:00:00.000"
    },
    {
        "Make": "MITSUBISHI",
        "Model": "ASX",
        "Variant": "ASX 2.0 5DR GL",
        "MM_CODES": 45033250,
        "GCode": 450,
        "MCode": 33,
        "Vcode": 250,
        "Discontinued_Date": "2018-10-17 00:00:00.000"
    },
    {
        "Make": "MITSUBISHI",
        "Model": "ASX",
        "Variant": "ASX 2.0 ES ",
        "MM_CODES": 45033251,
        "GCode": 450,
        "MCode": 33,
        "Vcode": 251,
        "Discontinued_Date": "2020-06-29 00:00:00.000"
    },
    {
        "Make": "MITSUBISHI",
        "Model": "ASX",
        "Variant": "ASX 2.0 5DR GLS A\/T",
        "MM_CODES": 45033280,
        "GCode": 450,
        "MCode": 33,
        "Vcode": 280,
        "Discontinued_Date": "2019-11-21 00:00:00.000"
    },
    {
        "Make": "MITSUBISHI",
        "Model": "ECLIPSE CROSS",
        "Variant": "ECLIPSE CROSS 2.0 GLS  CVT AWD",
        "MM_CODES": 45035220,
        "GCode": 450,
        "MCode": 35,
        "Vcode": 220,
        "Discontinued_Date": "2021-07-09 00:00:00.000"
    },
    {
        "Make": "MITSUBISHI",
        "Model": "OUTLANDER",
        "Variant": "OUTLANDER 2.4 GLS A\/T",
        "MM_CODES": 45061261,
        "GCode": 450,
        "MCode": 61,
        "Vcode": 261,
        "Discontinued_Date": "2014-03-06 00:00:00.000"
    },
    {
        "Make": "MITSUBISHI",
        "Model": "OUTLANDER",
        "Variant": "OUTLANDER 2.4 GLS EXCEED A\/T",
        "MM_CODES": 45061270,
        "GCode": 450,
        "MCode": 61,
        "Vcode": 270,
        "Discontinued_Date": "2017-02-03 00:00:00.000"
    },
    {
        "Make": "MITSUBISHI",
        "Model": "PAJERO",
        "Variant": "PAJERO 3.2 DI - DC GLX  A\/T",
        "MM_CODES": 45090542,
        "GCode": 450,
        "MCode": 90,
        "Vcode": 542,
        "Discontinued_Date": "2015-12-15 00:00:00.000"
    },
    {
        "Make": "MITSUBISHI",
        "Model": "PAJERO\/PAJERO SPORT",
        "Variant": "PAJERO 3.2 DI - DC GLX  A\/T",
        "MM_CODES": 45090542,
        "GCode": 450,
        "MCode": 90,
        "Vcode": 542,
        "Discontinued_Date": "2015-12-15 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER CLUBMAN",
        "Variant": "COOPER CLUBMAN A\/T",
        "MM_CODES": 45715111,
        "GCode": 457,
        "MCode": 15,
        "Vcode": 111,
        "Discontinued_Date": "2015-09-04 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER CLUBMAN",
        "Variant": "COOPER S CLUBMAN A\/T",
        "MM_CODES": 45715211,
        "GCode": 457,
        "MCode": 15,
        "Vcode": 211,
        "Discontinued_Date": "2015-09-04 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER CLUBMAN",
        "Variant": "COOPER JCW CLUBMAN A\/T",
        "MM_CODES": 45715230,
        "GCode": 457,
        "MCode": 15,
        "Vcode": 230,
        "Discontinued_Date": "2015-09-04 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER COUNTRYMAN",
        "Variant": "COOPER COUNTRYMAN",
        "MM_CODES": 45717100,
        "GCode": 457,
        "MCode": 17,
        "Vcode": 100,
        "Discontinued_Date": "2014-07-02 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER COUNTRYMAN",
        "Variant": "COOPER S COUNTRYMAN A\/T",
        "MM_CODES": 45717210,
        "GCode": 457,
        "MCode": 17,
        "Vcode": 210,
        "Discontinued_Date": "2014-07-02 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER COUNTRYMAN",
        "Variant": "COOPER S COUNTRYMAN A\/T",
        "MM_CODES": 45717211,
        "GCode": 457,
        "MCode": 17,
        "Vcode": 211,
        "Discontinued_Date": "2017-09-06 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER COUNTRYMAN",
        "Variant": "COOPER JCW COUNTRYMAN ALL4",
        "MM_CODES": 45717220,
        "GCode": 457,
        "MCode": 17,
        "Vcode": 220,
        "Discontinued_Date": "2014-07-02 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER COUNTRYMAN",
        "Variant": "COOPER S ALL4 COUNTRYMAN A\/T",
        "MM_CODES": 45717250,
        "GCode": 457,
        "MCode": 17,
        "Vcode": 250,
        "Discontinued_Date": "2013-12-16 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER COUNTRYMAN",
        "Variant": "COOPER D COUNTRYMAN A\/T",
        "MM_CODES": 45717310,
        "GCode": 457,
        "MCode": 17,
        "Vcode": 310,
        "Discontinued_Date": "2020-07-08 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER COUPE",
        "Variant": "COOPER COUPE (SX12)",
        "MM_CODES": 45720100,
        "GCode": 457,
        "MCode": 20,
        "Vcode": 100,
        "Discontinued_Date": "2015-12-02 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER ROADSTER",
        "Variant": "COOPER S ROADSTER (SY32)",
        "MM_CODES": 45723200,
        "GCode": 457,
        "MCode": 23,
        "Vcode": 200,
        "Discontinued_Date": "2015-12-02 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER ROADSTER",
        "Variant": "COOPER S ROADSTER A\/T (SY32)",
        "MM_CODES": 45723210,
        "GCode": 457,
        "MCode": 23,
        "Vcode": 210,
        "Discontinued_Date": "2015-12-02 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER PACEMAN",
        "Variant": "COOPER S PACEMAN",
        "MM_CODES": 45730201,
        "GCode": 457,
        "MCode": 30,
        "Vcode": 201,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER PACEMAN",
        "Variant": "COOPER JCW PACEMAN",
        "MM_CODES": 45730220,
        "GCode": 457,
        "MCode": 30,
        "Vcode": 220,
        "Discontinued_Date": "2014-07-02 00:00:00.000"
    },
    {
        "Make": "MINI",
        "Model": "COOPER PACEMAN",
        "Variant": "COOPER JCW PACEMAN",
        "MM_CODES": 45730221,
        "GCode": 457,
        "MCode": 30,
        "Vcode": 221,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "MICRA",
        "Variant": "MICRA 900T ACENTA PLUS TECH",
        "MM_CODES": 47010390,
        "GCode": 470,
        "MCode": 10,
        "Vcode": 390,
        "Discontinued_Date": "2021-10-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "MICRA",
        "Variant": "MICRA 1.0T TEKNA PLUS (84KW)",
        "MM_CODES": 47010430,
        "GCode": 470,
        "MCode": 10,
        "Vcode": 430,
        "Discontinued_Date": "2022-09-22 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "JUKE",
        "Variant": "JUKE 1.6 ACENTA + CVT",
        "MM_CODES": 47015220,
        "GCode": 470,
        "MCode": 15,
        "Vcode": 220,
        "Discontinued_Date": "2015-01-29 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "JUKE",
        "Variant": "JUKE 1.6 DIG -T TEKNA AWD CVT",
        "MM_CODES": 47015300,
        "GCode": 470,
        "MCode": 15,
        "Vcode": 300,
        "Discontinued_Date": "2015-01-29 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "JUKE",
        "Variant": "JUKE 1.6T TEKNA AWD CVT",
        "MM_CODES": 47015301,
        "GCode": 470,
        "MCode": 15,
        "Vcode": 301,
        "Discontinued_Date": "2019-09-09 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "1400\/NP200",
        "Variant": "NP200 1.5 DCI  P\/U S\/C",
        "MM_CODES": 47017630,
        "GCode": 470,
        "MCode": 17,
        "Vcode": 630,
        "Discontinued_Date": "2011-03-09 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "1400\/NP200",
        "Variant": "NP200 1.5 DCI  A\/C SAFETY PACK P\/U S\/C",
        "MM_CODES": 47017631,
        "GCode": 470,
        "MCode": 17,
        "Vcode": 631,
        "Discontinued_Date": "2022-04-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "1400\/NP200",
        "Variant": "NP200 1.5 DCI SE P\/U\/S\/C",
        "MM_CODES": 47017650,
        "GCode": 470,
        "MCode": 17,
        "Vcode": 650,
        "Discontinued_Date": "2022-04-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "1400\/NP200",
        "Variant": "NP200 1.6  P\/U S\/C",
        "MM_CODES": 47017660,
        "GCode": 470,
        "MCode": 17,
        "Vcode": 660,
        "Discontinued_Date": "2024-12-30 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "1600\/NP200",
        "Variant": "NP200 1.6  P\/U S\/C",
        "MM_CODES": 47017665,
        "GCode": 470,
        "MCode": 17,
        "Vcode": 660,
        "Discontinued_Date": "2015-12-30 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "HARDBODY 2002 - ON\/NP300",
        "Variant": "HARDBODY NP300 2.4I LWB HIRIDER (K12\/K30) P\/U S\/C",
        "MM_CODES": 47032105,
        "GCode": 470,
        "MCode": 32,
        "Vcode": 105,
        "Discontinued_Date": "2014-08-02 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "HARDBODY 2002 - ON\/NP300",
        "Variant": "HARDBODY NP300 2.4I LWB 4X4 (K14\/K35) P\/U S\/C",
        "MM_CODES": 47032121,
        "GCode": 470,
        "MCode": 32,
        "Vcode": 121,
        "Discontinued_Date": "2014-10-10 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "HARDBODY 2002 - ON\/NP300",
        "Variant": "HARDBODY NP300 2.4I LWB 4X4 P\/U S\/C",
        "MM_CODES": 47032122,
        "GCode": 470,
        "MCode": 32,
        "Vcode": 122,
        "Discontinued_Date": "2022-04-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "HARDBODY 2002 - ON\/NP300",
        "Variant": "HARDBODY NP300 2.4I HIRIDER (K13\/K31) P\/U D\/C",
        "MM_CODES": 47032125,
        "GCode": 470,
        "MCode": 32,
        "Vcode": 125,
        "Discontinued_Date": "2014-10-10 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "HARDBODY 2002 - ON\/NP300",
        "Variant": "HARDBODY NP300 2.4I HI-RIDER P\/U D\/C",
        "MM_CODES": 47032126,
        "GCode": 470,
        "MCode": 32,
        "Vcode": 126,
        "Discontinued_Date": "2022-04-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "HARDBODY 2002 - ON\/NP300",
        "Variant": "HARDBODY NP300 2.4I 4X4 P\/U D\/C",
        "MM_CODES": 47032144,
        "GCode": 470,
        "MCode": 32,
        "Vcode": 144,
        "Discontinued_Date": "2022-04-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "HARDBODY 2002 - ON\/NP300",
        "Variant": "HARDBODY NP300 2.5TDI LWB (K03\/K40) P\/U S\/C",
        "MM_CODES": 47032150,
        "GCode": 470,
        "MCode": 32,
        "Vcode": 150,
        "Discontinued_Date": "2014-10-10 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "HARDBODY 2002 - ON\/NP300",
        "Variant": "HARDBODY NP300 2.5 TDI LWB P\/U S\/C",
        "MM_CODES": 47032151,
        "GCode": 470,
        "MCode": 32,
        "Vcode": 151,
        "Discontinued_Date": "2022-04-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "HARDBODY 2002 - ON\/NP300",
        "Variant": "HARDBODY NP300 2.5 TDI LWB S (KO4\/K41) P\/U S\/C",
        "MM_CODES": 47032155,
        "GCode": 470,
        "MCode": 32,
        "Vcode": 155,
        "Discontinued_Date": "2014-03-18 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "HARDBODY 2002 - ON\/NP300",
        "Variant": "HARDBODY NP300 2.5 TDI LWB SE (KO5\/K28) P\/U S\/C",
        "MM_CODES": 47032160,
        "GCode": 470,
        "MCode": 32,
        "Vcode": 160,
        "Discontinued_Date": "2014-11-03 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "HARDBODY 2002 - ON\/NP300",
        "Variant": "HARDBODY NP300 2.5 TDI HIRIDER (K18\/K32) P\/U S\/C",
        "MM_CODES": 47032165,
        "GCode": 470,
        "MCode": 32,
        "Vcode": 165,
        "Discontinued_Date": "2014-10-10 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "HARDBODY 2002 - ON\/NP300",
        "Variant": "HARDBODY NP300 2.5TDI HI-RIDER P\/U S\/C",
        "MM_CODES": 47032166,
        "GCode": 470,
        "MCode": 32,
        "Vcode": 166,
        "Discontinued_Date": "2022-04-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "HARDBODY 2002 - ON\/NP300",
        "Variant": "HARDBODY NP300 2.5 TDI HI-RIDER P\/U D\/C",
        "MM_CODES": 47032186,
        "GCode": 470,
        "MCode": 32,
        "Vcode": 186,
        "Discontinued_Date": "2022-04-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "HARDBODY 2002 - ON\/NP300",
        "Variant": "HARDBODY NP300 2.5 TDI 4X4 P\/U D\/C",
        "MM_CODES": 47032195,
        "GCode": 470,
        "MCode": 32,
        "Vcode": 195,
        "Discontinued_Date": "2022-04-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "TIIDA",
        "Variant": "TIIDA 1.6 VISIA + 5DR (H35)",
        "MM_CODES": 47034150,
        "GCode": 470,
        "MCode": 34,
        "Vcode": 150,
        "Discontinued_Date": "2015-01-29 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "TIIDA",
        "Variant": "TIIDA 1.6 VISIA + A\/T (H32)",
        "MM_CODES": 47034380,
        "GCode": 470,
        "MCode": 34,
        "Vcode": 380,
        "Discontinued_Date": "2015-01-29 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "SENTRA 2013",
        "Variant": "SENTRA 1.6 ACENTA CVT",
        "MM_CODES": 47039160,
        "GCode": 470,
        "MCode": 39,
        "Vcode": 160,
        "Discontinued_Date": "2017-05-16 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "LEAF",
        "Variant": "LEAF 5DR",
        "MM_CODES": 47044150,
        "GCode": 470,
        "MCode": 44,
        "Vcode": 150,
        "Discontinued_Date": "2019-09-09 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.5 DCI  XE K\/CAB P\/U S\/C",
        "MM_CODES": 47047100,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 100,
        "Discontinued_Date": "2017-04-03 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.5 DCI XE K\/CAB 4X4 P\/U S\/C",
        "MM_CODES": 47047120,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 120,
        "Discontinued_Date": "2017-04-03 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.5 DCI  XE P\/U D\/C",
        "MM_CODES": 47047140,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 140,
        "Discontinued_Date": "2017-06-07 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.5 DCI P\/U D\/C",
        "MM_CODES": 47047150,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 150,
        "Discontinued_Date": "2010-05-05 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.5 DCI LE P\/U D\/C",
        "MM_CODES": 47047151,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 151,
        "Discontinued_Date": "2017-04-03 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.5 DCI A\/T P\/U D\/C",
        "MM_CODES": 47047160,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 160,
        "Discontinued_Date": "2010-05-05 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.5 DCI XE 4X4 P\/U D\/C",
        "MM_CODES": 47047165,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 165,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.5 DCI SE 4X4 P\/U D\/C",
        "MM_CODES": 47047168,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 168,
        "Discontinued_Date": "2014-03-18 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.5 DCI 4X4 P\/U D\/C",
        "MM_CODES": 47047171,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 171,
        "Discontinued_Date": "2010-05-05 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.5 DCI LE 4X4 P\/U D\/C",
        "MM_CODES": 47047172,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 172,
        "Discontinued_Date": "2016-01-13 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 4.0 LE P\/U D\/C",
        "MM_CODES": 47047251,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 251,
        "Discontinued_Date": "2015-10-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 4.0 V6 4X4 P\/U D\/C",
        "MM_CODES": 47047271,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 271,
        "Discontinued_Date": "2010-05-05 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.3D SE P\/U D\/C ",
        "MM_CODES": 47047380,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 380,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.3D SE A\/T P\/U D\/C",
        "MM_CODES": 47047390,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 390,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.3D SE 4X4 P\/U D\/C",
        "MM_CODES": 47047400,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 400,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.3D LE P\/U D\/C",
        "MM_CODES": 47047430,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 430,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.3D LE A\/T D\/C P\/U",
        "MM_CODES": 47047440,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 440,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.3D STEALTH A\/T D\/C P\/U",
        "MM_CODES": 47047441,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 441,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.3D LE 4X4 A\/T P\/U D\/C",
        "MM_CODES": 47047460,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 460,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.3D STEALTH 4X4 A\/T P\/U D\/C",
        "MM_CODES": 47047461,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 461,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NAVARA",
        "Variant": "NAVARA 2.5DDTI PRO-4X 4X4 A\/T D\/C P\/U",
        "MM_CODES": 47047615,
        "GCode": 470,
        "MCode": 47,
        "Vcode": 615,
        "Discontinued_Date": "1899-12-30 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NV",
        "Variant": "NV200 1.5DCI VISIA F\/C P\/V",
        "MM_CODES": 47048250,
        "GCode": 470,
        "MCode": 48,
        "Vcode": 250,
        "Discontinued_Date": "2020-06-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "PRIMASTAR\/ NV 200\/NV350",
        "Variant": "NV350 2.5 16 SEAT IMPENDULO",
        "MM_CODES": 47048275,
        "GCode": 470,
        "MCode": 48,
        "Vcode": 275,
        "Discontinued_Date": "2022-10-31 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NV",
        "Variant": "NV200 1.6I VISIA F\/C P\/V",
        "MM_CODES": 47048300,
        "GCode": 470,
        "MCode": 48,
        "Vcode": 300,
        "Discontinued_Date": "2020-06-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "NV",
        "Variant": "NV350 2.5DCI WIDE F\/C P\/V",
        "MM_CODES": 47048550,
        "GCode": 470,
        "MCode": 48,
        "Vcode": 550,
        "Discontinued_Date": "2021-04-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "QASHQAI",
        "Variant": "QASHQAI 1.6 VISIA",
        "MM_CODES": 47060101,
        "GCode": 470,
        "MCode": 60,
        "Vcode": 101,
        "Discontinued_Date": "2014-06-30 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "QASHQAI",
        "Variant": "QASHQAI 1.6 ACENTA",
        "MM_CODES": 47060121,
        "GCode": 470,
        "MCode": 60,
        "Vcode": 121,
        "Discontinued_Date": "2014-06-30 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "QASHQAI",
        "Variant": "QASHQAI 1.6T ACENTA",
        "MM_CODES": 47060122,
        "GCode": 470,
        "MCode": 60,
        "Vcode": 122,
        "Discontinued_Date": "2018-11-20 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "QASHQAI",
        "Variant": "QASHQAI 1.6 ACENTA N-TEC LTD",
        "MM_CODES": 47060130,
        "GCode": 470,
        "MCode": 60,
        "Vcode": 130,
        "Discontinued_Date": "2014-06-30 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "QASHQAI",
        "Variant": "QASHQAI 1.6T ACENTA TECH",
        "MM_CODES": 47060131,
        "GCode": 470,
        "MCode": 60,
        "Vcode": 131,
        "Discontinued_Date": "2018-11-20 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "QASHQAI",
        "Variant": "QASHQAI 1.6 DCI ACENTA CVT",
        "MM_CODES": 47060160,
        "GCode": 470,
        "MCode": 60,
        "Vcode": 160,
        "Discontinued_Date": "2018-11-20 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "QASHQAI",
        "Variant": "QASHQAI 2.0 ACENTA",
        "MM_CODES": 47060301,
        "GCode": 470,
        "MCode": 60,
        "Vcode": 301,
        "Discontinued_Date": "2014-06-30 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "QASHQAI",
        "Variant": "QASHQAI+2 2.0 ACENTA",
        "MM_CODES": 47060305,
        "GCode": 470,
        "MCode": 60,
        "Vcode": 305,
        "Discontinued_Date": "2014-06-30 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "QASHQAI",
        "Variant": "QASHQAI 2.0 N-TEC LIMITED",
        "MM_CODES": 47060310,
        "GCode": 470,
        "MCode": 60,
        "Vcode": 310,
        "Discontinued_Date": "2010-04-20 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "QASHQAI",
        "Variant": "QASHQAI 2.0 ACENTA N-TEC LTD",
        "MM_CODES": 47060311,
        "GCode": 470,
        "MCode": 60,
        "Vcode": 311,
        "Discontinued_Date": "2014-06-30 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "QASHQAI",
        "Variant": "QASHQAI 2.0 ACENTA CVT",
        "MM_CODES": 47060330,
        "GCode": 470,
        "MCode": 60,
        "Vcode": 330,
        "Discontinued_Date": "2014-06-30 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "QASHQAI",
        "Variant": "QASHQAI 2.0 DCI ACENTA",
        "MM_CODES": 47060361,
        "GCode": 470,
        "MCode": 60,
        "Vcode": 361,
        "Discontinued_Date": "2014-06-30 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "MURANO",
        "Variant": "MURANO (L20\/21\/22)",
        "MM_CODES": 47080100,
        "GCode": 470,
        "MCode": 80,
        "Vcode": 100,
        "Discontinued_Date": "2009-09-01 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "MURANO",
        "Variant": "MURANO (L24)",
        "MM_CODES": 47080102,
        "GCode": 470,
        "MCode": 80,
        "Vcode": 102,
        "Discontinued_Date": "2016-04-15 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "350 Z \/ 370 Z",
        "Variant": "370 Z COUPE A\/T",
        "MM_CODES": 47081220,
        "GCode": 470,
        "MCode": 81,
        "Vcode": 220,
        "Discontinued_Date": "2022-10-31 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "X TRAIL",
        "Variant": "X TRAIL 2.5 SE (R80\/R86)",
        "MM_CODES": 47084250,
        "GCode": 470,
        "MCode": 84,
        "Vcode": 250,
        "Discontinued_Date": "2014-09-29 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "X TRAIL",
        "Variant": "X TRAIL 2.5 CVT LE (R81\/R87)",
        "MM_CODES": 47084270,
        "GCode": 470,
        "MCode": 84,
        "Vcode": 270,
        "Discontinued_Date": "2014-09-29 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "X TRAIL",
        "Variant": "X TRAIL 2.5 ACENTA 4X4 CVT",
        "MM_CODES": 47084272,
        "GCode": 470,
        "MCode": 84,
        "Vcode": 272,
        "Discontinued_Date": "2023-03-28 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "PATHFINDER",
        "Variant": "PATHFINDER 2.5 DCI (L9\/12)",
        "MM_CODES": 47091100,
        "GCode": 470,
        "MCode": 91,
        "Vcode": 100,
        "Discontinued_Date": "2010-05-14 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "PATHFINDER\/TERRA",
        "Variant": "PATHFINDER 2.5 DCI (L9\/12)",
        "MM_CODES": 47091100,
        "GCode": 470,
        "MCode": 91,
        "Vcode": 100,
        "Discontinued_Date": "2010-05-14 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "PATHFINDER",
        "Variant": "PATHFINDER 2.5 DCI LE (L30\/38)",
        "MM_CODES": 47091101,
        "GCode": 470,
        "MCode": 91,
        "Vcode": 101,
        "Discontinued_Date": "2013-01-21 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "PATHFINDER\/TERRA",
        "Variant": "PATHFINDER 2.5 DCI LE (L30\/38)",
        "MM_CODES": 47091101,
        "GCode": 470,
        "MCode": 91,
        "Vcode": 101,
        "Discontinued_Date": "2013-01-21 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "PATHFINDER",
        "Variant": "PATHFINDER 2.5 DCI A\/T (L10\/13)",
        "MM_CODES": 47091150,
        "GCode": 470,
        "MCode": 91,
        "Vcode": 150,
        "Discontinued_Date": "2010-05-14 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "PATHFINDER\/TERRA",
        "Variant": "PATHFINDER 2.5 DCI A\/T (L10\/13)",
        "MM_CODES": 47091150,
        "GCode": 470,
        "MCode": 91,
        "Vcode": 150,
        "Discontinued_Date": "2010-05-14 00:00:00.000"
    },
    {
        "Make": "NISSAN",
        "Model": "GT-R",
        "Variant": "GT-R PREMIUM",
        "MM_CODES": 47094100,
        "GCode": 470,
        "MCode": 94,
        "Vcode": 100,
        "Discontinued_Date": "2016-10-04 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA 2002 - ON",
        "Variant": "CORSA 1.2 EDITION (55KW)",
        "MM_CODES": 48010510,
        "GCode": 480,
        "MCode": 10,
        "Vcode": 510,
        "Discontinued_Date": "2022-07-05 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA 2002 - ON",
        "Variant": "CORSA 1.2 ELEGANCE (55KW)",
        "MM_CODES": 48010515,
        "GCode": 480,
        "MCode": 10,
        "Vcode": 515,
        "Discontinued_Date": "2021-07-29 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA 2002 - ON",
        "Variant": "CORSA 1.4 COLOUR 3DR",
        "MM_CODES": 48010580,
        "GCode": 480,
        "MCode": 10,
        "Vcode": 580,
        "Discontinued_Date": "2013-10-04 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA 2002 - ON",
        "Variant": "CORSA 1.4 ESSENTIA 5DR",
        "MM_CODES": 48010600,
        "GCode": 480,
        "MCode": 10,
        "Vcode": 600,
        "Discontinued_Date": "2010-06-23 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA 2002 - ON",
        "Variant": "CORSA 1.0T ECOFLEX  ESSENTIA 5DR",
        "MM_CODES": 48010603,
        "GCode": 480,
        "MCode": 10,
        "Vcode": 603,
        "Discontinued_Date": "2020-09-30 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA 2002 - ON",
        "Variant": "CORSA 1.4T ENJOY 5DR",
        "MM_CODES": 48010626,
        "GCode": 480,
        "MCode": 10,
        "Vcode": 626,
        "Discontinued_Date": "2015-02-24 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA 2002 - ON",
        "Variant": "CORSA 1.4 ENJOY A\/T 5DR",
        "MM_CODES": 48010640,
        "GCode": 480,
        "MCode": 10,
        "Vcode": 640,
        "Discontinued_Date": "2010-02-05 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA 2002 - ON",
        "Variant": "CORSA 1.4 ENJOY A\/T 5DR",
        "MM_CODES": 48010641,
        "GCode": 480,
        "MCode": 10,
        "Vcode": 641,
        "Discontinued_Date": "2021-09-21 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA 2002 - ON",
        "Variant": "CORSA 1.0T ECOFLEX COSMO 5DR",
        "MM_CODES": 48010661,
        "GCode": 480,
        "MCode": 10,
        "Vcode": 661,
        "Discontinued_Date": "2017-12-12 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA 2002 - ON",
        "Variant": "CORSA 1.4 SPORT 3DR",
        "MM_CODES": 48010680,
        "GCode": 480,
        "MCode": 10,
        "Vcode": 680,
        "Discontinued_Date": "2009-03-25 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA 2002 - ON",
        "Variant": "CORSA 1.4 SPORT 3DR",
        "MM_CODES": 48010681,
        "GCode": 480,
        "MCode": 10,
        "Vcode": 681,
        "Discontinued_Date": "2015-02-24 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA 2002 - ON",
        "Variant": "CORSA GSI 1.4T (3DR)",
        "MM_CODES": 48010710,
        "GCode": 480,
        "MCode": 10,
        "Vcode": 710,
        "Discontinued_Date": "2022-01-17 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA 2002 - ON",
        "Variant": "CORSA 1.6 SPORT 5DR",
        "MM_CODES": 48010790,
        "GCode": 480,
        "MCode": 10,
        "Vcode": 790,
        "Discontinued_Date": "2015-02-24 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA 2002 - ON",
        "Variant": "CORSA 1.6 OPC NURBURGRING",
        "MM_CODES": 48010810,
        "GCode": 480,
        "MCode": 10,
        "Vcode": 810,
        "Discontinued_Date": "2014-01-15 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA UTILITY 2000 - 2010",
        "Variant": "CORSA UTILITY 1.4I CLUB P\/U S\/C",
        "MM_CODES": 48021320,
        "GCode": 480,
        "MCode": 21,
        "Vcode": 320,
        "Discontinued_Date": "2010-05-01 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA UTILITY 2000 - 2010",
        "Variant": "CORSA UTILITY 1.4I SPORT P\/U S\/C",
        "MM_CODES": 48021350,
        "GCode": 480,
        "MCode": 21,
        "Vcode": 350,
        "Discontinued_Date": "2010-05-01 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA UTILITY 2000 - 2010",
        "Variant": "CORSA UTILITY 1.7 DTI P\/U S\/C",
        "MM_CODES": 48021400,
        "GCode": 480,
        "MCode": 21,
        "Vcode": 400,
        "Discontinued_Date": "2010-05-01 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA UTILITY 2000 - 2010",
        "Variant": "CORSA UTILITY 1.7 DTI CLUB P\/U S\/C",
        "MM_CODES": 48021420,
        "GCode": 480,
        "MCode": 21,
        "Vcode": 420,
        "Discontinued_Date": "2010-05-01 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA UTILITY 2000 - 2010",
        "Variant": "CORSA UTILITY 1.7 DTI SPORT P\/U S\/C",
        "MM_CODES": 48021450,
        "GCode": 480,
        "MCode": 21,
        "Vcode": 450,
        "Discontinued_Date": "2010-05-01 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA UTILITY 2000 - 2010",
        "Variant": "CORSA UTILITY 1.8I P\/U S\/C",
        "MM_CODES": 48021500,
        "GCode": 480,
        "MCode": 21,
        "Vcode": 500,
        "Discontinued_Date": "2010-05-01 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA UTILITY 2000 - 2010",
        "Variant": "CORSA UTILITY 1.8I CLUB P\/U S\/C",
        "MM_CODES": 48021520,
        "GCode": 480,
        "MCode": 21,
        "Vcode": 520,
        "Discontinued_Date": "2010-05-01 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CORSA UTILITY 2000 - 2010",
        "Variant": "CORSA UTILITY 1.8 SPORT P\/U S\/C",
        "MM_CODES": 48021530,
        "GCode": 480,
        "MCode": 21,
        "Vcode": 530,
        "Discontinued_Date": "2010-05-01 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CROSSLAND X",
        "Variant": "CROSSLAND X 1.2",
        "MM_CODES": 48042200,
        "GCode": 480,
        "MCode": 42,
        "Vcode": 200,
        "Discontinued_Date": "2019-05-02 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CROSSLAND X",
        "Variant": "CROSSLAND X 1.2 ENJOY",
        "MM_CODES": 48042201,
        "GCode": 480,
        "MCode": 42,
        "Vcode": 201,
        "Discontinued_Date": "2021-05-05 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CROSSLAND X",
        "Variant": "CROSSLAND X 1.2T ENJOY A\/T",
        "MM_CODES": 48042270,
        "GCode": 480,
        "MCode": 42,
        "Vcode": 270,
        "Discontinued_Date": "2020-06-17 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CROSSLAND X",
        "Variant": "CROSSLAND X 1.2T COSMO A\/T",
        "MM_CODES": 48042290,
        "GCode": 480,
        "MCode": 42,
        "Vcode": 290,
        "Discontinued_Date": "2021-05-05 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "CROSSLAND X",
        "Variant": "CROSSLAND X 1.6TD ENJOY",
        "MM_CODES": 48042360,
        "GCode": 480,
        "MCode": 42,
        "Vcode": 360,
        "Discontinued_Date": "2021-05-05 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2004 - ON",
        "Variant": "ASTRA 1.4T ENJOY PLUS 5DR",
        "MM_CODES": 48043110,
        "GCode": 480,
        "MCode": 43,
        "Vcode": 110,
        "Discontinued_Date": "2015-12-15 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2004 - ON",
        "Variant": "ASTRA 1.4T ESSENTIA",
        "MM_CODES": 48043115,
        "GCode": 480,
        "MCode": 43,
        "Vcode": 115,
        "Discontinued_Date": "2016-04-07 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2004 - ON",
        "Variant": "ASTRA 1.4T ESSENTIA A\/T",
        "MM_CODES": 48043120,
        "GCode": 480,
        "MCode": 43,
        "Vcode": 120,
        "Discontinued_Date": "2014-03-18 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2004 - ON",
        "Variant": "ASTRA 1.4T ENJOY",
        "MM_CODES": 48043125,
        "GCode": 480,
        "MCode": 43,
        "Vcode": 125,
        "Discontinued_Date": "2016-04-07 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2004 - ON",
        "Variant": "ASTRA 1.6 ESSENTIA 5DR",
        "MM_CODES": 48043150,
        "GCode": 480,
        "MCode": 43,
        "Vcode": 150,
        "Discontinued_Date": "2010-09-27 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2004 - ON",
        "Variant": "ASTRA 1.6 ESSENTIA 5DR",
        "MM_CODES": 48043151,
        "GCode": 480,
        "MCode": 43,
        "Vcode": 151,
        "Discontinued_Date": "2016-04-07 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2004 - ON",
        "Variant": "ASTRA GTC 1.6T SPORT 3DR",
        "MM_CODES": 48043195,
        "GCode": 480,
        "MCode": 43,
        "Vcode": 195,
        "Discontinued_Date": "2016-04-07 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2004 - ON",
        "Variant": "ASTRA 2.0 OPC PLUS PACK",
        "MM_CODES": 48043700,
        "GCode": 480,
        "MCode": 43,
        "Vcode": 700,
        "Discontinued_Date": "2010-09-27 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2016",
        "Variant": "ASTRA 1.0T ESSENTIA (5DR)",
        "MM_CODES": 48044100,
        "GCode": 480,
        "MCode": 44,
        "Vcode": 100,
        "Discontinued_Date": "2021-05-05 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2016",
        "Variant": "ASTRA 1.0T ENJOY (5DR)",
        "MM_CODES": 48044140,
        "GCode": 480,
        "MCode": 44,
        "Vcode": 140,
        "Discontinued_Date": "2021-05-05 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2016",
        "Variant": "ASTRA 1.4T ENJOY A\/T (5DR)",
        "MM_CODES": 48044250,
        "GCode": 480,
        "MCode": 44,
        "Vcode": 250,
        "Discontinued_Date": "2021-05-05 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2016",
        "Variant": "ASTRA 1.4T SPORT (5DR)",
        "MM_CODES": 48044280,
        "GCode": 480,
        "MCode": 44,
        "Vcode": 280,
        "Discontinued_Date": "2019-09-03 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2016",
        "Variant": "ASTRA 1.4T SPORT A\/T (5DR)",
        "MM_CODES": 48044290,
        "GCode": 480,
        "MCode": 44,
        "Vcode": 290,
        "Discontinued_Date": "2019-09-03 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2016",
        "Variant": "ASTRA 1.6T SPORT(5DR)",
        "MM_CODES": 48044440,
        "GCode": 480,
        "MCode": 44,
        "Vcode": 440,
        "Discontinued_Date": "2019-09-03 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "ASTRA 2016",
        "Variant": "ASTRA 1.6T SPORT A\/T (5DR)",
        "MM_CODES": 48044450,
        "GCode": 480,
        "MCode": 44,
        "Vcode": 450,
        "Discontinued_Date": "2021-05-05 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "MOKKA",
        "Variant": "MOKKA 1.4T ENJOY",
        "MM_CODES": 48045220,
        "GCode": 480,
        "MCode": 45,
        "Vcode": 220,
        "Discontinued_Date": "2019-09-03 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "MOKKA",
        "Variant": "MOKKA 1.4T ENJOY A\/T",
        "MM_CODES": 48045230,
        "GCode": 480,
        "MCode": 45,
        "Vcode": 230,
        "Discontinued_Date": "2021-04-01 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "MOKKA",
        "Variant": "MOKKA \/ MOKKA X 1.4T COSMO",
        "MM_CODES": 48045240,
        "GCode": 480,
        "MCode": 45,
        "Vcode": 240,
        "Discontinued_Date": "2019-06-06 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "MOKKA",
        "Variant": "MOKKA X 1.4T COSMO A\/T",
        "MM_CODES": 48045250,
        "GCode": 480,
        "MCode": 45,
        "Vcode": 250,
        "Discontinued_Date": "2021-04-01 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "GRANDLAND X",
        "Variant": "GRANDLAND X 1.6T ENJOY A\/T",
        "MM_CODES": 48048280,
        "GCode": 480,
        "MCode": 48,
        "Vcode": 280,
        "Discontinued_Date": "2023-07-06 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "VIVARO",
        "Variant": "VIVARO 1.9 CDTI F\/C P\/V",
        "MM_CODES": 48059200,
        "GCode": 480,
        "MCode": 59,
        "Vcode": 200,
        "Discontinued_Date": "2015-11-02 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "VIVARO",
        "Variant": "VIVARO 1.9 CDTI HIGH F\/C P\/V",
        "MM_CODES": 48059220,
        "GCode": 480,
        "MCode": 59,
        "Vcode": 220,
        "Discontinued_Date": "2013-01-09 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "VIVARO",
        "Variant": "VIVARO 2.0 F\/C P\/V",
        "MM_CODES": 48059300,
        "GCode": 480,
        "MCode": 59,
        "Vcode": 300,
        "Discontinued_Date": "2015-11-02 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "VIVARO",
        "Variant": "VIVARO 2.0 HIGH F\/C P\/V",
        "MM_CODES": 48059320,
        "GCode": 480,
        "MCode": 59,
        "Vcode": 320,
        "Discontinued_Date": "2010-10-11 00:00:00.000"
    },
    {
        "Make": "OPEL",
        "Model": "VIVARO",
        "Variant": "VIVARO 1.9 CDTI BUS",
        "MM_CODES": 48059500,
        "GCode": 480,
        "MCode": 59,
        "Vcode": 500,
        "Discontinued_Date": "2016-11-28 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "BOXER",
        "Variant": "BOXER L2H2 2.2 HDI MH F\/C P\/V",
        "MM_CODES": 50010680,
        "GCode": 500,
        "MCode": 10,
        "Vcode": 680,
        "Discontinued_Date": "2012-11-14 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "BOXER",
        "Variant": "BOXER L3H2 2.2 HDI LH F\/C P\/V",
        "MM_CODES": 50010700,
        "GCode": 500,
        "MCode": 10,
        "Vcode": 700,
        "Discontinued_Date": "2014-05-13 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "BOXER",
        "Variant": "BOXER L4H2 2.2 HDI XLH4 F\/C P\/V",
        "MM_CODES": 50010720,
        "GCode": 500,
        "MCode": 10,
        "Vcode": 720,
        "Discontinued_Date": "2016-02-17 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "BOXER",
        "Variant": "BOXER 3.0 HDI MH F\/C C\/C",
        "MM_CODES": 50010750,
        "GCode": 500,
        "MCode": 10,
        "Vcode": 750,
        "Discontinued_Date": "2012-11-14 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "BOXER",
        "Variant": "BOXER L3H2 3.0 HDI LH F\/C P\/V",
        "MM_CODES": 50010760,
        "GCode": 500,
        "MCode": 10,
        "Vcode": 760,
        "Discontinued_Date": "2012-11-14 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "BOXER",
        "Variant": "BOXER L4H2 3.0 HDI XLH4 F\/C P\/V",
        "MM_CODES": 50010900,
        "GCode": 500,
        "MCode": 10,
        "Vcode": 900,
        "Discontinued_Date": "2019-01-08 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "107",
        "Variant": "107 URBAN",
        "MM_CODES": 50011200,
        "GCode": 500,
        "MCode": 11,
        "Vcode": 200,
        "Discontinued_Date": "2016-11-28 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "107 \/108",
        "Variant": "107 URBAN",
        "MM_CODES": 50011200,
        "GCode": 500,
        "MCode": 11,
        "Vcode": 200,
        "Discontinued_Date": "2016-11-28 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "107",
        "Variant": "107 TRENDY",
        "MM_CODES": 50011210,
        "GCode": 500,
        "MCode": 11,
        "Vcode": 210,
        "Discontinued_Date": "2016-11-28 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "107 \/108",
        "Variant": "107 TRENDY",
        "MM_CODES": 50011210,
        "GCode": 500,
        "MCode": 11,
        "Vcode": 210,
        "Discontinued_Date": "2016-11-28 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "107 \/108",
        "Variant": "108 1.0 THP ACTIVE",
        "MM_CODES": 50011350,
        "GCode": 500,
        "MCode": 11,
        "Vcode": 350,
        "Discontinued_Date": "2022-10-31 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "207",
        "Variant": "207 1.4 VVT ACTIVE",
        "MM_CODES": 50017101,
        "GCode": 500,
        "MCode": 17,
        "Vcode": 101,
        "Discontinued_Date": "2015-10-06 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "207",
        "Variant": "207 1.6 HDI DYNAMIC",
        "MM_CODES": 50017301,
        "GCode": 500,
        "MCode": 17,
        "Vcode": 301,
        "Discontinued_Date": "2012-11-14 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "207",
        "Variant": "207 1.6 HDI SPORTIUM 5DR",
        "MM_CODES": 50017305,
        "GCode": 500,
        "MCode": 17,
        "Vcode": 305,
        "Discontinued_Date": "2012-11-14 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "207",
        "Variant": "207 1.6 VTI DYNAMIC",
        "MM_CODES": 50017401,
        "GCode": 500,
        "MCode": 17,
        "Vcode": 401,
        "Discontinued_Date": "2012-11-14 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "207",
        "Variant": "207 1.6 VTI SPORTIUM 5DR",
        "MM_CODES": 50017408,
        "GCode": 500,
        "MCode": 17,
        "Vcode": 408,
        "Discontinued_Date": "2012-11-14 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "207",
        "Variant": "207 1.6 HDI DYNAMIC",
        "MM_CODES": 50017420,
        "GCode": 500,
        "MCode": 17,
        "Vcode": 420,
        "Discontinued_Date": "2010-08-19 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "207",
        "Variant": "207 1.6 GTI",
        "MM_CODES": 50017500,
        "GCode": 500,
        "MCode": 17,
        "Vcode": 500,
        "Discontinued_Date": "2012-11-14 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "207",
        "Variant": "207 1.6 SPORT II CC",
        "MM_CODES": 50017561,
        "GCode": 500,
        "MCode": 17,
        "Vcode": 561,
        "Discontinued_Date": "2016-05-10 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "208",
        "Variant": "208 1.2 VTI  ACCESS 5DR",
        "MM_CODES": 50018100,
        "GCode": 500,
        "MCode": 18,
        "Vcode": 100,
        "Discontinued_Date": "2016-11-28 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "208",
        "Variant": "208 1.2 VTI  ACTIVE 5DR",
        "MM_CODES": 50018120,
        "GCode": 500,
        "MCode": 18,
        "Vcode": 120,
        "Discontinued_Date": "2016-09-28 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "208",
        "Variant": "208 ACTIVE 1.2 PURETECH 5DR",
        "MM_CODES": 50018121,
        "GCode": 500,
        "MCode": 18,
        "Vcode": 121,
        "Discontinued_Date": "2021-04-26 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "208",
        "Variant": "208 ALLURE 1.2 PURETECH 5DR",
        "MM_CODES": 50018130,
        "GCode": 500,
        "MCode": 18,
        "Vcode": 130,
        "Discontinued_Date": "2021-04-26 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "208",
        "Variant": "208 1.2T PURETECH GT LINE 5DR",
        "MM_CODES": 50018210,
        "GCode": 500,
        "MCode": 18,
        "Vcode": 210,
        "Discontinued_Date": "2021-12-21 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "208",
        "Variant": "208 1.2T PURETECH GT LINE A\/T 5DR",
        "MM_CODES": 50018220,
        "GCode": 500,
        "MCode": 18,
        "Vcode": 220,
        "Discontinued_Date": "2021-12-15 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "208",
        "Variant": "208 1.6 VTI  ACTIVE A\/T 5DR",
        "MM_CODES": 50018290,
        "GCode": 500,
        "MCode": 18,
        "Vcode": 290,
        "Discontinued_Date": "2016-07-19 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "2008",
        "Variant": "2008 1.2T PURETECH GT-LINE+ A\/T",
        "MM_CODES": 50022160,
        "GCode": 500,
        "MCode": 22,
        "Vcode": 160,
        "Discontinued_Date": "2019-05-07 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "2008",
        "Variant": "2008 1.6 HDI ACTIVE ",
        "MM_CODES": 50022250,
        "GCode": 500,
        "MCode": 22,
        "Vcode": 250,
        "Discontinued_Date": "2019-11-11 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "2008",
        "Variant": "2008 1.6 HDI ALLURE",
        "MM_CODES": 50022260,
        "GCode": 500,
        "MCode": 22,
        "Vcode": 260,
        "Discontinued_Date": "2019-11-11 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "308",
        "Variant": "308 1.6 PREMIUM",
        "MM_CODES": 50032141,
        "GCode": 500,
        "MCode": 32,
        "Vcode": 141,
        "Discontinued_Date": "2014-05-13 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "308",
        "Variant": "308 1.6 PREMIUM \/ACTIVE  A\/T",
        "MM_CODES": 50032185,
        "GCode": 500,
        "MCode": 32,
        "Vcode": 185,
        "Discontinued_Date": "2014-05-13 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "308",
        "Variant": "308 1.6 THP CC",
        "MM_CODES": 50032251,
        "GCode": 500,
        "MCode": 32,
        "Vcode": 251,
        "Discontinued_Date": "2012-08-28 00:00:00.000"
    },
    {
        "Make": "PEUGEOT",
        "Model": "308",
        "Variant": "308 1.6 THP GTI 5DR",
        "MM_CODES": 50032295,
        "GCode": 500,
        "MCode": 32,
        "Vcode": 295,
        "Discontinued_Date": "2014-05-13 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "BOXSTER\/718",
        "Variant": "718 BOXTER GTS PDK",
        "MM_CODES": 50550471,
        "GCode": 505,
        "MCode": 50,
        "Vcode": 471,
        "Discontinued_Date": "2020-08-05 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYMAN",
        "Variant": "CAYMAN PDK",
        "MM_CODES": 50554112,
        "GCode": 505,
        "MCode": 54,
        "Vcode": 112,
        "Discontinued_Date": "2016-08-02 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYMAN",
        "Variant": "CAYMAN S PDK",
        "MM_CODES": 50554211,
        "GCode": 505,
        "MCode": 54,
        "Vcode": 211,
        "Discontinued_Date": "2013-04-02 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYMAN",
        "Variant": "718 CAYMAN S PDK ",
        "MM_CODES": 50554213,
        "GCode": 505,
        "MCode": 54,
        "Vcode": 213,
        "Discontinued_Date": "2020-11-26 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYMAN",
        "Variant": "CAYMAN R PDK",
        "MM_CODES": 50554250,
        "GCode": 505,
        "MCode": 54,
        "Vcode": 250,
        "Discontinued_Date": "2013-04-02 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYMAN",
        "Variant": "CAYMAN GTS PDK (981)",
        "MM_CODES": 50554270,
        "GCode": 505,
        "MCode": 54,
        "Vcode": 270,
        "Discontinued_Date": "2017-06-07 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYMAN",
        "Variant": "718 CAYMAN GTS PDK",
        "MM_CODES": 50554271,
        "GCode": 505,
        "MCode": 54,
        "Vcode": 271,
        "Discontinued_Date": "2020-08-19 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYMAN",
        "Variant": "CAYMAN GT4 (981)",
        "MM_CODES": 50554280,
        "GCode": 505,
        "MCode": 54,
        "Vcode": 280,
        "Discontinued_Date": "2017-06-07 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "911 (996)(997)",
        "Variant": "911 TARGA 4S PDK (991)",
        "MM_CODES": 50565253,
        "GCode": 505,
        "MCode": 65,
        "Vcode": 253,
        "Discontinued_Date": "2019-04-03 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "911 (996)(997)",
        "Variant": "911 CARRERA 4S PDK (991 GEN2)",
        "MM_CODES": 50565278,
        "GCode": 505,
        "MCode": 65,
        "Vcode": 278,
        "Discontinued_Date": "2019-02-21 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "911 (996)(997)",
        "Variant": "911 CARRERA GTS PDK (991 GEN2)",
        "MM_CODES": 50565462,
        "GCode": 505,
        "MCode": 65,
        "Vcode": 462,
        "Discontinued_Date": "2019-04-03 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "911 (996)(997)",
        "Variant": "911 CARRERA GTS CABRIOLET PDK (991)",
        "MM_CODES": 50565472,
        "GCode": 505,
        "MCode": 65,
        "Vcode": 472,
        "Discontinued_Date": "2019-04-03 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "911 (996)(997)",
        "Variant": "911 GT3 (991 GEN2)",
        "MM_CODES": 50565518,
        "GCode": 505,
        "MCode": 65,
        "Vcode": 518,
        "Discontinued_Date": "2023-03-17 07:54:50.057"
    },
    {
        "Make": "PORSCHE",
        "Model": "911 (996)(997)",
        "Variant": "911 GT3 RS (991)",
        "MM_CODES": 50565528,
        "GCode": 505,
        "MCode": 65,
        "Vcode": 528,
        "Discontinued_Date": "2018-08-28 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "911 (996)(997)",
        "Variant": "911 TURBO S PDK (991)",
        "MM_CODES": 50565647,
        "GCode": 505,
        "MCode": 65,
        "Vcode": 647,
        "Discontinued_Date": "2023-03-17 07:54:52.523"
    },
    {
        "Make": "PORSCHE",
        "Model": "911 (996)(997)",
        "Variant": "911 TURBO S CABRIOLET PDK",
        "MM_CODES": 50565670,
        "GCode": 505,
        "MCode": 65,
        "Vcode": 670,
        "Discontinued_Date": "2013-11-22 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "911 (996)(997)",
        "Variant": "911 GT2 RS",
        "MM_CODES": 50565720,
        "GCode": 505,
        "MCode": 65,
        "Vcode": 720,
        "Discontinued_Date": "2012-11-27 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "PANAMERA",
        "Variant": "PANAMERA S PDK",
        "MM_CODES": 50572101,
        "GCode": 505,
        "MCode": 72,
        "Vcode": 101,
        "Discontinued_Date": "2016-12-06 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "PANAMERA",
        "Variant": "PANAMERA 4S PDK",
        "MM_CODES": 50572130,
        "GCode": 505,
        "MCode": 72,
        "Vcode": 130,
        "Discontinued_Date": "2013-08-02 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "PANAMERA",
        "Variant": "PANAMERA 4S PDK",
        "MM_CODES": 50572131,
        "GCode": 505,
        "MCode": 72,
        "Vcode": 131,
        "Discontinued_Date": "2016-11-18 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "PANAMERA",
        "Variant": "PANAMERA 4S PDK",
        "MM_CODES": 50572132,
        "GCode": 505,
        "MCode": 72,
        "Vcode": 132,
        "Discontinued_Date": "2021-02-04 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "PANAMERA",
        "Variant": "PANAMERA S HYBRID TIP",
        "MM_CODES": 50572140,
        "GCode": 505,
        "MCode": 72,
        "Vcode": 140,
        "Discontinued_Date": "2013-08-02 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "PANAMERA",
        "Variant": "PANAMERA GTS PDK",
        "MM_CODES": 50572151,
        "GCode": 505,
        "MCode": 72,
        "Vcode": 151,
        "Discontinued_Date": "2017-03-03 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "PANAMERA",
        "Variant": "PANAMERA GTS",
        "MM_CODES": 50572152,
        "GCode": 505,
        "MCode": 72,
        "Vcode": 152,
        "Discontinued_Date": "2021-02-04 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "PANAMERA",
        "Variant": "PANAMERA TURBO PDK",
        "MM_CODES": 50572160,
        "GCode": 505,
        "MCode": 72,
        "Vcode": 160,
        "Discontinued_Date": "2013-08-02 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "PANAMERA",
        "Variant": "PANAMERA TURBO S PDK",
        "MM_CODES": 50572170,
        "GCode": 505,
        "MCode": 72,
        "Vcode": 170,
        "Discontinued_Date": "2013-08-02 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "PANAMERA",
        "Variant": "PANAMERA DIESEL TIP",
        "MM_CODES": 50572300,
        "GCode": 505,
        "MCode": 72,
        "Vcode": 300,
        "Discontinued_Date": "2013-08-02 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "PANAMERA",
        "Variant": "PANAMERA DIESEL TIP (220KW)",
        "MM_CODES": 50572306,
        "GCode": 505,
        "MCode": 72,
        "Vcode": 306,
        "Discontinued_Date": "2017-03-03 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "PANAMERA",
        "Variant": "PANAMERA DIESEL TIP PLATINUM EDITION",
        "MM_CODES": 50572310,
        "GCode": 505,
        "MCode": 72,
        "Vcode": 310,
        "Discontinued_Date": "2013-08-02 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "PANAMERA",
        "Variant": "PANAMERA  DIESEL EDITION",
        "MM_CODES": 50572312,
        "GCode": 505,
        "MCode": 72,
        "Vcode": 312,
        "Discontinued_Date": "2017-03-03 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "PANAMERA",
        "Variant": "PANAMERA 4 SPORT TURISMO",
        "MM_CODES": 50572550,
        "GCode": 505,
        "MCode": 72,
        "Vcode": 550,
        "Discontinued_Date": "2023-03-17 07:40:27.970"
    },
    {
        "Make": "PORSCHE",
        "Model": "MACAN",
        "Variant": "MACAN S DIESEL",
        "MM_CODES": 50575310,
        "GCode": 505,
        "MCode": 75,
        "Vcode": 310,
        "Discontinued_Date": "2019-01-02 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "MACAN",
        "Variant": "MACAN",
        "MM_CODES": 50575350,
        "GCode": 505,
        "MCode": 75,
        "Vcode": 350,
        "Discontinued_Date": "2021-09-29 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "MACAN",
        "Variant": "MACAN GTS",
        "MM_CODES": 50575380,
        "GCode": 505,
        "MCode": 75,
        "Vcode": 380,
        "Discontinued_Date": "2019-01-02 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "MACAN",
        "Variant": "MACAN TURBO",
        "MM_CODES": 50575400,
        "GCode": 505,
        "MCode": 75,
        "Vcode": 400,
        "Discontinued_Date": "2019-01-02 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "MACAN",
        "Variant": "MACAN TURBO",
        "MM_CODES": 50575401,
        "GCode": 505,
        "MCode": 75,
        "Vcode": 401,
        "Discontinued_Date": "2022-08-23 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYENNE (80)",
        "Variant": "CAYENNE S TIPTRONIC (E2)",
        "MM_CODES": 50580102,
        "GCode": 505,
        "MCode": 80,
        "Vcode": 102,
        "Discontinued_Date": "2014-10-22 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYENNE (80)",
        "Variant": "CAYENNE S II (E2)",
        "MM_CODES": 50580103,
        "GCode": 505,
        "MCode": 80,
        "Vcode": 103,
        "Discontinued_Date": "2018-03-23 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYENNE (80)",
        "Variant": "CAYENNE II GTS TIP (E2)",
        "MM_CODES": 50580162,
        "GCode": 505,
        "MCode": 80,
        "Vcode": 162,
        "Discontinued_Date": "2018-07-26 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYENNE (80)",
        "Variant": "CAYENNE II TURBO (E2)",
        "MM_CODES": 50580253,
        "GCode": 505,
        "MCode": 80,
        "Vcode": 253,
        "Discontinued_Date": "2018-03-23 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYENNE (80)",
        "Variant": "CAYENNE TURBO S TIP (E2)",
        "MM_CODES": 50580302,
        "GCode": 505,
        "MCode": 80,
        "Vcode": 302,
        "Discontinued_Date": "2014-12-08 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYENNE (80)",
        "Variant": "CAYENNE II TURBO S TIP (E2)",
        "MM_CODES": 50580303,
        "GCode": 505,
        "MCode": 80,
        "Vcode": 303,
        "Discontinued_Date": "2018-07-26 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYENNE (80)",
        "Variant": "CAYENNE S HYBRID TIP (E2)",
        "MM_CODES": 50580400,
        "GCode": 505,
        "MCode": 80,
        "Vcode": 400,
        "Discontinued_Date": "2014-10-22 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYENNE (80)",
        "Variant": "CAYENNE S E-HYBRID II (E2)",
        "MM_CODES": 50580401,
        "GCode": 505,
        "MCode": 80,
        "Vcode": 401,
        "Discontinued_Date": "2018-07-26 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYENNE COUPE",
        "Variant": "CAYENNE COUPE",
        "MM_CODES": 50585200,
        "GCode": 505,
        "MCode": 85,
        "Vcode": 200,
        "Discontinued_Date": "2023-06-28 00:00:00.000"
    },
    {
        "Make": "PORSCHE",
        "Model": "CAYENNE COUPE",
        "Variant": "CAYENNE S COUPE",
        "MM_CODES": 50585220,
        "GCode": 505,
        "MCode": 85,
        "Vcode": 220,
        "Discontinued_Date": "2023-06-28 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KANGOO",
        "Variant": "KANGOO 1.5 DCI EXPRESS F\/C P\/V",
        "MM_CODES": 54010200,
        "GCode": 540,
        "MCode": 10,
        "Vcode": 200,
        "Discontinued_Date": "2013-05-14 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KANGOO",
        "Variant": "KANGOO 1.6I EXPRESS F\/C P\/V",
        "MM_CODES": 54010350,
        "GCode": 540,
        "MCode": 10,
        "Vcode": 350,
        "Discontinued_Date": "2013-12-10 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KANGOO",
        "Variant": "KANGOO 1.6I EXPRESS F\/C P\/V",
        "MM_CODES": 54010351,
        "GCode": 540,
        "MCode": 10,
        "Vcode": 351,
        "Discontinued_Date": "2023-07-20 07:20:10.870"
    },
    {
        "Make": "RENAULT",
        "Model": "LOGAN",
        "Variant": "LOGAN 1.6 EXPRESSION",
        "MM_CODES": 54016100,
        "GCode": 540,
        "MCode": 16,
        "Vcode": 100,
        "Discontinued_Date": "2012-09-26 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "TWINGO",
        "Variant": "TWINGO 1.2 DYNAMIQUE",
        "MM_CODES": 54024160,
        "GCode": 540,
        "MCode": 24,
        "Vcode": 160,
        "Discontinued_Date": "2010-05-06 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "TWINGO",
        "Variant": "TWINGO 1.2 DYNAMIQUE PLUS",
        "MM_CODES": 54024170,
        "GCode": 540,
        "MCode": 24,
        "Vcode": 170,
        "Discontinued_Date": "2012-11-30 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "TWINGO",
        "Variant": "TWINGO 1.6 SPORT",
        "MM_CODES": 54024250,
        "GCode": 540,
        "MCode": 24,
        "Vcode": 250,
        "Discontinued_Date": "2012-01-24 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "TWINGO",
        "Variant": "TWINGO 1.6 SPORT GORDINI",
        "MM_CODES": 54024260,
        "GCode": 540,
        "MCode": 24,
        "Vcode": 260,
        "Discontinued_Date": "2012-11-30 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KWID",
        "Variant": "KWID 1.0 EXPRESSION 5DR",
        "MM_CODES": 54025120,
        "GCode": 540,
        "MCode": 25,
        "Vcode": 120,
        "Discontinued_Date": "2020-08-04 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KWID",
        "Variant": "KWID 1.0 EXPRESSION 5DR AMT",
        "MM_CODES": 54025125,
        "GCode": 540,
        "MCode": 25,
        "Vcode": 125,
        "Discontinued_Date": "2021-10-02 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KWID",
        "Variant": "KWID 1.0 DYNAMIQUE 5DR",
        "MM_CODES": 54025130,
        "GCode": 540,
        "MCode": 25,
        "Vcode": 130,
        "Discontinued_Date": "2020-08-04 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KWID",
        "Variant": "KWID 1.0 DYNAMIQUE 5DR A\/T",
        "MM_CODES": 54025140,
        "GCode": 540,
        "MCode": 25,
        "Vcode": 140,
        "Discontinued_Date": "2020-08-04 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KWID",
        "Variant": "KWID 1.0 CLIMBER 5DR",
        "MM_CODES": 54025150,
        "GCode": 540,
        "MCode": 25,
        "Vcode": 150,
        "Discontinued_Date": "2020-08-04 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "TRIBER",
        "Variant": "TRIBER 1.0 EXPRESSION",
        "MM_CODES": 54027110,
        "GCode": 540,
        "MCode": 27,
        "Vcode": 110,
        "Discontinued_Date": "1899-12-30 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "TRAFIC",
        "Variant": "TRAFIC 1.6 DCI F\/C P\/C",
        "MM_CODES": 54028120,
        "GCode": 540,
        "MCode": 28,
        "Vcode": 120,
        "Discontinued_Date": "2023-07-20 07:18:29.247"
    },
    {
        "Make": "RENAULT",
        "Model": "TRAFIC",
        "Variant": "TRAFIC 1.9DCI F\/C P\/V",
        "MM_CODES": 54028201,
        "GCode": 540,
        "MCode": 28,
        "Vcode": 201,
        "Discontinued_Date": "2016-03-01 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "SANDERO",
        "Variant": "SANDERO 1.6 STEPWAY",
        "MM_CODES": 54033110,
        "GCode": 540,
        "MCode": 33,
        "Vcode": 110,
        "Discontinued_Date": "2015-02-19 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "SANDERO",
        "Variant": "SANDERO 900 T EXPRESSION",
        "MM_CODES": 54033200,
        "GCode": 540,
        "MCode": 33,
        "Vcode": 200,
        "Discontinued_Date": "2021-09-08 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "SANDERO",
        "Variant": "SANDERO 900 T DYNAMIQUE",
        "MM_CODES": 54033230,
        "GCode": 540,
        "MCode": 33,
        "Vcode": 230,
        "Discontinued_Date": "2017-11-01 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "DUSTER",
        "Variant": "DUSTER 1.5 DCI DYNAMIQUE",
        "MM_CODES": 54037151,
        "GCode": 540,
        "MCode": 37,
        "Vcode": 151,
        "Discontinued_Date": "2020-05-06 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "DUSTER",
        "Variant": "DUSTER 1.5 DCI TECHROD EDC",
        "MM_CODES": 54037157,
        "GCode": 540,
        "MCode": 37,
        "Vcode": 157,
        "Discontinued_Date": "2021-12-21 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "DUSTER",
        "Variant": "DUSTER 1.5 DCI DYNAMIQUE 4X4",
        "MM_CODES": 54037160,
        "GCode": 540,
        "MCode": 37,
        "Vcode": 160,
        "Discontinued_Date": "2018-09-13 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "DUSTER",
        "Variant": "DUSTER 1.6 DYNAMIQUE",
        "MM_CODES": 54037190,
        "GCode": 540,
        "MCode": 37,
        "Vcode": 190,
        "Discontinued_Date": "2018-09-13 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO IV 1.2 AUTHENTIQUE 5DR (55KW)",
        "MM_CODES": 54042365,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 365,
        "Discontinued_Date": "2017-03-16 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO IV 1.2T EXPRESSION EDC 5DR (88KW)",
        "MM_CODES": 54042380,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 380,
        "Discontinued_Date": "2021-11-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO IV 1.2T GT- LINE (88KW)",
        "MM_CODES": 54042390,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 390,
        "Discontinued_Date": "2019-05-07 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO III 1.4 EXTREME 3DR",
        "MM_CODES": 54042402,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 402,
        "Discontinued_Date": "2009-08-20 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO III 1.4 EXTREME 5DR",
        "MM_CODES": 54042442,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 442,
        "Discontinued_Date": "2009-08-20 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO III 1.6 YAHOO 5DR",
        "MM_CODES": 54042665,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 665,
        "Discontinued_Date": "2014-11-04 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO III 1.6 YAHOO PLUS 5DR",
        "MM_CODES": 54042668,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 668,
        "Discontinued_Date": "2014-11-25 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO III 1.6 S 5DR",
        "MM_CODES": 54042675,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 675,
        "Discontinued_Date": "2014-11-25 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO III 1.6 AVANTAGE 5DR",
        "MM_CODES": 54042680,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 680,
        "Discontinued_Date": "2014-02-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO III 1.6 AVANTAGE A\/T 5DR",
        "MM_CODES": 54042685,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 685,
        "Discontinued_Date": "2014-02-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO III 1.6 DYNAMIQUE 5DR",
        "MM_CODES": 54042701,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 701,
        "Discontinued_Date": "2013-02-04 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO III 1.6 DYNAMIQUE 5DR A\/T",
        "MM_CODES": 54042710,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 710,
        "Discontinued_Date": "2012-01-24 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO IV 1.6 RS 200 EDC LUX",
        "MM_CODES": 54042750,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 750,
        "Discontinued_Date": "2015-11-10 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO IV 1.6 RS 200 EDC LUX",
        "MM_CODES": 54042751,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 751,
        "Discontinued_Date": "2019-01-10 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO IV 1.6 RS 200 EDC CUP",
        "MM_CODES": 54042760,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 760,
        "Discontinued_Date": "2017-05-19 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO IV 1.6 RS 220 EDC TROPHY",
        "MM_CODES": 54042770,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 770,
        "Discontinued_Date": "2018-05-25 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO IV RS 18 F1 EDC",
        "MM_CODES": 54042773,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 773,
        "Discontinued_Date": "2021-11-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO III 2.0 SPORT 3DR",
        "MM_CODES": 54042801,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 801,
        "Discontinued_Date": "2013-02-04 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO III 2.0 RS 20TH EDITION 3DR",
        "MM_CODES": 54042810,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 810,
        "Discontinued_Date": "2012-01-24 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO III 2.0 GORDINI RENAULT SPORT 3DR",
        "MM_CODES": 54042812,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 812,
        "Discontinued_Date": "2013-02-04 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CLIO",
        "Variant": "CLIO III 2.0 SPORT RED BULL ED 3DR",
        "MM_CODES": 54042814,
        "GCode": 540,
        "MCode": 42,
        "Vcode": 814,
        "Discontinued_Date": "2014-02-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CAPTUR",
        "Variant": "CAPTUR 900T BLAZE 5DR",
        "MM_CODES": 54046140,
        "GCode": 540,
        "MCode": 46,
        "Vcode": 140,
        "Discontinued_Date": "2021-11-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CAPTUR",
        "Variant": "CAPTUR 900T EXPRESSION 5DR (66KW)",
        "MM_CODES": 54046150,
        "GCode": 540,
        "MCode": 46,
        "Vcode": 150,
        "Discontinued_Date": "2017-11-01 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CAPTUR",
        "Variant": "CAPTUR 900T DYNAMIQUE 5DR (66KW)",
        "MM_CODES": 54046160,
        "GCode": 540,
        "MCode": 46,
        "Vcode": 160,
        "Discontinued_Date": "2021-11-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CAPTUR",
        "Variant": "CAPTUR 1.2T DYNAMIQUE EDC 5DR (88KW)",
        "MM_CODES": 54046190,
        "GCode": 540,
        "MCode": 46,
        "Vcode": 190,
        "Discontinued_Date": "2021-11-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "CAPTUR",
        "Variant": "CAPTUR 1.5 DCI DYNAMIQUE",
        "MM_CODES": 54046300,
        "GCode": 540,
        "MCode": 46,
        "Vcode": 300,
        "Discontinued_Date": "2021-11-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "FLUENCE",
        "Variant": "FLUENCE 1.6 AUTHENTIQUE",
        "MM_CODES": 54047180,
        "GCode": 540,
        "MCode": 47,
        "Vcode": 180,
        "Discontinued_Date": "2013-05-10 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "FLUENCE",
        "Variant": "FLUENCE 1.6 EXPRESSION",
        "MM_CODES": 54047200,
        "GCode": 540,
        "MCode": 47,
        "Vcode": 200,
        "Discontinued_Date": "2013-05-10 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "FLUENCE",
        "Variant": "FLUENCE 1.6 EXPRESSION",
        "MM_CODES": 54047201,
        "GCode": 540,
        "MCode": 47,
        "Vcode": 201,
        "Discontinued_Date": "2016-11-25 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "FLUENCE",
        "Variant": "FLUENCE 1.6 DYNAMIQUE",
        "MM_CODES": 54047220,
        "GCode": 540,
        "MCode": 47,
        "Vcode": 220,
        "Discontinued_Date": "2013-05-10 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "FLUENCE",
        "Variant": "FLUENCE 2.0 PRIVILEGE",
        "MM_CODES": 54047350,
        "GCode": 540,
        "MCode": 47,
        "Vcode": 350,
        "Discontinued_Date": "2013-05-10 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "FLUENCE",
        "Variant": "FLUENCE 2.0 DYNAMIQUE",
        "MM_CODES": 54047360,
        "GCode": 540,
        "MCode": 47,
        "Vcode": 360,
        "Discontinued_Date": "2016-11-25 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "FLUENCE",
        "Variant": "FLUENCE 2.0 DYNAMIQUE CVT",
        "MM_CODES": 54047370,
        "GCode": 540,
        "MCode": 47,
        "Vcode": 370,
        "Discontinued_Date": "2016-11-25 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE I \/ II 2001 - 2009",
        "Variant": "MEGANE II 1.6 SHAKE IT 5DR",
        "MM_CODES": 54056545,
        "GCode": 540,
        "MCode": 56,
        "Vcode": 545,
        "Discontinued_Date": "2009-09-23 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE I \/ II 2001 - 2009",
        "Variant": "MEGANE II 2.0T SPORT 5DR",
        "MM_CODES": 54056830,
        "GCode": 540,
        "MCode": 56,
        "Vcode": 830,
        "Discontinued_Date": "2009-09-23 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE I \/ II 2001 - 2009",
        "Variant": "MEGANE II 1.9 DCI DYNAMIQUE+",
        "MM_CODES": 54056940,
        "GCode": 540,
        "MCode": 56,
        "Vcode": 940,
        "Discontinued_Date": "2009-09-23 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "SCENIC I \/ II 2001 - 2009",
        "Variant": "SCENIC II EXPRESSION 1.6",
        "MM_CODES": 54057400,
        "GCode": 540,
        "MCode": 57,
        "Vcode": 400,
        "Discontinued_Date": "2007-04-19 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III 1.9DCI DYNAMIQUE 5DR",
        "MM_CODES": 54058160,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 160,
        "Discontinued_Date": "2012-07-18 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III 2.0T GT 5DR",
        "MM_CODES": 54058190,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 190,
        "Discontinued_Date": "2016-11-25 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III RS 250 SPORT LUX",
        "MM_CODES": 54058200,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 200,
        "Discontinued_Date": "2012-05-15 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III RS 265 SPORT 3DR",
        "MM_CODES": 54058201,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 201,
        "Discontinued_Date": "2016-08-08 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III RS 265 LUX 3DR",
        "MM_CODES": 54058205,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 205,
        "Discontinued_Date": "2017-05-19 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III RS 250 CUP",
        "MM_CODES": 54058210,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 210,
        "Discontinued_Date": "2012-05-15 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III RS 265 TROPHY 3DR",
        "MM_CODES": 54058215,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 215,
        "Discontinued_Date": "2014-02-07 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III RS 265 RB8",
        "MM_CODES": 54058217,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 217,
        "Discontinued_Date": "2016-01-07 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE RS 275 TROPHY 3DR",
        "MM_CODES": 54058230,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 230,
        "Discontinued_Date": "2018-04-03 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE IV RS 280 EDC LUX",
        "MM_CODES": 54058250,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 250,
        "Discontinued_Date": "2021-04-20 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III 1.4T DYNAMIQUE COUPE",
        "MM_CODES": 54058430,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 430,
        "Discontinued_Date": "2012-07-18 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE 1.4TCE GT- LINE COUPE 3DR",
        "MM_CODES": 54058440,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 440,
        "Discontinued_Date": "2014-08-05 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III 1.2T GT-LINE COUPE 3DR",
        "MM_CODES": 54058441,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 441,
        "Discontinued_Date": "2017-01-09 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III 1.6 EXPRESSION COUPE",
        "MM_CODES": 54058445,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 445,
        "Discontinued_Date": "2014-08-05 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III 1.6 DYNAMIQUE COUPE",
        "MM_CODES": 54058450,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 450,
        "Discontinued_Date": "2012-07-18 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III 1.6 DYNAMIQUE COUPE 3DR",
        "MM_CODES": 54058451,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 451,
        "Discontinued_Date": "2017-01-09 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III 2.0T GT COUPE 3DR",
        "MM_CODES": 54058480,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 480,
        "Discontinued_Date": "2017-01-09 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III 1.4 GT LINE COUPE CABRIO",
        "MM_CODES": 54058490,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 490,
        "Discontinued_Date": "2016-01-07 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "MEGANE III\/IV 2009 - ON",
        "Variant": "MEGANE III 1.4TCE COUPE CABRIO",
        "MM_CODES": 54058500,
        "GCode": 540,
        "MCode": 58,
        "Vcode": 500,
        "Discontinued_Date": "2012-07-18 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "SCENIC III 2009 - ON",
        "Variant": "SCENIC III 1.9DCI DYNAMIQUE",
        "MM_CODES": 54059200,
        "GCode": 540,
        "MCode": 59,
        "Vcode": 200,
        "Discontinued_Date": "2011-09-06 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "SCENIC III 2009 - ON",
        "Variant": "GRAND SCENIC III 1.6 DCI DYNAMIQUE",
        "MM_CODES": 54059275,
        "GCode": 540,
        "MCode": 59,
        "Vcode": 275,
        "Discontinued_Date": "2015-02-18 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "SCENIC III 2009 - ON",
        "Variant": "GRAND SCENIC III 1.9DCI DYNAM",
        "MM_CODES": 54059300,
        "GCode": 540,
        "MCode": 59,
        "Vcode": 300,
        "Discontinued_Date": "2011-09-06 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "SCENIC III 2009 - ON",
        "Variant": "GRAND SCENIC III 2.0 DCI BOSE LTD ED",
        "MM_CODES": 54059401,
        "GCode": 540,
        "MCode": 59,
        "Vcode": 401,
        "Discontinued_Date": "2015-02-18 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "LAGUNA II 2001 - ON",
        "Variant": "LAGUNA 3.5 COUPE",
        "MM_CODES": 54061550,
        "GCode": 540,
        "MCode": 61,
        "Vcode": 550,
        "Discontinued_Date": "2015-02-18 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KADJAR",
        "Variant": "KADJAR 1.2T BLAZE",
        "MM_CODES": 54063110,
        "GCode": 540,
        "MCode": 63,
        "Vcode": 110,
        "Discontinued_Date": "2018-11-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KADJAR",
        "Variant": "KADJAR 1.2T EXPRESSION",
        "MM_CODES": 54063120,
        "GCode": 540,
        "MCode": 63,
        "Vcode": 120,
        "Discontinued_Date": "2017-10-26 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KADJAR",
        "Variant": "KADJAR 1.2T DYNAMIQUE",
        "MM_CODES": 54063140,
        "GCode": 540,
        "MCode": 63,
        "Vcode": 140,
        "Discontinued_Date": "2018-11-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KADJAR",
        "Variant": "KADJAR 1.2T DYNAMIQUE EDC",
        "MM_CODES": 54063150,
        "GCode": 540,
        "MCode": 63,
        "Vcode": 150,
        "Discontinued_Date": "2018-11-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KADJAR",
        "Variant": "KADJAR 1.5 DCI DYNAMIQUE",
        "MM_CODES": 54063250,
        "GCode": 540,
        "MCode": 63,
        "Vcode": 250,
        "Discontinued_Date": "2018-11-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KADJAR",
        "Variant": "KADJAR 1.5 DCI DYNAMIQUE EDC",
        "MM_CODES": 54063260,
        "GCode": 540,
        "MCode": 63,
        "Vcode": 260,
        "Discontinued_Date": "2018-11-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KADJAR",
        "Variant": "KADJAR 1.6 DCI 4X4",
        "MM_CODES": 54063300,
        "GCode": 540,
        "MCode": 63,
        "Vcode": 300,
        "Discontinued_Date": "2018-11-12 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KOLEOS",
        "Variant": "KOLEOS 2.5 DYNAMIQUE",
        "MM_CODES": 54065250,
        "GCode": 540,
        "MCode": 65,
        "Vcode": 250,
        "Discontinued_Date": "2010-06-07 00:00:00.000"
    },
    {
        "Make": "RENAULT",
        "Model": "KOLEOS",
        "Variant": "KOLEOS 2.5 4X4 DYNAMIQUE",
        "MM_CODES": 54065270,
        "GCode": 540,
        "MCode": 65,
        "Vcode": 270,
        "Discontinued_Date": "2010-06-07 00:00:00.000"
    },
    {
        "Make": "SMART",
        "Model": "FORFOUR",
        "Variant": "FORFOUR PASSION A\/T",
        "MM_CODES": 58130415,
        "GCode": 581,
        "MCode": 30,
        "Vcode": 415,
        "Discontinued_Date": "2019-12-10 00:00:00.000"
    },
    {
        "Make": "SMART",
        "Model": "FORFOUR",
        "Variant": "FORFOUR PRIME A\/T",
        "MM_CODES": 58130430,
        "GCode": 581,
        "MCode": 30,
        "Vcode": 430,
        "Discontinued_Date": "2019-12-10 00:00:00.000"
    },
    {
        "Make": "SMART",
        "Model": "FORFOUR",
        "Variant": "FORFOUR BRARBUS XCLUSIVE DCT (80KW)",
        "MM_CODES": 58130530,
        "GCode": 581,
        "MCode": 30,
        "Vcode": 530,
        "Discontinued_Date": "2019-12-10 00:00:00.000"
    },
    {
        "Make": "SSANGYONG",
        "Model": "ACTYON\/ACTYON SPORTS",
        "Variant": "ACTYON SPORTS 2.3 4X4 P\/U D\/C",
        "MM_CODES": 58310150,
        "GCode": 583,
        "MCode": 10,
        "Vcode": 150,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "SSANGYONG",
        "Model": "KORANDO",
        "Variant": "KORANDO 2.0",
        "MM_CODES": 58320461,
        "GCode": 583,
        "MCode": 20,
        "Vcode": 461,
        "Discontinued_Date": "2020-01-21 00:00:00.000"
    },
    {
        "Make": "SSANGYONG",
        "Model": "KORANDO",
        "Variant": "KORANDO 2 2.0 CRD",
        "MM_CODES": 58320500,
        "GCode": 583,
        "MCode": 20,
        "Vcode": 500,
        "Discontinued_Date": "2015-06-29 00:00:00.000"
    },
    {
        "Make": "SSANGYONG",
        "Model": "REXTON",
        "Variant": "REXTON 2.7D 4X4",
        "MM_CODES": 58350510,
        "GCode": 583,
        "MCode": 50,
        "Vcode": 510,
        "Discontinued_Date": "2019-06-10 00:00:00.000"
    },
    {
        "Make": "SSANGYONG",
        "Model": "REXTON",
        "Variant": "REXTON 2.7D 4X4 A\/T 7 SEAT",
        "MM_CODES": 58350530,
        "GCode": 583,
        "MCode": 50,
        "Vcode": 530,
        "Discontinued_Date": "2019-07-15 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "XV",
        "Variant": "XV 2.0",
        "MM_CODES": 58515190,
        "GCode": 585,
        "MCode": 15,
        "Vcode": 190,
        "Discontinued_Date": "2017-06-22 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "XV\/CROSSTREK",
        "Variant": "XV 2.0",
        "MM_CODES": 58515190,
        "GCode": 585,
        "MCode": 15,
        "Vcode": 190,
        "Discontinued_Date": "2017-06-22 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "XV",
        "Variant": "XV 2.0R",
        "MM_CODES": 58515200,
        "GCode": 585,
        "MCode": 15,
        "Vcode": 200,
        "Discontinued_Date": "2012-02-13 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "XV",
        "Variant": "XV 2.0 HIGH",
        "MM_CODES": 58515201,
        "GCode": 585,
        "MCode": 15,
        "Vcode": 201,
        "Discontinued_Date": "2015-04-23 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "XV",
        "Variant": "XV 2.0 LINEARTRONIC",
        "MM_CODES": 58515210,
        "GCode": 585,
        "MCode": 15,
        "Vcode": 210,
        "Discontinued_Date": "2017-06-22 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "XV\/CROSSTREK",
        "Variant": "XV 2.0 LINEARTRONIC",
        "MM_CODES": 58515210,
        "GCode": 585,
        "MCode": 15,
        "Vcode": 210,
        "Discontinued_Date": "2017-06-22 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "XV\/CROSSTREK",
        "Variant": "XV 2.0I CVT",
        "MM_CODES": 58515211,
        "GCode": 585,
        "MCode": 15,
        "Vcode": 211,
        "Discontinued_Date": "2023-05-15 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "XV",
        "Variant": "XV 2.0R A\/T",
        "MM_CODES": 58515220,
        "GCode": 585,
        "MCode": 15,
        "Vcode": 220,
        "Discontinued_Date": "2012-02-13 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "XV",
        "Variant": "XV 2.0 HIGH LINEARTRONIC",
        "MM_CODES": 58515221,
        "GCode": 585,
        "MCode": 15,
        "Vcode": 221,
        "Discontinued_Date": "2017-06-22 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "XV\/CROSSTREK",
        "Variant": "XV 2.0 HIGH LINEARTRONIC",
        "MM_CODES": 58515221,
        "GCode": 585,
        "MCode": 15,
        "Vcode": 221,
        "Discontinued_Date": "2017-06-22 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "XV\/CROSSTREK",
        "Variant": "XV 2.0 IS-ES CVT",
        "MM_CODES": 58515222,
        "GCode": 585,
        "MCode": 15,
        "Vcode": 222,
        "Discontinued_Date": "2023-05-15 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "BRZ",
        "Variant": "BRZ 2.0",
        "MM_CODES": 58517200,
        "GCode": 585,
        "MCode": 17,
        "Vcode": 200,
        "Discontinued_Date": "2015-12-07 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "IMPREZA 2007 - ON",
        "Variant": "IMPREZA 2.5 WRX STI 5DR",
        "MM_CODES": 58522340,
        "GCode": 585,
        "MCode": 22,
        "Vcode": 340,
        "Discontinued_Date": "2012-08-03 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "IMPREZA 2007 - ON",
        "Variant": "IMPREZA 2.0I CVT",
        "MM_CODES": 58522441,
        "GCode": 585,
        "MCode": 22,
        "Vcode": 441,
        "Discontinued_Date": "2022-06-29 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "IMPREZA 2007 - ON",
        "Variant": "IMPREZA 2.0 RS",
        "MM_CODES": 58522450,
        "GCode": 585,
        "MCode": 22,
        "Vcode": 450,
        "Discontinued_Date": "2016-05-05 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "IMPREZA 2007 - ON",
        "Variant": "IMPREZA 2.0 RS A\/T",
        "MM_CODES": 58522460,
        "GCode": 585,
        "MCode": 22,
        "Vcode": 460,
        "Discontinued_Date": "2015-12-02 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "IMPREZA 2007 - ON",
        "Variant": "IMPREZA 2.0 IS-ES CVT",
        "MM_CODES": 58522463,
        "GCode": 585,
        "MCode": 22,
        "Vcode": 463,
        "Discontinued_Date": "2022-06-29 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "IMPREZA 2007 - ON",
        "Variant": "WRX 2.5 STI",
        "MM_CODES": 58522550,
        "GCode": 585,
        "MCode": 22,
        "Vcode": 550,
        "Discontinued_Date": "2014-06-11 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "IMPREZA 2007 - ON",
        "Variant": "WRX 2.5 STI PREMIUM",
        "MM_CODES": 58522551,
        "GCode": 585,
        "MCode": 22,
        "Vcode": 551,
        "Discontinued_Date": "2022-03-31 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "LEGACY 2006 - ON",
        "Variant": "LEGACY 2.0I PREMIUM CVT",
        "MM_CODES": 58531420,
        "GCode": 585,
        "MCode": 31,
        "Vcode": 420,
        "Discontinued_Date": "2014-04-01 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "LEGACY 2006 - ON",
        "Variant": "LEGACY 2.5 GT",
        "MM_CODES": 58531470,
        "GCode": 585,
        "MCode": 31,
        "Vcode": 470,
        "Discontinued_Date": "2015-03-04 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "LEGACY 2006 - ON",
        "Variant": "LEGACY 3.6 RS-ES CVT",
        "MM_CODES": 58531501,
        "GCode": 585,
        "MCode": 31,
        "Vcode": 501,
        "Discontinued_Date": "2022-12-22 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "FORESTER 2005 - ON",
        "Variant": "FORESTER 2.0 X",
        "MM_CODES": 58556200,
        "GCode": 585,
        "MCode": 56,
        "Vcode": 200,
        "Discontinued_Date": "2019-01-09 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "FORESTER 2005 - ON",
        "Variant": "FORESTER 2.0I CVT",
        "MM_CODES": 58556210,
        "GCode": 585,
        "MCode": 56,
        "Vcode": 210,
        "Discontinued_Date": "2022-09-29 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "FORESTER 2005 - ON",
        "Variant": "FORESTER 2.0I-L ES CVT",
        "MM_CODES": 58556220,
        "GCode": 585,
        "MCode": 56,
        "Vcode": 220,
        "Discontinued_Date": "2021-01-26 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "FORESTER 2005 - ON",
        "Variant": "FORESTER 2.0  XT TURBO LINEARTRONIC",
        "MM_CODES": 58556250,
        "GCode": 585,
        "MCode": 56,
        "Vcode": 250,
        "Discontinued_Date": "2019-01-08 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "FORESTER 2005 - ON",
        "Variant": "FORESTER 2.0D",
        "MM_CODES": 58556300,
        "GCode": 585,
        "MCode": 56,
        "Vcode": 300,
        "Discontinued_Date": "2013-02-06 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "FORESTER 2005 - ON",
        "Variant": "FORESTER 2.0D PREMIUM",
        "MM_CODES": 58556320,
        "GCode": 585,
        "MCode": 56,
        "Vcode": 320,
        "Discontinued_Date": "2013-02-06 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "FORESTER 2005 - ON",
        "Variant": "FORESTER 2.5 X",
        "MM_CODES": 58556421,
        "GCode": 585,
        "MCode": 56,
        "Vcode": 421,
        "Discontinued_Date": "2013-02-06 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "FORESTER 2005 - ON",
        "Variant": "FORESTER 2.5 X A\/T",
        "MM_CODES": 58556441,
        "GCode": 585,
        "MCode": 56,
        "Vcode": 441,
        "Discontinued_Date": "2013-02-06 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "FORESTER 2005 - ON",
        "Variant": "FORESTER 2.5  X LINEARTRONIC",
        "MM_CODES": 58556442,
        "GCode": 585,
        "MCode": 56,
        "Vcode": 442,
        "Discontinued_Date": "2019-05-07 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "FORESTER 2005 - ON",
        "Variant": "FORESTER 2.5 XS",
        "MM_CODES": 58556461,
        "GCode": 585,
        "MCode": 56,
        "Vcode": 461,
        "Discontinued_Date": "2013-02-06 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "FORESTER 2005 - ON",
        "Variant": "FORESTER 2.5 XS A\/T",
        "MM_CODES": 58556481,
        "GCode": 585,
        "MCode": 56,
        "Vcode": 481,
        "Discontinued_Date": "2013-02-06 00:00:00.000"
    },
    {
        "Make": "SUBARU",
        "Model": "FORESTER 2005 - ON",
        "Variant": "FORESTER 2.5 XS LINEARTRONIC",
        "MM_CODES": 58556482,
        "GCode": 585,
        "MCode": 56,
        "Vcode": 482,
        "Discontinued_Date": "2019-05-07 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "BALENO",
        "Variant": "BALENO 1.4 GL 5DR",
        "MM_CODES": 59010150,
        "GCode": 590,
        "MCode": 10,
        "Vcode": 150,
        "Discontinued_Date": "2022-05-25 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "BALENO",
        "Variant": "BALENO 1.4 GLX 5DR",
        "MM_CODES": 59010170,
        "GCode": 590,
        "MCode": 10,
        "Vcode": 170,
        "Discontinued_Date": "2022-05-25 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "BALENO",
        "Variant": "BALENO 1.4 GLX 5DR A\/T",
        "MM_CODES": 59010180,
        "GCode": 590,
        "MCode": 10,
        "Vcode": 180,
        "Discontinued_Date": "2022-05-25 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "CIAZ",
        "Variant": "CIAZ 1.4 GL",
        "MM_CODES": 59015100,
        "GCode": 590,
        "MCode": 15,
        "Vcode": 100,
        "Discontinued_Date": "2019-03-28 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "CIAZ",
        "Variant": "CIAZ 1.4 GLX",
        "MM_CODES": 59015120,
        "GCode": 590,
        "MCode": 15,
        "Vcode": 120,
        "Discontinued_Date": "2019-03-28 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "CIAZ",
        "Variant": "CIAZ 1.4 GLX A\/T",
        "MM_CODES": 59015130,
        "GCode": 590,
        "MCode": 15,
        "Vcode": 130,
        "Discontinued_Date": "2019-03-28 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "SX4",
        "Variant": "SX4 1.6 GL",
        "MM_CODES": 59030100,
        "GCode": 590,
        "MCode": 30,
        "Vcode": 100,
        "Discontinued_Date": "2017-09-12 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "SX4",
        "Variant": "SX4 1.6 GLX",
        "MM_CODES": 59030110,
        "GCode": 590,
        "MCode": 30,
        "Vcode": 110,
        "Discontinued_Date": "2017-09-12 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "SX4",
        "Variant": "SX4 1.6 GLX CVT",
        "MM_CODES": 59030120,
        "GCode": 590,
        "MCode": 30,
        "Vcode": 120,
        "Discontinued_Date": "2017-09-12 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "SX4",
        "Variant": "SX4 1.6 GLX ALLGRIP (AWD)",
        "MM_CODES": 59030130,
        "GCode": 590,
        "MCode": 30,
        "Vcode": 130,
        "Discontinued_Date": "2017-09-12 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "SX4",
        "Variant": "SX4 1.6 GLX CVT ALLGRIP (AWD)",
        "MM_CODES": 59030140,
        "GCode": 590,
        "MCode": 30,
        "Vcode": 140,
        "Discontinued_Date": "2017-09-12 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "SX4",
        "Variant": "SX4 2.0 JOCK EDITION",
        "MM_CODES": 59030203,
        "GCode": 590,
        "MCode": 30,
        "Vcode": 203,
        "Discontinued_Date": "2012-04-18 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "SX4",
        "Variant": "SX4 2.0 CVT",
        "MM_CODES": 59030211,
        "GCode": 590,
        "MCode": 30,
        "Vcode": 211,
        "Discontinued_Date": "2014-03-18 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "ERTIGA",
        "Variant": "ERTIGA 1.4 GL",
        "MM_CODES": 59036120,
        "GCode": 590,
        "MCode": 36,
        "Vcode": 120,
        "Discontinued_Date": "2019-02-26 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "ERTIGA",
        "Variant": "ERTIGA 1.5 GLX A\/T",
        "MM_CODES": 59036151,
        "GCode": 590,
        "MCode": 36,
        "Vcode": 151,
        "Discontinued_Date": "2022-06-20 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "ERTIGA \/ XL6",
        "Variant": "ERTIGA 1.5 GLX A\/T",
        "MM_CODES": 59036151,
        "GCode": 590,
        "MCode": 36,
        "Vcode": 151,
        "Discontinued_Date": "2022-06-20 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "KIZASHI",
        "Variant": "KIZASHI 2.4 SDLX",
        "MM_CODES": 59039400,
        "GCode": 590,
        "MCode": 39,
        "Vcode": 400,
        "Discontinued_Date": "2015-09-02 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "KIZASHI",
        "Variant": "KIZASHI 2.4 SDLX CVT",
        "MM_CODES": 59039410,
        "GCode": 590,
        "MCode": 39,
        "Vcode": 410,
        "Discontinued_Date": "2015-09-02 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "VITARA \/ VITARA BREZZA 2008 - ON",
        "Variant": "VITARA 1.6 GL",
        "MM_CODES": 59051150,
        "GCode": 590,
        "MCode": 51,
        "Vcode": 150,
        "Discontinued_Date": "2021-11-16 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "VITARA \/ VITARA BREZZA 2008 - ON",
        "Variant": "VITARA 1.6 GL+ A\/T",
        "MM_CODES": 59051165,
        "GCode": 590,
        "MCode": 51,
        "Vcode": 165,
        "Discontinued_Date": "2021-11-15 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "VITARA \/ VITARA BREZZA 2008 - ON",
        "Variant": "VITARA 1.6 GL + ALLGRIP",
        "MM_CODES": 59051170,
        "GCode": 590,
        "MCode": 51,
        "Vcode": 170,
        "Discontinued_Date": "2018-11-12 00:00:00.000"
    },
    {
        "Make": "SUZUKI",
        "Model": "VITARA 2008 - ON",
        "Variant": "VITARA 1.6 GL + ALLGRIP",
        "MM_CODES": 59051170,
        "GCode": 590,
        "MCode": 51,
        "Vcode": 170,
        "Discontinued_Date": "2018-11-12 00:00:00.000"
    },
    {
        "Make": "TATA",
        "Model": "INDICA",
        "Variant": "INDICA VISTA 1.4 INI BOUNCE",
        "MM_CODES": 59810302,
        "GCode": 598,
        "MCode": 10,
        "Vcode": 302,
        "Discontinued_Date": "2019-11-06 00:00:00.000"
    },
    {
        "Make": "TATA",
        "Model": "INDICA",
        "Variant": "INDICA VISTA 1.4 IGNIS",
        "MM_CODES": 59810320,
        "GCode": 598,
        "MCode": 10,
        "Vcode": 320,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "TATA",
        "Model": "INDIGO",
        "Variant": "INDIGO 1.4 MANZA INI",
        "MM_CODES": 59814250,
        "GCode": 598,
        "MCode": 14,
        "Vcode": 250,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "TATA",
        "Model": "INDIGO",
        "Variant": "INDIGO 1.4 MANZA IGNIS",
        "MM_CODES": 59814260,
        "GCode": 598,
        "MCode": 14,
        "Vcode": 260,
        "Discontinued_Date": "2018-07-30 00:00:00.000"
    },
    {
        "Make": "TATA",
        "Model": "BOLT",
        "Variant": "BOLT 1.2T XMS 5DR",
        "MM_CODES": 59816120,
        "GCode": 598,
        "MCode": 16,
        "Vcode": 120,
        "Discontinued_Date": "2019-11-06 00:00:00.000"
    },
    {
        "Make": "TATA",
        "Model": "BOLT",
        "Variant": "BOLT 1.2T XT 5DR",
        "MM_CODES": 59816130,
        "GCode": 598,
        "MCode": 16,
        "Vcode": 130,
        "Discontinued_Date": "2019-11-06 00:00:00.000"
    },
    {
        "Make": "TATA",
        "Model": "SUPER ACE",
        "Variant": "SUPER ACE 1.4 TCIC DLS P\/U D\/S",
        "MM_CODES": 59820100,
        "GCode": 598,
        "MCode": 20,
        "Vcode": 100,
        "Discontinued_Date": "2020-10-04 00:00:00.000"
    },
    {
        "Make": "TATA",
        "Model": "SUPER ACE",
        "Variant": "SUPER ACE 1.4 TCIC DLE P\/U D\/S",
        "MM_CODES": 59820105,
        "GCode": 598,
        "MCode": 20,
        "Vcode": 105,
        "Discontinued_Date": "2020-10-04 00:00:00.000"
    },
    {
        "Make": "TATA",
        "Model": "XENON",
        "Variant": "XENON 2.2 DLE D\/C P\/U",
        "MM_CODES": 59831180,
        "GCode": 598,
        "MCode": 31,
        "Vcode": 180,
        "Discontinued_Date": "2015-09-01 00:00:00.000"
    },
    {
        "Make": "TATA",
        "Model": "XENON",
        "Variant": "XENON XT 2.2 DICOR P\/U D\/C",
        "MM_CODES": 59831181,
        "GCode": 598,
        "MCode": 31,
        "Vcode": 181,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "TATA",
        "Model": "XENON",
        "Variant": "XENON XT EVOLVE 2.2 DICOR PU D\/C",
        "MM_CODES": 59831185,
        "GCode": 598,
        "MCode": 31,
        "Vcode": 185,
        "Discontinued_Date": "2020-12-06 00:00:00.000"
    },
    {
        "Make": "TATA",
        "Model": "XENON",
        "Variant": "XENON 2.2 DLE 4X4 P\/U D\/C",
        "MM_CODES": 59831190,
        "GCode": 598,
        "MCode": 31,
        "Vcode": 190,
        "Discontinued_Date": "2015-09-01 00:00:00.000"
    },
    {
        "Make": "TATA",
        "Model": "XENON",
        "Variant": "XENON XT 2.2 DICOR 4X4 P\/U D\/C",
        "MM_CODES": 59831191,
        "GCode": 598,
        "MCode": 31,
        "Vcode": 191,
        "Discontinued_Date": "2017-10-23 00:00:00.000"
    },
    {
        "Make": "TATA",
        "Model": "XENON",
        "Variant": "XENON 3.0 BASELINE P\/U S\/C",
        "MM_CODES": 59831270,
        "GCode": 598,
        "MCode": 31,
        "Vcode": 270,
        "Discontinued_Date": "2017-10-23 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "URBAN CRUISER",
        "Variant": "URBAN CRUISER 1.5XR",
        "MM_CODES": 60012230,
        "GCode": 600,
        "MCode": 12,
        "Vcode": 230,
        "Discontinued_Date": "2023-06-30 10:12:26.487"
    },
    {
        "Make": "TOYOTA",
        "Model": "URBAN CRUISER",
        "Variant": "URBAN CRUISER 1.5XR A\/T",
        "MM_CODES": 60012240,
        "GCode": 600,
        "MCode": 12,
        "Vcode": 240,
        "Discontinued_Date": "2023-06-30 10:12:30.167"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 1.6 XI",
        "MM_CODES": 60015201,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 201,
        "Discontinued_Date": "2012-12-13 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 1.6 XI",
        "MM_CODES": 60015203,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 203,
        "Discontinued_Date": "2018-09-05 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 1.6 XS",
        "MM_CODES": 60015221,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 221,
        "Discontinued_Date": "2012-12-13 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 1.6 XS",
        "MM_CODES": 60015222,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 222,
        "Discontinued_Date": "2015-06-08 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 1.6 XS",
        "MM_CODES": 60015223,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 223,
        "Discontinued_Date": "2018-09-05 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 1.6 SPORT X",
        "MM_CODES": 60015230,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 230,
        "Discontinued_Date": "2013-01-11 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 1.6 XR",
        "MM_CODES": 60015235,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 235,
        "Discontinued_Date": "2012-12-13 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 1.6 XR",
        "MM_CODES": 60015236,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 236,
        "Discontinued_Date": "2015-06-08 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 1.6 XR",
        "MM_CODES": 60015237,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 237,
        "Discontinued_Date": "2018-09-05 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 1.6 XR CVT",
        "MM_CODES": 60015246,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 246,
        "Discontinued_Date": "2018-09-05 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 1.6 TRD",
        "MM_CODES": 60015260,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 260,
        "Discontinued_Date": "2013-01-11 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 180 XS HSD (HYBRID)",
        "MM_CODES": 60015330,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 330,
        "Discontinued_Date": "2013-01-11 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 1.8 XS HSD (HYBRID)",
        "MM_CODES": 60015331,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 331,
        "Discontinued_Date": "2014-07-01 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 180 XR HSD (HYBRID)",
        "MM_CODES": 60015340,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 340,
        "Discontinued_Date": "2013-01-11 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 1.8 XR HSD (HYBRID)",
        "MM_CODES": 60015342,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 342,
        "Discontinued_Date": "2018-09-05 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AURIS",
        "Variant": "AURIS 2.0 D-4D XD",
        "MM_CODES": 60015421,
        "GCode": 600,
        "MCode": 15,
        "Vcode": 421,
        "Discontinued_Date": "2013-01-11 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "COROLLA 2002 - ON",
        "Variant": "COROLLA 1.3 IMPACT",
        "MM_CODES": 60027366,
        "GCode": 600,
        "MCode": 27,
        "Vcode": 366,
        "Discontinued_Date": "2014-02-07 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "COROLLA 2002 - ON",
        "Variant": "COROLLA 1.3 ESTEEM",
        "MM_CODES": 60027367,
        "GCode": 600,
        "MCode": 27,
        "Vcode": 367,
        "Discontinued_Date": "2017-10-27 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "COROLLA 2002 - ON",
        "Variant": "COROLLA 1.3 ESTEEM",
        "MM_CODES": 60027368,
        "GCode": 600,
        "MCode": 27,
        "Vcode": 368,
        "Discontinued_Date": "2020-10-12 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "COROLLA 2002 - ON",
        "Variant": "COROLLA 1.3 PRESTIGE",
        "MM_CODES": 60027372,
        "GCode": 600,
        "MCode": 27,
        "Vcode": 372,
        "Discontinued_Date": "2017-10-27 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "COROLLA 2002 - ON",
        "Variant": "COROLLA 1.3 PRESTIGE",
        "MM_CODES": 60027373,
        "GCode": 600,
        "MCode": 27,
        "Vcode": 373,
        "Discontinued_Date": "2020-04-29 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "COROLLA 2002 - ON",
        "Variant": "COROLLA 1.8 EXCLUSIVE",
        "MM_CODES": 60027621,
        "GCode": 600,
        "MCode": 27,
        "Vcode": 621,
        "Discontinued_Date": "2017-10-27 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "COROLLA 2002 - ON",
        "Variant": "COROLLA 1.8 EXCLUSIVE",
        "MM_CODES": 60027622,
        "GCode": 600,
        "MCode": 27,
        "Vcode": 622,
        "Discontinued_Date": "2020-04-29 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "COROLLA 2002 - ON",
        "Variant": "COROLLA 1.8 EXCLUSIVE CVT",
        "MM_CODES": 60027641,
        "GCode": 600,
        "MCode": 27,
        "Vcode": 641,
        "Discontinued_Date": "2017-10-27 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "COROLLA 2002 - ON",
        "Variant": "COROLLA 2.0D ADVANCED",
        "MM_CODES": 60027701,
        "GCode": 600,
        "MCode": 27,
        "Vcode": 701,
        "Discontinued_Date": "2014-02-07 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "COROLLA 2002 - ON",
        "Variant": "COROLLA 1.8 XS CVT",
        "MM_CODES": 60027770,
        "GCode": 600,
        "MCode": 27,
        "Vcode": 770,
        "Discontinued_Date": "2022-11-10 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "COROLLA 2002 - ON",
        "Variant": "COROLLA 2.0 XR",
        "MM_CODES": 60027800,
        "GCode": 600,
        "MCode": 27,
        "Vcode": 800,
        "Discontinued_Date": "2022-11-10 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 2.0 VVTI S P\/U S\/C",
        "MM_CODES": 60036106,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 106,
        "Discontinued_Date": "2016-02-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 2.0 VVTI S P\/U S\/C",
        "MM_CODES": 60036106,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 106,
        "Discontinued_Date": "2016-02-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0D-4D RAIDER XTRA CAB 4X4 P\/U S\/C",
        "MM_CODES": 60036416,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 416,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0D-4D RAIDER XTRA CAB 4X4 P\/U S\/C",
        "MM_CODES": 60036416,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 416,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D HERITAGE R\/B P\/U D\/C",
        "MM_CODES": 60036424,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 424,
        "Discontinued_Date": "2013-04-02 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D HERITAGE R\/B P\/U D\/C",
        "MM_CODES": 60036424,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 424,
        "Discontinued_Date": "2013-04-02 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D RAIDER R\/B P\/U D\/C",
        "MM_CODES": 60036425,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 425,
        "Discontinued_Date": "2011-08-19 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D RAIDER R\/B P\/U D\/C",
        "MM_CODES": 60036425,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 425,
        "Discontinued_Date": "2011-08-19 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D RAIDER R\/B P\/U D\/C",
        "MM_CODES": 60036426,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 426,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D RAIDER R\/B P\/U D\/C",
        "MM_CODES": 60036426,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 426,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0D-4D RAIDER R\/B A\/T P\/U D\/C",
        "MM_CODES": 60036427,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 427,
        "Discontinued_Date": "2011-08-19 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0D-4D RAIDER R\/B A\/T P\/U D\/C",
        "MM_CODES": 60036427,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 427,
        "Discontinued_Date": "2011-08-19 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0D-4D RAIDER R\/B A\/T P\/U D\/C",
        "MM_CODES": 60036428,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 428,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0D-4D RAIDER R\/B A\/T P\/U D\/C",
        "MM_CODES": 60036428,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 428,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0D-4D HERITAGE R\/B A\/T P\/U D\/C",
        "MM_CODES": 60036432,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 432,
        "Discontinued_Date": "2013-04-02 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0D-4D HERITAGE R\/B A\/T P\/U D\/C",
        "MM_CODES": 60036432,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 432,
        "Discontinued_Date": "2013-04-02 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D RAIDER 4X4 P\/U D\/C",
        "MM_CODES": 60036442,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 442,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D RAIDER 4X4 P\/U D\/C",
        "MM_CODES": 60036442,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 442,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D HERITAGE 4X4 P\/U D\/C",
        "MM_CODES": 60036444,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 444,
        "Discontinued_Date": "2013-04-02 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D HERITAGE 4X4 P\/U D\/C",
        "MM_CODES": 60036444,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 444,
        "Discontinued_Date": "2013-04-02 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D RAIDER 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60036445,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 445,
        "Discontinued_Date": "2011-08-19 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D RAIDER 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60036445,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 445,
        "Discontinued_Date": "2011-08-19 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D RAIDER 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60036446,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 446,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D RAIDER 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60036446,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 446,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D HERITAGE 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60036450,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 450,
        "Discontinued_Date": "2013-04-02 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 4.0 VVTI RAIDER R\/B A\/T P\/U D\/C",
        "MM_CODES": 60036495,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 495,
        "Discontinued_Date": "2011-08-19 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 4.0 VVTI RAIDER R\/B A\/T P\/U D\/C",
        "MM_CODES": 60036495,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 495,
        "Discontinued_Date": "2011-08-19 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 4.0 V6 RAIDER R\/B A\/T P\/U D\/C",
        "MM_CODES": 60036496,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 496,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 4.0 V6 RAIDER R\/B A\/T P\/U D\/C",
        "MM_CODES": 60036496,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 496,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 4.0 V6 HERITAGE R\/B A\/T P\/U D\/C",
        "MM_CODES": 60036499,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 499,
        "Discontinued_Date": "2013-04-02 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 4.0 V6 HERITAGE R\/B A\/T P\/U D\/C",
        "MM_CODES": 60036499,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 499,
        "Discontinued_Date": "2013-04-02 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 4.0 V6 RAIDER 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60036521,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 521,
        "Discontinued_Date": "2011-08-19 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 4.0 V6 RAIDER 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60036521,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 521,
        "Discontinued_Date": "2011-08-19 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 4.0 V6 RAIDER 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60036522,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 522,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 4.0 V6 RAIDER 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60036522,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 522,
        "Discontinued_Date": "2014-10-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 4.0 V6 HERITAGE 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60036526,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 526,
        "Discontinued_Date": "2013-04-02 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 2.7 VVTI LEGEND 45 R\/B P\/U S\/C",
        "MM_CODES": 60036555,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 555,
        "Discontinued_Date": "2016-02-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0D-4D LEGEND 45 XTRA CAB P\/U",
        "MM_CODES": 60036575,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 575,
        "Discontinued_Date": "2016-02-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0D-4D LEGEND 45 XTRA CAB P\/U",
        "MM_CODES": 60036575,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 575,
        "Discontinued_Date": "2016-02-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0D-4D LEGEND 45 4X4 XTRA CAB P\/U",
        "MM_CODES": 60036580,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 580,
        "Discontinued_Date": "2016-02-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0D-4D LEGEND 45 4X4 XTRA CAB P\/U",
        "MM_CODES": 60036580,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 580,
        "Discontinued_Date": "2016-02-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D LEGEND 45 R\/B P\/U D\/C",
        "MM_CODES": 60036585,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 585,
        "Discontinued_Date": "2016-02-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D LEGEND 45 R\/B P\/U D\/C",
        "MM_CODES": 60036585,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 585,
        "Discontinued_Date": "2016-02-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D LEGEND 45 4X4 P\/U D\/C",
        "MM_CODES": 60036595,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 595,
        "Discontinued_Date": "2016-02-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D LEGEND 45 4X4 P\/U D\/C",
        "MM_CODES": 60036595,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 595,
        "Discontinued_Date": "2016-02-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HI-LUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D LEGEND 45 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60036600,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 600,
        "Discontinued_Date": "2016-02-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 3.0 D-4D LEGEND 45 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60036600,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 600,
        "Discontinued_Date": "2016-02-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2005 - 2016",
        "Variant": "HILUX 4.0 V6 LEGEND 45 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60036610,
        "GCode": 600,
        "MCode": 36,
        "Vcode": 610,
        "Discontinued_Date": "2016-02-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.4 GD P\/U S\/C",
        "MM_CODES": 60039160,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 160,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.4 GD A\/C P\/U S\/C",
        "MM_CODES": 60039170,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 170,
        "Discontinued_Date": "2018-08-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.4 GD A\/C P\/U S\/C",
        "MM_CODES": 60039171,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 171,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.4 GD-6 RB SRX P\/U S\/C",
        "MM_CODES": 60039180,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 180,
        "Discontinued_Date": "2018-08-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.4 GD-6 SRX 4X4 P\/U S\/C",
        "MM_CODES": 60039190,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 190,
        "Discontinued_Date": "2018-08-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.4 GD-6 SRX 4X4 P\/U S\/C",
        "MM_CODES": 60039191,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 191,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.4 GD-6 RB SRX P\/U E\/CAB",
        "MM_CODES": 60039220,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 220,
        "Discontinued_Date": "2018-08-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.4 GD-6 RB SRX P\/U E\/CAB",
        "MM_CODES": 60039221,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 221,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.4 GD-6 RB SRX P\/U D\/C",
        "MM_CODES": 60039241,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 241,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.4 GD-6 RB SRX A\/T P\/U D\/C",
        "MM_CODES": 60039245,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 245,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.4 GD-6 SRX 4X4 P\/U D\/C",
        "MM_CODES": 60039251,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 251,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RAIDER 4X4 A\/T P\/U S\/C",
        "MM_CODES": 60039431,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 431,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RB RAIDER P\/U E\/CAB",
        "MM_CODES": 60039440,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 440,
        "Discontinued_Date": "2018-08-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RB RAIDER A\/T P\/U E\/CAB",
        "MM_CODES": 60039450,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 450,
        "Discontinued_Date": "2018-08-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RB RAIDER A\/T P\/U E\/CAB",
        "MM_CODES": 60039451,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 451,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RAIDER 4X4 P\/U E\/CAB",
        "MM_CODES": 60039460,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 460,
        "Discontinued_Date": "2018-08-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RAIDER 4X4 P\/U E\/CAB",
        "MM_CODES": 60039461,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 461,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RB RAIDER 4X4 P\/U E\/CAB A\/T",
        "MM_CODES": 60039470,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 470,
        "Discontinued_Date": "2018-08-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RB RAIDER 4X4 A\/T P\/U E\/CAB",
        "MM_CODES": 60039471,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 471,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RB RAIDER P\/U D\/C",
        "MM_CODES": 60039480,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 480,
        "Discontinued_Date": "2018-08-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RB RAIDER P\/U D\/C",
        "MM_CODES": 60039481,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 481,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RAIDER 4X4 P\/U D\/C",
        "MM_CODES": 60039500,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 500,
        "Discontinued_Date": "2018-08-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RAIDER 4X4 P\/U D\/C",
        "MM_CODES": 60039501,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 501,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RAIDER 4X4 P\/U D\/C A\/T",
        "MM_CODES": 60039520,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 520,
        "Discontinued_Date": "2018-08-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RAIDER 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60039521,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 521,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 GR-S 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60039522,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 522,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 4.0 V6 RAIDER 4X4 P\/U D\/C A\/T",
        "MM_CODES": 60039640,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 640,
        "Discontinued_Date": "2018-08-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 4.0 V6 RAIDER 4X4 A\/T P\/U D\/C",
        "MM_CODES": 60039641,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 641,
        "Discontinued_Date": "2020-11-16 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "HILUX 2016 ON",
        "Variant": "HILUX 2.8 GD-6 RB 21 LEGEND 4X4 P\/U D\/C",
        "MM_CODES": 60039929,
        "GCode": 600,
        "MCode": 39,
        "Vcode": 929,
        "Discontinued_Date": "2021-12-22 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA \/ INNOVA \/ RUMION",
        "Variant": "AVANZA 1.3 S",
        "MM_CODES": 60052103,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 103,
        "Discontinued_Date": "2022-04-01 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA \/ INNOVA \/ RUMION",
        "Variant": "AVANZA 1.3 SX",
        "MM_CODES": 60052113,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 113,
        "Discontinued_Date": "2022-04-01 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA \/ INNOVA \/ RUMION",
        "Variant": "AVANZA 1.5 SX",
        "MM_CODES": 60052261,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 261,
        "Discontinued_Date": "2015-12-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA\/INNOVA",
        "Variant": "AVANZA 1.5 SX",
        "MM_CODES": 60052261,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 261,
        "Discontinued_Date": "2015-12-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA \/ INNOVA \/ RUMION",
        "Variant": "AVANZA 1.5 SX A\/T",
        "MM_CODES": 60052265,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 265,
        "Discontinued_Date": "2015-12-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA\/INNOVA",
        "Variant": "AVANZA 1.5 SX A\/T",
        "MM_CODES": 60052265,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 265,
        "Discontinued_Date": "2015-12-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA \/ INNOVA \/ RUMION",
        "Variant": "AVANZA 1.5 SX A\/T",
        "MM_CODES": 60052266,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 266,
        "Discontinued_Date": "2019-04-29 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA\/INNOVA",
        "Variant": "AVANZA 1.5 SX A\/T",
        "MM_CODES": 60052266,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 266,
        "Discontinued_Date": "2019-04-29 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA \/ INNOVA \/ RUMION",
        "Variant": "AVANZA 1.5 SX A\/T",
        "MM_CODES": 60052267,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 267,
        "Discontinued_Date": "2022-04-01 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA \/ INNOVA \/ RUMION",
        "Variant": "AVANZA 1.5 TX",
        "MM_CODES": 60052271,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 271,
        "Discontinued_Date": "2015-12-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA\/INNOVA",
        "Variant": "AVANZA 1.5 TX",
        "MM_CODES": 60052271,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 271,
        "Discontinued_Date": "2015-12-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA \/ INNOVA \/ RUMION",
        "Variant": "AVANZA 1.5 TX",
        "MM_CODES": 60052272,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 272,
        "Discontinued_Date": "2019-04-29 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA\/INNOVA",
        "Variant": "AVANZA 1.5 TX",
        "MM_CODES": 60052272,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 272,
        "Discontinued_Date": "2019-04-29 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA \/ INNOVA \/ RUMION",
        "Variant": "AVANZA 1.5 TX",
        "MM_CODES": 60052273,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 273,
        "Discontinued_Date": "2022-04-01 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA \/ INNOVA \/ RUMION",
        "Variant": "INNOVA 2.7 VVTI 7 SEAT",
        "MM_CODES": 60052500,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 500,
        "Discontinued_Date": "2015-11-26 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA\/INNOVA",
        "Variant": "INNOVA 2.7 VVTI 7 SEAT",
        "MM_CODES": 60052500,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 500,
        "Discontinued_Date": "2015-11-26 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA \/ INNOVA \/ RUMION",
        "Variant": "INNOVA 2.7 VVTI 8 SEAT",
        "MM_CODES": 60052520,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 520,
        "Discontinued_Date": "2015-11-26 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA\/INNOVA",
        "Variant": "INNOVA 2.7 VVTI 8 SEAT",
        "MM_CODES": 60052520,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 520,
        "Discontinued_Date": "2015-11-26 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA \/ INNOVA \/ RUMION",
        "Variant": "RUMION 1.5 S",
        "MM_CODES": 60052600,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 600,
        "Discontinued_Date": "2023-06-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA \/ INNOVA \/ RUMION",
        "Variant": "RUMION 1.5 SX",
        "MM_CODES": 60052610,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 610,
        "Discontinued_Date": "2023-06-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "AVANZA \/ INNOVA \/ RUMION",
        "Variant": "RUMION 1.5 SX A\/T",
        "MM_CODES": 60052620,
        "GCode": 600,
        "MCode": 52,
        "Vcode": 620,
        "Discontinued_Date": "2023-06-14 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 3.0D-4D R\/B 4X4",
        "MM_CODES": 60054281,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 281,
        "Discontinued_Date": "2011-09-05 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 3.0D-4D 4X4",
        "MM_CODES": 60054282,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 282,
        "Discontinued_Date": "2016-12-06 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 3.0D-4D HERITAGE 4X4",
        "MM_CODES": 60054285,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 285,
        "Discontinued_Date": "2013-04-02 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 3.0D-4D 4X4 A\/T",
        "MM_CODES": 60054290,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 290,
        "Discontinued_Date": "2011-09-05 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 3.0D-4D 4X4 A\/T",
        "MM_CODES": 60054291,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 291,
        "Discontinued_Date": "2016-12-06 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 4.0 V6 HERITAGE A\/T 4X4",
        "MM_CODES": 60054446,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 446,
        "Discontinued_Date": "2013-04-02 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 4.0 V6 EPIC 4X4 A\/T",
        "MM_CODES": 60054450,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 450,
        "Discontinued_Date": "2010-03-30 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 2.4GD-6 R\/B",
        "MM_CODES": 60054500,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 500,
        "Discontinued_Date": "2020-10-27 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 2.4GD-6 R\/B",
        "MM_CODES": 60054501,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 501,
        "Discontinued_Date": "2023-01-23 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 2.4GD-6 R\/B A\/T",
        "MM_CODES": 60054521,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 521,
        "Discontinued_Date": "2023-01-23 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 2.4GD-6 4X4 A\/T",
        "MM_CODES": 60054530,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 530,
        "Discontinued_Date": "2020-10-27 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 2.7 VVTI R\/B A\/T",
        "MM_CODES": 60054550,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 550,
        "Discontinued_Date": "2020-10-27 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 2.8GD-6 R\/B A\/T",
        "MM_CODES": 60054600,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 600,
        "Discontinued_Date": "2020-10-27 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 2.8GD-6 EPIC BLACK A\/T",
        "MM_CODES": 60054610,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 610,
        "Discontinued_Date": "2021-03-31 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 2.8GD-6 VX A\/T",
        "MM_CODES": 60054615,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 615,
        "Discontinued_Date": "2023-01-23 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 2.8GD-6 4X4",
        "MM_CODES": 60054620,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 620,
        "Discontinued_Date": "2020-03-17 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 2.8GD-6 4X4 A\/T",
        "MM_CODES": 60054640,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 640,
        "Discontinued_Date": "2020-10-27 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 2.8GD-6 4X4 A\/T",
        "MM_CODES": 60054641,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 641,
        "Discontinued_Date": "2023-01-23 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 2.8GD-6 4X4 EPIC A\/T",
        "MM_CODES": 60054645,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 645,
        "Discontinued_Date": "2021-04-22 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 2.8 GD-6 4X4 VX A\/T",
        "MM_CODES": 60054655,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 655,
        "Discontinued_Date": "2023-01-23 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "FORTUNER",
        "Variant": "FORTUNER 4.0 V6 4X4 A\/T ",
        "MM_CODES": 60054700,
        "GCode": 600,
        "MCode": 54,
        "Vcode": 700,
        "Discontinued_Date": "2020-03-17 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "QUANTUM",
        "Variant": "QUANTUM 2.5 D-4D LWB F\/C P\/V",
        "MM_CODES": 60058220,
        "GCode": 600,
        "MCode": 58,
        "Vcode": 220,
        "Discontinued_Date": "2019-09-30 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "QUANTUM",
        "Variant": "QUANTUM 2.7 LWB F\/C P\/V",
        "MM_CODES": 60058320,
        "GCode": 600,
        "MCode": 58,
        "Vcode": 320,
        "Discontinued_Date": "2019-09-30 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "QUANTUM",
        "Variant": "QUANTUM 2.7 CREWCAB F\/C P\/V",
        "MM_CODES": 60058350,
        "GCode": 600,
        "MCode": 58,
        "Vcode": 350,
        "Discontinued_Date": "2019-09-30 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "QUANTUM",
        "Variant": "QUANTUM 2.5 D-4D 10 SEAT",
        "MM_CODES": 60058380,
        "GCode": 600,
        "MCode": 58,
        "Vcode": 380,
        "Discontinued_Date": "2020-11-10 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "QUANTUM",
        "Variant": "QUANTUM 2.5 D-4D SESFIKILE 15S",
        "MM_CODES": 60058405,
        "GCode": 600,
        "MCode": 58,
        "Vcode": 405,
        "Discontinued_Date": "2012-07-04 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "QUANTUM\/HIACE -2019",
        "Variant": "QUANTUM 2.5 D-4D SESFIKILE 15S",
        "MM_CODES": 60058405,
        "GCode": 600,
        "MCode": 58,
        "Vcode": 405,
        "Discontinued_Date": "2012-07-04 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "QUANTUM",
        "Variant": "QUANTUM 2.7 10 SEAT",
        "MM_CODES": 60058410,
        "GCode": 600,
        "MCode": 58,
        "Vcode": 410,
        "Discontinued_Date": "2020-11-10 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "QUANTUM",
        "Variant": "QUANTUM 2.7 SESFIKILE 15S",
        "MM_CODES": 60058417,
        "GCode": 600,
        "MCode": 58,
        "Vcode": 417,
        "Discontinued_Date": "2010-10-28 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "QUANTUM\/HIACE -2019",
        "Variant": "QUANTUM 2.7 SESFIKILE 15S",
        "MM_CODES": 60058417,
        "GCode": 600,
        "MCode": 58,
        "Vcode": 417,
        "Discontinued_Date": "2010-10-28 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "QUANTUM",
        "Variant": "QUANTUM 2.7 SESFIKILE 15S",
        "MM_CODES": 60058418,
        "GCode": 600,
        "MCode": 58,
        "Vcode": 418,
        "Discontinued_Date": "2012-07-04 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "QUANTUM\/HIACE -2019",
        "Variant": "QUANTUM 2.7 SESFIKILE 15S",
        "MM_CODES": 60058418,
        "GCode": 600,
        "MCode": 58,
        "Vcode": 418,
        "Discontinued_Date": "2012-07-04 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "RAV 4",
        "Variant": "RAV4 2.0 GX",
        "MM_CODES": 60077603,
        "GCode": 600,
        "MCode": 77,
        "Vcode": 603,
        "Discontinued_Date": "2019-02-26 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "RAV 4",
        "Variant": "RAV4 2.0 GX",
        "MM_CODES": 60077604,
        "GCode": 600,
        "MCode": 77,
        "Vcode": 604,
        "Discontinued_Date": "2022-01-21 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "RAV 4",
        "Variant": "RAV4 2.0 GX A\/T",
        "MM_CODES": 60077610,
        "GCode": 600,
        "MCode": 77,
        "Vcode": 610,
        "Discontinued_Date": "2013-02-21 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "RAV 4",
        "Variant": "RAV4 2.0 GX A\/T",
        "MM_CODES": 60077611,
        "GCode": 600,
        "MCode": 77,
        "Vcode": 611,
        "Discontinued_Date": "2015-11-20 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "RAV 4",
        "Variant": "RAV4 2.0 GX A\/T",
        "MM_CODES": 60077612,
        "GCode": 600,
        "MCode": 77,
        "Vcode": 612,
        "Discontinued_Date": "2019-02-26 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "RAV 4",
        "Variant": "RAV4 2.0 GX-R CVT AWD",
        "MM_CODES": 60077620,
        "GCode": 600,
        "MCode": 77,
        "Vcode": 620,
        "Discontinued_Date": "2022-01-24 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "RAV 4",
        "Variant": "RAV4 2.0 VX A\/T",
        "MM_CODES": 60077651,
        "GCode": 600,
        "MCode": 77,
        "Vcode": 651,
        "Discontinued_Date": "2013-02-21 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "RAV 4",
        "Variant": "RAV 2.2D VX A\/T",
        "MM_CODES": 60077730,
        "GCode": 600,
        "MCode": 77,
        "Vcode": 730,
        "Discontinued_Date": "2015-11-20 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "RAV 4",
        "Variant": "RAV4 2.5 GX HYBRID CVT",
        "MM_CODES": 60077740,
        "GCode": 600,
        "MCode": 77,
        "Vcode": 740,
        "Discontinued_Date": "2022-01-21 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "LANDCRUISER 70 SERIES",
        "Variant": "LANDCRUISER 76 4.2D S\/W",
        "MM_CODES": 60078101,
        "GCode": 600,
        "MCode": 78,
        "Vcode": 101,
        "Discontinued_Date": "2015-08-04 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "LANDCRUISER 70 SERIES",
        "Variant": "LANDCRUISER 78 4.2D S\/W",
        "MM_CODES": 60078110,
        "GCode": 600,
        "MCode": 78,
        "Vcode": 110,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "LAND CRUISER PICK UP",
        "Variant": "LAND CRUISER 79 4.2D 60TH ED P\/U S\/C",
        "MM_CODES": 60078415,
        "GCode": 600,
        "MCode": 78,
        "Vcode": 415,
        "Discontinued_Date": "2013-12-01 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "LAND CRUISER PICK UP",
        "Variant": "LAND CRUISER 79 4.5D NAMIB P\/U D\/C",
        "MM_CODES": 60078550,
        "GCode": 600,
        "MCode": 78,
        "Vcode": 550,
        "Discontinued_Date": "2021-06-29 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "PRADO 2002 - ON",
        "Variant": "PRADO TX 3.0D A\/T",
        "MM_CODES": 60079182,
        "GCode": 600,
        "MCode": 79,
        "Vcode": 182,
        "Discontinued_Date": "2020-09-30 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "PRADO 2002 - ON",
        "Variant": "PRADO 3.0TD TX 60TH ANNIVERSARY ED",
        "MM_CODES": 60079185,
        "GCode": 600,
        "MCode": 79,
        "Vcode": 185,
        "Discontinued_Date": "2013-10-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "PRADO 2002 - ON",
        "Variant": "PRADO VX 3.0 TDI A\/T",
        "MM_CODES": 60079200,
        "GCode": 600,
        "MCode": 79,
        "Vcode": 200,
        "Discontinued_Date": "2009-10-26 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "PRADO 2002 - ON",
        "Variant": "PRADO VX 3.0 TDI A\/T",
        "MM_CODES": 60079201,
        "GCode": 600,
        "MCode": 79,
        "Vcode": 201,
        "Discontinued_Date": "2013-10-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "PRADO 2002 - ON",
        "Variant": "PRADO VX-L 2.8GD A\/T",
        "MM_CODES": 60079211,
        "GCode": 600,
        "MCode": 79,
        "Vcode": 211,
        "Discontinued_Date": "1899-12-30 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "PRADO 2002 - ON",
        "Variant": "PRADO TX 4.0 V6 A\/T",
        "MM_CODES": 60079380,
        "GCode": 600,
        "MCode": 79,
        "Vcode": 380,
        "Discontinued_Date": "2013-10-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "PRADO 2002 - ON",
        "Variant": "PRADO VX 4.0 V6 A\/T",
        "MM_CODES": 60079402,
        "GCode": 600,
        "MCode": 79,
        "Vcode": 402,
        "Discontinued_Date": "2013-10-15 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "PRADO 2002 - ON",
        "Variant": "PRADO VX 4.0 V6 A\/T",
        "MM_CODES": 60079403,
        "GCode": 600,
        "MCode": 79,
        "Vcode": 403,
        "Discontinued_Date": "2015-07-29 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "PRADO 2002 - ON",
        "Variant": "PRADO VX 4.0 V6 A\/T",
        "MM_CODES": 60079404,
        "GCode": 600,
        "MCode": 79,
        "Vcode": 404,
        "Discontinued_Date": "2017-09-27 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "LAND CRUISER \/ LEXUS",
        "Variant": "L\/CRUISER FJ 4.0 V6 CRUISER",
        "MM_CODES": 60080606,
        "GCode": 600,
        "MCode": 80,
        "Vcode": 606,
        "Discontinued_Date": "2022-06-30 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "LAND CRUISER \/ LEXUS",
        "Variant": "L\/CRUISER FJ 4.0 V6 SPORT CRUISER",
        "MM_CODES": 60080611,
        "GCode": 600,
        "MCode": 80,
        "Vcode": 611,
        "Discontinued_Date": "2016-06-02 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "LAND CRUISER \/ LEXUS",
        "Variant": "L\/CRUISER FJ 4.0 V6 TRAIL CRUISER",
        "MM_CODES": 60080616,
        "GCode": 600,
        "MCode": 80,
        "Vcode": 616,
        "Discontinued_Date": "2012-07-04 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "LAND CRUISER \/ LEXUS",
        "Variant": "LAND CRUISER 200 V8 4.5D GX A\/T",
        "MM_CODES": 60080691,
        "GCode": 600,
        "MCode": 80,
        "Vcode": 691,
        "Discontinued_Date": "2022-07-08 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "LAND CRUISER \/ LEXUS",
        "Variant": "LANDCRUISER 200 V8 TD VX A\/T",
        "MM_CODES": 60080700,
        "GCode": 600,
        "MCode": 80,
        "Vcode": 700,
        "Discontinued_Date": "2009-10-26 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "LAND CRUISER \/ LEXUS",
        "Variant": "LANDCRUISER 200 V8 4.5D VX A\/T",
        "MM_CODES": 60080702,
        "GCode": 600,
        "MCode": 80,
        "Vcode": 702,
        "Discontinued_Date": "2016-11-23 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "LAND CRUISER \/ LEXUS",
        "Variant": "LANDCRUISER 200 V8 TD VX SPECIAL ED A\/T",
        "MM_CODES": 60080705,
        "GCode": 600,
        "MCode": 80,
        "Vcode": 705,
        "Discontinued_Date": "2012-11-08 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "LAND CRUISER \/ LEXUS",
        "Variant": "LAND CRUISER 200 V8 4.5D VX-R A\/T",
        "MM_CODES": 60080715,
        "GCode": 600,
        "MCode": 80,
        "Vcode": 715,
        "Discontinued_Date": "2022-06-30 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "LAND CRUISER \/ LEXUS",
        "Variant": "LANDCRUISER 200 V8 4.6 VX A\/T",
        "MM_CODES": 60080762,
        "GCode": 600,
        "MCode": 80,
        "Vcode": 762,
        "Discontinued_Date": "2016-04-12 00:00:00.000"
    },
    {
        "Make": "TOYOTA",
        "Model": "LAND CRUISER \/ LEXUS",
        "Variant": "LANDCRUISER 300 V6 3.5T ZX",
        "MM_CODES": 60080850,
        "GCode": 600,
        "MCode": 80,
        "Vcode": 850,
        "Discontinued_Date": "1899-12-30 00:00:00.000"
    },
    {
        "Make": "UD TRUCKS",
        "Model": "QUON",
        "Variant": "CW 26 490 (E05) A\/T F\/C C\/C",
        "MM_CODES": 61120480,
        "GCode": 611,
        "MCode": 20,
        "Vcode": 480,
        "Discontinued_Date": "2019-08-13 00:00:00.000"
    },
    {
        "Make": "UD TRUCKS",
        "Model": "QUON",
        "Variant": "GW 26 410 (E08) A\/T T\/T C\/C",
        "MM_CODES": 61120600,
        "GCode": 611,
        "MCode": 20,
        "Vcode": 600,
        "Discontinued_Date": "2018-09-10 00:00:00.000"
    },
    {
        "Make": "UD TRUCKS",
        "Model": "QUON",
        "Variant": "GW 26 450 (E15) A\/T T\/T C\/C",
        "MM_CODES": 61120650,
        "GCode": 611,
        "MCode": 20,
        "Vcode": 650,
        "Discontinued_Date": "2019-09-02 00:00:00.000"
    },
    {
        "Make": "UD TRUCKS",
        "Model": "QUON",
        "Variant": "GW 26 450 (E16) HR A\/T T\/T C\/C",
        "MM_CODES": 61120655,
        "GCode": 611,
        "MCode": 20,
        "Vcode": 655,
        "Discontinued_Date": "2019-09-02 00:00:00.000"
    },
    {
        "Make": "UD TRUCKS",
        "Model": "QUON",
        "Variant": "GW 26 490 (E11) A\/T T\/T C\/C",
        "MM_CODES": 61120705,
        "GCode": 611,
        "MCode": 20,
        "Vcode": 705,
        "Discontinued_Date": "2018-09-10 00:00:00.000"
    },
    {
        "Make": "UD TRUCKS",
        "Model": "QUESTER",
        "Variant": "CWE330 (E22) 6X4 MIX C\/C",
        "MM_CODES": 61125110,
        "GCode": 611,
        "MCode": 25,
        "Vcode": 110,
        "Discontinued_Date": "2019-10-02 00:00:00.000"
    },
    {
        "Make": "UD TRUCKS",
        "Model": "QUESTER",
        "Variant": "CWE330 (E44) 6X4 A\/T COMPACTOR C\/C",
        "MM_CODES": 61125135,
        "GCode": 611,
        "MCode": 25,
        "Vcode": 135,
        "Discontinued_Date": "2023-08-17 00:00:00.000"
    },
    {
        "Make": "UD TRUCKS",
        "Model": "QUESTER",
        "Variant": "GKE370 (E20) 4X2 T\/T C\/C",
        "MM_CODES": 61125180,
        "GCode": 611,
        "MCode": 25,
        "Vcode": 180,
        "Discontinued_Date": "2019-10-02 00:00:00.000"
    },
    {
        "Make": "UD TRUCKS",
        "Model": "QUESTER",
        "Variant": "CGE420 (E32) 8X4 MIX C\/C",
        "MM_CODES": 61125250,
        "GCode": 611,
        "MCode": 25,
        "Vcode": 250,
        "Discontinued_Date": "2019-08-13 00:00:00.000"
    },
    {
        "Make": "UD TRUCKS",
        "Model": "QUESTER",
        "Variant": "CGE420 (E28) 8X4 TIP C\/C",
        "MM_CODES": 61125255,
        "GCode": 611,
        "MCode": 25,
        "Vcode": 255,
        "Discontinued_Date": "2019-10-02 00:00:00.000"
    },
    {
        "Make": "UD TRUCKS",
        "Model": "QUESTER",
        "Variant": "CGE420 (E29) 8X4 F\/C C\/C",
        "MM_CODES": 61125260,
        "GCode": 611,
        "MCode": 25,
        "Vcode": 260,
        "Discontinued_Date": "2020-06-08 00:00:00.000"
    },
    {
        "Make": "UD TRUCKS",
        "Model": "QUESTER",
        "Variant": "CWE420 (E27) 6X4 F\/C C\/C",
        "MM_CODES": 61125280,
        "GCode": 611,
        "MCode": 25,
        "Vcode": 280,
        "Discontinued_Date": "2019-08-13 00:00:00.000"
    },
    {
        "Make": "UD TRUCKS",
        "Model": "QUESTER",
        "Variant": "GWE420 (E30) 6X4 LOGISTICS T\/T C\/C",
        "MM_CODES": 61125290,
        "GCode": 611,
        "MCode": 25,
        "Vcode": 290,
        "Discontinued_Date": "2019-08-13 00:00:00.000"
    },
    {
        "Make": "UD TRUCKS",
        "Model": "CRONER",
        "Variant": "CRONER MKE 180 (H21) 4X2 A\/T F\/C C\/C",
        "MM_CODES": 61128105,
        "GCode": 611,
        "MCode": 28,
        "Vcode": 105,
        "Discontinued_Date": "2019-08-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "UP!",
        "Variant": "MOVE UP! 1.0 3DR",
        "MM_CODES": 64018160,
        "GCode": 640,
        "MCode": 18,
        "Vcode": 160,
        "Discontinued_Date": "2016-11-28 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "UP!",
        "Variant": "CROSS UP! 1.0 5DR",
        "MM_CODES": 64018220,
        "GCode": 640,
        "MCode": 18,
        "Vcode": 220,
        "Discontinued_Date": "2020-08-28 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO GP 1.4 STREET 5DR",
        "MM_CODES": 64020109,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 109,
        "Discontinued_Date": "2018-07-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.4 TRENDLINE 5DR",
        "MM_CODES": 64020110,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 110,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO GP 1.4 TRENDLINE 5DR",
        "MM_CODES": 64020111,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 111,
        "Discontinued_Date": "2018-07-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.4 TRENDLINE TIP 5DR",
        "MM_CODES": 64020120,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 120,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO GP 1.4 TRENDLINE TIP 5DR",
        "MM_CODES": 64020121,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 121,
        "Discontinued_Date": "2018-07-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO GP 1.4 ECLIPSE 5DR",
        "MM_CODES": 64020130,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 130,
        "Discontinued_Date": "2016-09-05 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.4 STORM 5DR",
        "MM_CODES": 64020135,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 135,
        "Discontinued_Date": "2018-07-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.6 GT 3DR",
        "MM_CODES": 64020140,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 140,
        "Discontinued_Date": "2014-03-28 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.6 5DR",
        "MM_CODES": 64020150,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 150,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.6 TRENDLINE 5DR",
        "MM_CODES": 64020160,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 160,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.6 GT 5DR",
        "MM_CODES": 64020170,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 170,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO GP 1.6 GT 5DR",
        "MM_CODES": 64020171,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 171,
        "Discontinued_Date": "2018-07-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO GP 1.6 GTS 5DR",
        "MM_CODES": 64020176,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 176,
        "Discontinued_Date": "2018-07-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.6 MAXX 5DR",
        "MM_CODES": 64020180,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 180,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO GP 1.6 MAXX 5DR",
        "MM_CODES": 64020181,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 181,
        "Discontinued_Date": "2018-07-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.4",
        "MM_CODES": 64020500,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 500,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO GP 1.4 CONCEPTLINE",
        "MM_CODES": 64020501,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 501,
        "Discontinued_Date": "2018-12-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.4 BLUELINE",
        "MM_CODES": 64020505,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 505,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO GP 1.4 BLUELINE",
        "MM_CODES": 64020506,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 506,
        "Discontinued_Date": "2018-11-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.4 TRENDLINE",
        "MM_CODES": 64020510,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 510,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO GP 1.4 TRENDLINE",
        "MM_CODES": 64020511,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 511,
        "Discontinued_Date": "2018-12-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.4 TRENDLINE TIP",
        "MM_CODES": 64020520,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 520,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO GP 1.4 ECLIPSE",
        "MM_CODES": 64020530,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 530,
        "Discontinued_Date": "2016-09-05 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.6",
        "MM_CODES": 64020550,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 550,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.6 TRENDLINE",
        "MM_CODES": 64020560,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 560,
        "Discontinued_Date": "2014-08-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO GP 1.6 TRENDLINE",
        "MM_CODES": 64020561,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 561,
        "Discontinued_Date": "2018-12-18 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO GP 1.6 COMFORTLINE",
        "MM_CODES": 64020590,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 590,
        "Discontinued_Date": "2018-12-18 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO VIVO",
        "Variant": "POLO VIVO 1.4 MSWENKO (5DR)",
        "MM_CODES": 64020730,
        "GCode": 640,
        "MCode": 20,
        "Vcode": 730,
        "Discontinued_Date": "2022-01-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO 1.6 COMFORTLINE SE",
        "MM_CODES": 64027565,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 565,
        "Discontinued_Date": "2010-01-22 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO GP 1.0 TSI BLUEMOTION",
        "MM_CODES": 64027653,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 653,
        "Discontinued_Date": "2018-02-08 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO GP 1.0 TSI R-LINE DSG",
        "MM_CODES": 64027654,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 654,
        "Discontinued_Date": "2018-02-08 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO 1.2 TDI BLUEMOTION 5DR",
        "MM_CODES": 64027655,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 655,
        "Discontinued_Date": "2015-07-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO GP 1.2 TSI TRENDLINE (66KW)",
        "MM_CODES": 64027660,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 660,
        "Discontinued_Date": "2018-02-08 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO 1.0 TSI TRENDLINE",
        "MM_CODES": 64027661,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 661,
        "Discontinued_Date": "2022-01-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO GP 1.2 TSI COMFORTLINE (66KW)",
        "MM_CODES": 64027663,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 663,
        "Discontinued_Date": "2018-02-08 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO 1.0 TSI COMFORTLINE",
        "MM_CODES": 64027664,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 664,
        "Discontinued_Date": "2022-01-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO 1.0 TSI COMFORTLINE DSG",
        "MM_CODES": 64027670,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 670,
        "Discontinued_Date": "2022-01-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO GP 1.2 TSI HIGHLINE (81KW)",
        "MM_CODES": 64027678,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 678,
        "Discontinued_Date": "2018-02-08 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO 1.0 TSI HIGHLINE (85KW)",
        "MM_CODES": 64027679,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 679,
        "Discontinued_Date": "2020-12-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO GP 1.2 TSI HIGHLINE DSG (81KW)",
        "MM_CODES": 64027696,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 696,
        "Discontinued_Date": "2018-02-08 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO 1.0 TSI HIGHLINE DSG (85KW)",
        "MM_CODES": 64027697,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 697,
        "Discontinued_Date": "2022-01-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO CROSS 1.2 TSI",
        "MM_CODES": 64027698,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 698,
        "Discontinued_Date": "2018-02-08 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO 1.4 TRENDLINE 5DR",
        "MM_CODES": 64027700,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 700,
        "Discontinued_Date": "2014-12-30 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO 1.4 COMFORTLINE 5DR",
        "MM_CODES": 64027710,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 710,
        "Discontinued_Date": "2014-12-30 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO GP 1.4 TDI TRENDLINE",
        "MM_CODES": 64027730,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 730,
        "Discontinued_Date": "2018-02-08 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO GP 1.4 TDI HIGHLINE",
        "MM_CODES": 64027740,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 740,
        "Discontinued_Date": "2018-02-08 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO GP 1.4 TDI CROSS",
        "MM_CODES": 64027750,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 750,
        "Discontinued_Date": "2017-06-20 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO 1.6 CONCEPTLINE 5DR",
        "MM_CODES": 64027770,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 770,
        "Discontinued_Date": "2020-09-30 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO 1.6 TRENDLINE 5DR",
        "MM_CODES": 64027780,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 780,
        "Discontinued_Date": "2014-12-30 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO 1.6 COMFORTLINE 5DR",
        "MM_CODES": 64027790,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 790,
        "Discontinued_Date": "2014-12-30 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO PLAYA \/ POLO",
        "Variant": "POLO GTI 1.4TSI DSG",
        "MM_CODES": 64027870,
        "GCode": 640,
        "MCode": 27,
        "Vcode": 870,
        "Discontinued_Date": "2014-12-30 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO CLASSIC",
        "Variant": "POLO 1.4 COMFORTLINE",
        "MM_CODES": 64028521,
        "GCode": 640,
        "MCode": 28,
        "Vcode": 521,
        "Discontinued_Date": "2015-08-31 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO CLASSIC",
        "Variant": "POLO GP 1.4 COMFORTLINE",
        "MM_CODES": 64028522,
        "GCode": 640,
        "MCode": 28,
        "Vcode": 522,
        "Discontinued_Date": "2022-12-06 22:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO CLASSIC",
        "Variant": "POLO 1.6 TRENDLINE",
        "MM_CODES": 64028702,
        "GCode": 640,
        "MCode": 28,
        "Vcode": 702,
        "Discontinued_Date": "2015-08-31 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO CLASSIC",
        "Variant": "POLO GP 1.6 TRENDLINE",
        "MM_CODES": 64028703,
        "GCode": 640,
        "MCode": 28,
        "Vcode": 703,
        "Discontinued_Date": "2022-09-08 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO CLASSIC",
        "Variant": "POLO 1.6 COMFORTLINE",
        "MM_CODES": 64028722,
        "GCode": 640,
        "MCode": 28,
        "Vcode": 722,
        "Discontinued_Date": "2015-08-31 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO CLASSIC",
        "Variant": "POLO CLASSIC 1.6 COMFORTLINE SE",
        "MM_CODES": 64028723,
        "GCode": 640,
        "MCode": 28,
        "Vcode": 723,
        "Discontinued_Date": "2011-05-09 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO CLASSIC",
        "Variant": "POLO GP 1.6 COMFORTLINE",
        "MM_CODES": 64028724,
        "GCode": 640,
        "MCode": 28,
        "Vcode": 724,
        "Discontinued_Date": "2022-09-08 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "POLO CLASSIC",
        "Variant": "POLO 1.6 COMFORTLINE TIP",
        "MM_CODES": 64028726,
        "GCode": 640,
        "MCode": 28,
        "Vcode": 726,
        "Discontinued_Date": "2015-08-31 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY 1.0 TSI TRENDLINE",
        "MM_CODES": 64038395,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 395,
        "Discontinued_Date": "2021-08-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY CREWBUS 1.6I",
        "MM_CODES": 64038400,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 400,
        "Discontinued_Date": "2020-01-28 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY4 CREWBUS 2.0 TDI",
        "MM_CODES": 64038420,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 420,
        "Discontinued_Date": "2021-08-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY 2.0TDI (81KW) TRENDLINE",
        "MM_CODES": 64038450,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 450,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY 2.0TDI TRENDLINE",
        "MM_CODES": 64038451,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 451,
        "Discontinued_Date": "2021-08-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY CROSS 2.0 TDI (81KW)",
        "MM_CODES": 64038470,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 470,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY ALLTRACK 2.0 TDI",
        "MM_CODES": 64038471,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 471,
        "Discontinued_Date": "2021-02-12 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY ALLTRACK 2.0 TDI DSG (103KW)",
        "MM_CODES": 64038480,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 480,
        "Discontinued_Date": "2021-08-20 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY MAXI CREWBUS 1.6 TDI DSG",
        "MM_CODES": 64038490,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 490,
        "Discontinued_Date": "2019-05-07 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY4 MAXI CREWBUS 1.6 TDI DSG",
        "MM_CODES": 64038491,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 491,
        "Discontinued_Date": "2019-05-07 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY 2.0TDI (81KW) F\/C P\/V",
        "MM_CODES": 64038501,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 501,
        "Discontinued_Date": "2015-11-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY 2.0TDI (81KW) F\/C P\/V",
        "MM_CODES": 64038502,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 502,
        "Discontinued_Date": "2021-11-03 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY MAXI 2.0TDI (81KW) F\/C P\/V",
        "MM_CODES": 64038550,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 550,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY MAXI 2.0TDI (81KW) F\/C P\/V",
        "MM_CODES": 64038551,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 551,
        "Discontinued_Date": "2021-11-03 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY MAXI SPORT 2.0TDI (81KW) F\/C P\/V",
        "MM_CODES": 64038554,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 554,
        "Discontinued_Date": "2021-03-25 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY MAXI 2.0TDI (103KW) DSG F\/C P\/V",
        "MM_CODES": 64038557,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 557,
        "Discontinued_Date": "2021-11-03 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY MAXI 2.0TDI (81KW) CREWBUS P\/V",
        "MM_CODES": 64038560,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 560,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY MAXI CREWBUS 2.0 TDI",
        "MM_CODES": 64038565,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 565,
        "Discontinued_Date": "2021-08-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY MAXI 2.0TDI (81KW) TRND LNE",
        "MM_CODES": 64038570,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 570,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY MAXI 2.0 TDI TRENDLINE",
        "MM_CODES": 64038571,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 571,
        "Discontinued_Date": "2021-08-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY MAXI  CREWBUS 2.0 TDI DSG",
        "MM_CODES": 64038590,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 590,
        "Discontinued_Date": "2021-08-20 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY MAXI 2.0TDI TREND DSG",
        "MM_CODES": 64038600,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 600,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CADDY",
        "Variant": "CADDY MAXI 2.0 TDI TRENDLINE DSG (103KW)",
        "MM_CODES": 64038601,
        "GCode": 640,
        "MCode": 38,
        "Vcode": 601,
        "Discontinued_Date": "2021-08-20 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 1.0 TSI TRENDLINE ",
        "MM_CODES": 64045401,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 401,
        "Discontinued_Date": "2021-10-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 1.0 TSI COMFORTLINE",
        "MM_CODES": 64045402,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 402,
        "Discontinued_Date": "2021-10-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII 1.2 TSI TRENDLINE",
        "MM_CODES": 64045403,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 403,
        "Discontinued_Date": "2015-08-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 1.2 TSI TRENDLINE",
        "MM_CODES": 64045403,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 403,
        "Discontinued_Date": "2015-08-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII 1.2 TSI TRENDLINE",
        "MM_CODES": 64045404,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 404,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 1.2 TSI TRENDLINE",
        "MM_CODES": 64045404,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 404,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF SV 1.2 TSI TRENDLINE",
        "MM_CODES": 64045406,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 406,
        "Discontinued_Date": "2017-06-20 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF SV 1.2 TSI TRENDLINE",
        "MM_CODES": 64045406,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 406,
        "Discontinued_Date": "2017-06-20 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI 1.4 TSI TRENDLINE",
        "MM_CODES": 64045410,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 410,
        "Discontinued_Date": "2013-02-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VI 1.4 TSI TRENDLINE",
        "MM_CODES": 64045410,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 410,
        "Discontinued_Date": "2013-02-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII 1.4 TSI TRENDLINE",
        "MM_CODES": 64045411,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 411,
        "Discontinued_Date": "2015-08-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 1.4 TSI TRENDLINE",
        "MM_CODES": 64045411,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 411,
        "Discontinued_Date": "2015-08-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII 1.4 TSI TRENDLINE",
        "MM_CODES": 64045412,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 412,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 1.4 TSI TRENDLINE",
        "MM_CODES": 64045412,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 412,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI 1.4 TSI COMFORTLINE DSG",
        "MM_CODES": 64045425,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 425,
        "Discontinued_Date": "2013-02-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VI 1.4 TSI COMFORTLINE DSG",
        "MM_CODES": 64045425,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 425,
        "Discontinued_Date": "2013-02-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII 1.4 TSI COMFORTLINE DSG",
        "MM_CODES": 64045426,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 426,
        "Discontinued_Date": "2015-08-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 1.4 TSI COMFORTLINE DSG",
        "MM_CODES": 64045426,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 426,
        "Discontinued_Date": "2015-08-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII 1.4 TSI COMFORTLINE DSG",
        "MM_CODES": 64045427,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 427,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 1.4 TSI COMFORTLINE DSG",
        "MM_CODES": 64045427,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 427,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 1.4 TSI COMFORTLINE DSG",
        "MM_CODES": 64045428,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 428,
        "Discontinued_Date": "2021-10-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF SV 1.4 TSI COMFORTLINE DSG",
        "MM_CODES": 64045435,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 435,
        "Discontinued_Date": "2017-06-20 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF SV 1.4 TSI COMFORTLINE DSG",
        "MM_CODES": 64045435,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 435,
        "Discontinued_Date": "2017-06-20 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI 1.4 TSI HIGHLINE (118KW)",
        "MM_CODES": 64045460,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 460,
        "Discontinued_Date": "2013-02-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VI 1.4 TSI HIGHLINE (118KW)",
        "MM_CODES": 64045460,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 460,
        "Discontinued_Date": "2013-02-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII 1.4 TSI HIGHLINE",
        "MM_CODES": 64045461,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 461,
        "Discontinued_Date": "2015-08-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 1.4 TSI HIGHLINE",
        "MM_CODES": 64045461,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 461,
        "Discontinued_Date": "2015-08-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII 1.4 TSI HIGHLINE",
        "MM_CODES": 64045462,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 462,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 1.4 TSI HIGHLINE",
        "MM_CODES": 64045462,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 462,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI 1.4 TSI CABRIO C\/LINE",
        "MM_CODES": 64045470,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 470,
        "Discontinued_Date": "2015-08-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VI 1.4 TSI CABRIO C\/LINE",
        "MM_CODES": 64045470,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 470,
        "Discontinued_Date": "2015-08-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI 1.4 TSI CABRIO C\/LINE",
        "MM_CODES": 64045471,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 471,
        "Discontinued_Date": "2018-12-12 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VI 1.4 TSI CABRIO C\/LINE",
        "MM_CODES": 64045471,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 471,
        "Discontinued_Date": "2018-12-12 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI 1.6 TDI COMFORTLINE",
        "MM_CODES": 64045610,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 610,
        "Discontinued_Date": "2013-02-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VI 1.6 TDI COMFORTLINE",
        "MM_CODES": 64045610,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 610,
        "Discontinued_Date": "2013-02-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI 1.6 TDI COMFORTLINE DSG",
        "MM_CODES": 64045620,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 620,
        "Discontinued_Date": "2013-02-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VI 1.6 TDI COMFORTLINE DSG",
        "MM_CODES": 64045620,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 620,
        "Discontinued_Date": "2013-02-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 2.0 TDI COMFORTLINE DSG",
        "MM_CODES": 64045835,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 835,
        "Discontinued_Date": "2020-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF SV 2.0 TDI COMFORTLINE",
        "MM_CODES": 64045840,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 840,
        "Discontinued_Date": "2017-06-20 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF SV 2.0 TDI COMFORTLINE",
        "MM_CODES": 64045840,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 840,
        "Discontinued_Date": "2017-06-20 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI 2.0 TDI HIGHLINE",
        "MM_CODES": 64045852,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 852,
        "Discontinued_Date": "2013-02-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VI 2.0 TDI HIGHLINE",
        "MM_CODES": 64045852,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 852,
        "Discontinued_Date": "2013-02-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII 2.0 TDI HIGHLINE DSG",
        "MM_CODES": 64045880,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 880,
        "Discontinued_Date": "2017-06-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 2.0 TDI HIGHLINE DSG",
        "MM_CODES": 64045880,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 880,
        "Discontinued_Date": "2017-06-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII GTD 2.0 TDI DSG",
        "MM_CODES": 64045885,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 885,
        "Discontinued_Date": "2019-06-27 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII GTD 2.0 TDI DSG",
        "MM_CODES": 64045885,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 885,
        "Discontinued_Date": "2019-06-27 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF SV 2.0 TDI COMFORTLINE DSG",
        "MM_CODES": 64045890,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 890,
        "Discontinued_Date": "2017-06-20 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF SV 2.0 TDI COMFORTLINE DSG",
        "MM_CODES": 64045890,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 890,
        "Discontinued_Date": "2017-06-20 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI GTI 2.0 TSI",
        "MM_CODES": 64045901,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 901,
        "Discontinued_Date": "2013-06-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VI GTI 2.0 TSI",
        "MM_CODES": 64045901,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 901,
        "Discontinued_Date": "2013-06-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII GTI 2.0 TSI",
        "MM_CODES": 64045902,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 902,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII GTI 2.0 TSI",
        "MM_CODES": 64045902,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 902,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI GTI 2.O TSI  ED35",
        "MM_CODES": 64045905,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 905,
        "Discontinued_Date": "2013-06-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VI GTI 2.O TSI  ED35",
        "MM_CODES": 64045905,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 905,
        "Discontinued_Date": "2013-06-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI GTI 2.0 TSI DSG",
        "MM_CODES": 64045921,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 921,
        "Discontinued_Date": "2013-06-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VI GTI 2.0 TSI DSG",
        "MM_CODES": 64045921,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 921,
        "Discontinued_Date": "2013-06-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII GTI 2.0 TSI DSG",
        "MM_CODES": 64045922,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 922,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII GTI 2.0 TSI DSG",
        "MM_CODES": 64045922,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 922,
        "Discontinued_Date": "2017-04-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII GTI 2.0 TSI DSG",
        "MM_CODES": 64045923,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 923,
        "Discontinued_Date": "2021-08-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII GTI 2.0 TSI DSG",
        "MM_CODES": 64045923,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 923,
        "Discontinued_Date": "2021-08-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI GTI 2.O TSI DSG ED35",
        "MM_CODES": 64045926,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 926,
        "Discontinued_Date": "2013-06-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VI GTI 2.O TSI DSG ED35",
        "MM_CODES": 64045926,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 926,
        "Discontinued_Date": "2013-06-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII GTI 2.0 TSI DSG PERFORMANCE",
        "MM_CODES": 64045927,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 927,
        "Discontinued_Date": "2016-12-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII GTI 2.0 TSI DSG PERFORMANCE",
        "MM_CODES": 64045927,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 927,
        "Discontinued_Date": "2016-12-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI GTI 2.0 TSI DSG CABRIO",
        "MM_CODES": 64045928,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 928,
        "Discontinued_Date": "2015-08-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VI GTI 2.0 TSI DSG CABRIO",
        "MM_CODES": 64045928,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 928,
        "Discontinued_Date": "2015-08-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI GTI 2.0 TSI DSG CABRIO",
        "MM_CODES": 64045929,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 929,
        "Discontinued_Date": "2017-12-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII 2.0 TSI R",
        "MM_CODES": 64045931,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 931,
        "Discontinued_Date": "2017-05-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 2.0 TSI R",
        "MM_CODES": 64045931,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 931,
        "Discontinued_Date": "2017-05-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII GTI 2.0 TSI DSG CLUBSPORT",
        "MM_CODES": 64045936,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 936,
        "Discontinued_Date": "2017-09-21 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII GTI 2.0 TSI DSG CLUBSPORT",
        "MM_CODES": 64045936,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 936,
        "Discontinued_Date": "2017-09-21 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII GTI  2.0 TSI CLUBSPORT S",
        "MM_CODES": 64045938,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 938,
        "Discontinued_Date": "2017-08-24 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VI 2.0 TSI R DSG",
        "MM_CODES": 64045940,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 940,
        "Discontinued_Date": "2013-06-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VI 2.0 TSI R DSG",
        "MM_CODES": 64045940,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 940,
        "Discontinued_Date": "2013-06-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII 2.0 TSI R DSG",
        "MM_CODES": 64045941,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 941,
        "Discontinued_Date": "2017-05-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 2.0 TSI R DSG",
        "MM_CODES": 64045941,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 941,
        "Discontinued_Date": "2017-05-19 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7",
        "Variant": "GOLF VII 2.0 TSI R DSG",
        "MM_CODES": 64045942,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 942,
        "Discontinued_Date": "2019-01-07 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "GOLF 4 \/ 5 \/ 6\/ 7\/ 8",
        "Variant": "GOLF VII 2.0 TSI R DSG",
        "MM_CODES": 64045942,
        "GCode": 640,
        "MCode": 45,
        "Vcode": 942,
        "Discontinued_Date": "2019-01-07 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0TSI TRENDLINE 118KW S\/C P\/U",
        "MM_CODES": 64072110,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 110,
        "Discontinued_Date": "2015-09-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0TSI TRENDLINE 118KW D\/C P\/U",
        "MM_CODES": 64072130,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 130,
        "Discontinued_Date": "2015-09-01 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0TDI TRENDLINE 90KW S\/C P\/U",
        "MM_CODES": 64072165,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 165,
        "Discontinued_Date": "2014-07-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0TDI 90KW 4 MOT S\/C P\/U",
        "MM_CODES": 64072170,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 170,
        "Discontinued_Date": "2014-07-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0TDI TRENDLINE 90KW 4MOT S\/C P\/U",
        "MM_CODES": 64072175,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 175,
        "Discontinued_Date": "2014-07-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0 BITDI TRENDLINE 120KW S\/C P\/U",
        "MM_CODES": 64072210,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 210,
        "Discontinued_Date": "2014-07-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0 BITDI TRENDLINE 120KW 4MOT S\/C P\/U",
        "MM_CODES": 64072220,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 220,
        "Discontinued_Date": "2014-07-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0 BITDI TRENDLINE 132KW S\/C P\/U",
        "MM_CODES": 64072225,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 225,
        "Discontinued_Date": "2017-10-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0 BITDI TRENDLINE 132KW 4MOT S\/C P\/U",
        "MM_CODES": 64072230,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 230,
        "Discontinued_Date": "2017-10-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0TDI TRENDLINE 90KW D\/C P\/U",
        "MM_CODES": 64072260,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 260,
        "Discontinued_Date": "2014-07-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0TDI TRENDLINE 90KW 4MOT D\/C P\/U",
        "MM_CODES": 64072280,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 280,
        "Discontinued_Date": "2014-07-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0 BITDI HIGHLINE 120KW D\/C P\/U",
        "MM_CODES": 64072380,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 380,
        "Discontinued_Date": "2012-07-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0 BITDI HIGHLINE 132KW D\/C P\/U",
        "MM_CODES": 64072400,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 400,
        "Discontinued_Date": "2018-11-05 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0 BITDI HIGHLINE 132KW D\/C P\/U",
        "MM_CODES": 64072401,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 401,
        "Discontinued_Date": "2020-05-21 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0 BITDI HIGHLINE 120KW 4MOT D\/C P\/U",
        "MM_CODES": 64072420,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 420,
        "Discontinued_Date": "2012-07-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0 BITDI HIGHLINE 132KW 4MOT D\/C P\/U",
        "MM_CODES": 64072440,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 440,
        "Discontinued_Date": "2018-11-05 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0 BITDI HIGHLINE 132KW 4MOT A\/T D\/C P\/U",
        "MM_CODES": 64072445,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 445,
        "Discontinued_Date": "2019-01-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0 BITDI HIGHLINE 132KW 4MOT A\/T D\/C P\/U",
        "MM_CODES": 64072446,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 446,
        "Discontinued_Date": "2023-10-10 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0 BITDI DARK LABEL 4MOT A\/T D\/C P\/U",
        "MM_CODES": 64072448,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 448,
        "Discontinued_Date": "2021-03-25 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0 BITDI H-LINE EX 132KW 4MOT A\/T D\/C P\/U",
        "MM_CODES": 64072455,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 455,
        "Discontinued_Date": "2020-04-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 2.0 BITDI ULTIMATE 132KW 4MOT A\/T D\/C P\/U",
        "MM_CODES": 64072460,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 460,
        "Discontinued_Date": "2018-11-05 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 3.0 TDI HIGHLINE 165KW 4MOT A\/T D\/C P\/U",
        "MM_CODES": 64072700,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 700,
        "Discontinued_Date": "2020-11-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK CANYON 3.0TDI 4MOT A\/T D\/C P\/U",
        "MM_CODES": 64072704,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 704,
        "Discontinued_Date": "2020-11-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 3.0 TDI H-LINE + 4MOT A\/T D\/C P\/U",
        "MM_CODES": 64072705,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 705,
        "Discontinued_Date": "2020-11-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 3.0TDI H-LINE 190KW 4MOT A\/T D\/C P\/U",
        "MM_CODES": 64072706,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 706,
        "Discontinued_Date": "2023-10-10 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 3.0 TDI H-LINE EX 4MOT A\/T D\/C P\/U",
        "MM_CODES": 64072710,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 710,
        "Discontinued_Date": "2020-11-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "AMAROK",
        "Variant": "AMAROK 3.0TDI H-LINE EX 190KW 4MOT A\/T D\/C P\/U",
        "MM_CODES": 64072711,
        "GCode": 640,
        "MCode": 72,
        "Vcode": 711,
        "Discontinued_Date": "2023-10-10 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC",
        "Variant": "CC 1.8 TSI DSG",
        "MM_CODES": 64073120,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 120,
        "Discontinued_Date": "2017-03-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 1.8 TSI DSG",
        "MM_CODES": 64073120,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 120,
        "Discontinued_Date": "2017-03-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 2.0 TSI",
        "MM_CODES": 64073200,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 200,
        "Discontinued_Date": "2010-08-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC",
        "Variant": "CC 2.0 TSI",
        "MM_CODES": 64073201,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 201,
        "Discontinued_Date": "2011-06-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 2.0 TSI",
        "MM_CODES": 64073201,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 201,
        "Discontinued_Date": "2011-06-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 2.0 TSI  (155KW)",
        "MM_CODES": 64073202,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 202,
        "Discontinued_Date": "2012-04-25 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC",
        "Variant": "CC 2.0 TSI TIPTRONIC",
        "MM_CODES": 64073210,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 210,
        "Discontinued_Date": "2010-08-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 2.0 TSI TIPTRONIC",
        "MM_CODES": 64073210,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 210,
        "Discontinued_Date": "2010-08-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 2.0 TSI DSG",
        "MM_CODES": 64073211,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 211,
        "Discontinued_Date": "2011-06-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC",
        "Variant": "CC 2.0 TSI DSG (155KW)",
        "MM_CODES": 64073212,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 212,
        "Discontinued_Date": "2012-04-25 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 2.0 TSI DSG (155KW)",
        "MM_CODES": 64073212,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 212,
        "Discontinued_Date": "2012-04-25 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC",
        "Variant": "CC 2.0 TSI DSG (155KW)",
        "MM_CODES": 64073213,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 213,
        "Discontinued_Date": "2017-03-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 2.0 TSI DSG (155KW)",
        "MM_CODES": 64073213,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 213,
        "Discontinued_Date": "2017-03-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC",
        "Variant": "CC 2.0 TDI DSG",
        "MM_CODES": 64073250,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 250,
        "Discontinued_Date": "2010-08-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 2.0 TDI DSG",
        "MM_CODES": 64073250,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 250,
        "Discontinued_Date": "2010-08-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 2.0 TDI DSG",
        "MM_CODES": 64073251,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 251,
        "Discontinued_Date": "2012-04-25 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC",
        "Variant": "CC 2.0 TDI BLUEMOTION DSG",
        "MM_CODES": 64073252,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 252,
        "Discontinued_Date": "2012-04-25 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 2.0 TDI BLUEMOTION DSG",
        "MM_CODES": 64073252,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 252,
        "Discontinued_Date": "2012-04-25 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC",
        "Variant": "CC 2.0 TDI BLUEMOTION DSG",
        "MM_CODES": 64073253,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 253,
        "Discontinued_Date": "2013-01-31 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 2.0 TDI BLUEMOTION DSG",
        "MM_CODES": 64073253,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 253,
        "Discontinued_Date": "2013-01-31 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC",
        "Variant": "CC 2.0 TDI BLUEMOTION DSG",
        "MM_CODES": 64073254,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 254,
        "Discontinued_Date": "2017-03-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 2.0 TDI BLUEMOTION DSG",
        "MM_CODES": 64073254,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 254,
        "Discontinued_Date": "2017-03-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 3.6 V6 DSG",
        "MM_CODES": 64073301,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 301,
        "Discontinued_Date": "2012-04-25 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC",
        "Variant": "CC 3.6 V6 DSG 4MOT",
        "MM_CODES": 64073302,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 302,
        "Discontinued_Date": "2017-03-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "CC 3.6 V6 DSG 4MOT",
        "MM_CODES": 64073302,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 302,
        "Discontinued_Date": "2017-03-16 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "ARTEON 2.0 TSI R-LINE 4M DSG",
        "MM_CODES": 64073450,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 450,
        "Discontinued_Date": "2020-05-18 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CC\/ ARTEON",
        "Variant": "ARTEON 2.0 TDI R-LINE DSG ",
        "MM_CODES": 64073500,
        "GCode": 640,
        "MCode": 73,
        "Vcode": 500,
        "Discontinued_Date": "2020-05-18 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T-ROC",
        "Variant": "T-ROC 1.4 TSI DESIGN TIPTRONIC",
        "MM_CODES": 64077200,
        "GCode": 640,
        "MCode": 77,
        "Vcode": 200,
        "Discontinued_Date": "2022-07-04 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T-ROC",
        "Variant": "T-ROC 2.0 TSI DESIGN 4MOT DSG",
        "MM_CODES": 64077300,
        "GCode": 640,
        "MCode": 77,
        "Vcode": 300,
        "Discontinued_Date": "2022-07-04 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T-ROC",
        "Variant": "T-ROC 2.0 TSI 4M R-LINE DSG",
        "MM_CODES": 64077330,
        "GCode": 640,
        "MCode": 77,
        "Vcode": 330,
        "Discontinued_Date": "2022-07-04 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "TIGUAN 1.4 TSI TREND-FUN 4MOT",
        "MM_CODES": 64078100,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 100,
        "Discontinued_Date": "2011-09-27 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "TIGUAN 1.4 TSI TRACK-FIELD 4MOT",
        "MM_CODES": 64078110,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 110,
        "Discontinued_Date": "2011-06-10 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "TIGUAN 2.0 TSI TRACK-FIELD 4MOT TIP",
        "MM_CODES": 64078270,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 270,
        "Discontinued_Date": "2011-06-10 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "TIGUAN 2.0 TSI SPRT-STYL 4MOT TIP",
        "MM_CODES": 64078290,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 290,
        "Discontinued_Date": "2011-09-27 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "TIGUAN 2.0 TSI HIGHLINE 4MOT DSG",
        "MM_CODES": 64078295,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 295,
        "Discontinued_Date": "2021-06-17 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "TIGUAN 2.0 TDI B\/MOT TREND- FUN",
        "MM_CODES": 64078310,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 310,
        "Discontinued_Date": "2011-09-27 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "TIGUAN 2.0 TDI COMFORTLINE",
        "MM_CODES": 64078317,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 317,
        "Discontinued_Date": "2019-06-27 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "2.0 TDI COMFORTLINE 4\/MOT DSG",
        "MM_CODES": 64078331,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 331,
        "Discontinued_Date": "2021-06-17 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "TIGUAN 2.0 TDI TRK-FLD 4MOT TIP",
        "MM_CODES": 64078350,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 350,
        "Discontinued_Date": "2011-09-27 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "TIGUAN 2.0 TDI SPORT-STYLE 4MOT",
        "MM_CODES": 64078360,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 360,
        "Discontinued_Date": "2011-09-27 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "TIGUAN 2.0 TDI HIGHLINE 4\/MOT DSG",
        "MM_CODES": 64078380,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 380,
        "Discontinued_Date": "2021-06-17 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "TIGUAN ALLSPACE 1.4 TSI TRENDLINE DSG (110KW)",
        "MM_CODES": 64078710,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 710,
        "Discontinued_Date": "2022-03-31 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "TIGUAN ALLSPACE 1.4 TSI C\/LINE DSG (110KW)",
        "MM_CODES": 64078720,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 720,
        "Discontinued_Date": "2022-03-31 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "TIGUAN ALLSPACE 2.0 TSI C\/LINE 4MOT DSG(132KW)",
        "MM_CODES": 64078800,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 800,
        "Discontinued_Date": "2022-03-31 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TIGUAN",
        "Variant": "TIGUAN ALLSPACE 2.0 TDI COMFORTLINE 4MOT DSG",
        "MM_CODES": 64078860,
        "GCode": 640,
        "MCode": 78,
        "Vcode": 860,
        "Discontinued_Date": "2020-08-28 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "TRANSPORTER T6 2.0TDI SWB 75KW F\/C P\/V",
        "MM_CODES": 64080111,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 111,
        "Discontinued_Date": "2019-12-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "TRANSPORTER T5 2.0 TDI LWB 75KW F\/C P\/V",
        "MM_CODES": 64080112,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 112,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "TRANSPORTER T6 2.0TDI LWB 75KW F\/C P\/V",
        "MM_CODES": 64080113,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 113,
        "Discontinued_Date": "2020-11-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "TRANSPORTER T5 2.0 TDI LWB 103 KW F\/C P\/V",
        "MM_CODES": 64080114,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 114,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "TRANSPORTER T6 SPORT 2.0TDI 103KW DSG F\/C P\/V",
        "MM_CODES": 64080115,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 115,
        "Discontinued_Date": "2019-12-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "TRANSPORTER T6 2.0TDI LWB 103KW DSG F\/C P\/V",
        "MM_CODES": 64080140,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 140,
        "Discontinued_Date": "2020-11-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 C\/BUS 2.0 TDI SWB 75KW F\/C P\/V",
        "MM_CODES": 64080350,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 350,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T6 C\/BUS 2.0 TDI 75KW LWB  F\/C P\/V",
        "MM_CODES": 64080361,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 361,
        "Discontinued_Date": "2019-12-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 C\/BUS 2.0 TDI LWB 103KW DSG F\/C P\/V",
        "MM_CODES": 64080370,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 370,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T6 C\/BUS 2.0 TDI LWB 103KW DSG F\/C P\/V",
        "MM_CODES": 64080371,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 371,
        "Discontinued_Date": "2020-11-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T6 C\/BUS 2.0 TDI SWB 103KW 4MOT F\/C P\/V",
        "MM_CODES": 64080374,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 374,
        "Discontinued_Date": "2019-12-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 C\/BUS 2.0 BITDI SWB 132 KW F\/C P\/V",
        "MM_CODES": 64080380,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 380,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 C\/BUS 2.0 BITDI LWB 132 KW F\/C P\/V",
        "MM_CODES": 64080390,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 390,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 C\/BUS 2.0 BITDI SWB 132 KW 4MOT F\/C P\/V",
        "MM_CODES": 64080400,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 400,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 C\/BUS 2.0 BITDI SWB 132 KW DSG F\/C P\/V",
        "MM_CODES": 64080410,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 410,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T6 C\/BUS 2.0 BITDI SWB 132 KW DSG F\/C P\/V",
        "MM_CODES": 64080411,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 411,
        "Discontinued_Date": "2019-12-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 C\/BUS 2.0 BITDI SWB 132 KW 4MOT DSG F\/C P\/V",
        "MM_CODES": 64080420,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 420,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T6 C\/BUS 2.0 BITDI SWB 132 KW 4MOT DSG F\/C P\/V",
        "MM_CODES": 64080421,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 421,
        "Discontinued_Date": "2020-11-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 C\/BUS 2.0 BITDI LWB 132 KW 4MOT F\/C P\/V",
        "MM_CODES": 64080430,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 430,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 C\/BUS 2.0 BITDI LWB 132 KW 4MOT DSG F\/C P\/V",
        "MM_CODES": 64080440,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 440,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T6 C\/BUS 2.0 BITDI LWB 132 KW 4MOT DSG",
        "MM_CODES": 64080441,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 441,
        "Discontinued_Date": "2020-11-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 2.0 TDI 75KW LWB P\/U S\/C",
        "MM_CODES": 64080521,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 521,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T6 2.0TDI 75KW LWB P\/U S\/C",
        "MM_CODES": 64080522,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 522,
        "Discontinued_Date": "2020-11-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 2.0TDI 103 KW LWB P\/U S\/C",
        "MM_CODES": 64080525,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 525,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 2.0TDI 103 KW DSG LWB P\/U S\/C",
        "MM_CODES": 64080530,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 530,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 2.0 TDI 75 KW LWB P\/U D\/C",
        "MM_CODES": 64080541,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 541,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T6 2.0TDI 75KW LWB P\/U D\/C",
        "MM_CODES": 64080542,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 542,
        "Discontinued_Date": "2020-11-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 2.0 BITDI 132 KW LWB P\/U D\/C",
        "MM_CODES": 64080545,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 545,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T6 2.0BITDI 132KW LWB DSG P\/U D\/C",
        "MM_CODES": 64080548,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 548,
        "Discontinued_Date": "2020-11-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TRANSPORTER",
        "Variant": "T5 2.0 BITDI 132 KW 4MOT LWB P\/U D\/C",
        "MM_CODES": 64080550,
        "GCode": 640,
        "MCode": 80,
        "Vcode": 550,
        "Discontinued_Date": "2015-10-15 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CRAFTER",
        "Variant": "CRAFTER 35 80 F\/C P\/V",
        "MM_CODES": 64082120,
        "GCode": 640,
        "MCode": 82,
        "Vcode": 120,
        "Discontinued_Date": "2012-01-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CRAFTER",
        "Variant": "CRAFTER 35 120 F\/C P\/V",
        "MM_CODES": 64082160,
        "GCode": 640,
        "MCode": 82,
        "Vcode": 160,
        "Discontinued_Date": "2012-01-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CRAFTER",
        "Variant": "CRAFTER 50 HR 80 F\/C P\/V",
        "MM_CODES": 64082300,
        "GCode": 640,
        "MCode": 82,
        "Vcode": 300,
        "Discontinued_Date": "2012-01-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CRAFTER",
        "Variant": "CRAFTER 50 2.0 TDI HR 80KW F\/C P\/V",
        "MM_CODES": 64082301,
        "GCode": 640,
        "MCode": 82,
        "Vcode": 301,
        "Discontinued_Date": "2019-01-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CRAFTER",
        "Variant": "CRAFTER 50 HR80 XLWB F\/C P\/V",
        "MM_CODES": 64082340,
        "GCode": 640,
        "MCode": 82,
        "Vcode": 340,
        "Discontinued_Date": "2010-07-23 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CRAFTER",
        "Variant": "CRAFTER 50 2.0 TDI HR 80KW XLWB F\/C P\/V",
        "MM_CODES": 64082341,
        "GCode": 640,
        "MCode": 82,
        "Vcode": 341,
        "Discontinued_Date": "2019-01-06 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CRAFTER",
        "Variant": "CRAFTER 50 HR 120 F\/C P\/V",
        "MM_CODES": 64082400,
        "GCode": 640,
        "MCode": 82,
        "Vcode": 400,
        "Discontinued_Date": "2012-01-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "CRAFTER",
        "Variant": "CRAFTER 50 2.0 BITDI HR 120KW F\/C P\/V",
        "MM_CODES": 64082401,
        "GCode": 640,
        "MCode": 82,
        "Vcode": 401,
        "Discontinued_Date": "2019-05-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T5 KOMBI 2.0 TDI (75KW) BASE (TRENDLINE)",
        "MM_CODES": 64088490,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 490,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T6 KOMBI 2.0 TDI (TRENDLINE)",
        "MM_CODES": 64088491,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 491,
        "Discontinued_Date": "2020-11-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T5 KOMBI 2.0 TDI",
        "MM_CODES": 64088500,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 500,
        "Discontinued_Date": "2012-06-11 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "TRANSPORTER CREWBUS 2.0 TDI LWB (10 SEAT)",
        "MM_CODES": 64088505,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 505,
        "Discontinued_Date": "2021-11-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T5 KOMBI 2.0 TDI LWB (75KW) BASE (TRENDLINE)",
        "MM_CODES": 64088515,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 515,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T6 KOMBI 2.0 TDI TREND LWB (75KW)",
        "MM_CODES": 64088521,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 521,
        "Discontinued_Date": "2021-11-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T6 KOMBI 2.0 TDI 103KW (COMFORTLINE)",
        "MM_CODES": 64088541,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 541,
        "Discontinued_Date": "2020-11-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T5 KOMBI 2.0 TDI DSG 103KW (TRENDLINE)",
        "MM_CODES": 64088545,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 545,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T5 KOMBI 2.0 TDI DSG 103KW (COMFORTLINE)",
        "MM_CODES": 64088550,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 550,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T5 KOMBI 2.0 TDI LWB 103KW (COMFORTLINE)",
        "MM_CODES": 64088570,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 570,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T5 KOMBI 2.0 TDI DSG LWB 103KW (COMFORTLINE)",
        "MM_CODES": 64088600,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 600,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T6 KOMBI 2.0 TDI DSG LWB 103KW (COMFORTLINE)",
        "MM_CODES": 64088601,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 601,
        "Discontinued_Date": "2020-11-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T6 KOMBI 2.0 BITDI COMFORT DSG (132KW)",
        "MM_CODES": 64088610,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 610,
        "Discontinued_Date": "2022-12-20 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T5 CARAVELLE 2.0 BITDI",
        "MM_CODES": 64088650,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 650,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T5 CARAVELLE 2.0 BITDI DSG",
        "MM_CODES": 64088670,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 670,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T6 CARAVELLE 2.0 BITDI COMFORTLINE DSG",
        "MM_CODES": 64088671,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 671,
        "Discontinued_Date": "2020-07-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T6 CARAVELLE 2.0 BITDI HIGHLINE DSG",
        "MM_CODES": 64088680,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 680,
        "Discontinued_Date": "2020-09-02 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T5 CARAVELLE 2.0 BITDI DSG 4MOT",
        "MM_CODES": 64088690,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 690,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T6 CARAVELLE 2.0 BITDI COMFORTLINE DSG 4 MOTION",
        "MM_CODES": 64088691,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 691,
        "Discontinued_Date": "2020-01-28 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T6 CARAVELLE 2.0 BITDI PAN AMERICANA 4M DSG",
        "MM_CODES": 64088695,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 695,
        "Discontinued_Date": "2021-12-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T6 CARAVELLE 2.0 BITDI HIGHLINE DSG 4 MOTION",
        "MM_CODES": 64088700,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 700,
        "Discontinued_Date": "2020-12-08 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T6 CALIFORNIA BEACH 2.0 TDI 4MOT (103KW)",
        "MM_CODES": 64088715,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 715,
        "Discontinued_Date": "2020-01-28 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T5 CALIFORNIA BEACH 2.0 BITDI 4MOT",
        "MM_CODES": 64088730,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 730,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T5 CALIFORNIA BEACH 2.0 BITDI 4MOT DSG",
        "MM_CODES": 64088740,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 740,
        "Discontinued_Date": "2015-10-14 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "T5\/T6",
        "Variant": "T6 CALIFORNIA COAST 2.0 BITDI 4M DSG (150KW)",
        "MM_CODES": 64088746,
        "GCode": 640,
        "MCode": 88,
        "Vcode": 746,
        "Discontinued_Date": "2020-11-10 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TOUAREG",
        "Variant": "TOUAREG 3.0 V6 TDI TIP BLU MOT",
        "MM_CODES": 64092153,
        "GCode": 640,
        "MCode": 92,
        "Vcode": 153,
        "Discontinued_Date": "2012-01-17 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TOUAREG",
        "Variant": "TOUAREG 3.0 V6 TDI TIP BLU MOT 180KW",
        "MM_CODES": 64092155,
        "GCode": 640,
        "MCode": 92,
        "Vcode": 155,
        "Discontinued_Date": "2016-01-04 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TOUAREG",
        "Variant": "TOUAREG GP 3.0 V6 TDI LUXURY TIP",
        "MM_CODES": 64092156,
        "GCode": 640,
        "MCode": 92,
        "Vcode": 156,
        "Discontinued_Date": "2018-07-18 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TOUAREG",
        "Variant": "TOUAREG GP 3.0 V6 TDI ESCAPE TIP",
        "MM_CODES": 64092170,
        "GCode": 640,
        "MCode": 92,
        "Vcode": 170,
        "Discontinued_Date": "2018-07-18 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TOUAREG",
        "Variant": "TOUAREG 3.6 FSI V6 TIP",
        "MM_CODES": 64092181,
        "GCode": 640,
        "MCode": 92,
        "Vcode": 181,
        "Discontinued_Date": "2014-12-04 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TOUAREG",
        "Variant": "TOUAREG GP 3.6 V6 ELEGANCE TIP",
        "MM_CODES": 64092182,
        "GCode": 640,
        "MCode": 92,
        "Vcode": 182,
        "Discontinued_Date": "2018-02-13 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TOUAREG",
        "Variant": "TOUAREG 4.2 V8 TDI TIP",
        "MM_CODES": 64092220,
        "GCode": 640,
        "MCode": 92,
        "Vcode": 220,
        "Discontinued_Date": "2014-12-04 00:00:00.000"
    },
    {
        "Make": "VOLKSWAGEN",
        "Model": "TOUAREG",
        "Variant": "TOUAREG GP 4.2 V8 TDI EXEC TIP",
        "MM_CODES": 64092230,
        "GCode": 640,
        "MCode": 92,
        "Vcode": 230,
        "Discontinued_Date": "2018-07-18 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "C30",
        "Variant": "C30 1.6 ESSENTIAL",
        "MM_CODES": 65020102,
        "GCode": 650,
        "MCode": 20,
        "Vcode": 102,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "C30",
        "Variant": "C30 1.6 ELITE",
        "MM_CODES": 65020110,
        "GCode": 650,
        "MCode": 20,
        "Vcode": 110,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "C30",
        "Variant": "C30 D2 DRIVE",
        "MM_CODES": 65020130,
        "GCode": 650,
        "MCode": 20,
        "Vcode": 130,
        "Discontinued_Date": "2011-09-02 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "C30",
        "Variant": "C30 D2 ESSENTIAL",
        "MM_CODES": 65020131,
        "GCode": 650,
        "MCode": 20,
        "Vcode": 131,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "C30",
        "Variant": "C30 2.0 ESSENTIAL",
        "MM_CODES": 65020216,
        "GCode": 650,
        "MCode": 20,
        "Vcode": 216,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "C30",
        "Variant": "C30 2.0 ELITE",
        "MM_CODES": 65020224,
        "GCode": 650,
        "MCode": 20,
        "Vcode": 224,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "C30",
        "Variant": "C30 2.0 ESSENTIAL POWERSHIFT",
        "MM_CODES": 65020232,
        "GCode": 650,
        "MCode": 20,
        "Vcode": 232,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "C30",
        "Variant": "C30 2.0 ELITE POWERSHIFT",
        "MM_CODES": 65020240,
        "GCode": 650,
        "MCode": 20,
        "Vcode": 240,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "C30",
        "Variant": "C30 T5 R-DESIGN",
        "MM_CODES": 65020305,
        "GCode": 650,
        "MCode": 20,
        "Vcode": 305,
        "Discontinued_Date": "2010-02-04 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "C30",
        "Variant": "C30 T5 R-DESIGN",
        "MM_CODES": 65020306,
        "GCode": 650,
        "MCode": 20,
        "Vcode": 306,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "C30",
        "Variant": "C30 T5 A\/T R-DESIGN",
        "MM_CODES": 65020325,
        "GCode": 650,
        "MCode": 20,
        "Vcode": 325,
        "Discontinued_Date": "2010-02-04 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 ESSENTIAL",
        "MM_CODES": 65028120,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 120,
        "Discontinued_Date": "2015-06-24 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 KINETIC",
        "MM_CODES": 65028121,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 121,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 KINETIC",
        "MM_CODES": 65028122,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 122,
        "Discontinued_Date": "2018-07-04 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 EXCEL",
        "MM_CODES": 65028130,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 130,
        "Discontinued_Date": "2015-06-24 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 MOMENTUM",
        "MM_CODES": 65028131,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 131,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 MOMENTUM",
        "MM_CODES": 65028132,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 132,
        "Discontinued_Date": "2018-07-04 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 ELITE",
        "MM_CODES": 65028140,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 140,
        "Discontinued_Date": "2015-06-24 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 INSCRIPTION",
        "MM_CODES": 65028141,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 141,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 INSCRIPTION",
        "MM_CODES": 65028142,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 142,
        "Discontinued_Date": "2018-07-04 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 R-DESIGN",
        "MM_CODES": 65028150,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 150,
        "Discontinued_Date": "2015-06-24 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 KINETIC GEARTRONIC",
        "MM_CODES": 65028160,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 160,
        "Discontinued_Date": "2019-12-09 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 MOMENTUM GEARTRONIC",
        "MM_CODES": 65028165,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 165,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 INSCRIPTION GEARTRONIC",
        "MM_CODES": 65028170,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 170,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 INSCRIPTION GEARTRONIC",
        "MM_CODES": 65028171,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 171,
        "Discontinued_Date": "2019-12-09 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 R-DESIGN GEARTRONIC",
        "MM_CODES": 65028175,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 175,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T3 R-DESIGN GEARTRONIC",
        "MM_CODES": 65028176,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 176,
        "Discontinued_Date": "2019-12-09 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T4 EXCEL",
        "MM_CODES": 65028200,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 200,
        "Discontinued_Date": "2015-06-24 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T4 MOMENTUM",
        "MM_CODES": 65028203,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 203,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T4 INSCRIPTION",
        "MM_CODES": 65028206,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 206,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T4 MOMENTUM GEARTRONIC",
        "MM_CODES": 65028213,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 213,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T5 INSCRIPTION GEARTRONIC",
        "MM_CODES": 65028432,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 432,
        "Discontinued_Date": "2019-12-09 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 T5 R-DESIGN GEARTRONIC",
        "MM_CODES": 65028441,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 441,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T4 ESSENTIAL",
        "MM_CODES": 65028530,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 530,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T4 ESSENTIAL POWERSHIFT",
        "MM_CODES": 65028540,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 540,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T4 EXCEL",
        "MM_CODES": 65028550,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 550,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T4 MOMENTUM",
        "MM_CODES": 65028551,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 551,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T4 EXCEL POWERSHIFT",
        "MM_CODES": 65028560,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 560,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T4 MOMENTUM GEARTRONIC",
        "MM_CODES": 65028561,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 561,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T4 MOMENTUM GEARTRONIC",
        "MM_CODES": 65028562,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 562,
        "Discontinued_Date": "2019-12-09 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T4 ELITE",
        "MM_CODES": 65028570,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 570,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T4 ELITE POWERSHIFT",
        "MM_CODES": 65028580,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 580,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T4 INSCRIPTION GEARTRONIC",
        "MM_CODES": 65028582,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 582,
        "Discontinued_Date": "2019-12-09 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC D3 EXCEL GEARTRONIC",
        "MM_CODES": 65028620,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 620,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC D3 MOMENTUM GEARTRONIC",
        "MM_CODES": 65028621,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 621,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC D3 INSCRIPTION GEARTRONIC",
        "MM_CODES": 65028631,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 631,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC D4 EXCEL GEARTRONIC",
        "MM_CODES": 65028660,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 660,
        "Discontinued_Date": "2014-06-13 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC D4 EXCEL\/MOMENTUM GEARTRONIC",
        "MM_CODES": 65028661,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 661,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC D4 MOMENTUM GEARTRONIC",
        "MM_CODES": 65028662,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 662,
        "Discontinued_Date": "2019-12-09 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC D4 ELITE GEARTRONIC",
        "MM_CODES": 65028670,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 670,
        "Discontinued_Date": "2014-06-13 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC D4 ELITE\/INSCRIPTION GEARTRONIC",
        "MM_CODES": 65028671,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 671,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC D4 INSCRIPTION GEARTRONIC",
        "MM_CODES": 65028672,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 672,
        "Discontinued_Date": "2019-12-09 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T5 EXCEL GEARTRONIC AWD",
        "MM_CODES": 65028710,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 710,
        "Discontinued_Date": "2014-11-26 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T5 EXCEL\/MOMENTUM GEARTRONIC AWD",
        "MM_CODES": 65028711,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 711,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T5 ELITE GEARTRONIC AWD",
        "MM_CODES": 65028720,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 720,
        "Discontinued_Date": "2014-11-26 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T5 ELITE\/INSCRIPTION GEARTRONIC AWD",
        "MM_CODES": 65028721,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 721,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V40",
        "Variant": "V40 CC T5 ELITE GEARTRONIC",
        "MM_CODES": 65028730,
        "GCode": 650,
        "MCode": 28,
        "Vcode": 730,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S40 \/ V40 \/ V50",
        "Variant": "S40 1.6D DRIVE",
        "MM_CODES": 65030445,
        "GCode": 650,
        "MCode": 30,
        "Vcode": 445,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S40 \/ V40 \/ V50",
        "Variant": "S40 1.6 D2 DRIVE",
        "MM_CODES": 65030446,
        "GCode": 650,
        "MCode": 30,
        "Vcode": 446,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC40",
        "Variant": "XC40 T3 INSCRIPTION",
        "MM_CODES": 65033106,
        "GCode": 650,
        "MCode": 33,
        "Vcode": 106,
        "Discontinued_Date": "2021-03-29 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC40",
        "Variant": "XC40 T3 MOMENTUM GEARTRONIC",
        "MM_CODES": 65033140,
        "GCode": 650,
        "MCode": 33,
        "Vcode": 140,
        "Discontinued_Date": "2023-07-17 13:33:02.907"
    },
    {
        "Make": "VOLVO",
        "Model": "XC40",
        "Variant": "XC40 D4 MOMENTUM AWD GEARTRONIC",
        "MM_CODES": 65033255,
        "GCode": 650,
        "MCode": 33,
        "Vcode": 255,
        "Discontinued_Date": "2022-02-11 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC40",
        "Variant": "XC40 D4 INSCRIPTION AWD GEARTRONIC",
        "MM_CODES": 65033260,
        "GCode": 650,
        "MCode": 33,
        "Vcode": 260,
        "Discontinued_Date": "2022-02-11 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC40",
        "Variant": "XC40 D4 R-DESIGN AWD GEARTRONIC",
        "MM_CODES": 65033265,
        "GCode": 650,
        "MCode": 33,
        "Vcode": 265,
        "Discontinued_Date": "2022-02-11 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC40",
        "Variant": "XC40 T5 R-DESIGN AWD GEARTRONIC",
        "MM_CODES": 65033295,
        "GCode": 650,
        "MCode": 33,
        "Vcode": 295,
        "Discontinued_Date": "2023-07-17 13:33:25.763"
    },
    {
        "Make": "VOLVO",
        "Model": "XC40",
        "Variant": "XC40 B5 PLUS DARK GEARTRONIC AWD (MILD HYBRID)",
        "MM_CODES": 65033375,
        "GCode": 650,
        "MCode": 33,
        "Vcode": 375,
        "Discontinued_Date": "2023-09-05 12:50:00.473"
    },
    {
        "Make": "VOLVO",
        "Model": "XC40",
        "Variant": "XC40 B5 GEARTRONIC ULTIMATE DARK AWD (MILD HYBRID)",
        "MM_CODES": 65033380,
        "GCode": 650,
        "MCode": 33,
        "Vcode": 380,
        "Discontinued_Date": "2023-09-05 12:50:01.937"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 2.0T",
        "MM_CODES": 65045102,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 102,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 2.0T POWERSHIFT",
        "MM_CODES": 65045150,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 150,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 2.0T R-DESIGN",
        "MM_CODES": 65045170,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 170,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 2.0T R-DESIGN POWERSHIFT",
        "MM_CODES": 65045175,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 175,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 D3",
        "MM_CODES": 65045410,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 410,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 D3 GEARTRONIC",
        "MM_CODES": 65045415,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 415,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 D3 ESSENTIAL",
        "MM_CODES": 65045427,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 427,
        "Discontinued_Date": "2013-08-16 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 D3 EXCEL",
        "MM_CODES": 65045431,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 431,
        "Discontinued_Date": "2013-08-16 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 D4 EXCEL",
        "MM_CODES": 65045432,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 432,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 D3 ELITE",
        "MM_CODES": 65045435,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 435,
        "Discontinued_Date": "2013-08-16 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 D4 ELITE",
        "MM_CODES": 65045436,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 436,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 D3 ESSENTIAL GEARTRONIC",
        "MM_CODES": 65045439,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 439,
        "Discontinued_Date": "2013-08-16 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 D3 EXCEL GEARTRONIC",
        "MM_CODES": 65045443,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 443,
        "Discontinued_Date": "2013-08-16 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 D4 EXCEL GEARTRONIC",
        "MM_CODES": 65045444,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 444,
        "Discontinued_Date": "2014-01-13 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 D4 ELITE GEARTRONIC",
        "MM_CODES": 65045448,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 448,
        "Discontinued_Date": "2014-01-13 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 D5 R-DESIGN GEARTRONIC",
        "MM_CODES": 65045491,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 491,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 D5 R-DESIGN GEARTRONIC",
        "MM_CODES": 65045492,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 492,
        "Discontinued_Date": "2019-07-18 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T5",
        "MM_CODES": 65045502,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 502,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T5 ESSENTIAL",
        "MM_CODES": 65045504,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 504,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T5 ELITE",
        "MM_CODES": 65045512,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 512,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T5 ESSENTIAL POWERSHIFT",
        "MM_CODES": 65045520,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 520,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T5 ELITE POWERSHIFT",
        "MM_CODES": 65045529,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 529,
        "Discontinued_Date": "2014-01-13 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T5 ELITE\/INSCRIPTION GEARTRONIC (DRIVE-E)",
        "MM_CODES": 65045530,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 530,
        "Discontinued_Date": "2019-07-18 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T5 R-DESIGN GEARTRONIC (DRIVE-E)",
        "MM_CODES": 65045535,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 535,
        "Discontinued_Date": "2019-07-18 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T5 POWERSHIFT",
        "MM_CODES": 65045552,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 552,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T5 R-DESIGN",
        "MM_CODES": 65045555,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 555,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T5 R-DESIGN POWERSHIFT",
        "MM_CODES": 65045558,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 558,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T5 R-DESIGN POWERSHIFT",
        "MM_CODES": 65045559,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 559,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T6 ESSENTIAL GEARTRONIC AWD",
        "MM_CODES": 65045561,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 561,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T6 EXCEL GEARTRONIC AWD",
        "MM_CODES": 65045564,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 564,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T6 ELITE GEARTRONIC AWD",
        "MM_CODES": 65045567,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 567,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T6 ELITE GEARTRONIC AWD",
        "MM_CODES": 65045568,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 568,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T6 R-DESIGN GEARTRONIC AWD",
        "MM_CODES": 65045571,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 571,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 T6 R-DESIGN GEATRONIC AWD",
        "MM_CODES": 65045572,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 572,
        "Discontinued_Date": "2019-07-18 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S60",
        "Variant": "S60 POLESTAR AWD",
        "MM_CODES": 65045580,
        "GCode": 650,
        "MCode": 45,
        "Vcode": 580,
        "Discontinued_Date": "2016-09-09 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "FM 2006 - ON",
        "Variant": "FMX (4) 440 6X4 SLEEP T\/T C\/C",
        "MM_CODES": 65052771,
        "GCode": 650,
        "MCode": 52,
        "Vcode": 771,
        "Discontinued_Date": "2021-06-07 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 2.0T R-DESIGN",
        "MM_CODES": 65063100,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 100,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 2.0T R-DESIGN POWERSHIFT",
        "MM_CODES": 65063110,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 110,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 D3 ESSENTIAL",
        "MM_CODES": 65063151,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 151,
        "Discontinued_Date": "2012-07-23 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 D4 EXCEL",
        "MM_CODES": 65063155,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 155,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 D4 ELITE",
        "MM_CODES": 65063158,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 158,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 D3 GEARTRONIC",
        "MM_CODES": 65063160,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 160,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 D3 ESSENTIAL GEARTRONIC",
        "MM_CODES": 65063161,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 161,
        "Discontinued_Date": "2012-07-23 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 D3 ELITE GEARTRONIC",
        "MM_CODES": 65063167,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 167,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 D4 ELITE GEARTRONIC (DRIVE-E)",
        "MM_CODES": 65063169,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 169,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 D4 MOMENTUM GEARTRONIC",
        "MM_CODES": 65063173,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 173,
        "Discontinued_Date": "2020-12-15 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 D4 INSCRIPTION GEARTRONIC",
        "MM_CODES": 65063176,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 176,
        "Discontinued_Date": "2020-12-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 D5 EXCEL GEARTRONIC",
        "MM_CODES": 65063185,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 185,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 D5 ELITE GEARTRONIC",
        "MM_CODES": 65063189,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 189,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 D5 GEARTRONIC",
        "MM_CODES": 65063190,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 190,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 D5 R-DESIGN GEARTRONIC",
        "MM_CODES": 65063196,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 196,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T3",
        "MM_CODES": 65063200,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 200,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T3 EXCEL",
        "MM_CODES": 65063216,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 216,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T3 ELITE",
        "MM_CODES": 65063219,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 219,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T3 EXCEL POWERSHIFT",
        "MM_CODES": 65063233,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 233,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T4 ELITE",
        "MM_CODES": 65063247,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 247,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T4 ELITE",
        "MM_CODES": 65063248,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 248,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T4 POWERSHIFT",
        "MM_CODES": 65063250,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 250,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T4 ESSENTIAL POWERSHIFT",
        "MM_CODES": 65063251,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 251,
        "Discontinued_Date": "2012-07-23 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T4 EXCEL POWERSHIFT",
        "MM_CODES": 65063254,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 254,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T4 INSCRIPTION GEARTRONIC",
        "MM_CODES": 65063259,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 259,
        "Discontinued_Date": "2020-12-14 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T5",
        "MM_CODES": 65063290,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 290,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T5 ESSENTIAL",
        "MM_CODES": 65063291,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 291,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T5 ELITE",
        "MM_CODES": 65063297,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 297,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T5 ESSENTIAL POWERSHIFT",
        "MM_CODES": 65063301,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 301,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T5 ELITE\/INSCRIPTION GEARTRONIC (DRIVE-E)",
        "MM_CODES": 65063309,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 309,
        "Discontinued_Date": "2020-12-15 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T6 GEARTRONIC",
        "MM_CODES": 65063350,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 350,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T6 EXCEL GEARTRONIC AWD",
        "MM_CODES": 65063354,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 354,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T6 ELITE GEARTRONIC AWD",
        "MM_CODES": 65063357,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 357,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T6 INSCRIPTION GEARTRONIC AWD",
        "MM_CODES": 65063359,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 359,
        "Discontinued_Date": "2020-12-14 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T6 R-DESIGN GEARTRONIC AWD",
        "MM_CODES": 65063360,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 360,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 T6 R-DESIGN GEARTRONIC AWD",
        "MM_CODES": 65063361,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 361,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 CC D4 MOMENTUM GEARTRONIC AWD",
        "MM_CODES": 65063580,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 580,
        "Discontinued_Date": "2020-12-14 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V60",
        "Variant": "V60 CC D4 INSCRIPTION GEARTRONIC AWD",
        "MM_CODES": 65063590,
        "GCode": 650,
        "MCode": 63,
        "Vcode": 590,
        "Discontinued_Date": "2020-12-15 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V90",
        "Variant": "V90 CC D4 MOMENTUM GEARTRONIC AWD",
        "MM_CODES": 65064250,
        "GCode": 650,
        "MCode": 64,
        "Vcode": 250,
        "Discontinued_Date": "2021-12-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V90",
        "Variant": "V90 CC D5 INSCRIPTION GEARTRONIC",
        "MM_CODES": 65064310,
        "GCode": 650,
        "MCode": 64,
        "Vcode": 310,
        "Discontinued_Date": "2021-12-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S70 \/ V70 \/ C70",
        "Variant": "C70 T5 GEARTRONIC",
        "MM_CODES": 65065721,
        "GCode": 650,
        "MCode": 65,
        "Vcode": 721,
        "Discontinued_Date": "2011-07-12 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S70 \/ V70 \/ C70",
        "Variant": "C70 T5 ESSENTIAL GEARTRONIC",
        "MM_CODES": 65065722,
        "GCode": 650,
        "MCode": 65,
        "Vcode": 722,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "S70 \/ V70 \/ C70",
        "Variant": "C70 T5 ELITE GEARTRONIC",
        "MM_CODES": 65065730,
        "GCode": 650,
        "MCode": 65,
        "Vcode": 730,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC60 2.0 D3 DRIVE",
        "MM_CODES": 65066220,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 220,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC60 2.0 D3 GEARTRONIC",
        "MM_CODES": 65066230,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 230,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC60 2.4D",
        "MM_CODES": 65066301,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 301,
        "Discontinued_Date": "2010-09-07 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC60 2.4D GEARTRONIC",
        "MM_CODES": 65066303,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 303,
        "Discontinued_Date": "2010-09-07 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC60 2.0T POWERSHIFT",
        "MM_CODES": 65066304,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 304,
        "Discontinued_Date": "2011-09-02 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC60 2.0 T5 POWERSHIFT",
        "MM_CODES": 65066308,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 308,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC60 D5 GEARTRONIC",
        "MM_CODES": 65066311,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 311,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC60 D5 A\/T R-DESIGN",
        "MM_CODES": 65066313,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 313,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC60 3.0T A\/T R-DESIGN",
        "MM_CODES": 65066320,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 320,
        "Discontinued_Date": "2010-09-07 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC60 T6 GEARTRONIC",
        "MM_CODES": 65066322,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 322,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC60 T6 GEARTRONIC R-DESIGN",
        "MM_CODES": 65066324,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 324,
        "Discontinued_Date": "2011-07-12 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC70 D5 GEARTRONIC EXCEL AWD",
        "MM_CODES": 65066372,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 372,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC70 D5 GEARTRONIC ELITE AWD",
        "MM_CODES": 65066376,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 376,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC70 D5 GEARTRONIC ELITE AWD",
        "MM_CODES": 65066377,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 377,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "V70 \/ XC60 \/ XC70",
        "Variant": "XC70 D5 INSCRIPTION GEARTRONIC AWD",
        "MM_CODES": 65066378,
        "GCode": 650,
        "MCode": 66,
        "Vcode": 378,
        "Discontinued_Date": "2017-07-12 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D3 DRIVE ESSENTIAL",
        "MM_CODES": 65067100,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 100,
        "Discontinued_Date": "2013-08-16 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D3 DRIVE EXCEL",
        "MM_CODES": 65067104,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 104,
        "Discontinued_Date": "2013-08-16 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D3 DRIVE ELITE",
        "MM_CODES": 65067108,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 108,
        "Discontinued_Date": "2013-08-16 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D3 GEARTRONIC ESSENTIAL",
        "MM_CODES": 65067115,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 115,
        "Discontinued_Date": "2013-08-16 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D3 GEARTRONIC EXCEL",
        "MM_CODES": 65067118,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 118,
        "Discontinued_Date": "2013-08-16 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D3 GEARTRONIC ELITE",
        "MM_CODES": 65067121,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 121,
        "Discontinued_Date": "2013-08-16 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T5 ESSENTIAL POWERSHIFT",
        "MM_CODES": 65067160,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 160,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T5 ESSENTIAL POWERSHIFT",
        "MM_CODES": 65067161,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 161,
        "Discontinued_Date": "2014-01-13 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T5 ESSENTIAL GEARTRONIC (DRIVE-E)",
        "MM_CODES": 65067162,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 162,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D4 EXCEL GEARTRONIC",
        "MM_CODES": 65067192,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 192,
        "Discontinued_Date": "2014-01-13 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D4 EXCEL GEARTRONIC (DRIVE-E)",
        "MM_CODES": 65067193,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 193,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D4 MOMENTUM GEARTRONIC",
        "MM_CODES": 65067194,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 194,
        "Discontinued_Date": "2018-05-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D4 ELITE GEARTRONIC",
        "MM_CODES": 65067195,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 195,
        "Discontinued_Date": "2014-01-13 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D4 ELITE GEARTRONIC (DRIVE-E)",
        "MM_CODES": 65067196,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 196,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D4 INSCRIPTION GEARTRONIC",
        "MM_CODES": 65067197,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 197,
        "Discontinued_Date": "2018-05-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D4 R-DESIGN GEARTRONIC (DRIVE-E)",
        "MM_CODES": 65067198,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 198,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D4 R- DESIGN GEARTRONIC",
        "MM_CODES": 65067199,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 199,
        "Discontinued_Date": "2018-05-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D5 GEARTRONIC EXCEL AWD",
        "MM_CODES": 65067204,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 204,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D5 GEARTRONIC EXCEL AWD",
        "MM_CODES": 65067205,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 205,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D5 MOMENTUM GEARTRONIC AWD",
        "MM_CODES": 65067206,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 206,
        "Discontinued_Date": "2018-05-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T6 GEARTRONIC EXCEL AWD",
        "MM_CODES": 65067254,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 254,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T6 GEARTRONIC EXCEL AWD",
        "MM_CODES": 65067255,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 255,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T6 MOMENTUM GEARTRONIC AWD",
        "MM_CODES": 65067256,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 256,
        "Discontinued_Date": "2018-05-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T6 GEARTRONIC ELITE AWD",
        "MM_CODES": 65067258,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 258,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T6 GEARTRONIC ELITE AWD",
        "MM_CODES": 65067259,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 259,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T6 INSCRIPTION GEARTRONIC AWD",
        "MM_CODES": 65067260,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 260,
        "Discontinued_Date": "2018-05-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T6 GEARTRONIC R-DESIGN AWD",
        "MM_CODES": 65067262,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 262,
        "Discontinued_Date": "2013-08-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T6 GEARTRONIC R-DESIGN AWD",
        "MM_CODES": 65067263,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 263,
        "Discontinued_Date": "2015-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T6 R-DESIGN GEARTRONIC AWD",
        "MM_CODES": 65067264,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 264,
        "Discontinued_Date": "2018-05-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D4 MOMENTUM GEARTRONIC AWD",
        "MM_CODES": 65067350,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 350,
        "Discontinued_Date": "2021-10-12 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D4 INSCRIPTION GEARTRONIC AWD",
        "MM_CODES": 65067360,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 360,
        "Discontinued_Date": "2022-02-11 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T5 MOMENTUM GEARTRONIC AWD",
        "MM_CODES": 65067400,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 400,
        "Discontinued_Date": "2021-10-12 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T5 INSCRIPTION AWD GEARTRONIC",
        "MM_CODES": 65067410,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 410,
        "Discontinued_Date": "2022-02-11 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T5 R-DESIGN GEARTRONIC AWD",
        "MM_CODES": 65067420,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 420,
        "Discontinued_Date": "2022-02-11 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D5 INSCRIPTION GEARTRONIC AWD",
        "MM_CODES": 65067440,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 440,
        "Discontinued_Date": "2022-02-11 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 D5 R-DESIGN GEARTRONIC AWD",
        "MM_CODES": 65067450,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 450,
        "Discontinued_Date": "2022-02-11 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T6 MOMENTUM GEARTRONIC AWD",
        "MM_CODES": 65067460,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 460,
        "Discontinued_Date": "2022-02-11 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T6 INSCRIPTION GEARTRONIC AWD",
        "MM_CODES": 65067470,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 470,
        "Discontinued_Date": "2022-02-11 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 T6 R-DESIGN GEARTRONIC AWD",
        "MM_CODES": 65067480,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 480,
        "Discontinued_Date": "2022-02-11 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC60 2011 - ON",
        "Variant": "XC60 B5 MOMENTUM GEARTRONIC",
        "MM_CODES": 65067520,
        "GCode": 650,
        "MCode": 67,
        "Vcode": 520,
        "Discontinued_Date": "2023-07-17 13:34:42.127"
    },
    {
        "Make": "VOLVO",
        "Model": "960 \/ S80 \/ S90 \/V90",
        "Variant": "S80 2.0T POWERSHIFT",
        "MM_CODES": 65070306,
        "GCode": 650,
        "MCode": 70,
        "Vcode": 306,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "960 \/ S80 \/ S90 \/V90",
        "Variant": "S80 2.0 T5 POWERSHIFT",
        "MM_CODES": 65070310,
        "GCode": 650,
        "MCode": 70,
        "Vcode": 310,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "960 \/ S80 \/ S90 \/V90",
        "Variant": "S80 3.0T GEARTRONIC",
        "MM_CODES": 65070322,
        "GCode": 650,
        "MCode": 70,
        "Vcode": 322,
        "Discontinued_Date": "2010-09-07 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "960 \/ S80 \/ S90 \/V90",
        "Variant": "S80 D3 ESSENTIAL GEARTRONIC",
        "MM_CODES": 65070326,
        "GCode": 650,
        "MCode": 70,
        "Vcode": 326,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "960 \/ S80 \/ S90 \/V90",
        "Variant": "S80 D3 ELITE GEARTRONIC",
        "MM_CODES": 65070332,
        "GCode": 650,
        "MCode": 70,
        "Vcode": 332,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "960 \/ S80 \/ S90 \/V90",
        "Variant": "S80 D5 GEARTRONIC",
        "MM_CODES": 65070341,
        "GCode": 650,
        "MCode": 70,
        "Vcode": 341,
        "Discontinued_Date": "2010-09-07 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "960 \/ S80 \/ S90 \/V90",
        "Variant": "S80 2.4 D5 EXECUTIVE GEARTRONIC",
        "MM_CODES": 65070350,
        "GCode": 650,
        "MCode": 70,
        "Vcode": 350,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "960 \/ S80 \/ S90 \/V90",
        "Variant": "S80 D5 GEARTRONIC EXECUTIVE",
        "MM_CODES": 65070351,
        "GCode": 650,
        "MCode": 70,
        "Vcode": 351,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "960 \/ S80 \/ S90 \/V90",
        "Variant": "S80 T6 EXEC GEARTRONIC",
        "MM_CODES": 65070365,
        "GCode": 650,
        "MCode": 70,
        "Vcode": 365,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "960 \/ S80 \/ S90 \/V90",
        "Variant": "S80 T5 ESSENTIAL POWERSHIFT",
        "MM_CODES": 65070391,
        "GCode": 650,
        "MCode": 70,
        "Vcode": 391,
        "Discontinued_Date": "2013-07-22 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 2.5T AWD 7 SEAT GEARTRONIC",
        "MM_CODES": 65072108,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 108,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 2.5T AWD 7 SEAT GTRON R-DES",
        "MM_CODES": 65072115,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 115,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 T5 GEARTRONIC AWD",
        "MM_CODES": 65072120,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 120,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 T5 GEARTRONIC  R-DESIGN AWD",
        "MM_CODES": 65072124,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 124,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 D5 7 SEAT A\/T",
        "MM_CODES": 65072220,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 220,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 D5 R-DESIGN GT 7SEAT A\/T",
        "MM_CODES": 65072225,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 225,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 D5 GEARTRONIC AWD",
        "MM_CODES": 65072226,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 226,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 D5 GEARTRONIC  R-DESIGN AWD",
        "MM_CODES": 65072229,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 229,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 D5 GEARTRONIC EXECUTIVE AWD",
        "MM_CODES": 65072232,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 232,
        "Discontinued_Date": "2015-06-26 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 3.2 A\/T 7 SEAT",
        "MM_CODES": 65072240,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 240,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 3.2 A\/T R-DESIGN GT 7 SEAT",
        "MM_CODES": 65072245,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 245,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 V8 EXECUTIVE 7 SEAT",
        "MM_CODES": 65072380,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 380,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 V8 R-DESIGN GT 7SEAT A\/T",
        "MM_CODES": 65072390,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 390,
        "Discontinued_Date": "2011-07-08 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 D4 MOMENTUM",
        "MM_CODES": 65072530,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 530,
        "Discontinued_Date": "2019-11-27 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 D4 INSCRIPTION",
        "MM_CODES": 65072540,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 540,
        "Discontinued_Date": "2019-11-27 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 T5 MOMENTUM AWD",
        "MM_CODES": 65072560,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 560,
        "Discontinued_Date": "2021-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 T5 INSCRIPTION AWD",
        "MM_CODES": 65072570,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 570,
        "Discontinued_Date": "2021-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 T5 R-DESIGN AWD",
        "MM_CODES": 65072580,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 580,
        "Discontinued_Date": "2021-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 D5 MOMENTUM AWD",
        "MM_CODES": 65072600,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 600,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 D5 MOMENTUM AWD",
        "MM_CODES": 65072601,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 601,
        "Discontinued_Date": "2019-12-11 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 D5 INSCRIPTION AWD",
        "MM_CODES": 65072610,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 610,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 D5 INSCRIPTION AWD",
        "MM_CODES": 65072611,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 611,
        "Discontinued_Date": "2021-11-16 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 D5 R-DESIGN AWD",
        "MM_CODES": 65072620,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 620,
        "Discontinued_Date": "2016-11-21 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 D5 R-DESIGN AWD",
        "MM_CODES": 65072621,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 621,
        "Discontinued_Date": "2021-11-16 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 D5 1ST EDITION AWD",
        "MM_CODES": 65072630,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 630,
        "Discontinued_Date": "2017-07-03 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 T6 MOMENTUM AWD",
        "MM_CODES": 65072650,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 650,
        "Discontinued_Date": "2019-12-09 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 T6 INSCRIPTION AWD",
        "MM_CODES": 65072660,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 660,
        "Discontinued_Date": "2021-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 T6 R-DESIGN AWD",
        "MM_CODES": 65072670,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 670,
        "Discontinued_Date": "2021-06-25 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 T8 TWIN ENGINE MOMENTUM AWD (HYBRID)",
        "MM_CODES": 65072750,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 750,
        "Discontinued_Date": "2019-12-09 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 T8 TWIN ENGINE INSCRIPTION AWD 6 SEATER",
        "MM_CODES": 65072755,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 755,
        "Discontinued_Date": "2022-08-12 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 T8 TWIN ENGINE INSCRIPTION AWD (HYBRID)",
        "MM_CODES": 65072760,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 760,
        "Discontinued_Date": "2022-10-06 00:00:00.000"
    },
    {
        "Make": "VOLVO",
        "Model": "XC 90",
        "Variant": "XC90 T8 TWIN ENGINE R-DESIGN AWD (HYBRID)",
        "MM_CODES": 65072770,
        "GCode": 650,
        "MCode": 72,
        "Vcode": 770,
        "Discontinued_Date": "2022-10-06 00:00:00.000"
    }
]
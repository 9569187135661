import React from 'react';
import dataAnimation from '../../assets/lottie/Servisor_05_Confirmation.json';

import servisorIcon from '../../assets/servisorWhiteLogo.png';
import vehicleTag from '../../assets/tags/confirmnew.svg';

import {
    Container,
    Box,
    Typography,
    Button,
    BottomNavigation,
    Paper,
} from '@mui/material';

import { BodyComponent } from './components/bodyComponent';
import { useHistory } from 'react-router';
import { HeaderText } from '../../components/common/HeaderText';
import { SubHeaderText } from '../../components/common/SubHeaderText';
import { PageLayout } from '../../components/layout/PageLayout';
import IssueHelpButton from '../../components/common/IssueHelpButton';

const Confirmation: React.FC = () => {
    const history = useHistory();
    return (
        <PageLayout animationData={dataAnimation}>
                         <HeaderText
                    headerText='CONFIRMATION'
                    headerImage={servisorIcon}
                />
            <Box
                sx={{
                    overflow: 'auto',
                    maxHeight: 'calc(100vh - 64px)',
                    position: 'relative',
                    paddingTop: { xs: '50px', lg: '100px' },
                    display: 'flex',
                    alignSelf: { xs: 'normal', lg: 'center' },
                    flexDirection: { xs: 'column', lg: 'column' },
                }}
            >
                <Box
                    sx={{
                        display: { xs: 'none', lg: 'flex' },
                        alignSelf: 'center',
                        width: { xs: '100%', lg: '70%' },
                    }}
                >
                    <img src={vehicleTag} alt='/' />
                </Box>

   
                <SubHeaderText
                    headerText='            Please confirm your vehicle details below before proceeding
'
                />

                <Container
                    disableGutters
                    sx={{
                        width: { xs: '90%', lg: '70%' },
                        marginTop: { xs: '-10px', lg: '0px' },
                        borderRadius: { xs: '4px', lg: '4px' },
                        backgroundColor: '#FFF',
                        pb: {xs: '0', lg: '50px'}
                    }}
                >
                    <Box
                        sx={{
                            display: { xs: 'flex', lg: 'none' },
                            alignSelf: 'left',
                            width: { xs: '100%', lg: '70%' },
                            paddingTop: { xs: '20px', lg: '0' },
                            paddingLeft: '10px',
                        }}
                    >
                        <img src={vehicleTag} alt='/' />
                    </Box>
                    

                    <BodyComponent />

                    <Paper
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            right: 0,
                            zIndex: '99',
                            paddingY: '20px',
                            width: { xs: '100%', lg: '50%' },
                            borderRadius: '0',
                        }}
                        elevation={0}
                    >
                        <BottomNavigation>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '10px',
                                    width: { xs: '90%', lg: '544px' },
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    fullWidth
                                    variant='outlined'
                                    sx={{
                                        paddingY: '10px',

                                        borderColor: '#004FFA',
                                    }}
                                    onClick={() =>
                                        history.push('/onboarding/1')
                                    }
                                >
                                    <Typography
                                        textTransform='none'
                                        sx={{
                                            color: '#004FFA',
                                            fontWeight: '600',
                                            fontSize: '18px',
                                        }}
                                    >
                                        Start Over
                                    </Typography>
                                </Button>

                                <Button
                                    fullWidth
                                    variant='contained'
                                    sx={{
                                        paddingY: '10px',

                                        backgroundColor: '#004FFA',
                                        ":hover": {
                                            backgroundColor: '#002064'
                                          }
                                  
                                    }}
                                    onClick={() => history.push('/details')}
                                >
                                    <Typography
                                        textTransform='none'
                                        sx={{
                                            color: '#FFF',
                                            fontWeight: '600',
                                            fontSize: '18px',
                                        }}
                                    >
                                        Confirm
                                    </Typography>
                                </Button>
                            </Box>
                        </BottomNavigation>
                    </Paper>
                </Container>
            </Box>
        </PageLayout>
    );
};

export default Confirmation;

import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function ProgressBar({value}: any) {
  
  return (
    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}} >

    <LinearProgress variant="determinate"  value={value} sx={{width: '70%', borderRadius: '4px'}}/>
    </Box>
  );
}
 
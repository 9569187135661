
/**
 * Calculates the discount percentage based on the given estimate and market price.
 *
 * @param {any} estimate - The estimate data for the workshop.
 * @param {number} marketprice - The market price for comparison.
 * @returns {number} The discount percentage.
 */
export const returnDiscountCalc = (estimate: any, marketprice: number, marketPriceState:any) => {
  const price = returnPriceCalc(estimate,marketPriceState);
  const diff = marketprice - price;
  const comb = marketprice + price;
  const result = 100 * (diff / (comb / 2));
  return result;
};


/**
 * Filters and sorts the listed workshops based on the given criteria.
 *
 * @param {string} target - The filter criteria ("savingdesc", "savingasc", "closest", "amountdesc", "amountasc").
 * @param {string} text - The text associated with the filter criteria.
 * @param {any} listedShops - The list of workshops to be filtered.
 * @param {any} setListedShops - A function to set the filtered list of workshops.
 * @param {any} setFilterText - A function to set the text associated with the filter.
 * @returns {void}
 */
export const filterResults = (
  target: string,
  text: string,
  listedShops: any,
  setListedShops: any,
  setFilterText: any
) => {
  switch (target) {
    case "savingdesc": {
      listedShops.sort((listedShopA: any, listedShopB: any) => {
        if (listedShopA.savings < listedShopB.savings) {
          return 1;
        }
        if (listedShopA.savings > listedShopB.savings) {
          return -1;
        }
        return 0;
      });
      setListedShops(listedShops);
      setFilterText(text);
      break;
    }
    case "savingasc": {
      listedShops.sort((listedShopA: any, listedShopB: any) => {
        if (listedShopA.savings > listedShopB.savings) {
          return 1;
        }
        if (listedShopA.savings < listedShopB.savings) {
          return -1;
        }
        return 0;
      });
      setListedShops(listedShops);
      setFilterText(text);
      break;
    }
    case "closest": {
      listedShops.sort((listedShopA: any, listedShopB: any) => {
        if (listedShopA.distance > listedShopB.distance) {
          return 1;
        }
        if (listedShopA.distance < listedShopB.distance) {
          return -1;
        }
        return 0;
      });
      setListedShops(listedShops);
      setFilterText(text);
      break;
    }
    case "amountdesc": {
      listedShops.sort((listedShopA: any, listedShopB: any) => {
        if (listedShopA.amount < listedShopB.amount) {
          return 1;
        }
        if (listedShopA.amount > listedShopB.amount) {
          return -1;
        }
        return 0;
      });
      setListedShops(listedShops);
      setFilterText(text);
      break;
    }
    case "amountasc": {
      listedShops.sort((listeShopA: any, listedShopB: any) => {
        if (listeShopA.amount > listedShopB.amount) {
          return 1;
        }
        if (listeShopA.amount < listedShopB.amount) {
          return -1;
        }
        return 0;
      });
      setListedShops(listedShops);
      setFilterText(text);
      break;
    }
  }
};

/**
 * Calculates the price for the given workshop data.
 *
 * @param {any} workshop - The workshop data for price calculation.
 * @returns {number} The calculated price for the workshop.
 */
export const returnPriceCalc = (workshop: any, marketPriceState:any) => {
  // @ts-ignore
  const marketPrice = JSON.parse(JSON.stringify(marketPriceState));
  const rate_discount = workshop.workshop.rate_discount;
  const rate = workshop.workshop.rate;
  const parts_discount = workshop.workshop.parts_discount;

  let currentAmount = 0;
  let labour = rate * marketPrice.total_labour_time * parseFloat(`0.${rate_discount}`);
  marketPrice.items.map(async (priceData : any) => {
    currentAmount += priceData.amount_ex;
  });
  const price = currentAmount - currentAmount * parseFloat(`0.${parts_discount}`) + labour;
  return price;
};

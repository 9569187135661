import React from 'react';
import { Box, Typography } from '@mui/material';

/**
 * AddressInfoCard Component
 *
 * This component displays information about a workshop's address, contact details,
 * and provides an option to save the workshop. It calculates and displays a discount
 * percentage based on the market-related price and the workshop's pricing.
 *
 * @component
 * @param {Object} props - Props for the component.
 * @param {Object} props.workshop - Workshop details object.
 * @returns {JSX.Element} AddressInfoCard component JSX element.
 */
export const AddressInfoCard: React.FC<any> = ({
    workshop,
    discountPercentage,
}) => {
    return (
        <Box
            sx={{
                borderRadius: '4px',
                backgroundColor: '#FFF',
                padding: '16px 16px',
            }}
        >
            <Box>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '600',
                                color: '#101828',
                            }}
                        >
                            {workshop?.workshop_name}
                        </Typography>

                        {discountPercentage > 0 && 
                        <Box>

                            <Typography
                                sx={{
                                    color: '#00C88C',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    textTransform: 'capitalize',
                                }}
                            >
                                <strong>Save</strong>&nbsp;
                                {`${discountPercentage}%`}
                            </Typography>
                        </Box>
}
                    </Box>
                    <Box>
                        <Typography></Typography>
                    </Box>
                </Box>
            </Box>
            <Box pt={2}>
                <Typography
                    sx={{
                        fontSize: '18px',
                        fontWeight: '600',
                        color: '#101828',
                    }}
                >
                    Contact Details
                </Typography>
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#101828',
                    }}
                >
                    {workshop?.phone_number}
                </Typography>
            </Box>
            <Box pt={2}>
                <Typography
                    sx={{
                        fontSize: '18px',
                        fontWeight: '600',
                        color: '#101828',
                    }}
                >
                    Physical Address
                </Typography>
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#101828',
                    }}
                >
                    {workshop?.address}
                </Typography>
            </Box>
        </Box>
    );
};

import { Box, Typography } from "@mui/material";

export const ConfirmDisplay = ({ headerText, children }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Box sx={{ pb: "10px" }}>
        <Typography
          sx={{
            color: "#818181",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "120%",
          }}
        >
          {headerText}
        </Typography>
      </Box>
      <Box sx={{ borderBottom: "1px solid #000", pb: "5px" }}>
        <Typography
          sx={{
            fontSize: "18px",
            color: "#000",
            fontWeight: "600",
            lineHeight: "120%",
          }}
        >
          {children}
        </Typography>
      </Box>
    </Box>
  );
};

// import { Modal } from '@mui/material';
import config from "../../config/config";

export const base = window.location.origin;

export const baseLocal = "http://localhost:5000";

// locally
// export const WORKSHOP_URL = `${baseLocal}/api/v1/servisor/consumer`;
// export const BOOKING_URL = `${baseLocal}/api/v1/servisor/workshop`;
// export const PAYMENT_URL = `${baseLocal}/api/v1/servisor/payments`;

//prod
export const WORKSHOP_URL = `${base}/api/v1/servisor/consumer`;
export const BOOKING_URL = `${base}/api/v1/servisor/workshop`;
export const PAYMENT_URL = `${base}/api/v1/servisor/payments`;

export const getUuid = async (url: string) => {
  try {
    const response = await fetch(`${WORKSHOP_URL}${url}`);
    const workshopData = await response.json();
    return workshopData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//Workshops

export const getWorkShops = async (url: string) => {
  try {
    const response = await fetch(`${WORKSHOP_URL}${url}`);
    const workshopData = await response.json();
    return workshopData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getWorkShopsById = async (url: string) => {
  try {
    const response = await fetch(`${WORKSHOP_URL}${url}`);
    const workshopData = await response.json();
    return workshopData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//Bookings
export const createBooking = async (data: any) => {
  try {
    let response = await fetch(`${BOOKING_URL}/create-booking`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(
        `Error in securing a booking: ${response.status} ${response.statusText}`
      );
    }

    let result = await response.json();
    return result;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

//Payments

export const gePayment = async (url: string) => {
  try {
    const response = await fetch(`${PAYMENT_URL}`);
    const paymentdata = await response.json();
    return paymentdata;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createPayment = async (data: any) => {
  try {
    const response = await fetch(`${PAYMENT_URL}/pay`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Error in paying");
    }

    const result = await response.json();
    return result.redirectUrl;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// B's code
export const API_URL_INJOZI =
  "https://servisor-staging.injozitech.com/api/v1/servisor";

export const API_URL = "https://api.servisor.co.za/api";

//prod
// export const API_URL_INJOZI = `${base}/api/v1/servisor`;

// export const API_URL = `${base}/api`;

// ! Heroku API
// export const API_URL_INJOZI =
//     'https://servisor-staging.herokuapp.com/api/v1/servisor';

// ! B's Code - Our Api
export const fetchList = async (url: string) => {
  const response = await fetch(`${API_URL_INJOZI}/${url}`);

  if (response.status == 404) {
    for (let count = 0; count < 3; ++count) {
      let triedResponse = await fetch(`${API_URL_INJOZI}/${url}`);
      if (triedResponse.status == 200) {
        const triedData = triedResponse.json();
        return triedData;
      }
    }
  }

  const data = await response.json();

  return data;
};

export const postData = async (url: string, data: any) => {
  const response = await fetch(`${API_URL_INJOZI}/${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${window.localStorage.getItem(
      //     'Authorization'
      // )}`,
    },
    body: JSON.stringify(data),
  });

  const d = await response.json();
  return d;
};

export const LIST = async (url: string) => {
  try {
    const response = await fetch(`${API_URL}/${url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${window.localStorage.getItem("Authorization")}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorResponse = await response.json();
      throw errorResponse;
    }
  } catch (error) {
    throw error;
  }
};

export const MARKET = async (url: string) => {
  try {
    const response = await fetch(`${API_URL}/${url}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${window.localStorage.getItem("Authorization")}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorResponse = await response.json();
      throw errorResponse;
    }
  } catch (error) {
    throw error;
  }
};

export const POST = async (url: string, params: any) => {
  try {
    const response = await fetch(`${API_URL}/${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${window.localStorage.getItem("Authorization")}`,
      },
      body: JSON.stringify(params),
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorResponse = await response.json();
      throw errorResponse;
    }
  } catch (error) {
    throw error;
  }
};

export const GET = async (url: string, params: any) => {
  try {
    const response = await fetch(`${API_URL}/${url}/${params._id.$oid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${window.localStorage.getItem("Authorization")}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorResponse = await response.json();
      throw errorResponse;
    }
  } catch (error) {
    throw error;
  }
};

export const PUT = async (url: string, params: any) => {
  try {
    const response = await fetch(`${API_URL}/${url}/${params._id.$oid}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${window.localStorage.getItem("Authorization")}`,
      },
      body: JSON.stringify(params),
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorResponse = await response.json();
      throw errorResponse;
    }
  } catch (error) {
    throw error;
  }
};

export const DELETE = async (url: string, params: any) => {
  try {
    const response = await fetch(`${API_URL}/${url}/${params._id.$oid}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${window.localStorage.getItem("Authorization")}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorResponse = await response.json();
      throw errorResponse;
    }
  } catch (error) {
    throw error;
  }
};

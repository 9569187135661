import dayjs from 'dayjs';
export const getDisableDates = () => {
    const dateNow = dayjs();

    const disabledDates = [
        dateNow.set('month', 0).set('date', 1),
        dateNow.set('month', 2).set('date', 21),
        dateNow.set('month', 3).set('date', 7),
        dateNow.set('month', 3).set('date', 10),
        dateNow.set('month', 3).set('date', 27),
        dateNow.set('month', 4).set('date', 1),
        dateNow.set('month', 4).set('date', 29),
        dateNow.set('month', 4).set('date', 27),
        dateNow.set('month', 5).set('date', 16),
        dateNow.set('month', 5).set('date', 17),
        dateNow.set('month', 7).set('date', 9),
        dateNow.set('month', 8).set('date', 24),
        dateNow.set('month', 11).set('date', 15),
        dateNow.set('month', 11).set('date', 16),
        dateNow.set('month', 11).set('date', 25),
        dateNow.set('month', 11).set('date', 26),
    ];
    return disabledDates;
};

import Logo from "../../assets/icons/servisorFinal.svg";
import welcomeTag from "../../assets/tags/tagsWelcomes.svg";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/Servisor_00_Main.json";
import { Container, Box, Button, Typography } from "@mui/material";
import { useHistory } from "react-router";

//Home  - Landing Page
const Home: React.FC = () => {
  // Configuration for the lottie animation
  const defaultOptions = {
    loop: true,
    autoplay: true,

    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const history = useHistory();
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        backgroundColor: "#FFF",
        overflowY: "scroll",

      }}
    >
      <Box sx={{ display: { xs: "flex" }, py: {xs: '2%', lg: '1%'}, px: {xs: '4%', lg: '2%'}, position: 'absolute', background: '#FFF', width: '100%' }}>
        <img src={Logo} alt="Servisor Logo" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: "auto", lg: "0" },
          justifyContent: { xs: "start", lg: "space-evenly" },
          paddingTop: { xs: "30%", sm: '10%', lg: "4%" },
          height: { xs: "100vh", lg: "0" },

          paddingX: { xs: "4%", lg: "2%" },

        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", lg: "column" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: { xs: "15px", lg: "0px" },
              flexDirection: "column",
              paddingTop: { xs: "0", lg: "0" },
              width: { xs: "100%", lg: "50%" },
              justifyContent: { xs: "start", lg: "center" },
              alignItems: { xs: "start", lg: "center" },
            }}
          >
            <Box
              sx={{
                display: { xs: "none", lg: "flex", justifyContent: "center" },
              }}
            >
              <img
                src={welcomeTag}
                alt="Servisor Logo"
                style={{ display: "flex", justifyContent: "center" }}
              />
            </Box>

            <Box
              sx={{
                display: {
                  xs: "flex",
                  lg: "none",
                  justifyContent: "left",
                },
              }}
            >
              <img
                src={welcomeTag}
                alt="Servisor Logo"
                style={{
                  marginTop: '0',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </Box>

            <Typography
              sx={{
                color: "#002064",
                fontSize: { xs: "32px", lg: "60px" },
                fontWeight: "600",
                width: { xs: "100%", lg: "100%" },
                textAlign: { xs: "start", lg: "center" },
                lineHeight: { xs: "35.2px", lg: "66px" },
              }}
            >
              Compare car service costs in minutes
            </Typography>
            <Typography
              sx={{
                color: "#002064",
                fontSize: { xs: "18px", lg: "24px" },
                fontWeight: "500",
                width: { xs: "100%", lg: "470px" },
                textAlign: { xs: "start", lg: "center" },
                paddingY: { xs: "0", lg: "30px" },
              }}
            >
              Save up to 30% on your Car Service from a Servisor trusted
              workshop partner.
            </Typography>
            <Box
              sx={{
                pb: "20px",
                display: { xs: "none", lg: "flex" },
                justifyContent: { xs: "center" },
                paddingTop: "0",
                position: "relative",
                zIndex: "999",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#004FFA",
                  height: "60px",
                  paddingY: "10px",
                  width: { xs: "100%", lg: "256px" },
                  textAlign: { xs: "start", lg: "center" },
                  ":hover": {
                    backgroundColor: "#002064",
                  },
                }}
                onClick={() => history.push("/onboarding/1")}
              >
                <Typography
                  textTransform="none"
                  sx={{ color: "#FFF", fontWeight: "600", fontSize: "18px" }}
                >
                  Let's go!
                </Typography>
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              width: { xs: "auto", lg: "100%" },
              display: "flex",
              alignSelf: "center",
              marginTop: { xs: "0", lg: "-150px" },
              paddingX: { xs: "0", lg: "200px" },
              overflow: 'hidden',
              pb: {xs: '20%', lg: '0'}
            }}
          >
            <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
          </Box>
        </Box>
        <Box sx={{ pb: "5%", display: { xs: "flex", lg: "none",  } }}>
          <Button
            variant="contained"
            fullWidth={true}
            sx={{
              backgroundColor: "#004FFA",
              height: "60px",
              paddingY: "10px",
              ":hover": {
                backgroundColor: "#002064",
              },
            }}
            onClick={() => history.push("/onboarding/1")}
          >
            <Typography
              textTransform="none"
              sx={{ color: "#FFF", fontWeight: "600", fontSize: "18px" }}
            >
              Let’s go!
            </Typography>
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Home;

import {
  Container,
  Box,
  Button,
  Typography,
  BottomNavigation,
  Paper,
  CircularProgress,
} from "@mui/material";
import dataAnimation from "../../assets/lottie/Servisor_06_Service Details.json";
import React, { useCallback, useEffect, useState } from "react";
import "./Details.scss";
import { emsGetEstimateData } from "../../services/apis/ems.service";
import { useHistory } from "react-router";
import { MainDetailsCard } from "./components/MainDetailsCard";
import { PageLayout } from "../../components/layout/PageLayout";

const Details: React.FC = () => {
  // @ts-ignore
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [emsData, setEmsData] = useState();

  const [priceError, setPriceError]: any = useState();

  // ! We will move this into marketPriceCalculations
  const getEmsEstimateData = useCallback(() => {
    setIsLoading(true);
    const emsAuthToken = window.localStorage.getItem("ems-auth-token");
    const vehicleInfo = JSON.parse(
      localStorage.getItem("vehicle-details") ?? ""
    );
    const bodyData = JSON.stringify({
      access_token: emsAuthToken,
      request_reference: "testggfg",
      vehicle_registration: vehicleInfo.registration_number,
      unique_model_code: vehicleInfo.umc,
      mileage_km: vehicleInfo.vehicleMilleage,
      equipment_list: [
        vehicleInfo.cc1,
        vehicleInfo.cc2,
        vehicleInfo.cc3,
        vehicleInfo.cc4,
      ],
      age: vehicleInfo.age,
    });
    emsGetEstimateData(bodyData)
      .then((response) => {
        const emsApiData = response.data;
        emsApiData.items.push({
          item_desc: "SUNDRIES",
          price_desc: "SUNDRIES",
          amount_ex: 90.0,
          labour_time: 0,
          price_ex: 90.0,
          quantity: 1,
        });
        const areaRate = JSON.parse(
          localStorage.getItem("temporary-rate-data") ?? ""
        );

        const partsAmount = emsApiData.items
          .reduce((acc: number, item: any) => acc + item.amount_ex, 0)
          .toFixed(2);
        const market_price_incl_vat =
          Number(partsAmount) +
          emsApiData.maintenance_details.labour_time * areaRate.rate;
        const test_me = market_price_incl_vat * 0.15;
        emsApiData.market_price =
          market_price_incl_vat + market_price_incl_vat * 0.15;
        emsApiData.maintenance_details.labour_time *= 1.1;

        localStorage.setItem(
          "maintenance-details",
          JSON.stringify(response.data)
        );
        setEmsData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        history.push("/workshops-error");
      });
  }, []);

  useEffect(() => {
    getEmsEstimateData();
  }, [getEmsEstimateData]);

  return (
    <PageLayout animationData={dataAnimation}>
      <>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "75%",
              mt: 16,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              overflow: "auto",
              maxHeight: "calc(100vh - 64px)",
              position: "relative",
              paddingTop: { xs: "0", lg: "82px" },
              display: "flex",
              alignSelf: { xs: "normal", lg: "center" },
              flexDirection: { xs: "column", lg: "column" },
              backgroundColor: "#EAEDF6",
            }}
          >
            <Box>
              <MainDetailsCard emsEstimateData={emsData} />

              <Container maxWidth={false} disableGutters>
                <Paper
                  sx={{
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                    zIndex: "99",
                    paddingY: "20px",
                    width: { xs: "100%", lg: "50%" },
                    borderRadius: "0",
                  }}
                  elevation={0}
                >
                  <BottomNavigation>
                    {priceError ? (
                      <Button
                        sx={{
                          height: "60px",
                          paddingY: "10px",
                          width: {
                            xs: "90%",
                            lg: "544px",
                          },
                        }}
                        variant="contained"
                        onClick={() => history.push("/allresults")}
                      >
                        <Typography
                          textTransform="none"
                          sx={{
                            color: "#FFF",
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                        >
                          View Workshops
                        </Typography>
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => history.push("/customise")}
                        sx={{
                          height: "60px",
                          paddingY: "10px",
                          width: {
                            xs: "90%",
                            lg: "544px",
                          },
                          backgroundColor: "#004FFA",
                          ":hover": {
                            backgroundColor: "#002064",
                          },
                        }}
                      >
                        <Typography
                          textTransform="none"
                          sx={{
                            color: "#FFF",
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                        >
                          Find me a better deal!
                        </Typography>
                      </Button>
                    )}
                  </BottomNavigation>
                </Paper>
              </Container>
            </Box>
          </Box>
        )}
      </>
    </PageLayout>
  );
};

export default Details;

import { Box, Typography } from "@mui/material";
import servisorLogo from "../../assets/Servisor_logo.svg";

export const HeaderText = ({ headerText, headerImage }: any) => {
  return (
    <Box
      sx={{
        position: { xs: "fixed", lg: "relative" },
        zIndex: "999",
        backgroundColor: { xs: "#004FFA", lg: "#002064" },
        width: "100%",
        display: { xs: "flex", lg: "none" },
        gap: "8px",
        paddingX: "4%",
        alignSelf: "center",
        height: "64px",
        justifyContent: { xs: "flex-start", lg: "center" },
        pt: "10px",
      }}
    >
      <Box
        sx={{
          display: { xs: "none", lg: "flex" },
          position: "absolute",
          left: "0",
          paddingLeft: "10px",
          zIndex: "auto",
        }}
      >
        <img src={servisorLogo} alt="/" />
      </Box>
      <Box>
        <img src={headerImage} alt="/" />
      </Box>
      <Box sx={{ display: { xs: "none", lg: "flex" } }}>
        <Typography
          sx={{
            textTransform: "capitalize",
            color: "#FFF",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {headerText}
        </Typography>
      </Box>
    </Box>
  );
};

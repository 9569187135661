import { Box, Typography } from "@mui/material";

export const SubHeaderText = ({ headerText, isTextCenter }: any) => {
  return (
    <Box
      sx={{
        padding: "relative",
        backgroundColor: { xs: "#004FFA", lg: "transparent" },
        width: { xs: "100%", lg: "70%" },
        display: "flex",
        paddingX: { xs: "4%", lg: "0%" },
        paddingY: {xs: '30px', lg: '10px'},
        alignSelf: {xs: "center", lg: 'left'},
        alignItems:  {xs: "center", lg: 'left'},
        height: { xs: "100%", lg: "100%" },
        justifyContent: { xs: "flex-start", lg: "start" },
      }}
    >
      <Box sx={{width: '100%'}}>
        <Typography
          sx={{
            textTransform: "none",
            color: { xs: "#FFF", lg: "#002064" },
            fontSize: { xs: "20px", lg: "28px" },
            fontWeight: "500",
            width: {xs: '100%', lg:'544px'},
            textAlign:  isTextCenter ? {xs: 'left', lg:'center'} : 'left',
            lineHeight: {xs: '110%'}

          }}
        >
          {headerText}
        </Typography>
      </Box>
    </Box>
  );
};

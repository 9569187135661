import { PageLayout } from "../../components/layout/PageLayout";
import dataAnimation from "../../assets/lottie/Servisor_06_Service Details.json";
import { HeaderText } from "../../components/common/HeaderText";
import servisorIcon from "../../assets/servisorWhiteLogo.png";
import { useHistory } from "react-router";
import {
  BottomNavigation,
  Box,
  Button,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import { SubHeaderText } from "../../components/common/SubHeaderText";
import vehicleTag from "../../assets/tags/confirmnew.svg";

function ErrorHandling() {
  const history = useHistory();
  return (
    <PageLayout animationData={dataAnimation}>
      <HeaderText headerText="CONFIRMATION" headerImage={servisorIcon} />

        <Container
          disableGutters
          sx={{
            width: { xs: "90%", lg: "70%" },
            height: '100%',
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px'
          }}
        >

            <Box>
                <Typography sx={{
                                color: "#002064" ,
                                fontSize: { xs: "20px", lg: "28px" },
                                fontWeight: "500",
                                lineHeight: {xs: '110%'},
                                textAlign: 'center'

                    
                }}>
                It appears that the car is currently unavailable. 

                </Typography>
            </Box>
          <Box>
            <Button
              fullWidth
              variant="contained"
              sx={{
                width: '100%',
                paddingY: "10px",

                backgroundColor: "#004FFA",
                ":hover": {
                  backgroundColor: "#002064",
                },
              }}
              onClick={() => history.push("/onboarding/2")}
            >
              <Typography
                textTransform="none"
                sx={{
                  color: "#FFF",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Go Back
              </Typography>
            </Button>
          </Box>
        </Container>
    </PageLayout>
  );
}

export default ErrorHandling;

import React from "react";
import { Box, Divider, Typography } from "@mui/material";

/**
 * ServiceDetailsCard Component
 *
 * This component displays detailed service and pricing information for a vehicle,
 * including registration number, kilometers, next service due description,
 * part details, labor hours, and total cost.
 *
 * @component
 
 * @param {string} props.Gear - URL of the gear icon image.
 * @param {Object} props.maintenanceDetails - Maintenance details.
 * @param {Object} props.discountedWorkshopPrice - Workshop discounted price.
 * @returns {JSX.Element} ServiceDetailsCard component JSX element.
 */
export const ServiceDetailsCard: React.FC<any> = ({
  Gear,
  maintenanceDetails,
  discountedWorkshopPrice,
  adminFee,
}) => {
  const vehicleDetails = JSON.parse(
    localStorage.getItem("vehicle-details") || ""
  );

  const hours = Math.floor(maintenanceDetails?.total_labour_time);
  const mins = (maintenanceDetails?.total_labour_time - hours) * 60;

  const totalPrice = Number(discountedWorkshopPrice) + Number(adminFee);
  const formattedTotalPrice = totalPrice.toFixed(2)

  return (
    <Box
      sx={{
        borderRadius: "4px",
        backgroundColor: "#FFF",
        padding: "16px 16px",
      }}
    >
      <Box>
        <Box pb={2}>
          {/* ! Will add logo when api is avialable */}
          {/* <img
                            src={nissanImage}
                            alt='/'
                            style={{ width: 50, marginBottom: 20 }}
                        /> */}
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#101828",
            }}
          >
            {maintenanceDetails?.vehicle_make}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#101828",
            }}
          >
            {maintenanceDetails?.vehicle_model}
          </Typography>
        </Box>
        <Divider />
        <Box py={1}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#101828",
              }}
            >
              Registration Number
            </Typography>

            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#101828",
              }}
            >
              {maintenanceDetails?.vehicle_registration}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#101828",
              }}
            >
              Kilometers
            </Typography>

            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#101828",
              }}
            >
              {vehicleDetails?.vehicleMilleage}
            </Typography>
          </Box>
          {/* ) : null} */}
        </Box>
        <Divider />
        <Box py={2}>
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              paddingY: "10px",
              alignItems: "center",
            }}
          >
            <img src={Gear} className="gear-icon" alt="/" />
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                color: "#101828",
              }}
            >
              Service Interval
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#101828",
              }}
            >
              {maintenanceDetails?.maintenance_details.description}
            </Typography>
          </Box>
        </Box>
        <Divider />

        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Typography
              sx={{
                color: "#101828",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              {" "}
              Part
            </Typography>

            <Typography
              sx={{
                color: "#101828",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              {" "}
              Qty
            </Typography>
          </Box>
          {maintenanceDetails.items.map(
            (item: any, index: SVGAnimatedNumber) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  py: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#101828",
                    fontSize: "16px",
                    fontWeight: "400",
                    textTransform: "capitalize",
                  }}
                >
                  {item.price_desc.toLowerCase()}
                </Typography>

                <Typography
                  sx={{
                    color: "#101828",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {item.quantity}
                </Typography>
              </Box>
            )
          )}
        </Box>

        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            paddingY: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#101828",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Labour
          </Typography>
          <Typography
            sx={{
              color: "#101828",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            {`${hours}h ${mins.toFixed(0)}m`}
          </Typography>
          {/* );
            }
          })} */}
        </Box>

        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            paddingY: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#101828",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Admin Fee
          </Typography>

          <Typography
            sx={{
              color: "#101828",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            R {adminFee}
          </Typography>
        </Box>

        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            paddingY: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#101828",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Workshop price
          </Typography>

          <Typography
            sx={{
              color: "#101828",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            R {discountedWorkshopPrice}
          </Typography>
        </Box>
        <Divider/>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            paddingY: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#101828",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Total
          </Typography>

          <Typography
            sx={{
              color: "#101828",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            R {formattedTotalPrice}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import "./theme/global.scss";
import "./theme/datepicker.scss";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import Home from "./pages/home/Home";
import Onboarding from "./pages/onboarding/Onboarding";
import Confirmation from "./pages/confirmation/Confirmation";
import Details from "./pages/details/Details";
import Customize from "./pages/customise/Customize";
import Result from "./pages/results/Result";
import Workshop from "./pages/workshop/Workshop";
import Payment from "./pages/payment/Payment";
import WorkshopListings from "./pages/workshopListings/workshopListings";

import {
  useGlobalContext,
  GlobalContextProvider,
} from "./services/context/globalContext";
import { useContext, useState } from "react";
import { ThemeProvider } from "@mui/material";
import { themeStyles } from "./theme/ThemeStyles";
import { DetailsEvaluationPage } from "./pages/onboarding/detailsEvaluation/DetailsEvaluation";
import ManualSearch from "./pages/onboarding/ManualSearch/ManualSearch";
import { ServicePlanSlide } from "./pages/onboarding/servicePlanSlide/ServicePlanSlide";
import { LocationEvaluation } from "./pages/onboarding/locationEvaluation/locationEvaluation";
import ErrorHandling from "./pages/error/ErrorHandling";
import SingleWorkshop from "./pages/workshopListings/components/singleWorkshop";
import PaymentSuccess from "./pages/payment/PaymentSuccess";
import PaymentFail from "./pages/payment/PaymentFail";
import BookingErrorHandling from "./pages/error/BookingErrorHandling";
import GoogleRouteTracker from "./components/googleTracker/googleAnalyticsTracker";
import ServisorRedirect from "./pages/home/redirect";

const App: React.FC = () => {
  //Vehicle manual

  return (
    <Router>
      <GoogleRouteTracker />
      <GlobalContextProvider>
        <ThemeProvider theme={themeStyles}>
          <Switch>
            {/* @ts-ignore */}
            <Route exact path="/">
              <ServisorRedirect url="https://www.servisor.co.za/"/>
            </Route>
            {/* @ts-ignore */}
            {/* @ts-ignore */}
            <Route exact path="/home">
              <Home/>
            </Route>
            {/* <Route path="/onboarding/:id" component={Onboarding}></Route> */}
            <Route
              path="/onboarding/1"
              component={DetailsEvaluationPage}
            ></Route>
            <Route path="/onboarding/2" component={ManualSearch}></Route>
            <Route path="/onboarding/3" component={ServicePlanSlide}></Route>
            <Route path="/onboarding/4" component={LocationEvaluation}></Route>

            {/* @ts-ignore */}
            <Route exact path="/confirmation">
              <Confirmation />
            </Route>
            {/* @ts-ignore */}
            <Route exact path="/details">
              <Details />
            </Route>
            {/* @ts-ignore */}
            <Route exact path="/customise">
              <Customize />
            </Route>
            {/* @ts-ignore */}
            {/* <Route
              path="/results/:distance/:lift/:date/:marketprice"
              component={Result}
            ></Route>  */}
            <Route
              path="/results/:distance/:date/:marketprice"
              component={Result}
            ></Route>
            {/* @ts-ignore */}
            <Route path="/workshop-result/:id" component={Workshop}></Route>
            <Route
              path="/workshop-details/:id"
              component={SingleWorkshop}
            ></Route>
            {/* @ts-ignore */}
            <Route
              path="/workshop-listings"
              component={WorkshopListings}
            ></Route>
            <Route path="/workshops-error" component={ErrorHandling}></Route>
            <Route
              path="/booking-error"
              component={BookingErrorHandling}
            ></Route>

            {/* @ts-ignore */}
            <Route exact path="/payment">
              {/* @ts-ignore */}
              <Redirect to="/payment" />
              <Payment />
            </Route>

            <Route path="/success">
              <PaymentSuccess />
            </Route>

            <Route path="/failed">
              <PaymentFail />
            </Route>

            {/* @ts-ignore */}
            <Route exact path="/">
              {/* @ts-ignore */}
              <Redirect to="/home" />
            </Route>
          </Switch>
        </ThemeProvider>
      </GlobalContextProvider>
    </Router>
  );
};

export default App;

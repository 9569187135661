// ShopDetail.js
import { Box, Button, Divider, Typography } from '@mui/material';
import Stars from '../../../assets/5Stars_Blue@1x.svg';
import { useHistory } from 'react-router';
import { returnPriceCalc } from '../../../services/utilities/workshopUtilities';
import { IonButton } from '@ionic/react';
import { useGlobalContext } from '../../../services/context/globalContext';
import locationIcon from '../../../assets/icons/location_on.svg';
import arrowIcon from '../../../assets/icons/arrow_right.svg';
import { useEffect, useState } from 'react';

/**
 * Component to render single shop card using details given
 *
 * @param index
 * @param shopdetails
 * @param workshopsData
 */
const ListedShops = ({
    index,
    shopDetails,
    workshopsData,
    isPricingVisible,
}: {
    index: any;
    shopDetails: any;
    workshopsData: any;
    isPricingVisible: Boolean;
}) => {
    const { setDistance, marketPriceState } = useGlobalContext();

    const history = useHistory();

    return (
        <Box
            sx={{
                overflowY: 'auto',
                height: '100vh',
                pb: '100px',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
                '&-ms-overflow-style:': {
                    display: 'none',
                },
            }}
        >
            {workshopsData.map((elem: any, index: number) => (
                <Button
                    // key={shopDetails._id}
                    key={elem._id}
                    fullWidth
                    onClick={(event) => {
                        event.preventDefault();
                        // setDistance(
                        //   `${(shopDetails.distance / 1000).toLocaleString(undefined, {
                        //     maximumFractionDigits: 2,
                        //   })}`
                        // );
                        setDistance('dummy distance');

                        // history.push(`/workshop-result/${shopDetails.workshop.id_business}`);
                        // history.push(`/workshop-result/${shopDetails._id}`);
                        history.push(`/workshop-result/${elem._id}`);
                    }}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid rgba(225, 235, 255, 1)',
                        mb: '5px',
                    }}
                >
                    <Box sx={{ width: '40%' }}>
                        <Box>
                            <Typography
                                textAlign={'start'}
                                sx={{
                                    color: '#002064',
                                    fontWeight: '600',
                                    fontSize: '16px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {/* {shopDetails.workshop_name} */}
                                {/* {elem.province.name} */}
                                {elem.province.name}
                            </Typography>
                        </Box>
                        <Box>
                            <Box>
                                <Typography
                                    textAlign={'start'}
                                    sx={{
                                        color: 'rgba(0, 32, 100, 0.70)',
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {/* {shopDetails.address} */}
                                    {elem.workshop_name}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box>
                                <Typography
                                    textAlign={'start'}
                                    sx={{
                                        color: 'rgba(0, 32, 100, 0.70)',
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {/* {shopDetails.address} */}
                                    {elem.address}
                                </Typography>
                            </Box>
                        </Box>
                        {/* ! Will apply distance one the api is ready */}
                        {/* <Box
                            sx={{
                                backgroundColor: '#E1EBFF',
                                borderRadius: '54px',
                                padding: '4px 4px',
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                                width: '70%',
                            }}
                        >
                            <Box>
                                <img
                                    src={locationIcon}
                                    alt='/'
                                    style={{
                                        display: 'flex',
                                        alignSelf: 'center',
                                    }}
                                />
                            </Box>
                            <Typography
                                textAlign={'start'}
                                sx={{
                                    color: '#7085B2',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                }}
                            >
                                5.2KM
                            </Typography>
                        </Box> */}
                        {/* <Box>
          <Typography>
            <small className="font-bold">
              R{" "} */}
                        {/* {returnPriceCalc(shopDetails, marketPriceState).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })} */}
                        {/* 50 gazillion */}
                        {/* </small>
          </Typography>
        </Box> */}
                    </Box>
                    {/* <Box >
        <Box>
          <h6 className="ion-no-margin">
            <small className="font-light ion-color-primary ion-text-uppercase"> */}
                    {/* {shopDetails.workshop.distance} */}
                    {/* 50
              km Away
            </small>
          </h6>
        </Box>
      </Box> */}
                    {/* 
      <Box >
        <Box>
          <h6 className="ion-no-margin">
            <small className="font-bold ion-color-primary ion-text-uppercase"> */}
                    {/* {shopDetails.workshop.address} */}
                    {/* dummy address */}
                    {/* </small>
          </h6>
        </Box>
      </Box> */}
                    <Box>
                        {/* <Box>
          <img src={Stars} className="rating-stars" alt='/' />
        </Box> */}
                        <Box
                            sx={{
                                display: isPricingVisible ? 'flex' : 'none',
                                flexDirection: 'column',
                            }}
                        >
                            <Button
                                fullWidth
                                sx={{
                                    display: 'flex',
                                    alignSelf: 'start',
                                    textAlign: 'start',
                                    backgroundColor: '#E1EBFF',
                                    gap: '10px',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        color: '#002064',
                                    }}
                                >
                                    {`R${elem.discounted_workshop_price}`}
                                    {/* {console.log('workshop pied: ', elem.discounted_workshop_price)} } */}
                                </Typography>
                                <Box>
                                    <img
                                        src={arrowIcon}
                                        alt='/'
                                        style={{
                                            display: 'flex',
                                            alignSelf: 'center',
                                        }}
                                    />
                                </Box>
                            </Button>
                            {elem.percentage_saved > 0 && (
                                <Button
                                    fullWidth
                                    sx={{
                                        display: 'flex',
                                        alignSelf: 'start',
                                        textAlign: 'start',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: '#00C88C',
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        <strong>Save &nbsp;</strong>
                                        {/* {shopDetails.rate_discount} */}
                                        {`${elem.percentage_saved}%`}
                                    </Typography>

                                    {/* <strong> */}
                                    {/* {shopDetails.savings.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })} */}
                                    {/*               
            </strong> */}
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Button>
            ))}
        </Box>
    );
};

export default ListedShops;


export class ServiceDetailsModel {
    registration_number: any;
    milleage: any;
    service_plan: any;
  
    constructor(obj: any) {
      this.registration_number = obj.registration_number;
      this.milleage = obj.milleage;
      this.service_plan = obj.service_plan;
    }
  }
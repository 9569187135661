//local
// export const baseLocal = "http://localhost:5000";

export const base = window.location.origin;

//local
// export const CUSTOMER_URL = `${baseLocal}/api/v1/servisor/workshop`;

// //prod
export const CUSTOMER_URL = `${base}/api/v1/servisor/workshop`

//The API to extract the data
export const customerDataExtract = async (data: any) => {
  try {
    const response = await fetch(`${CUSTOMER_URL}/customer-extract`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(
        result.smg || "Failed to create or update the extracted data"
      );
    }
    return result;
  } catch (error) {
    console.error("Error in extracting data: ", error);
    throw error;
  }
};

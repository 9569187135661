import Logo from "../../assets/icons/servisorFinal.svg"
import personlTag from '../../assets/tags/welcomeTag.svg'
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/Servisor_00_Main.json";
import { Container, Box, Button, Typography, Modal, Fade } from "@mui/material";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";



//Payment  - Fail
const PaymentFail: React.FC = () => {

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  // Configuration for the lottie animation
  const defaultOptions = {
    loop: true,
    autoplay: true,
    
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }; 
  const history = useHistory();

  const instructionModal = () => {
    return (

      <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition

    >
      <Fade in={open}>
        <Box>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: {
                xs: "90%",
                lg: "30%",
              },
              backgroundColor: "#FFF",
              borderRadius: "8px",
              boxShadow: "24",
              padding: "24px 16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#002064",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
Something went wrong                              </Typography>
              </Box>
         
            </Box>
            <Box>
              <Typography
                sx={{
                  mt: 2,
                  fontSize: "16px",
                  color: "#525456",
                  fontWeight: "400",
                }}
              >
Payment Failed       </Typography>
         
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: 'center',
                alignItems: 'center',
                gap: "10px",
                paddingTop: "10px",
              margin: '0',
              }}
            >
              <Button
                onClick={() => window.location.href = "mailto:info@servisor.co.za.com"}
                variant="text"
                sx={{
                  borderRadius: "var(--1, 8px)",
                  width: '50%',
                  margin:'0',

                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                  }}
                >
                  Contact us
                </Typography>
              </Button>
             
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>    );
  };

  useEffect(() => {
    const uuidData = localStorage.getItem('uuid-data') ?? '';
    const urlData = localStorage.getItem('urlID') ?? '';

    if(localStorage.length === 0 || uuidData === null || urlData === null ) {
      history.push('/home')
    }

    handleOpen();

    return () => handleClose();
    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container disableGutters maxWidth={false} sx={{ backgroundColor: "#FFF", overflowY: "scroll", maxHeight: {xs: 'calc(100vh - 64px)', lg: '0'}, paddingX: {xs: '4%', lg:'2%'}, }}>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: {xs: '50px', lg:"0"}, 
        justifyContent: {xs: 'space-between', lg: "space-evenly"},
        paddingTop: {xs: '25px', lg: '30px'}
      }}
    >
       {instructionModal()}
      <Box sx={{display: {xs: 'flex', }}}>
        <img src={Logo} alt="Servisor Logo"  />
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', flexDirection: {xs: 'column', lg: 'column'}}}>
      <Box sx={{ display: "flex", gap: {xs: "25px", lg: "0px"}, flexDirection: "column", paddingTop: {xs: '0', lg:'0'}, width: {xs: '100%', lg: '50%'}, justifyContent: {xs: 'start', lg:'center'}, alignItems: {xs: 'start', lg:'center'}}}>
      {/* <Box sx={{display: {xs: 'none', lg: 'flex', justifyContent: 'center', }}}>
        <img src={personlTag} alt="Servisor Logo"  style={{display: 'flex', justifyContent: 'center'}}/>
      </Box>  */}

      

        <Typography
          sx={{ color: "#002064", fontSize: {xs: '32px', lg: '60px'}, fontWeight: "600", width: {xs: '100%', lg: '100%'}, textAlign: {xs: "start", lg: 'center'}, lineHeight: {xs: '35.2px', lg:'66px'} }}
        >
          Compare car service costs in minutes
        </Typography>
        <Typography 
          sx={{ color: "#002064", fontSize: {xs: '18px', lg: '24px'}, fontWeight: "500", width: {xs: '100%', lg: '470px'}, textAlign: {xs: "start", lg: 'center'}, paddingY: {xs: '0', lg: '30px'} }}
        >
          Save up to 30% on your Car Service from a Servisor trusted workshop
          partner.
        </Typography>
  

      </Box>

      <Box sx={{width: {xs:'100%', lg: '100%'}, display: 'flex', alignSelf: 'center', marginTop: {xs: '0', lg:'-0'}, paddingX: {xs: '0px', lg:'200px'} }}>
        <Lottie options={defaultOptions}  isClickToPauseDisabled={true}/>
      </Box>
      </Box>

    </Box>
    </Container>
  );
};

export default PaymentFail;

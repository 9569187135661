export class CustomModel {
    service_date: any;
    distance: any;
    // lift: any;
    original_parts: any;
    market_price: any;
    parts_needed: any;
    labour: any;
    lat: any;
    long: any;

    constructor(obj: any) {
        this.service_date = obj.service_date;
        this.distance = obj.distance;
        // this.lift = obj.lift;
        this.original_parts = obj.original_parts;
        this.market_price = obj.market_price;
        this.parts_needed = obj.parts_needed;
        this.labour = obj.labour;
        this.lat = obj.lat;
        this.long = obj.long;
    }
}
import { useEffect, useState } from "react";
import Home from "./Home";

const ServisorRedirect = ({url}: {url: string}) => {
    const [redirectFailed, setRedirectFailed] = useState(false);
    useEffect(() => {
        window.location.href = url;

        const timer = setTimeout(() => {
            setRedirectFailed(true)
        }, 3000)

        return () => {
            clearTimeout(timer)
        };
    }, [url])

    if (redirectFailed) {
        return <Home/>
    }

    return null;
}

export default ServisorRedirect;
const base = window.location.origin;

export const baseLocal = "http://localhost:5000";

// Testing
// const TESTING_WORKSHOP_URL =
//   "https://servisor-staging.injozitech.com/api/v1/servisor/consumer";

// locally
//    export const TESTING_WORKSHOP_URL = `${baseLocal}/api/v1/servisor/consumer`;

//prod
export const TESTING_WORKSHOP_URL = `${base}/api/v1/servisor/consumer`;

export const getAllActiveWorkshops = async () => {
  try {
    const response = await fetch(
      `${TESTING_WORKSHOP_URL}/get_active_workshops`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    if (response.ok) {
      return await response.json();
    } else {
      const errorResponse = await response.json();
      throw errorResponse;
    }
  } catch (error) {
    throw error;
  }
};

export const getSelectedWorkshopById = async (id: string) => {
  try {
    const response = await fetch(
      `${TESTING_WORKSHOP_URL}/get_active_workshop_by_id/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    if (response.ok) {
      return await response.json();
    } else {
      const errorResponse = await response.json();
      throw errorResponse;
    }
  } catch (error) {
    throw error;
  }
};

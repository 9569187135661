import { Button, Typography } from "@mui/material";

declare global {
  interface Window {
    FreshworksWidget?: any;
  }
}

const IssueHelpButton = () => {
  const widgetButton = () => {
    window.FreshworksWidget("open");
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          width: "100px",
          paddingX: "20px",
          padding: "5px",
        }}
        onClick={widgetButton}
      >
        <Typography
          sx={{
            textTransform: "none",
          }}
        >
          Help/Issue
        </Typography>
      </Button>
    </>
  );
};

export default IssueHelpButton;

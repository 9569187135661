// ShopDetail.js
import { useState } from "react";
import { filterResults } from "../../../services/utilities/workshopUtilities";
import { Box, Button, Popover, Typography } from "@mui/material";

/**
 * Component to render single shop card using details given
 *
 * @param index
 * @param shopdetails
 */
export const FilterBanner = ({
  showActionSheet,
  setShowActionSheet,
  listedShops,
  setListedShops,
  setFilterText,
}: {
  showActionSheet: any;
  setShowActionSheet: any;
  listedShops: any;
  setListedShops: any;
  setFilterText: any;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);



  return (
    <>
      <Popover
      
        open={showActionSheet}
        onClose={() => setShowActionSheet(false)}

        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
      }}
     
      anchorReference='anchorEl'

      anchorEl={anchorEl}      
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "5px 5px",
          }}
        >
          <Button
            sx={{ display: "flex", justifyContent: "start" }}
            onClick={() => {
              filterResults(
                "savingdesc",
                "Savings - (High to Low)",
                listedShops, 
                setListedShops,
                setFilterText
              );
              setShowActionSheet(false);
            }}
          >
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              <strong>Savings</strong> - (High to low)
            </Typography>
          </Button>
          <Button
            sx={{ display: "flex", justifyContent: "start" }}
            onClick={() => {
              filterResults(
                "savingasc",
                "Savings - (Low to high)",
                listedShops,
                setListedShops,
                setFilterText
              );
              setShowActionSheet(false);
            }}
          >
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              <strong>Savings</strong> - (Low to high)
            </Typography>
          </Button>
          <Button
            sx={{ display: "flex", justifyContent: "start" }}
            onClick={() => {
              filterResults(
                "closest",
                "Distance - (Closest)",
                listedShops,
                setListedShops,
                setFilterText
              );
              setShowActionSheet(false);
            }}
          >
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: "14px",
              }}
            >
              <strong>Distance </strong> - (Closest)
            </Typography>
          </Button>
          <Button
            sx={{ display: "flex", justifyContent: "start" }}
            onClick={() => {
              filterResults(
                "amountdesc",
                "Amount - (High to low)",
                listedShops,
                setListedShops,
                setFilterText
              );
              setShowActionSheet(false);
            }}
          >
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              <strong>Amount</strong> - (High to low)
            </Typography>
          </Button>
          <Button
            sx={{ display: "flex", justifyContent: "start" }}
            onClick={() => {
              filterResults(
                "amountasc",
                "Amount - (Low to high)",
                listedShops,
                setListedShops,
                setFilterText
              );
              setShowActionSheet(false);
            }}
          >
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              <strong>Amount</strong> - (Low to high)
            </Typography>
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default FilterBanner;

import { POST, LIST, fetchList, getWorkShops, getWorkShopsById, getUuid } from './api.service';

declare var google: any;

export const searchworkshops = (params: any) => {
    return POST('search/workshop', params);
};
export const getAllworkshops = () => {
    return POST('all/workshop', null);
};
export const getworkshopbyid = (id: any) => {
    return LIST(`workshop/${id}`);
};

// ! B's call
export const findWorkshops = (location: string) => {
    return fetchList(`search/getworkshops?location=${location}`);
};
// !B's Call
export const findWorkshopById = (id: string) => {
    return fetchList(`search/getSpecificWorkshops?id=${id}`);
};


export const workshopFetch = async () => {
    try {
      const response = await getWorkShops('/get_active_workshops');
      return response;
    } catch (error) {
      console.error(error); 
      throw error; 
    }
  };  
  
  export const workshopFetchById = async (id: any) => {
    try {
      const response = await getWorkShopsById(`/get_active_workshop_by_id/${id}`);
      return response;
    } catch (error) {
      console.error(error);
      throw error;  
    }
  };  
  
  export const uuidFetch = async () => {
    try {
      const response = await getUuid('/generate_uuid');
      return response;
    } catch (error) {
      console.error(error); 
      throw error; 
    }
  };  

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  
  export const formatDate = (dateString:string) => {
    const date = new Date(dateString);
    const day = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayNumber = date.getDate();
    const yearNow = date.getFullYear();
    return `${day}, ${dayNumber} ${month} '${yearNow.toLocaleString().slice(3)}`;
  }
  
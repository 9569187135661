import { IonContent, IonPage } from "@ionic/react";

import "./workshopListings.scss";

import React, { useEffect, useState } from "react";

import { fetchAllLocations } from "../../services/apis/location.service";
import { findWorkshops } from "../../services/apis/workshop.service";
import Loader from "../../components/Loading/Loading";
import { ResultHeader } from "../../components/common/ResultHeader";
import ShopDetail from "./components/singleShopDetails";
import { Container } from "@mui/material";

/**
 * Renders a list of workshops given from an api call
 * @returns IonPage
 */
const WorkshopListings: React.FC = () => {
  const [listedShops, setListedShops] = useState([]);
  const [loading, setLoading] = useState(true);

  // const searchWorkshops = () => {
  //   // TODO: We will need to include logic to add different areas to the api call. Searching the entire database's workshops as suggested will have a big performance hit. We could add a select for the user
  //   findWorkshops("Gauteng").then((data) => {
  //     console.log("workshops", data);
  //     setListedShops(data);
  //     setLoading(false);
  //   });
  // };

  // // api call to gather workshop data on page load
  // useEffect(() => {
    
  //   setTimeout(() => {
  //     fetchAllLocations().then(async () => {
  //       await searchWorkshops();
      
  //     });
  //   }, 1000);
  // }, []);

  return (
    <>
      <ShopDetail />

    </>



  );
};

export default WorkshopListings;



  {/* <IonPage> */}

      {/* {loading ? (
        <Loader />
      ) : (
        <>
          <ResultHeader />
          <IonContent fullscreen className="grey-bg">
            {listedShops && listedShops.length > 0 ? (
              listedShops.map((shopDetails: any, index:any) => {
                return <ShopDetail index={index} shopDetails={shopDetails} />;
              })
            ) : (
              <div className="">
                <div className="spacer"></div>
                <h6 className="ion-text-center" style={{ color: "#999" }}>
                  No workshops available.
                </h6>
              </div>
            )}
          </IonContent>
        </>
      )} */}
      

      {/* <ShopDetail /> */}
    {/* </IonPage> */}

export class LocationModel {
    location: any;
    place_id: any;
    description: any;
  
    constructor(obj: any) {
      this.location = obj.location;
      this.place_id = obj.place_id;
      this.description = obj.description;
    }
  }
import { Box, CircularProgress } from '@mui/material';

const Loader: React.FC = () => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: '999',
            }}
        >
            <CircularProgress sx={{ height: '50px', width: '50px' }} />
        </Box>
    );
};

export default Loader;

import dataAnimation from '../../../assets/lottie/Servisor_09_Confirmation_1.json';
import servisorIcon from '../../../assets/servisorWhiteLogo.png';
import React, { useCallback, useEffect, useState } from 'react';
// import '../Workshop.scss';
import Gear from '../../../assets/icon-service.svg';
import Calendar from '../../../assets/icons/calendar.svg';
import Calculator from '../../../assets/icons/calculator.svg';
import Steering from '../../../assets/icons/steering-wheel.svg';
import { RouteComponentProps } from 'react-router';
import { uuidFetch } from '../../../services/apis/workshop.service';
import { getSelectedWorkshopById } from '../../../services/apis/worksshopsData.service';
import { formatDate } from '../../../services/utilities/dateFormating';
import personalTag from '../../../assets/tags/bookingdetailsnew.svg'
import {
    BottomNavigation, 
    Box,
    Button,
    CircularProgress,
    Container,
    Paper,
    Typography, 
} from '@mui/material';
import { HeaderText } from '../../../components/common/HeaderText';
import { createBooking, createPayment } from '../../../services/apis/api.service';
import { PageLayout } from '../../../components/layout/PageLayout';
import { SubHeaderText } from '../../../components/common/SubHeaderText';
import { AddressInfoCard } from '../../workshop/components/AddressInfoCard';
import { SingleAddressInfoCard } from './singleAddressInfo';

interface ResultProps
    extends RouteComponentProps<{
        id: string;
    }> {}

const SingleWorkshop: React.FC<ResultProps> = () => {
    const [clicked, setClicked] = useState(false);
    const [activeBrand, setActiveBrand]: any = useState(null);
    const [uuidData, setUuidData] = useState('');

    useEffect(() => {
        setActiveBrand('../../assets/nissan.svg');
    }, []);

    const currUrl = window.location.href;

    const urlParts = currUrl.split('/');
    const urlId = urlParts[urlParts.length - 1];

    const [workshopData, setWorkshopData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const selectedWorkshopDetails = JSON.parse(
        localStorage.getItem('selected-workshop-details') ?? ''
    );

    // const maintenanceDetails = JSON.parse(
    //     localStorage.getItem('maintenance-details') ?? '{}'
    // );
    // const discountPercentage =
    //     selectedWorkshopDetails?.data[0].percentage_saved;

    // const discountedWorkshopPrice =
    //     selectedWorkshopDetails?.data[0].discounted_workshop_price;

    const findWorkShop = useCallback(() => {
        setIsLoading(true);
        getSelectedWorkshopById(urlId).then((response) => {
            setWorkshopData(response.data);
            setIsLoading(false);
        });
    }, [urlId]);

    useEffect(() => {
        findWorkShop();
    }, [findWorkShop]);

    useEffect(() => {
        async function fetchUuid() {
            try {
                const data = await uuidFetch();
                setUuidData(data.uuid);
            } catch (error) {
                console.error('Something went wrong fetching the uuid:', error);
            }
        }
        fetchUuid();
    }, []);

    // const serviceDetails = JSON.parse(
    //     localStorage.getItem('servicesInfo') ?? ''
    // );

    // console.log('first', serviceDetails);

    // const details = JSON.parse(
    //     localStorage.getItem('details-evaluation') ?? ''
    // );

    // const vehicleInfomation = JSON.parse(
    //     localStorage.getItem('vehicle-details') ?? ''
    // );

    // const serviceInfomation = JSON.parse(
    //     localStorage.getItem('ServicePlan') ?? ''
    // );

    // const locationInfo = JSON.parse(localStorage.getItem('location') ?? '');

    // const areaCodeDetails = localStorage.getItem('areaCode') ?? '';




    return (
        <PageLayout animationData={dataAnimation}>
            {isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '75%',
                        mt: 16,
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {' '}
                    <HeaderText
                                headerText='WORKSHOPS'
                                headerImage={servisorIcon}
                            />
                    <Box
                        sx={{
                            overflow: 'auto',
                            height: '100%',
                            backgroundColor: '#EAEDF6',
                            paddingBottom: '100px',
                            paddingTop: {xs: '80px', lg: '100px'}
                        }}
                    >
                        <>




                            <Container
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    width: { xs: '90%', lg: '544px' },

                                }}
                                disableGutters
                            >


<Box sx={{ display: { xs: "none", lg: "flex" }, width: '544px',  flexDirection: 'column'  }} >
            <Box>
            <img src={personalTag} alt="/" /> 
            </Box>

            <Box >

<SubHeaderText headerText='Workshop details:' />
</Box>
</Box>
                            </Container>

                            <Container
                                disableGutters
                                sx={{
                                    paddingTop: '20px',
                                    width: { xs: '90%', lg: '544px' },
                                    display: 'flex',
                                    gap: '15px',
                                    flexDirection: 'column',
                                }}
                            >
                                <SingleAddressInfoCard
                                    workshop={workshopData}
                                />
                       

                             
                            </Container>

                         
                        </>
                    </Box>
                </>
            )}
        </PageLayout>
    );
};

export default SingleWorkshop;

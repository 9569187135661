import { base } from './api.service';

// ! PRODUCTION
const INJOZI_URL = `${base}/api/v2/servisor/ems`;


// ! LOCAL
// const INJOZI_URL = 'http://172.25.0.2:5000/api/v2/servisor/ems';
// const INJOZI_URL = 'https://servisor-staging.injozitech.com/api/v2/servisor/ems';

export const emsAuth = async () => {
    try {
        const response = await fetch(`${INJOZI_URL}/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorResponse = await response.json();
            throw errorResponse;
        }
    } catch (error) {
        throw error;
    }
};

export const emsGetVehicleMakes = async (token: any) => {
    try {
        const response = await fetch(`${INJOZI_URL}/get-makes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: token,
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorResponse = await response.json();
            throw errorResponse;
        }
    } catch (error) {
        throw error;
    }
};

export const emsGetVehicleModels = async (makeData: any) => {
    try {
        const response = await fetch(`${INJOZI_URL}/get-model`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: makeData,
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorResponse = await response.json();
            throw errorResponse;
        }
    } catch (error) {
        throw error;
    }
};

export const emsGetModelYears = async (modelData: any) => {
    try {
        const response = await fetch(`${INJOZI_URL}/get-model-years`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: modelData,
        });

        if (response.ok) {
            return await response.json();    
        } else {
            const errorResponse = await response.json();
            throw errorResponse;
        }
    } catch (error) {
        throw error;
    }
};

export const emsGetModelEngineData = async (modelData: any) => {
    try {
        const response = await fetch(`${INJOZI_URL}/get-model-engine`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: modelData,
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorResponse = await response.json();
            throw errorResponse;
        }
    } catch (error) {
        throw error;
    }
};
export const emsGetModelGearboxType = async (gearboxData: any) => {
    try {
        const response = await fetch(`${INJOZI_URL}/get-model-gearbox`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: gearboxData,
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorResponse = await response.json();
            throw errorResponse;
        }
    } catch (error) {
        throw error;
    }
};

export const emsGetModelGearboxDrive = async (gearboxDriveData: any) => {
    try {
        const response = await fetch(`${INJOZI_URL}/get-model-drive`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: gearboxDriveData,
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorResponse = await response.json();
            throw errorResponse;
        }
    } catch (error) {
        throw error;
    }
};

export const emsGetModelDetails = async (vehicleData: any) => {
    try {
        const response = await fetch(`${INJOZI_URL}/get-model-details`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: vehicleData,
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorResponse = await response.json();
            throw errorResponse;
        }
    } catch (error) {
        throw error;
    }
};

export const emsGetEstimateData = async (vehicleData: any) => {
    try {
        const response = await fetch(`${INJOZI_URL}/get-estimate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: vehicleData,
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorResponse = await response.json();
            throw errorResponse;
        }
    } catch (error) {
        throw error;
    }
};

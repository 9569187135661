import dataAnimation from "../../../assets/lottie/Servisor_02_Vehicle Details V2.json";
import NextIcon from "../../../assets/icons/ChevronRightFilled.svg";
import servisorIcon from "../../../assets/servisorWhiteLogo.png";
import kmIcon from "../../../assets/forms/kmIcon.png";
import vehicleTag from "../../../assets/forms/vehicleTag.svg";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import plusIcon from "../../../assets/forms/plus.svg";

import {
  customModel,
  getManualMakeApi,
  getManualModelApi,
  getModelYearsApi,
  customYear,
  customGear,
  calculateVehicleAge,
  getModelEngineDataApi,
  getModelGearboxTypeDataApi,
  getModelGearboxDriveTypeApi,
  getModelDetailsApi,
} from "../../../services/utilities/vehicleUtilities";
import {
  emsGetVehicleMakes,
  emsGetVehicleModels,
  emsGetModelYears,
  emsGetModelEngineData,
  emsGetModelGearboxType,
  emsGetModelGearboxDrive,
  emsGetModelDetails,
} from "../../../services/apis/ems.service";
import {
  TextField,
  Box,
  Container,
  Select,
  MenuItem,
  CircularProgress,
  Button,
  Typography,
  FormControl,
  BottomNavigation,
  Paper,
  InputAdornment,
} from "@mui/material";
import { HeaderText } from "../../../components/common/HeaderText";
import { SubHeaderText } from "../../../components/common/SubHeaderText";
import ProgressBar from "../../../components/common/ProgressBar";
import { PageLayout } from "../../../components/layout/PageLayout";
import IssueHelpButton from "../../../components/common/IssueHelpButton";
import Loader from "../../../components/Loading/Loading";
import { customerDataExtract } from "../../../services/apis/customer.service";

type VariantList = {
  umc: string;
  variant: string;
  kw: number;
  nm: number;
  cc2: string;
  model_class: string;
  [key: string]: any;
};

const ManualSearch: React.FC<any> = () => {
  // !EMS auth token
  const history = useHistory();
  const emsAuthToken = window.localStorage.getItem("ems-auth-token");

  const customerDetailsString =
    window.localStorage.getItem("details-evaluation") || "{}";
  const customerDetails = JSON.parse(customerDetailsString);

  const [clicked, setClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMake, setLoadingMake] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [loadingYears, setLoadingYears] = useState(false);
  const [loadingModelDetails, setLoadingModelDetails] = useState(false);
  const [loadingEngine, setLoadingEngine] = useState(false);
  const [loadingGearboxType, setLoadingGearboxType] = useState(false);
  const [loadingGearboxDrive, setLoadingGearboxDrive] = useState(false);
  const [vehiclereg, setVehiclereg]: any = useState("");
  const [vehicleMilleage, setVehicleMilleage]: any = useState(0);
  const [Makes, setMakes] = useState([]);
  const [CurrentMake, setCurrentMake] = useState("");
  const [Variants, setVariants] = useState([]);
  const [selectedRegistrationNumber, setSelectedRegistrationNumber] =
    useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedModelYear, setSelectedModelYear] = useState(0);
  const [selectedModelAge, setSelectedModelAge] = useState("");
  const [selectedEngine, setSelectedEngine]: any = useState({});
  const [engineList, setEngineList]: any = useState([]);
  const [selectedGearboxType, setSelectedGearboxType]: any = useState("");
  const [gearboxTypeList, setGearboxTypeList]: any = useState([]);
  const [selectedGearboxDrive, setSelectedGearboxDrive] = useState("");
  const [gearboxDriveList, setGearboxDriveList]: any = useState([]);
  const [modelDetailsList, setModelDetailsList]: any = useState([]);
  const [vehicleYears, setVehicleYears] = useState<number[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<VariantList | null>(
    null
  );
  const [isRestoring, setIsRestoring] = useState(true);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("modelMakeData") || "{}");

    if (savedData) {
      if (savedData.vehiclereg) setVehiclereg(savedData.vehiclereg);
      if (savedData.vehicleMilleage)
        setVehicleMilleage(savedData.vehicleMilleage);
      if (savedData.selectedRegistrationNumber)
        setSelectedRegistrationNumber(savedData.selectedRegistrationNumber);
      if (savedData.selectedModel) setSelectedModel(savedData.selectedModel);
      if (savedData.selectedModelYear)
        setSelectedModelYear(savedData.selectedModelYear);
      if (savedData.selectedModelAge)
        setSelectedModelAge(savedData.selectedModelAge);
      if (savedData.selectedEngine) setSelectedEngine(savedData.selectedEngine);
      if (savedData.selectedGearboxType)
        setSelectedGearboxType(savedData.selectedGearboxType);
      if (savedData.selectedGearboxDrive)
        setSelectedGearboxDrive(savedData.selectedGearboxDrive);
      if (savedData.selectedVariant)
        setSelectedVariant(savedData.selectedVariant);
      if (savedData.CurrentMake) setCurrentMake(savedData.CurrentMake);
    }
    setIsRestoring(false);
  }, []);

  useEffect(() => {
    if (!isRestoring) {
      const dataToSave = {
        vehiclereg,
        vehicleMilleage,
        selectedRegistrationNumber,
        selectedModel,
        selectedModelYear,
        selectedModelAge,
        selectedEngine,
        selectedGearboxType,
        selectedGearboxDrive,
        selectedVariant,
        CurrentMake,
      };
      localStorage.setItem("modelMakeData", JSON.stringify(dataToSave));
    }
  }, [
    vehiclereg,
    vehicleMilleage,
    selectedRegistrationNumber,
    selectedModel,
    selectedModelYear,
    selectedModelAge,
    selectedEngine,
    selectedGearboxType,
    selectedGearboxDrive,
    selectedVariant,
    CurrentMake,
    isRestoring,
  ]);

  useEffect(() => {
    if (!isRestoring) {
      const dataToSave = {
        vehiclereg,
        vehicleMilleage,
        selectedRegistrationNumber,
        selectedModel,
        selectedModelYear,
        selectedModelAge,
        selectedEngine,
        selectedGearboxType,
        selectedGearboxDrive,
        selectedVariant,
        CurrentMake,
      };
      localStorage.setItem("modelMakeData", JSON.stringify(dataToSave));

      // Prepare data to send to the API
      const dataToSend = {
        ...customerDetails,
        ...dataToSave,
      };

      Object.keys(dataToSend).forEach((key) => {
        if (dataToSend[key] == null) {
          delete dataToSend[key];
        }
      });

      const timeoutId = setTimeout(() => {
        customerDataExtract(dataToSend)
          .then((res) => {
            // console.log(res);
          })
          .catch((error) => {
            console.error("Error updating customer data: ", error);
          });
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [
    vehiclereg,
    vehicleMilleage,
    selectedRegistrationNumber,
    selectedModel,
    selectedModelYear,
    selectedModelAge,
    selectedEngine,
    selectedGearboxType,
    selectedGearboxDrive,
    selectedVariant,
    CurrentMake,
    isRestoring,
  ]);

  useEffect(() => {
    if (!emsAuthToken) {
      localStorage.removeItem("modelMakeData");
      history.push("/onboarding/1");
    } else {
      const bodyData = JSON.stringify({ access_token: emsAuthToken });
      getManualMakeApi(
        setMakes,
        setLoadingMake,
        setClicked,
        emsGetVehicleMakes,
        bodyData,
        history
      );
    }
  }, [emsAuthToken]);

  useEffect(() => {
    if (!isRestoring && CurrentMake) {
      const carMake = CurrentMake.toUpperCase();
      const bodyData = JSON.stringify({
        access_token: emsAuthToken,
        model: carMake,
      });

      getManualModelApi(
        setLoadingModel,
        setVariants,
        setClicked,
        emsGetVehicleModels,
        bodyData
      );
    }
  }, [emsAuthToken, CurrentMake, isRestoring]);

  useEffect(() => {
    if (!isRestoring && CurrentMake && selectedModel) {
      const bodyData = JSON.stringify({
        model: CurrentMake,
        desc: selectedModel,
      });

      getModelYearsApi(
        setLoadingYears,
        setClicked,
        setVehicleYears,
        emsGetModelYears,
        bodyData
      );
    }
  }, [selectedModel, CurrentMake, isRestoring]);

  useEffect(() => {
    if (!isRestoring && CurrentMake && selectedModel && selectedModelYear) {
      const bodyData = JSON.stringify({
        model: CurrentMake,
        desc: selectedModel,
        year: selectedModelYear,
      });

      getModelEngineDataApi(
        setLoadingEngine,
        setClicked,
        setEngineList,
        emsGetModelEngineData,
        bodyData,
        vehicleYears,
        selectedModelYear
      );

      calculateVehicleAge(selectedModelYear, setSelectedModelAge);
    }
  }, [selectedModelYear, CurrentMake, selectedModel, isRestoring]);

  useEffect(() => {
    if (
      !isRestoring &&
      CurrentMake &&
      selectedModel &&
      selectedModelYear &&
      selectedEngine &&
      selectedEngine.displacement &&
      selectedEngine.fuel_type
    ) {
      const bodyData = JSON.stringify({
        model: CurrentMake,
        desc: selectedModel,
        year: selectedModelYear,
        displacement: selectedEngine.displacement,
        fuel_type: selectedEngine.fuel_type,
      });

      getModelGearboxTypeDataApi(
        setLoadingGearboxType,
        setClicked,
        setGearboxTypeList,
        emsGetModelGearboxType,
        bodyData,
        vehicleYears,
        selectedModelYear
      );
    }
  }, [
    selectedEngine,
    CurrentMake,
    selectedModel,
    selectedModelYear,
    isRestoring,
  ]);

  useEffect(() => {
    if (
      !isRestoring &&
      CurrentMake &&
      selectedModel &&
      selectedModelYear &&
      selectedEngine &&
      selectedGearboxType
    ) {
      const bodyData = JSON.stringify({
        model: CurrentMake,
        desc: selectedModel,
        year: selectedModelYear,
        displacement: selectedEngine.displacement,
        fuel_type: selectedEngine.fuel_type,
        model_gearbox_types: selectedGearboxType,
      });

      getModelGearboxDriveTypeApi(
        setLoadingGearboxDrive,
        setClicked,
        setGearboxDriveList,
        emsGetModelGearboxDrive,
        bodyData,
        vehicleYears,
        selectedModelYear
      );
    }
  }, [
    selectedGearboxType,
    CurrentMake,
    selectedModel,
    selectedModelYear,
    selectedEngine,
    isRestoring,
  ]);

  useEffect(() => {
    if (
      !isRestoring &&
      CurrentMake &&
      selectedModel &&
      selectedModelYear &&
      selectedEngine &&
      selectedGearboxType &&
      selectedGearboxDrive
    ) {
      const bodyData = JSON.stringify({
        model: CurrentMake,
        desc: selectedModel,
        year: selectedModelYear,
        displacement: selectedEngine.displacement,
        fuel_type: selectedEngine.fuel_type,
        model_gearbox_types: selectedGearboxType,
        model_drive_types: selectedGearboxDrive,
      });

      getModelDetailsApi(
        setLoadingModelDetails,
        setClicked,
        setModelDetailsList,
        emsGetModelDetails,
        bodyData,
        vehicleYears,
        selectedModelYear
      );
    }
  }, [
    selectedGearboxDrive,
    selectedGearboxType,
    selectedEngine,
    selectedModelYear,
    selectedModel,
    CurrentMake,
    isRestoring,
  ]);

  const [registrationError, setRegistrationError] = useState("");
  const [mileageError, setMileageError] = useState("");
  const [makeError, setMakeError] = useState("");
  const [modelError, setModelError] = useState("");
  const [yearError, setYearError] = useState("");
  const [engineError, setEngineError] = useState("");

  const onSubmit = () => {
    history.push("/onboarding/3");
  };

  const yearRange = (years: number[]) => {
    if (years.length === 0) return [];

    const sortedYears = [...years].sort((a, b) => a - b);
    const firstYear = sortedYears[0];
    const lastYear = sortedYears[sortedYears.length - 1];
    const fullRange = [];

    for (let year = firstYear; year <= lastYear; year++) {
      fullRange.push(year);
    }

    return fullRange;
  };

  const getVariantDisplayName = (
    variant: VariantList,
    allVariants: VariantList[]
  ): string => {
    let displayName = variant.variant;

    const sameVariants = allVariants.filter(
      (v) => v.variant === variant.variant
    );

    if (sameVariants.length > 1) {
      const nmdiff = sameVariants.some((v) => v.nm !== variant.nm);

      if (nmdiff) {
        displayName += ` (${variant.nm} NM)`;
      }

      const cc2diff = sameVariants.some((v) => v.cc2 !== variant.cc2);

      if (cc2diff) {
        let modelCalss = variant.model_class;
        let modelClassParts = modelCalss.split("-");
        let getModelClass = modelClassParts[modelClassParts.length - 1].trim();
        displayName += `- (${getModelClass})`;
      }
    }

    return displayName;
  };

  return (
    <PageLayout animationData={dataAnimation}>
      <HeaderText headerText="VEHICLE DETAILS" headerImage={servisorIcon} />
      <Box
        sx={{
          backgroundColor: "#FFF",
          overflow: "auto",
          maxHeight: "calc(100vh - 64px)",
          paddingBottom: "100px",
          position: "relative",
          paddingTop: { xs: "50px", lg: "82px" },
          display: "flex",
          alignSelf: { xs: "normal", lg: "center" },
          flexDirection: { xs: "column", lg: "column" },
        }}
      >
        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            alignSelf: "center",
            width: { xs: "100%", lg: "70%" },
          }}
        >
          <img src={vehicleTag} alt="/" />
        </Box>

        <SubHeaderText headerText="Tell us a little more about the vehicle you’d like to have serviced" />

        <Container
          sx={{
            height: { xs: "100%", lg: "100%" },
            width: { xs: "90%", lg: "70%" },
            marginTop: { xs: "-10px", lg: "0" },

            borderRadius: { xs: "4px", lg: "4px" },
            backgroundColor: "#FFF",
            paddingX: { xs: "20px", lg: "0" },
          }}
          disableGutters
        >
          <Box
            sx={{
              display: { xs: "flex", lg: "none" },
              alignSelf: "left",
              width: { xs: "100%", lg: "70%" },
              paddingTop: { xs: "20px", lg: "0" },
            }}
          >
            <img src={vehicleTag} alt="/" />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "50px",
              width: "100%",
              paddingTop: "25px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
                width: { xs: "100%", lg: "90%" },
              }}
            >
              <Typography
                sx={{
                  color: "var(--gray-700, #344054)",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Vehicle Registration{" "}
              </Typography>

              <TextField
                InputProps={{
                  sx: { borderRadius: "8px" },
                }}
                placeholder="Vehicle Registration"
                fullWidth
                variant="outlined"
                type="text"
                name="vehiclereg"
                value={vehiclereg}
                onChange={(ev) => {
                  const reg = ev.target.value;
                  if (!/^[A-Za-z0-9- ]*$/.test(reg)) {
                    setRegistrationError("Vehicle Registration invalid.");
                  } else if (reg.length > 13) {
                    setRegistrationError("Vehicle Registration too long.");
                  } else if (reg.startsWith(" ")) {
                    setRegistrationError(
                      "Vehicle Registration cannot start with a space."
                    );
                  } else {
                    setRegistrationError("");
                  }
                  setVehiclereg(reg);
                  setSelectedRegistrationNumber(reg);
                }}
                error={Boolean(registrationError)}
                helperText={registrationError}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--gray-700, #344054)",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Mileage{" "}
              </Typography>

              <TextField
                InputProps={{
                  sx: {
                    borderRadius: "8px",
                    width: "50%",

                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    '& input[type="number"]': {
                      "-moz-appearance": "textfield",
                    },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={kmIcon} alt="/" />
                    </InputAdornment>
                  ),
                }}
                placeholder="80 000"
                type="number"
                name="vehicleMilleage"
                value={vehicleMilleage}
                onChange={(ev) => {
                  const newMileage = ev.target.value;

                  setVehicleMilleage(newMileage);
                  setMileageError("");
                }}
                error={Boolean(mileageError)}
                helperText={mileageError}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "100%",
              paddingTop: "25px",
            }}
          >
            <Box>
              {loadingMake ? (
                <CircularProgress />
              ) : (
                <FormControl fullWidth error={Boolean(makeError)}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--gray-700, #344054)",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Make{" "}
                    </Typography>

                    <Select
                      displayEmpty
                      IconComponent={(props) => (
                        <img src={plusIcon} alt="/" {...props} />
                      )}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      sx={{
                        textAlign: "start",
                        borderRadius: "8px",
                      }}
                      onChange={(ev: any) => {
                        Makes?.map((currentVehicle: any, index: number) => {
                          if (
                            currentVehicle.make_description === ev.target.value
                          ) {
                            const carMake =
                              currentVehicle.make_description.toUpperCase();
                            const bodyData = JSON.stringify({
                              access_token: emsAuthToken,
                              model: carMake,
                            });

                            getManualModelApi(
                              setLoadingModel,
                              setVariants,
                              setClicked,
                              emsGetVehicleModels,
                              bodyData
                            );
                            setCurrentMake(carMake);

                            setMakeError("");
                          }
                        });
                      }}
                      value={CurrentMake || ""}
                      renderValue={(selectedMake) => {
                        if (selectedMake === "") {
                          return <span>Select Make</span>;
                        } else {
                          return selectedMake;
                        }
                      }}
                    >
                      <MenuItem selected disabled>
                        Select Make
                      </MenuItem>
                      {Makes?.sort((a: any, b: any) =>
                        a.make_description.localeCompare(b.make_description)
                      ).map((currentVehicle: any, index: number) => (
                        <MenuItem
                          key={index}
                          value={currentVehicle.make_description}
                        >
                          {currentVehicle.make_description}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </FormControl>
              )}
            </Box>

            <Box>
              {loadingModel ? (
                <CircularProgress />
              ) : (
                <FormControl fullWidth error={Boolean(modelError)}>
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--gray-700, #344054)",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Model{" "}
                    </Typography>

                    <Select
                      displayEmpty
                      fullWidth
                      IconComponent={(props) => (
                        <img src={plusIcon} alt="/" {...props} />
                      )}
                      // label="Select Model"
                      // labelId="demo-simple-select-label"
                      // id="demo-simple-select"
                      // disabled={!Variants}
                      sx={{
                        textAlign: "start",
                        borderRadius: "8px",
                        "&& .Mui": {
                          color: "pink",
                        },
                      }}
                      onChange={(ev: any) => {
                        Variants?.map((currentVehicle: any, index: number) => {
                          if (currentVehicle === ev.target.value) {
                            const bodyData = JSON.stringify({
                              model: CurrentMake,
                              desc: currentVehicle,
                            });

                            getModelYearsApi(
                              setLoadingModel,
                              setClicked,
                              setVehicleYears,
                              emsGetModelYears,
                              bodyData
                            );

                            setSelectedModel(currentVehicle);

                            setModelError("");
                          }
                        });
                      }}
                      value={selectedModel || ""}
                      renderValue={(modelSelected) => {
                        if (modelSelected === "") {
                          return <span>Select Model</span>;
                        } else {
                          return modelSelected;
                        }
                      }}
                      placeholder={customModel.header}
                    >
                      <MenuItem selected disabled>
                        Select Model
                      </MenuItem>
                      {Variants?.sort((a: any, b: any) => a - b).map(
                        (currentVehicle: any, index: number) => {
                          return (
                            <MenuItem key={index} value={currentVehicle}>
                              {currentVehicle}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </Box>
                </FormControl>
              )}
            </Box>

            <Box>
              {loadingYears ? (
                <CircularProgress />
              ) : (
                <FormControl fullWidth error={Boolean(yearError)}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--gray-700, #344054)",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Year{" "}
                    </Typography>

                    <Select
                      displayEmpty
                      IconComponent={(props) => (
                        <img src={plusIcon} alt="/" {...props} />
                      )}
                      disabled={!Variants}
                      id="demo-simple-select"
                      sx={{
                        textAlign: "start",
                        borderRadius: "8px",
                      }}
                      value={selectedModelYear || ""}
                      onChange={(ev: any) => {
                        const selectedYear = parseInt(ev.target.value);
                        let closestYear = selectedYear;

                        if (!vehicleYears.includes(selectedYear)) {
                          const sortedYears = vehicleYears.sort(
                            (a, b) => a - b
                          );
                          const previousYears = sortedYears.filter(
                            (year) => year < selectedYear
                          );
                          closestYear =
                            previousYears[previousYears.length - 1] ||
                            sortedYears[0];
                        }

                        const bodyData = JSON.stringify({
                          model: CurrentMake,
                          desc: selectedModel,
                          year: closestYear,
                        });

                        getModelEngineDataApi(
                          setLoadingEngine,
                          setClicked,
                          setEngineList,
                          emsGetModelEngineData,
                          bodyData,
                          vehicleYears,
                          closestYear
                        );

                        setSelectedModelYear(closestYear);
                        calculateVehicleAge(closestYear, setSelectedModelAge);
                      }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <Typography>Select Year</Typography>;
                        }
                        return selected;
                      }}
                    >
                      <MenuItem selected disabled>
                        Select Year
                      </MenuItem>
                      {yearRange(vehicleYears)?.map((year, index) => (
                        <MenuItem key={index} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </FormControl>
              )}
            </Box>

            <Box>
              {loadingEngine ? (
                <CircularProgress />
              ) : (
                <FormControl fullWidth error={Boolean(engineError)}>
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--gray-700, #344054)",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Engine{" "}
                    </Typography>{" "}
                    <Select
                      IconComponent={(props) => (
                        <img src={plusIcon} alt="/" {...props} />
                      )}
                      disabled={engineList === 0}
                      displayEmpty
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      sx={{
                        textAlign: "start",
                        borderRadius: "8px",
                      }}
                      onChange={(event: any) => {
                        const newEngine = engineList.find(
                          (engine: any) =>
                            engine.displacement === event.target.value
                        );
                        if (
                          newEngine &&
                          newEngine.displacement !==
                            selectedEngine?.displacement
                        ) {
                          setSelectedEngine(newEngine);
                        }
                      }}
                      value={
                        selectedEngine &&
                        selectedEngine.display &&
                        selectedEngine.fuel_type
                          ? `${selectedEngine.display} L - ${selectedEngine.fuel_type}`
                          : ""
                      }
                      renderValue={(value: any) => {
                        if (value === "") {
                          return <span>Select Engine</span>;
                        } else {
                          const currentEngine = engineList.find(
                            (engine: any) => engine.displacement === value
                          );
                          if (currentEngine) {
                            return `${currentEngine.display} L - ${currentEngine.fuel_type}`;
                          } else {
                            return value;
                          }
                        }
                      }}
                      placeholder={customYear.header}
                    >
                      <MenuItem disabled>Select Engine</MenuItem>
                      {engineList
                        ?.sort((a: any, b: any) =>
                          a.displacement.localeCompare(b.displacement)
                        )
                        .map((currentVehicle: any, index: number) => {
                          return (
                            <MenuItem
                              key={index}
                              value={currentVehicle.displacement}
                            >
                              {`${currentVehicle.display} L - ${currentVehicle.fuel_type}`}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Box>
                </FormControl>
              )}

              {loadingGearboxDrive ? (
                <CircularProgress />
              ) : (
                <>
                  <Box pt={2}>
                    <FormControl fullWidth>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "var(--gray-700, #344054)",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          Gearbox Type{" "}
                        </Typography>
                        <Select
                          IconComponent={(props) => (
                            <img src={plusIcon} alt="/" {...props} />
                          )}
                          displayEmpty
                          // disabled={engineList === 0}
                          sx={{
                            textAlign: "start",
                            borderRadius: "8px",
                          }}
                          value={selectedGearboxType || ""}
                          onChange={(ev: any) => {
                            const currentVehicle = ev.target.value;
                            const bodyData = JSON.stringify({
                              model: CurrentMake,
                              desc: selectedModel,
                              year: selectedModelYear,
                              displacement: selectedEngine.displacement,
                              fuel_type: selectedEngine.fuel_type,
                              model_gearbox_types: currentVehicle,
                            });

                            getModelGearboxDriveTypeApi(
                              setLoadingGearboxDrive,
                              setClicked,
                              setGearboxDriveList,
                              emsGetModelGearboxDrive,
                              bodyData,
                              vehicleYears,
                              selectedModelYear
                            );
                            setSelectedGearboxType(currentVehicle);
                          }}
                          renderValue={(gearBoxTypeSelected) => {
                            if (gearBoxTypeSelected === "") {
                              return <span>Select Gear Type</span>;
                            } else {
                              return gearBoxTypeSelected;
                            }
                          }}
                          placeholder={customGear.header}
                        >
                          <MenuItem disabled>Select Gear Type</MenuItem>
                          {gearboxTypeList
                            ?.sort((a: any, b: any) => a.localeCompare(b))
                            .map((currentVehicle: any, index: any) => (
                              <MenuItem key={index} value={currentVehicle}>
                                {currentVehicle}
                              </MenuItem>
                            ))}
                        </Select>
                      </Box>
                    </FormControl>
                  </Box>
                  {loadingGearboxType ? (
                    <CircularProgress />
                  ) : (
                    <Box pt={2}>
                      <FormControl fullWidth>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "2px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--gray-700, #344054)",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            Gearbox Drive type{" "}
                          </Typography>{" "}
                          <Select
                            IconComponent={(props) => (
                              <img src={plusIcon} alt="/" {...props} />
                            )}
                            displayEmpty
                            // disabled={gearboxTypeList.length === 0}
                            sx={{
                              textAlign: "start",
                              borderRadius: "8px",
                            }}
                            onChange={(ev: any) => {
                              const currentVehicle = ev.target.value;

                              const bodyData = JSON.stringify({
                                model: CurrentMake,
                                desc: selectedModel,
                                year: selectedModelYear,
                                displacement: selectedEngine.displacement,
                                fuel_type: selectedEngine.fuel_type,
                                model_gearbox_types: selectedGearboxType,
                                model_drive_types: currentVehicle,
                              });

                              getModelDetailsApi(
                                setLoadingModelDetails,
                                setClicked,
                                setModelDetailsList,
                                emsGetModelDetails,
                                bodyData,
                                vehicleYears,
                                selectedModelYear
                              );
                              setSelectedGearboxDrive(currentVehicle);
                            }}
                            value={selectedGearboxDrive || ""}
                            renderValue={(gearBoxTypeSelected) => {
                              if (gearBoxTypeSelected === "") {
                                return <span>Select Gearbox Drive Type</span>;
                              } else {
                                return gearBoxTypeSelected;
                              }
                            }}
                            placeholder={customGear.header}
                          >
                            <MenuItem disabled>
                              Select Gearbox Drive Type
                            </MenuItem>
                            {gearboxDriveList
                              ?.sort((a: any, b: any) => a.localeCompare(b))
                              .map((currentVehicle: any, index: number) => (
                                <MenuItem key={index} value={currentVehicle}>
                                  {currentVehicle}
                                </MenuItem>
                              ))}
                          </Select>
                        </Box>
                      </FormControl>
                    </Box>
                  )}
                  {loadingModelDetails ? (
                    <CircularProgress />
                  ) : (
                    <Box pt={2}>
                      <FormControl fullWidth>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "2px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--gray-700, #344054)",
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            Variant{" "}
                          </Typography>{" "}
                          <Select
                            IconComponent={(props) => (
                              <img src={plusIcon} alt="/" {...props} />
                            )}
                            displayEmpty
                            value={selectedVariant?.umc || ""}
                            onChange={(event) => {
                              const selectedVariant = modelDetailsList.find(
                                (variant: any) =>
                                  variant.umc === event.target.value
                              );
                              setSelectedVariant(selectedVariant || {});
                              localStorage.setItem(
                                "vehicle-details",
                                JSON.stringify({
                                  ...selectedVariant,
                                  vehicle_make: CurrentMake,
                                  displayDisplacement: selectedEngine.display,
                                  vehicleMilleage: vehicleMilleage,
                                  registration_number: vehiclereg,
                                  age: selectedModelAge,
                                })
                              );
                            }}
                            renderValue={(selectedUmc) => {
                              if (selectedUmc === "") {
                                return <span>Select Variant</span>;
                              } else {
                                const umcVariant = modelDetailsList.find(
                                  (v: VariantList) => v.umc === selectedUmc
                                );
                                if (umcVariant) {
                                  return getVariantDisplayName(
                                    umcVariant,
                                    modelDetailsList
                                  );
                                } else if (selectedVariant) {
                                  return getVariantDisplayName(
                                    selectedVariant,
                                    [selectedVariant]
                                  );
                                } else {
                                  return <span>Select Variant</span>;
                                }
                              }
                            }}
                            sx={{
                              textAlign: "start",
                              borderRadius: "8px",
                            }}
                          >
                            <MenuItem disabled>Select Variant</MenuItem>
                            {modelDetailsList
                              ?.sort((a: any, b: any) => a - b)
                              .map((currentVehicle: any, index: number) => (
                                <MenuItem
                                  key={index}
                                  value={currentVehicle.umc}
                                >
                                  {/* {currentVehicle.variant} */}
                                  {getVariantDisplayName(
                                    currentVehicle,
                                    modelDetailsList
                                  )}
                                </MenuItem>
                              ))}
                          </Select>
                        </Box>
                      </FormControl>
                    </Box>
                  )}
                </>
              )}
            </Box>
            <IssueHelpButton />

            <Paper
              sx={{
                position: { xs: "fixed", lg: "fixed" },
                bottom: 0,
                right: 0,
                zIndex: "99",
                paddingY: "20px",
                display: "flex",
                borderRadius: " 0",
                alignItems: "center",
                justifyContent: "center",

                width: { xs: "100%", lg: "50%" },
              }}
              elevation={0}
            >
              <BottomNavigation
                sx={{
                  width: { xs: "100%", lg: "70%" },
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  py={3}
                  sx={{
                    display: { xs: "flex" },
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box>
                    <Button
                      onClick={() => history.push("/onboarding/1")}
                      sx={{
                        padding: "0",
                        margin: "0",
                        display: "flex",
                        borderRadius: "0",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={NextIcon}
                          alt="/"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            transform: "rotate(180deg)",
                          }}
                        />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: "700",
                          color: "#101828",
                        }}
                      >
                        Back
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ProgressBar value={50} />
                  </Box>
                  <Box>
                    <Button
                      disabled={
                        !vehiclereg ||
                        !vehicleMilleage ||
                        !selectedGearboxType ||
                        !selectedGearboxDrive ||
                        !selectedVariant ||
                        !!registrationError
                      }
                      onClick={() => onSubmit()}
                      sx={{
                        padding: "0",
                        margin: "0",
                        display: "flex",
                        borderRadius: "0",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: "700",
                          color:
                            !vehiclereg ||
                            !vehicleMilleage ||
                            !selectedGearboxType ||
                            !selectedGearboxDrive ||
                            !selectedVariant ||
                            !!registrationError
                              ? "grey"
                              : "#101828",
                        }}
                      >
                        Next
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={NextIcon}
                          alt="/"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        />
                      </Box>{" "}
                    </Button>
                  </Box>
                </Box>
              </BottomNavigation>
            </Paper>
          </Box>
        </Container>
      </Box>
      {isLoading && <Loader />}
    </PageLayout>
  );
};

export default ManualSearch;

import { createContext, useState , useContext} from "react";

/***
 * This is the main file that is used to provide global state data throughout our codebase
 */
type GlobalContextType = {
  vehicleDetails: any,
  setVehicleDetails: React.Dispatch<React.SetStateAction<any>>,
  priceState: any,
  setPriceState: React.Dispatch<React.SetStateAction<any>>,
  servicesInfo: any,
  setServicesInfo: React.Dispatch<React.SetStateAction<any>>,
  marketPriceState: any,
  setMarketPriceState: React.Dispatch<React.SetStateAction<any>>,
  areaCodeState: any,
  setAreaCodeState: React.Dispatch<React.SetStateAction<any>>,
  milleage: any,
  setMilleage: React.Dispatch<React.SetStateAction<any>>,
  registrationNumber: any,
  setRegistrationNumber: React.Dispatch<React.SetStateAction<any>>,
  bookingId: any,
  setbookingId: React.Dispatch<React.SetStateAction<any>>,
  authorization: any,
  setAuthorization: React.Dispatch<React.SetStateAction<any>>,
  userDetailState: any,
  setUserDetailState: React.Dispatch<React.SetStateAction<any>>,
  workShopName: any,
  setworkShopName: React.Dispatch<React.SetStateAction<any>>,
  workShopEmail: any,
  setWorkShopEmail: React.Dispatch<React.SetStateAction<any>>,
  distance: any,
  setDistance: React.Dispatch<React.SetStateAction<any>>,
  locations: any,
  setLocations: React.Dispatch<React.SetStateAction<any>>,
  hasServicePlan: any,
  setHasServicePlan: React.Dispatch<React.SetStateAction<any>>,
  
}

const GlobalContext = createContext({} as GlobalContextType)

export const GlobalContextProvider = ({children} : any) => {
 
  const [vehicleDetails, setVehicleDetails] = useState<any>();
  const [priceState, setPriceState] = useState<any>();
  const [servicesInfo, setServicesInfo] = useState<any>();
  const [marketPriceState,setMarketPriceState] = useState<any>();
  const [areaCodeState, setAreaCodeState] = useState<any>();
  const [milleage, setMilleage] = useState<any>();
  const [registrationNumber, setRegistrationNumber] = useState<any>();
  const [bookingId, setbookingId] = useState<any>();
  const [authorization, setAuthorization] = useState<any>();
  // Onboarding
  const [userDetailState, setUserDetailState] = useState<any>();
  const [workShopName, setworkShopName] = useState<any>();
  const [workShopEmail, setWorkShopEmail] = useState<any>();
  const [distance, setDistance] = useState<any>();
  const [locations, setLocations] = useState<any>();
  const [hasServicePlan, setHasServicePlan] = useState<any>();
  
  const contextValue = {
    vehicleDetails, setVehicleDetails,
    priceState, setPriceState,
    servicesInfo, setServicesInfo,
    marketPriceState, setMarketPriceState,
    areaCodeState, setAreaCodeState,
    milleage, setMilleage,
    registrationNumber, setRegistrationNumber,
    bookingId, setbookingId,
    authorization, setAuthorization,
    userDetailState, setUserDetailState,
    workShopName, setworkShopName,
    workShopEmail, setWorkShopEmail,
    distance, setDistance,
    locations, setLocations,
    hasServicePlan,setHasServicePlan
  };

  return ( <GlobalContext.Provider value={contextValue}> {children} </GlobalContext.Provider> )
}

export const useGlobalContext = () =>{
  return(useContext(GlobalContext))
} 

export class VehicleDetailsModel {
  MMCode: string;
  Manufacturer: string;
  Make: string;
  Model: string;
  Variant: string;
  Year: string;
  Mileage: number;
  Condition: string;
  IsManualVehicle: boolean;
  Reg: string;
  Vin: string;
  Engine: string;
  Add_extras: string;
  Colour: string;
  RegistrationNumber: string;
  VinNumber: string;
  EngineNumber: string;
  IsImageUploaded: boolean;
  AverageMonthlyKilometers: number;
  IsUserVehicle: boolean;
  DiscDate: string;
  Base64Image: string;

  constructor(obj: any){
    this.MMCode = obj.MMCode || "";
    this.Manufacturer = obj.Manufacturer || "";
    this.Make = obj.Make || "";
    this.Model = obj.Model || "";
    this.Variant = obj.Variant || "";
    this.Year = obj.Year || "";
    this.Mileage = obj.Mileage || 0;
    this.Condition = obj.Condition || "E";
    this.IsManualVehicle = obj.IsManualVehicle || true;
    this.Reg = obj.Reg || "";
    this.Vin = obj.Vin || "";
    this.Engine = obj.Engine || "";
    this.Add_extras = obj.Add_extras || "";
    this.Colour = obj.Colour || "";
    this.RegistrationNumber = obj.RegistrationNumber || "";
    this.VinNumber = obj.VinNumber || "";
    this.EngineNumber = obj.EngineNumber || "";
    this.IsImageUploaded = obj.IsImageUploaded || false;
    this.AverageMonthlyKilometers = obj.AverageMonthlyKilometers || 0;
    this.IsUserVehicle = obj.IsUserVehicle || false;
    this.DiscDate = obj.DiscDate || "";
    this.Base64Image = obj.Base64Image || "";
  }
}
import { Box, Container, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { ImageContainer } from "../common/ImageContainer";
import Lottie from "react-lottie";



export const PageLayout = ({step , children, animationData} : any) => {


  return (
    <Container disableGutters maxWidth={false} sx={{display: 'flex', flexDirection: 'row', width: '100%', height: '100vh', backgroundColor: '#FFF'}}> 

        <Box sx={{width:  '50%', display: {xs: 'none', lg: 'flex'}}}>
        <ImageContainer animationData={animationData}/>

        </Box>

        <Box sx={{width: {xs:'100%', lg:'50%'}}}>
            {children}
        </Box>

    </Container>
  );
};
